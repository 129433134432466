<template>
    <div>

        <ListView title="Service Prices List" :collection="collection" @pageChanged='pageChanged' :fields="fields" :loading="loading"  :modalObject='currentModalObject' :hasFilterActions='true'>
            <template v-slot:actions>
                <b-button v-if='can("service_prices.import")' @click="modal('importPricesForm', {}, 'refresh')" variant='primary' class='mr-2'>
                    Import Prices
                </b-button>
            </template>

            <template v-slot:filter>
                <div class='form'>
                    <b-form-row>
                        <b-col cols=3>

                            <b-form-group>
                                <b-form-checkbox
                                    v-model="filter.no_insurance_provider"
                                    value="1"
                                    unchecked-value="0"
                                    :inline='true'
                                >
                                    Private
                                </b-form-checkbox>
                            </b-form-group>
                        </b-col>
                    </b-form-row>
                    <b-form-row>
                        <b-col cols=3>
                            <b-form-group label="Insurance">
                                <InsuranceProvidersSelect v-model='filter.insurance_provider_id' :returnId='true'/>
                            </b-form-group>
                        </b-col>
                        <b-col cols=3>
                            <b-form-group label="Branch">
                                <branches-select v-model='filter.branch_id' :returnId='true'/>
                            </b-form-group>
                        </b-col>
                        <b-col cols=3>
                            <b-form-group label="Section">
                                <SectionsSelect v-model='filter.section_id' :returnId='true'/>
                            </b-form-group>
                        </b-col>
                        <b-col cols=3>
                            <b-form-group label="Services">
                                <ServicesSelect v-model='filter.service_id' :section_id='filter.section_id ? filter.section_id : null' :returnId='true'/>
                            </b-form-group>
                        </b-col>
                    </b-form-row>
                </div>
            </template>

            <template v-slot:filter_actions>
                <authorized-url v-if='can("service_prices.export")' class='btn btn-primary float-right' url='service-prices/export' :params='filter' target="_blank">
                    <i class="fa fa-file-excel"></i>
                    Export
                </authorized-url>
            </template>
        </ListView>
    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import AuthorizedUrl from '../../components/AuthorizedUrl.vue';
import BranchesSelect from '../../components/BranchesSelect.vue';

export default {
  components: { AuthorizedUrl, BranchesSelect },
    extends: BaseList,
    methods: {
        load: function (apiClient, cancelToken) {
            return apiClient.get('service-prices', this.filter, cancelToken);
        }
    },
    data: function() {
        return {
            service: null,
            section: null,
            insuranceProvider: null,
            fields: [
                // { key: 'id', label: '#' },
                { key: 'service.section.name', label: 'Section' },
                { key: 'service.name', label: 'Service' },
                { key: 'price', label: 'Price' },
                { key: 'branch.name', label: 'Branch' },
                { key: 'insurance_provider.name', label: 'Insurance' },
                // { key: 'actions', label: 'Actions' }
            ]
        };
    },
    watch: {
        filter: {
            handler: function () {
                if (this.filter.section && this.filter.service) {
                    if (this.filter.section.id != this.filter.service.section_id) {
                        this.filter.service_id = null;
                    }
                }
            },
            deep: true
        }
    }
}
</script>