<template>
    <div>
        <loader v-if='loading' />
        <b-form v-if="!loading" class='basic-form' @submit="submit" novalidate>

            <b-form-group label="Revenue Account">
                <financial-accounts-select :returnId="true" balance_type="CREDIT" v-model="defaultAccounts.revenue" />
            </b-form-group>

            <b-form-group label="Inusrance Revenue Account">
                <financial-accounts-select :returnId="true" balance_type="CREDIT"
                    v-model="defaultAccounts.insurance_revenue" />
            </b-form-group>

            <b-form-group label="Account Receivable Parent">
                <financial-accounts-select :returnId="true" balance_type="DEBIT"
                    v-model="defaultAccounts.account_receivable" />
            </b-form-group>

            <b-form-group label="Account Payable Parent">
                <financial-accounts-select :returnId="true" balance_type="CREDIT"
                    v-model="defaultAccounts.account_payable" />
            </b-form-group>

            <b-form-group label="Expenses">
                <financial-accounts-select :returnId="true" balance_type="DEBIT" v-model="defaultAccounts.expenses" />
            </b-form-group>

            <b-form-group label="COGS">
                <financial-accounts-select :returnId="true" balance_type="DEBIT" v-model="defaultAccounts.cogs" />
            </b-form-group>

            <b-form-group label="Inventory COGS">
                <financial-accounts-select :returnId="true" balance_type="DEBIT" v-model="defaultAccounts.inventory_cogs" />
            </b-form-group>

            <b-form-group label="Inventory">
                <financial-accounts-select :returnId="true" balance_type="DEBIT" v-model="defaultAccounts.inventory" />
            </b-form-group>


            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>
            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
import FinancialAccountsSelect from './FinancialAccountsSelect.vue';

export default {
    components: { FinancialAccountsSelect },
    extends: BaseForm,
    data: function () {
        return {
            defaultAccounts: {}
        }
    },
    mounted() {
        this.loading = true;
        this.load().then(() => this.loading = false);
    },
    methods: {
        load: function () {
            return this.apiRequest(apiClient => apiClient.get('default-accounts'))
                .then(response => {
                    if (response && !Array.isArray(response)) {
                        this.defaultAccounts = response
                    }
                });
        },
        save: function (apiClient) {
            return apiClient.post('default-accounts', this.defaultAccounts);
        },
        afterSave: function () {
            this.$emit('saved');
        },
    },
}
</script>