<template>
    <div>
        <loader v-if='loading'/>
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>
            <b-form-group label="Name">
                <b-form-input v-model="branch.name" :state="states.name" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.name'/>
            </b-form-group>
            <b-form-group label="Inventory">
                <inventories-select v-model='inventory'/>
                <FormGroupError :errors='errors.inventory_id'/>
            </b-form-group>
            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
    </div>

</template>

<script>
import BaseForm from '@/views/BaseForm'
import InventoriesSelect from '@/components/InventoriesSelect'

export default {
    extends: BaseForm,
    components: {
        InventoriesSelect
    },
    data: function () {
        return {
            branch: {
            },
            inventory: null
        }
    },
    methods: {
        load: function(id) {
            return this.apiRequest(apiClient => apiClient.get('branches/'+id))
                .then(response => {
                    this.branch = response;
                    this.inventory = response.inventory;
                });
        },
        save: function (apiClient) {
            let branch = {
                name: this.branch.name,
                inventory_id: this.inventory.id
            };
            if (this.id) {
                return apiClient.put('branches/'+this.id, branch);
            } else {
                return apiClient.post('branches', branch);
            }
        },
        afterSave: function () {
            this.$emit('saved', 1);
        }
    },
}
</script>