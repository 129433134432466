<template>
    <div>
        <ListView title="Patients List" :collection="collection"  @pageChanged='pageChanged' :fields="fields" :loading="loading">
            <template v-slot:actions>
                <router-link :to="{name: 'CreatePatient'}" class="btn btn-primary btn-sm">
                    <i class="fa fa-plus"></i>
                    New Patient
                </router-link>
            </template>

            <template v-slot:filter>
                <div class='form'>
                    <div class='form-row'>
                        <div class='form-group col-md-4'>
                            <b-form-input v-model="filter.name" placeholder="#ID - name - phone"></b-form-input>
                        </div>
                    </div>
                    <div>
                        <b-form-group label="Date Range">
                            <my-date-range-picker v-model='dateRange' :style='{display:"inline-block"}'/>
                            <div class='btn btn-danger btn-xxs ml-2' @click='() => dateRange = {startDate: null, endDate: null}'>x</div>
                        </b-form-group>
                    </div>
                    <div class='form-row'>
                        <b-col>
                            <b-form-group label="Date Type">
                                <b-form-radio-group
                                    v-model="filter.date_type"
                                    :options="['insurance_approval', 'patient_service', 'appointments', 'patients']"
                                ></b-form-radio-group>
                            </b-form-group>
                        </b-col>
                    </div>
                    <div class='form-row'>
                        <div class='col-3'>
                            <div class='form-group m-2'>
                                <label>Branch</label>
                                <BranchesSelect v-model='filter.branch_id' :returnId='true'/>
                            </div>
                        </div>
                        <div class='col-3'>
                            <div class='form-group m-2'>
                                <label>Insurance</label>
                                <insurance-providers-select v-model='filter.insurance_provider_id' :returnId='true'/>
                            </div>
                        </div>
                        <div class='col-3'>
                            <div class='form-group m-2'>
                                <label>Section</label>
                                <SectionsSelect v-model='filter.section_id' :returnId='true'/>
                            </div>
                        </div>
                    </div>
                    <div class='form-row'>
                        <b-col>
                            <b-form-group label="Status">
                                <b-form-radio-group
                                    v-model="filter.patient_status"
                                    :options="['all', 'new', 'active', 'inactive']"
                                ></b-form-radio-group>
                            </b-form-group>
                        </b-col>
                    </div>
                    <div class='form-row'>
                        <b-col>
                            <b-form-group label="Insurance Status">
                                <b-form-radio-group
                                    v-model="filter.insurance_status"
                                    :options="['all', 'insured', 'private']"
                                ></b-form-radio-group>
                            </b-form-group>
                        </b-col>
                    </div>

                    <div>
                        <b-form-group label="Insurance Expiry Date Range">
                            <my-date-range-picker v-model='insuranceDateRange' :style='{display:"inline-block"}'/>
                            <div class='btn btn-danger btn-xxs ml-2' @click='() => insuranceDateRange = {startDate: null, endDate: null}'>x</div>
                        </b-form-group>
                    </div>
                    <div class="form-row">
                        <b-form-checkbox
                            v-model="filter.unpaid"
                            value="1"
                            unchecked-value="0"
                            :inline='true'
                        >
                            Unpaid
                        </b-form-checkbox>
                        <b-form-checkbox
                            v-model="filter.paid"
                            value="1"
                            unchecked-value="0"
                            :inline='true'
                        >
                            Payed
                        </b-form-checkbox>
                        
                        <b-form-checkbox
                            v-model="filter.non_approved_services"
                            value="1"
                            unchecked-value="0"
                            :inline='true'
                        >
                            Needs Insurance Approval
                        </b-form-checkbox>
                        
                        <b-form-checkbox
                            v-model="filter.no_next_appointment"
                            value="1"
                            unchecked-value="0"
                            :inline='true'
                        >
                            Needs Reservation
                        </b-form-checkbox>
                        
                        <b-form-checkbox
                            v-model="filter.unconfirmed_appointments"
                            value="1"
                            unchecked-value="0"
                            :inline='true'
                        >
                            Unconfirmed Reservations
                        </b-form-checkbox>

                        <b-form-checkbox
                            v-model="filter.confirmed_appointments"
                            value="1"
                            unchecked-value="0"
                            :inline='true'
                        >
                            Confirmed Reservations
                        </b-form-checkbox>
                    </div>
                </div>
            </template>

			<template v-slot:cell(name)="data">
				
            	<router-link :to="{ name: 'ViewPatient', params: {id: data.item.id}}" class=''>
					<h5>
					{{ data.item.name }}
					</h5>
                    <div class='badge badge-light' v-if='can("patients.view_contact_details")'>
                        <i class='fa fa-phone'></i> {{ data.item.phone }}
                    </div>
				</router-link>
			</template>

            <template v-slot:cell(balance)='data'>
                <div v-for='(financialAccount, index) in data.item.financial_accounts' :key='index'>
                    <div class='badge badge-light'>
                        {{ financialAccount.branch ? financialAccount.branch.name : '' }}
                    </div>
                    <div v-if='financialAccount.balance < 0' class='badge badge-danger'>
                        {{ financialAccount.balance | currency}}
                    </div>
                    <div v-if='financialAccount.balance > 0' class='badge badge-success'>
                        {{ financialAccount.balance | currency}}
                    </div>
                    <div v-if='financialAccount.balance == 0' class='badge badge-light'>
                        {{ 0 | currency }}
                    </div>
                </div>
            </template>
            <template v-slot:cell(appointments)='data'>
                <div v-for='(appointment, index) in data.item.active_appointments' :key='index'  @click="modal('editAppointmentForm', {id: appointment.id}, 'refresh')" >
                    <div class='badge badge-success badge-sm m-2'>
                        <i class='fa fa-calendar'></i>
                        {{ appointment.datetime | datetime }}
                    </div>
                    <div v-if='appointment.confirmed_at' class='badge badge-success badge-sm m-2'>
                        <i class='fa fa-check'></i>
                        confirmed at:
                        {{ appointment.confirmed_at | datetime }}
                    </div>
                    <div v-else  class='badge badge-danger badge-sm m-2'>
                        not confirmed
                    </div>
                </div>
                <span v-if='!data.item.active_appointments.length' class='badge badge-light'>No reservations</span>

            </template>

            <template v-slot:cell(last_appointment)='data'>
                <div v-if='data.item.last_appointment' class='badge badge-success badge-sm m-2' @click="modal('editAppointmentForm', {id: data.item.last_appointment.id}, 'refresh')">
                    <i class='fa fa-calendar'></i>
                    {{ data.item.last_appointment.datetime | datetime }}
                </div>
                <span v-else class='badge badge-light'>No visit before</span>

            </template>

            <template v-slot:cell(services)="data">
                
                <div v-for='(patientService, index) in data.item.unpaid_services' :key='index' >
                    
                    <div class='mb-3'>
                        <span class='badge badge-success badge-sm m-2' 
                            :class="serviceStatusBadges[patientService.status]"
                            :title='patientService.status'
                            v-if='can("patient_services.edit")' @click="modal('patientServiceForm', {id: patientService.id}, 'refresh')"
                        >
                            <i class='fa fa-stethoscope'></i>
                            {{ patientService.service.name | str_limit(40) }}
                        </span>
                        <span v-if='patientService.remaining_appointments_count' class='badge badge-warning badge-sm m-2'>
                            {{ patientService.remaining_appointments_count }} remaining
                        </span>
                        <span v-if='patientService.finished_appointments_count' class='badge badge-success badge-sm m-2'>
                            <i class='fa fa-check'></i>
                            {{ patientService.finished_appointments_count }} finished
                        </span>
                        <div class='badge badge-danger m-2'>
                            remaining:
                            {{ patientService.unpaid_transactions[0].amount_remaining }}
                        </div>
                        <div class='badge badge-light m-2'>
                        {{ patientService.unpaid_transactions[0].amount_paid }}
                        /
                        {{ patientService.unpaid_transactions[0].amount }}
                        </div>
                    </div>
                </div>
                
                <div v-for='(patientService, index) in data.item.active_services' :key='"b"+index' >
                    <span class='badge badge-success badge-sm m-2' 
                        :class="serviceStatusBadges[patientService.status]"
                        :title='patientService.status'
                        v-if='can("patient_services.edit")' @click="modal('patientServiceForm', {id: patientService.id}, 'refresh')"
                    >
                        <i class='fa fa-stethoscope'></i>
                        {{ patientService.service.name | str_limit(40) }}
                    </span>
                    <span v-if='patientService.insurance_approval' class='badge badge-light'>approval #{{ patientService.insurance_approval.id }}</span>
                    <span v-else-if='patientService.insurance_provider_id' class='badge badge-danger'>no approval</span>
                    <span v-if='patientService.remaining_appointments_count' class='badge badge-warning badge-sm m-2'>
                        {{ patientService.remaining_appointments_count }} remaining
                    </span>
                    <span v-if='patientService.finished_appointments_count' class='badge badge-success badge-sm m-2'>
                        <i class='fa fa-check'></i>
                        {{ patientService.finished_appointments_count }} finished
                    </span>

                </div>
            </template>
            
            <template v-slot:cell(insurance)='data'>

                <div v-for='(patientInsurance, index) in data.item.active_patient_insurances' :key='"a"+index' >
                    <span class='badge badge-success badge-sm m-2' 
                        :class='patientInsurance.is_active =="pending" ? "badge-warning" : "badge-success"'
                    >
                        <i class='fas fa-info'></i>
                        {{ patientInsurance.insurance_provider.name }}
                    </span>
                    <span class='badge badge-success badge-sm m-2'>
                        expire at:
                        {{ patientInsurance.expire_at | date }}
                    </span>
                    <hr>
                </div>
                <div v-for='(insuranceApproval, index) in data.item.active_insurance_approvals' :key='index' >
                    <span class='badge badge-success badge-sm m-2' 
                        :class='insuranceApproval.status =="pending" ? "badge-warning" : "badge-success"'
                        :title='insuranceApproval.status'
                        @click="modal('insuranceApprovalForm', {id: insuranceApproval.id}, 'refresh')"
                    >
                        <i class='fas fa-clipboard-list'></i>
                        approval #{{ insuranceApproval.id }}
                        {{ insuranceApproval.insurance_provider.name }}
                    </span>
                </div>
            </template>
            <template v-slot:cell(actions)='data'>
                <button v-if='can("appointments.create")' class='btn btn-success btn-xs m-1'  @click="modal('appointmentForm', {patientId: data.item.id, doctorId: getDoctorId(data.item), branchId: getBranchId(data.item)}, 'refresh')">
                    <i class='fa fa-calendar'></i>    
                    New Appointment
                </button>

                <button v-if='can("insurance_approvals.create") && data.item.is_insured && patientInsuranceId(data.item)' class='btn btn-primary btn-xs m-1' @click="modal('servicesInsuranceForm', {patientId: data.item.id, patientInsuranceId: patientInsuranceId(data.item)}, 'refresh')">+ New Insurance Approval</button>
                <button v-if='can("patients.add_payment")' class='btn btn-primary btn-xs m-2' @click="modal('cashPaymentForm', {entityType: 'Patient', transactionIds:transactionIds(data.item), entityId: data.item.id, branchId:patientBranchId(data.item)}, 'refresh')"><i class='fa fa-credit-card'></i> New Payment</button>
                <br>
                <router-link :to="{ name: 'EditPatient', params: {id: data.item.id}}" class='btn btn-success btn-xs m-1'><i class='fa fa-edit'></i> Edit</router-link>
            </template>
                    
        </ListView>
        
        <Modals :modalName='currentModal' :modalData='currentModalData' @saved='modalCallback' @closed='modalClosed'>
        </Modals>
    </div>
</template>

<script>
import BaseList from '@/views/BaseList';

export default {
    extends: BaseList,
    props: ['pushHistory'],
    methods: {
        load: function (apiClient, cancelToken) {
            return apiClient.get('patients', {...this.filter, group_by_section: 1, with_details: 1}, cancelToken);
        },
        patientPaymentSaved() {
            this.$bvModal.hide('patient-payment-modal');
            this.refresh();
        },
        patientServiceSaved() {
            this.$bvModal.hide('patient-service-modal');
            this.refresh();
        },
        serviceInsuranceSaved() {
            this.$bvModal.hide('service-insurance-modal');
            this.refresh();
        },
		resolveQuery: function (query) {
			this.filter = query;
		},
        getDoctorId: function (patientSection) {
            if (patientSection.active_services.length == 0) {
                return null;
            }

            return patientSection.active_services[0].doctor_id;
        },
        getBranchId: function (patientSection) {
            if (patientSection.active_services.length == 0) {
                return null;
            }

            return patientSection.active_services[0].branch_id;
        },
        patientInsuranceId: function (patientSection) {
            if (!patientSection.active_services) {
                return null;
            }

            for (var i=0; i < patientSection.active_services.length; i++) {
                if (patientSection.active_services[i].insurance_provider_id && !patientSection.active_services[i].insurance_approval_id) {
                    return patientSection.active_services[i].patient_insurance_id;
                }
            }
        },
        patientBranchId: function (patientSection) {
            if (!patientSection.unpaid_services) {
                return null;
            }
            
            for (var i=0; i < patientSection.unpaid_services.length; i++) {
                return patientSection.unpaid_services[i].branch_id;
            }
        },
        transactionIds: function (patientSection) {
            if (!patientSection.unpaid_services) {
                return null;
            }
            
            var transactionIds = [];
            debugger;
            for (var i=0; i < patientSection.unpaid_services.length; i++) {
                if (patientSection.unpaid_services[i].unpaid_transactions) {
                    transactionIds.push(patientSection.unpaid_services[i].unpaid_transactions[0].id);
                }
            }
            return transactionIds;
        }
    },
    data: function() {
        return {
            dateRange: {},
            insuranceDateRange: {},
            selectedPatient: null,
            selectedPatientService: null,
            selectedInsuranceId: null,
			serviceStatusBadges: {
				'finished': 'badge-success',
				'in progress': 'badge-warning',
				'pending': 'badge-light',
			},
            fields: [
                { key: 'id', label: '#' },
                { key: 'name', label: 'Name' },
                { key: 'section_name', label: 'Section' },
                // { key: 'balance', label: 'Balance' },
                { key: 'services', label: 'Services' },
                // { key: 'services', label: 'Services' },
                { key: 'insurance', label: 'Insurance' },
                { key: 'last_appointment', label: 'Last Visit' },
                { key: 'appointments', label: 'Next Appointments' },
                { key: 'actions', label: '' }
            ]
        };
    },
    watch: {
		dateRange: function () {
			var startDate = this.dateRange.startDate ? this.date(this.dateRange.startDate) : null;
			var endDate = this.dateRange.endDate ? this.date(this.dateRange.endDate) : null; 
            
            
			if (startDate == this.filter.start_date && endDate == this.filter.end_date) {
				return;
			} 
			
			this.filter = {
				... this.filter,
				start_date: startDate,
				end_date: endDate
			};
		},
		insuranceDateRange: function () {
			var startDate = this.insuranceDateRange.startDate ? this.date(this.insuranceDateRange.startDate) : null;
			var endDate = this.insuranceDateRange.endDate ? this.date(this.insuranceDateRange.endDate) : null; 
            
            
			if (startDate == this.filter.insurance_start_date && endDate == this.filter.insurance_end_date) {
				return;
			} 
			
			this.filter = {
				... this.filter,
				insurance_start_date: startDate,
				insurance_end_date: endDate
			};
		},
        filter: {
            handler() {
				var filter = this.filter;
                
                this.dateRange = {
                    startDate: filter.start_date ? filter.start_date : null,
                    endDate: filter.end_date ? filter.end_date : null
                };

                this.insuranceDateRange = {
                    startDate: filter.insurance_start_date ? filter.insurance_start_date : null,
                    endDate: filter.insurance_end_date ? filter.insurance_end_date : null
                };
            },
            deep: true
        }
    }
}
</script>
