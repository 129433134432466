<template>
    <div>
        <loader v-if='loading'/>
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>
            <b-form-group label="Assistant">
                <doctors-select is_assistant=1 v-model='appointment.assistant_id' :section_id="appointment.section_id" :returnId='true'/>
                <FormGroupError :errors='errors.assisstant_id'/>
            </b-form-group>

            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
import DoctorsSelect from '../../components/DoctorsSelect.vue';
export default {
  components: { DoctorsSelect },
    extends: BaseForm,
    data: function () {
        return {
            appointment: null,
        }
    },
    methods: {
        load: function(id) {
            return this.apiRequest(apiClient => apiClient.get('appointments/'+id))
                .then(response => this.appointment = response);
        },
        save: function (apiClient) {
            if (this.id) {
                return apiClient.put('appointments/'+this.id, {assistant_id: this.appointment.assistant_id});
            }
        },
        afterSave: function () {
            this.$emit('saved', 1);
        }
    },
}
</script>