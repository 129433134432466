<template>
    <div>
        <loader v-if='loading'/>
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>
            <b-form-group label="Name">
                <b-form-input v-model="clinic.name" :state="states.name" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.name'/>
            </b-form-group>

            <b-form-group label="Branch">
                <v-select :options="branches"  :state="states.branch_id" :reduce="branch => branch.id" label='name' v-model='clinic.branch_id'></v-select>
                <FormGroupError :errors='errors.branch_id'/>
            </b-form-group>

            <b-form-group label="Section">
                <v-select :options="sections" :reduce="section => section.id" label='name' v-model='clinic.section_id'></v-select>
                <FormGroupError :errors='errors.section_id'/>
            </b-form-group>

            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
export default {
    extends: BaseForm,
    data: function () {
        return {
            clinic: {
            },
            branches: [],
            sections: []
        }
    },
    mounted() {
        this.apiRequest(apiClient => apiClient.get('branches'))
            .then(response => {
                this.branches = response.data;
            });

        this.apiRequest(apiClient => apiClient.get('sections'))
            .then(response => {
                this.sections = response.data;
            });
    },
    methods: {
        load: function(id) {
            return this.apiRequest(apiClient => apiClient.get('clinics/'+id))
                .then(response => this.clinic = response);
        },
        save: function (apiClient) {
            if (this.id) {
                return apiClient.put('clinics/'+this.id, this.clinic);
            } else {
                return apiClient.post('clinics', this.clinic);
            }
        },
        afterSave: function () {
            this.$emit('saved', 1);
        }
    },
}
</script>