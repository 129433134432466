<template>
    <div>
        <base-list :collection='collection' :loading='loading' :fields='fields' :modalObject='currentModalObject' @pageChanged='pageChanged'>
            
			<template v-slot:cell(date)='data'>
                <div v-if='data.item.date'>
                    {{ data.item.date | date }}
                </div>
                <div v-if='data.item.year'>
                    Year: {{ data.item.year }}
                </div>
                <div v-if='data.item.month'>
                    Month: {{ data.item.month }}
                </div>
            </template>

			<template v-slot:cell(branch)='data'>

                <h4 v-if='data.item.branch'>
                    <div class='badge badge-light m-1'>
                        {{ data.item.branch.name  }}
                    </div>
                </h4>
            </template>

			<template v-slot:cell(entity)='data'>

                <h4 v-if='data.item.entity'>
                    <div class='badge badge-light m-1'>
                        {{ data.item.entity_type  }}
                    </div>
                    <div class='badge badge-light m-1'>
                        {{data.item.entity.name}}
                    </div>
                </h4>
                <div v-else>
                    {{ data.item.entity_type }}
                </div>
            </template>


			<template v-slot:cell(amount)='data'>
                {{ data.item.amount | currency }}
            </template>

			<template v-slot:cell(amount_paid)='data'>
                {{ data.item.amount_paid | currency }}
            </template>

			<template v-slot:cell(amount_remaining)='data'>
                {{ data.item.amount_remaining | currency }}
            </template>


			<template v-slot:cell(collected)='data'>

                <div class='badge badge-light m-2'>
                    Required:
                    {{ data.item.required_for_supplier | decimal}}
                </div>
                <div class='badge badge-light m-2'>
                    Not Collected:
                    {{ data.item.not_collected_for_supplier | decimal}}
                </div>
            </template>

			<template v-slot:cell(actions)='data'>
				<button v-if='data.item.entity' class='btn btn-xs btn-primary m-2' @click="modal('cashPaymentForm', {
                    entityType: data.item.entity_type,
                    entityId: data.item.entity.id,
                    branchId: data.item.branch_id,
                    minDate: data.item.date ? data.item.date:(data.item.year && data.item.month ? startOfMonth(data.item.month, data.item.year) : (data.item.year ? startOfYear(data.item.year): filter.start_date)),
                    maxDate: data.item.date ? data.item.date:(data.item.year && data.item.month ? endOfMonth(data.item.month, data.item.year) : (data.item.year ? endOfYear(data.item.year):  filter.end_date)),
                    transactionsStatus: filter.status,
                    settleAll: 1,
                    collectedOnly: data.item.entity_type == 'Doctor' ? 1 : 0,
                }, 'refresh')">Create Cash Payment</button>
           </template>
        </base-list>
    </div>
</template>

<script>
import BaseList from './BaseList.vue';
import BaseListMethods from './BaseListMethods';

export default {
	props: ['viewType'],
    components: { BaseList },
    extends: BaseListMethods,
    data() {
        return {
			fields: [
                { key: 'date', label: 'Date' },
                { key: 'type', label: 'Type' },
                { key: 'branch', label: 'Branch' },
                { key: 'entity', label: 'Entity' },
                { key: 'amount', label: 'Amount' },
                { key: 'amount_paid', label: 'Amount Paid' },
                { key: 'amount_remaining', label: 'Amount Remaining' },
                { key: 'collected', label: 'Collected' },
                { key: 'actions', label: 'Actions' },
			]
        }
    },
	methods: {
        load: function (apiClient, cancelToken, filter) {
			return apiClient.get('transactions/summary', filter, cancelToken);
		}
    },
    watch: {

		// viewType: {
		// 	immediate: true,
		// 	handler: function () {
		// 	}
		// },
    }
}
</script>