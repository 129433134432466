<template>
    <div>
        <ListView title="AuditNotes List" :hideHeader='patientId ? true : false' :modalObject='currentModalObject'
            :hasFilterActions='true'>
            <template v-slot:actions>
                <b-button v-if='can("audit_notes.create")' @click="modal('auditNoteForm', {}, 'refreshTable')"
                    variant='primary' class='mr-2'>
                    Create Audit Note
                </b-button>
            </template>

            <template v-slot:filter>
                <div class='form'>
                    <div class='form-row'>
                        <b-col>
                            <b-form-group label="Date Range">
                                <my-date-range-picker v-model='dateRange' :style='{ display: "inline-block" }' />
                                <div class='btn btn-danger btn-xxs ml-2'
                                    @click='() => dateRange = { startDate: null, endDate: null }'>x</div>
                            </b-form-group>
                        </b-col>
                    </div>
                    <div class='form-row'>
                        <b-col>
                            <b-form-group label="Status">
                                <b-form-radio-group v-model="filter.status"
                                    :options="['unresolved', 'resolved', 'all']"></b-form-radio-group>
                            </b-form-group>
                        </b-col>
                    </div>
                    <div class='form-row'>
                        <b-col>
                            <b-form-group v-if='!userBranch()' label="Branch">
                                <branches-select v-model='filter.branch_id' returnId='true' />
                            </b-form-group>
                        </b-col>
                    </div>
                </div>
            </template>
            <template v-slot:filter_actions>
                <authorized-url v-if='can("audit_notes.export")' class='btn btn-primary float-right'
                    url='audit-notes/export' :params='filter' target="_blank">
                    <i class="fa fa-file-excel"></i>
                    Export
                </authorized-url>
            </template>
            <template v-slot:table>
                <AuditNotesListTable :filter='filter' ref='tableList' :viewType='viewType'></AuditNotesListTable>
            </template>

        </ListView>
    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import AuditNotesListTable from '@/components/Lists/AuditNotesListTable';
import AuthorizedUrl from '@/components/AuthorizedUrl.vue';


export default {
    extends: BaseList,
    components: { AuditNotesListTable, AuthorizedUrl },
    props: ['patientId'],
    data() {
        return {
            dateRange: {},
            defaultFilter: {
                status: 'unresolved'
            },
        }
    },
    methods: {
        refreshTable(item) {
            this.$refs.tableList.refresh(item);
        }
    },
    watch: {
        patientId: {
            immediate: true,
            handler: function () {
                if (this.patientId) {
                    this.viewType = 'patientView';
                    this.defaultFilter.patient_id = this.patientId;
                    this.filter.patient_id = this.patientId;
                }
            }
        },
        dateRange: function () {
            var startDate = this.dateRange.startDate ? this.date(this.dateRange.startDate) : null;
            var endDate = this.dateRange.endDate ? this.date(this.dateRange.endDate) : null;


            if (startDate == this.filter.start_date && endDate == this.filter.end_date) {
                return;
            }

            this.filter = {
                ... this.filter,
                start_date: startDate,
                end_date: endDate
            };
        },
        filter: {
            handler() {
                var filter = this.filter;

                this.dateRange = {
                    startDate: filter.start_date ? filter.start_date : null,
                    endDate: filter.end_date ? filter.end_date : null
                };
            },
            deep: true
        }
    }
}
</script>
