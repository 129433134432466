<template>
    <div>
        <loader v-if='loading'/>
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>
            <b-form-group label="Name">
                <b-form-input v-model="vendor.name" :state="states.name" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.name'/>
            </b-form-group>
            <b-form-group label="Type">
                <v-select :options="types" v-model='vendor.type'></v-select>
                
                <FormGroupError :errors='errors.type'/>
            </b-form-group>
            <div class='m-2'>
                <b-button variant='primary' class='btn btn-primary btn ml-2 align-right' @click="modal('externalServicesSelector', {multiple: true}, 'addServices')">Add Service</b-button>
            </div>

            <b-row  v-for='(externalServiceVendor, index) in externalServiceVendors' :key='index' class='mt-4'>
                <b-col>
                <b class='m-2' :title='externalServiceVendor.external_service.name'>
                    <i class='fa fa-stethoscope'></i>
                    {{ externalServiceVendor.external_service.name | str_limit(50) }}
                </b>
                </b-col>
                <b-col>
                <div>
                    <b-form-input type='text' placeholder="Price" v-model='externalServiceVendor.price'>
                    </b-form-input>
                </div>
                </b-col>
                <b-col>
                <b-button variant='danger' class='m-2 btn btn-danger btn-xs sharp' @click='removeExternalService(index)'>
                    <i class='fa fa-remove'></i>
                </b-button>
                </b-col>
            </b-row>
            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
		<Modals :modalName='currentModal' :modalData='currentModalData' @saved='modalCallback' @closed='modalClosed'>
		</Modals>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
export default {
    extends: BaseForm,
    data: function () {
        return {
            vendor: {
            },
            types: [
                "lab",
                "implant company",
            ],
            externalServiceVendors: []
        }
    },
    methods: {
        load: function(id) {
            return this.apiRequest(apiClient => apiClient.get('vendors/'+id))
                .then(response => {
                    this.vendor = response;
                    this.externalServiceVendors = response.external_service_vendors;
                });
        },
        save: function (apiClient) {
            var vendor = {
                ...this.vendor,
                external_service_vendors: this.externalServiceVendors
            };
            if (this.id) {
                return apiClient.put('vendors/'+this.id, vendor);
            } else {
                return apiClient.post('vendors', vendor);
            }
        },
        addServices(services) {
            services.forEach(service => {
                this.externalServiceVendors.push({
                    external_service: service,
                    external_service_id: service.id,
                    price: null
                });
            });
        },
        removeExternalService(index) {
            this.externalServiceVendors.splice(index, 1);
        },
        afterSave: function () {
            this.$emit('saved', 1);
        }
    },
}
</script>