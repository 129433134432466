var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-list',{attrs:{"collection":_vm.collection,"loading":_vm.loading,"fields":_vm.fields,"modalObject":_vm.currentModalObject},on:{"pageChanged":_vm.pageChanged},scopedSlots:_vm._u([{key:"cell(date)",fn:function(data){return [(data.item.date)?_c('div',[_vm._v(" "+_vm._s(_vm._f("date")(data.item.date))+" ")]):_vm._e(),(data.item.year)?_c('div',[_vm._v(" Year: "+_vm._s(data.item.year)+" ")]):_vm._e(),(data.item.month)?_c('div',[_vm._v(" Month: "+_vm._s(data.item.month)+" ")]):_vm._e()]}},{key:"cell(branch)",fn:function(data){return [(data.item.branch)?_c('h4',[_c('div',{staticClass:"badge badge-light m-1"},[_vm._v(" "+_vm._s(data.item.branch.name)+" ")])]):_vm._e()]}},{key:"cell(entity)",fn:function(data){return [(data.item.entity)?_c('h4',[_c('div',{staticClass:"badge badge-light m-1"},[_vm._v(" "+_vm._s(data.item.entity_type)+" ")]),_c('div',{staticClass:"badge badge-light m-1"},[_vm._v(" "+_vm._s(data.item.entity.name)+" ")])]):_c('div',[_vm._v(" "+_vm._s(data.item.entity_type)+" ")])]}},{key:"cell(amount)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("currency")(data.item.amount))+" ")]}},{key:"cell(amount_paid)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("currency")(data.item.amount_paid))+" ")]}},{key:"cell(amount_remaining)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("currency")(data.item.amount_remaining))+" ")]}},{key:"cell(collected)",fn:function(data){return [_c('div',{staticClass:"badge badge-light m-2"},[_vm._v(" Required: "+_vm._s(_vm._f("decimal")(data.item.required_for_supplier))+" ")]),_c('div',{staticClass:"badge badge-light m-2"},[_vm._v(" Not Collected: "+_vm._s(_vm._f("decimal")(data.item.not_collected_for_supplier))+" ")])]}},{key:"cell(actions)",fn:function(data){return [(data.item.entity)?_c('button',{staticClass:"btn btn-xs btn-primary m-2",on:{"click":function($event){_vm.modal('cashPaymentForm', {
                    entityType: data.item.entity_type,
                    entityId: data.item.entity.id,
                    branchId: data.item.branch_id,
                    minDate: data.item.date ? data.item.date:(data.item.year && data.item.month ? _vm.startOfMonth(data.item.month, data.item.year) : (data.item.year ? _vm.startOfYear(data.item.year): _vm.filter.start_date)),
                    maxDate: data.item.date ? data.item.date:(data.item.year && data.item.month ? _vm.endOfMonth(data.item.month, data.item.year) : (data.item.year ? _vm.endOfYear(data.item.year):  _vm.filter.end_date)),
                    transactionsStatus: _vm.filter.status,
                    settleAll: 1,
                    collectedOnly: data.item.entity_type == 'Doctor' ? 1 : 0,
                }, 'refresh')}}},[_vm._v("Create Cash Payment")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }