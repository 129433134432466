<template>
    <div>
        <loader v-if='loading'/>
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>
            <b-form-group label="Name">
                <b-form-input v-model="externalService.name" :state="states.name" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.name'/>
            </b-form-group>
            
            <b-form-group label="Type">
                <v-select :options="types" v-model='externalService.type'></v-select>
                <FormGroupError :errors='errors.type'/>
            </b-form-group>

            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
export default {
    extends: BaseForm,
    data: function () {
        return {
            externalService: {
            },
            types: [
                "crown",
                "teeth implant",
                "bones implant",
            ]
        }
    },
    methods: {
        load: function(id) {
            return this.apiRequest(apiClient => apiClient.get('external-services/'+id))
                .then(response => this.externalService = response);
        },
        save: function (apiClient) {
            if (this.id) {
                return apiClient.put('external-services/'+this.id, this.externalService);
            } else {
                return apiClient.post('external-services', this.externalService);
            }
        },
        afterSave: function () {
            this.$emit('saved', 1);
        }
    },
}
</script>