<template>
    <div>

        <b-form @submit="submit" novalidate>
            <b-form-group label="Email">
                <b-form-tags v-model="insuranceApprovalEmail.emails" placeholder='' separator=" ,;" ></b-form-tags>
                <FormGroupError :errors='errors.message'/>
            </b-form-group>

            <b-form-checkbox size='lg' v-model='insuranceApprovalEmail.separate_emails' :value='1' :unchecked-value="0">
                Multiple emails 
            </b-form-checkbox>
            <b-form-group label="Subject">
                <b-form-input v-model="insuranceApprovalEmail.subject" :state="states.subject" type="text" placeholder=""/>

                <FormGroupError :errors='errors.subject'/>
            </b-form-group>
            <b-form-group label="Message">
                <b-form-textarea v-model="insuranceApprovalEmail.message" :state="states.message" type="text" placeholder="">
                </b-form-textarea>
                <FormGroupError :errors='errors.message'/>
            </b-form-group>

            <FileUpload v-on:input='value => this.insuranceApprovalEmail.file_ids = value'/>
            
            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Send</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                sending email...
            </b-button>
        </b-form>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm';

export default {
    extends: BaseForm,
    props: ["insuranceApprovalId"],
    data() {
        return {
            insuranceApprovalEmail: {
                subject: 'Medical Report'
            },
            insuranceApproval: null
        }
    },
    mounted() {
        this.apiRequest(apiClient => {
            return apiClient.get('insurance-approvals/'+this.insuranceApprovalId)
                .then(insuranceApproval => {
                    this.insuranceApproval = insuranceApproval;
                    this.insuranceApprovalEmail = {
                        ...this.insuranceApprovalEmail,
                        emails: [insuranceApproval.insurance_provider.email]
                    };
                });
        });
    },
    methods: {
        save: function (apiClient) {
            return apiClient.post('insurance-approvals/send/'+this.insuranceApprovalId, this.insuranceApprovalEmail);
        },
        afterSave: function () {
            this.$emit('saved', 1);
        }
    },

}
</script>