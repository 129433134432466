<template>
    <div>
        <b-row>
            <b-col cols=4>
                <b-form-radio-group
                    v-if='clickableImages.length > 1'
                    v-model="bodyPartsImage"
                    :options="clickableImages.map(item => item.name)"
                    name="radios-btn-default"
                    buttons
                    button-variant="primary"
                ></b-form-radio-group>
                <clickable-svg v-if='selectedImage' :svgContent='selectedImage.svg_content' @clickedItem='viewServices' :value='selectedBodyParts' />
            </b-col>
            <b-col>
                <b-input v-model='selectedService' type='search' placeholder="service name" class='m-2'/>
                <b-input v-model='selectedBodyPart' type='search' placeholder="body part" class='m-2'/>
                
                <b-form-group label='Group By' class='m-2'>
                    <b-form-radio-group
                        v-model="groupBy"
                        :options='["none", "body_parts", "service"]'
                        name="radios-btn-default"
                        button-variant="primary"
                    ></b-form-radio-group>
                </b-form-group>
                <hr>

                <b-button v-if='reorderingMode' :disabled='savingReorder' variant="primary" class='m-2' @click="updateOrdering">
                    save
                </b-button>
                <b-button v-if='reorderingMode' variant="danger" class='m-2' @click="cancelReorder">
                    cancel
                </b-button>
                <b-button v-else variant="primary" class='m-2' @click="enableReorder">
                    reorder
                </b-button>
                
                <div v-if='groupBy=="service"'>
                    <div v-for='(patientServiceGroup, index) in groupedByService' :key='index'>
                            <div class='badge badge-warning ml-1'>
                                <b>
                                {{ patientServiceGroup.service.name }}
                                </b>
                            </div>
                            <div class='ml-3' v-for='(patientService, index3) in patientServiceGroup.patient_services' :key='"b"+index3'>
                                {{ patientService.id }}
                                <div v-for='(bodyPart, index2) in patientService.body_parts' :key='"a"+index2' class='badge badge-light ml-1'>
                                    <b>
                                    {{ bodyPart }}
                                    </b>
                                </div>
                                <div class='badge badge-light m-2'>
                                    {{ patientService.status }}
                                </div>
                            </div>
                            <hr>
                    </div>
                </div>

                <div v-else-if='groupBy=="body_parts"'>
                    <div v-for='(patientServiceGroup, index) in groupedByBodyParts' :key='index'>
                        <div v-for='(bodyPart, index2) in patientServiceGroup.body_parts' :key='"a"+index2' class='badge badge-warning ml-1'>
                            <b>
                            {{ bodyPart }}
                            </b>
                        </div>
                        <div class='ml-3' v-for='(patientService, index3) in patientServiceGroup.patient_services' :key='"b"+index3'>
                            <div :class='patientService.selected?"selected-service": ""'>
                                {{ patientService.id }}

                                <div class='badge badge-light m-2'>
                                    <i class='fa fa-stethoscope'></i>
                                    {{ patientService.service.name }}
                                </div>
                                <div class='badge badge-light m-2'>
                                    {{ patientService.status }}
                                </div>
                            </div>
                        </div>
                        <hr>
                    </div>
                </div>
                <div v-else>
                    <div class='ml-3' v-for='(patientService, index3) in filteredPatientServices' :key='"b"+index3'>
                        <b-row :class='patientService.selected?"selected-service": ""'>
                            <b-col cols=8>
                            
                                {{ patientService.id }}

                                <div class='badge badge-light m-2'>
                                    <i class='fa fa-stethoscope'></i>
                                    {{ patientService.service.name }}
                                </div>
                                <div v-for='(bodyPart, index2) in patientService.body_parts' :key='"a"+index2' class='badge badge-light ml-1'>
                                    <b>
                                    {{ bodyPart }}
                                    </b>
                                </div>
                                <div class='badge badge-light m-2'>
                                    {{ patientService.status }}
                                </div>
                            </b-col>
                            <b-col>
                                <span v-if="reorderingMode">
                                    <button :disabled="getFirstElement.id==patientService.id" class='btn btn-xxs m-1' :class='lastReorderedId == patientService.id ? "btn-primary":"btn-light"' @click="reorderService('very_top', patientService)"><i class="fas fa-angle-double-up"></i></button>
                                    <button :disabled="getFirstElement.id==patientService.id" class='btn btn-xxs m-1' :class='lastReorderedId == patientService.id ? "btn-primary":"btn-light"' @click="reorderService('top', patientService)"><i class="fas fa-angle-up"></i></button>
                                    <button :disabled="getLastElement.id==patientService.id" class='btn btn-xxs m-1' :class='lastReorderedId == patientService.id ? "btn-primary":"btn-light"' @click="reorderService('down', patientService)"><i class="fas fa-angle-down"></i></button>
                                    
                                    <button :disabled="getLastElement.id==patientService.id" class='btn btn-xxs m-1' :class='lastReorderedId == patientService.id ? "btn-primary":"btn-light"' @click="reorderService('very_down', patientService)"><i class="fas fa-angle-double-down"></i></button>
                                </span>
                            </b-col>
        
                        </b-row>
                                           
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import ClickableSvg from '../../components/ClickableImages/ClickableSvg.vue';
export default {
  components: { ClickableSvg },
    props: ['patientId'],
    data: function () {
        return {
            selectedBodyParts: [],
            clickableImages: [],
            selectedBodyPart: null,
            selectedService: null,
            patientServices: [],
            bodyPartsImage: null,
            groupedPatientServices: [],
            groupBy: 'none',
            reorderingMode: false,
            lastReorderedId: null,
            savingReorder: false,
            originalOrderList: []
        };
    },
    methods: {
        enableReorder(){
            this.reorderingMode = true;
            this.groupBy = 'none';
            this.originalOrderList = [...this.patientServices];
        },
        cancelReorder(){
            this.reorderingMode = false;
            this.patientServices = this.originalOrderList;
        },
        updateOrdering() {
            let payLoad = {patient_service_ids: this.patientServices.map(item => item.id)}
            this.savingOrder = true;
            this.apiRequest(apiClient => {
                return apiClient.put('patient-services/reorder', payLoad);
            }).then(() => {
                this.savingOrder = false;
                this.reorderingMode = false;
            });

        },
        viewServices: function (bodyPart) {
            // this.selectedPatientServices = [];
            this.selectedBodyPart = bodyPart;
            // this.patientServices.forEach(item => {
            //     if (item.body_parts && item.body_parts.includes(bodyPart)) {
            //         this.selectedPatientServices.push(item);
            //     } 
            // });
        },
		reorderService(action, item) {
			const fromIndex = this.patientServices.findIndex(object => object.id === item.id);	
            var toIndex = null;
            if (action==='very_top') {
                toIndex = 0;
            } else if (action==='very_down') {
                toIndex = this.patientServices.length;
            } else {
                toIndex = (action==='top') ? fromIndex - 1 : fromIndex + 1;
            }

			this.patientServices.splice(fromIndex, 1);
			this.patientServices.splice(toIndex, 0, item);
            this.lastReorderedId = item.id;
		},
        loadServices: function () {
            this.apiRequest(apiClient => apiClient.get('patient-services', {patient_id: this.patientId, 'active': 1, 'order_by': "position"}))
                .then(response => {
                    this.patientServices = response.data;
                    var patientServices = response.data;
                    var bodyParts = [];
                    var sectionIds = [];

                    var groupedPatientServices = [];
                    patientServices.forEach(
                        item => {
                            var cBodyParts = item.body_parts ? item.body_parts : [];
                            //find group with same body_parts
                            var group = groupedPatientServices.find(tgroup => {
                                if (tgroup.body_parts.length == 0 && cBodyParts.length == 0) {
                                    return true;
                                }
                                if (tgroup.body_parts.length == 0) {
                                    return false;
                                }
                                return tgroup.body_parts.every(bodyPart => {
                                    return cBodyParts.includes(bodyPart);
                                })
                            });
                            
                            if (!group) {
                                group = {body_parts: cBodyParts, patient_services: []};
                                groupedPatientServices.push(group);
                            }

                            group.patient_services.push(item);

                            bodyParts = [...bodyParts, ...cBodyParts];
                            if (!sectionIds.includes(item.section_id)) {
                                sectionIds.push(item.section_id);
                            }
                        }
                    );

                    this.groupedPatientServices = groupedPatientServices;

                    this.selectedBodyParts = bodyParts;
                    this.clickableImages = [];
                    this.bodyPartsImage = null;
                    sectionIds.forEach(sectionId => {
                        this.apiRequest(apiClient => apiClient.get('sections/clickable-images/'+sectionId))
                            .then(clickableImages => {
                                this.clickableImages = [...this.clickableImages, ...clickableImages];
                                this.bodyPartsImage = this.clickableImages.length>0 ? this.clickableImages[0].name : null;
                            });
                    });
                });
        },
    },
    watch: {
        patientId: {
            immediate: true,
            handler: function () {
                this.loadServices();
            }
        }
    },
    computed: {
		getFirstElement(){
			return this.patientServices[0];
		},
		getLastElement(){
			return this.patientServices[this.patientServices.length-1];
		},
        selectedImage: function () {
            return this.clickableImages.find(item => item.name == this.bodyPartsImage);
        },
        filteredPatientServices: function () {
            var patientServices = this.patientServices.map(item => {
                var valid = true;
                if (this.selectedBodyPart) {
                    valid &= item.body_parts && item.body_parts.some(bodyPart => bodyPart.toLowerCase().includes(this.selectedBodyPart.toLowerCase()));
                }
                
                if (this.selectedService) {
                    valid &= item.service.name.toLowerCase().includes(this.selectedService.toLowerCase());
                }
                
                if (this.selectedBodyPart || this.selectedService) {
                    item.selected = valid;
                } else {
                    item.selected = undefined;
                }

                return item;
            });
            if (this.reorderingMode) {
                return patientServices;
            }
            
            return patientServices.filter(item => {    
                return item.selected != undefined ? item.selected : true;
            });
        },
        groupedByBodyParts: function () {
            var groupedPatientServices = [];
            this.filteredPatientServices.forEach(
                item => {
                    var bodyParts = item.body_parts ? item.body_parts : [];
                    
                    //find group with same body_parts
                    var group = groupedPatientServices.find(group => {
                        if (group.body_parts.length > 0) {
                            return group.body_parts.every(bodyPart => {
                                return bodyParts.includes(bodyPart);
                            });
                        }

                        if (group.body_parts.length == 0  && bodyParts.length == 0) {
                            return true;
                        }
                    });
                    
                    if (!group) {
                        group = {body_parts: bodyParts, patient_services: []};
                        groupedPatientServices.push(group);
                    }

                    group.patient_services.push(item);
                }
            );

            return groupedPatientServices;
        },
        groupedByService: function () {
            var groupedPatientServices = [];

            this.filteredPatientServices.forEach(
                item => {
                    var group = groupedPatientServices.find(group => {
                        return group.service.id == item.service.id;
                    });

                    if (!group) {
                        group = {service: item.service, patient_services: []};
                        groupedPatientServices.push(group);
                    }

                    group.patient_services.push(item);
                }
            );

            return groupedPatientServices;
        }
    }
}
</script>

<style>
.selected-service {
    background-color: #efefef;
}
</style>