<template>

    <SimpleListView title="Patients List" :collection="collection" :fields="fields" :loading="loading">
        <template v-slot:filter>
            <div class='form'>
                <div class='form-group'>
                    <b-form-input v-model="filter.name" placeholder="#ID - name - phone"></b-form-input>
                </div>
            </div>
        </template>

        <template v-slot:cell(actions)='data'>
            <button class='btn btn-primary' @click="$emit('clickedItem', data.item)">Select</button>
        </template>
                
    </SimpleListView>
</template>

<script>
import BaseList from '@/views/BaseList';

export default {
    extends: BaseList,
    methods: {
        load: function (apiClient, cancelToken) {
            return apiClient.get('patients', this.filter, cancelToken);
        }
    },
    data: function() {
        return {
            fields: [
                { key: 'id', label: '#' },
                { key: 'name', label: 'Name' },
                { key: 'phone', label: 'Phone' },
                { key: 'actions', label: '' }
            ]
        };
    }
}
</script>

