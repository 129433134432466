<template>
  <div>
    <!--*******************
          Preloader start
      ********************-->
    <div id="preloader" style="display: none;">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <!--*******************
          Preloader end
      ********************-->

    <!--**********************************
          Main wrapper start
      ***********************************-->
    <div id="main-wrapper" class='show menu-toggle'>

      <!--**********************************
              Nav header start
          ***********************************-->
      <div class="nav-header">
        <a href="index.html" class="brand-logo">
          <img class="logo-abbr" src="../images/cdc-logoWhite.png" alt="">
          <img class="logo-compact" src="../images/logo-text.png" alt="">
          <img class="brand-title" src="../images/logo-text.png" alt="">
        </a>

        <div class="nav-control" @click='() => this.showSideMenu = !this.showSideMenu'>
          <div class="hamburger">
            <span class="line"></span><span class="line"></span><span class="line"></span>
          </div>
        </div>
      </div>
      <!--**********************************
              Nav header end
          ***********************************-->

      <!--**********************************
              Sidebar Fixed
          ***********************************-->
      <div class="fixed-content-box" :class='showSideMenu ? "show" : ""' v-if='!userDoctor()'>
        <div class="head-name">
          <img class="logo-abbr" style='max-width: 150px;' src="../images/cdc-logoGrey.png" alt="">
        </div>
        <div class="fixed-content-body dz-scroll" id="DZ_W_Fixed_Contant">
          <div class="tab-content" id="menu" v-if='!userMultipleDoctors()'>
            <div class="tab-pane fade show active" id="dashboard" role="tabpanel">
              <ul class="metismenu tab-nav-menu">
                <li v-if='can("appointments.create")'>
                  <button class='btn btn-success btn-xs m-1' @click="modal('fileUploadForm', { uploadOnly: true })">ُ
                    <i class='fa fa-upload'></i> Upload File
                  </button>

                </li>
                <li v-if='can("appointments.create")'>
                  <router-link to="/appointments/create">
                    <i class='fa fa-plus'></i> New Appointment
                  </router-link>
                </li>
                <li>
                  <router-link to="/calendar"><i class='fa fa-calendar'></i> Calendar</router-link>
                </li>
                <li v-if='can("patients.list")'>
                  <router-link to="/leads"><i class='fa fa-headset'></i> Leads</router-link>
                </li>
                <li v-if='can("patients.list")'>
                  <router-link to="/patients-board"><i class='fa fa-grip-vertical'></i> Patients Board</router-link>
                </li>
                <li v-if='can("patients.list")'>
                  <router-link to="/patients"><i class='fa fa-user'></i> Patients</router-link>
                </li>
                <li v-if='can("audit_notes.list")'>
                  <router-link to="/audit-notes"><i class='fa fa-list'></i> Audit Notes</router-link>
                </li>
                <li v-if='can("appointments.list")'>
                  <router-link to="/appointments"><i class='fa fa-clock'></i> Appointments</router-link>
                </li>
                <li v-if='can("patient_services.list")'>
                  <router-link to="/patient-services"><i class="fas fa-stethoscope"></i> Patient Services</router-link>
                </li>
                <li>
                  <router-link to="/service-prices"><i class="fas fa-coins"></i> Service Prices</router-link>
                </li>
                <li v-if='can("insurance_approvals.list")'>
                  <router-link to="/insurance-approvals"><i class="fas fa-clipboard-list"></i> Insurance
                    Approvals</router-link>
                </li>
                <li>
                  <router-link to="/invoices"><i class="fas fa-file-invoice"></i> Invocies</router-link>
                </li>
                <li>
                  <router-link to="/requested-services"><i class='fa fa-paper-plane'></i> Requested Services</router-link>
                </li>
                <li>
                  <router-link v-if='can("cash_payments.list")' to="/cash-payments">
                    <i class='fa fa-credit-card'></i>
                    Cash Payments
                  </router-link>
                </li>
                <li v-if='can("inventories.list")'>
                  <a><i class='fa fa-cube'></i>Inventory</a>
                  <ul>
                    <li>
                      <router-link to="/inventory">Inventory Balance</router-link>
                    </li>
                    <li>
                      <router-link to="/inventory-transactions">Inventory Movements</router-link>
                    </li>
                    <li>
                      <router-link to="/inventory-movements">Inventory Item Movements</router-link>
                    </li>
                  </ul>
                </li>

                <hr>
                <li v-if='can("purchases.list")'>
                  <router-link to="/purchases"><i class='fa fa-shopping-cart'></i> Purchases</router-link>
                </li>
                <li v-if='can("expenses.list-general")'>
                  <router-link to="/general-expenses"><i class="fas fa-receipt"></i> General Expenses</router-link>
                </li>
                <li v-if='can("expenses.list-petty")'>
                  <router-link to="/petty-expenses"><i class="fas fa-wallet"></i> Petty Cash Expenses</router-link>
                </li>
                <li v-if='false'>
                  <a><i class="fa fa-truck"></i> Suppliers</a>
                  <ul>
                    <li>
                      <router-link to="/expenses">Suppliers List</router-link>
                    </li>
                    <li>
                      <router-link to="/supplier-payments">Suppliers Payments</router-link>
                    </li>
                  </ul>
                </li>
                <li v-if='false'>
                  <router-link to="/attendance"><i class='fa fa-id-badge'></i> Attendance</router-link>
                </li>
                <hr>
                <li v-if='can("accounts.list")'>
                  <a><i class='fa fa-calculator'></i> Accounting</a>
                  <ul>
                    <li>
                      <router-link to='/transactions-list'><i class='fa fa-exchange-alt'></i> Transactions</router-link>
                    </li>
                    <li>
                      <router-link to='/accounting/transactions'><i class='fa fa-exchange-alt'></i> Financial
                        Transactions</router-link>
                    </li>
                    <li>
                      <router-link to='/accounting/accounts'><i class='fa fa-stream'></i> Accounts</router-link>
                    </li>
                    <li>
                      <router-link to='/accounting/chart-of-accounts'><i class='fa fa-tree'></i>Chart of
                        Accounts</router-link>
                    </li>
                    <li>
                      <router-link to='/accounting/balance-sheet'><i class='fa fa-scroll'></i> Balance Sheet</router-link>
                    </li>
                    <li>
                      <router-link to='/accounting/income-statement'><i class='fa fa-arrow-up'></i> Income
                        Statement</router-link>
                    </li>
                    <!-- <li>
                      <router-link to='/accounting/cash-flow'><i class='fa fa-money-bill-alt'></i> Cash Flow
                      </router-link>
                    </li> -->

                  </ul>
                </li>
              </ul>
            </div>
            <div class="tab-pane" id="setup" role="tabpanel">
              <ul class="metismenu tab-nav-menu">

                <li v-if='can("roles.list")'>
                  <router-link to="/roles"><i class='fa fa-user-check'></i> Roles</router-link>
                </li>
                <li v-if='can("users.list")'>
                  <router-link to="/users"><i class='fa fa-users'></i> Users</router-link>
                </li>
                <li v-if='can("users.list")'>
                  <router-link to="/user-logs"><i class='fa fa-clipboard-list'></i> User Logs</router-link>
                </li>
                <li v-if='can("sections.list")'>
                  <router-link to="/sections"><i class='fa fa-cubes'></i> Sections</router-link>
                </li>
                <li v-if='can("branches.list")'>
                  <router-link to="/branches"><i class='fa fa-user'></i> Branches</router-link>
                </li>
                <li v-if='can("clinics.list")'>
                  <router-link to="/clinics"><i class='fa fa-cube'></i> Clinics</router-link>
                </li>
                <li v-if='can("services.list")'>
                  <router-link to="/services"><i class='fa fa-stethoscope'></i> Services</router-link>
                </li>
                <li v-if='can("services.list")'>
                  <router-link to="/service-products"><i class='fa fa-warehouse'></i> Service Products</router-link>
                </li>
                <li v-if='can("services.list")'>
                  <router-link to="/body-part-groups"><i class='fa fa-bone'></i> Bodyparts</router-link>
                </li>
                <li v-if='can("insurance_providers.list")'>
                  <router-link to="/insurance-providers"><i class='fa fa-building'></i> Insurance Providers</router-link>
                </li>
                <li v-if='can("suppliers.list")'>
                  <router-link to="/suppliers"><i class='fa fa-truck'></i> Suppliers</router-link>
                </li>
                <li v-if='can("products.list")'>
                  <router-link to="/products"><i class='fa fa-box'></i> Products</router-link>
                </li>
                <li v-if='can("products.list")'>
                  <router-link to="/brands"><i class='fa fa-box'></i> Brands</router-link>
                </li>
                <li v-if='can("inventories.list")'>
                  <router-link to="/inventories"><i class='fa fa-boxes'></i> Inventories</router-link>
                </li>
                <li v-if='can("workers.list")'>
                  <router-link to="/workers"><i class='fa fa-user-tie'></i> Workers</router-link>
                </li>
                <li v-if='can("workers.list")'>
                  <router-link to="/workers-attendance"><i class='fa fa-user-tie'></i> Workers Attendance</router-link>
                </li>
                <li v-if='can("workers.list")'>
                  <router-link to="/worker-payables"><i class='fa fa-user-tie'></i> Worker Payables</router-link>
                </li>
                <li v-if='can("doctors.list")'>
                  <router-link to="/doctors"><i class='fa fa-user-md'></i> Doctors</router-link>
                </li>
                <li v-if='can("working_days.list")'>
                  <router-link to="/"><i class='fa fa-calendar-week'></i> Working Days</router-link>
                </li>
                <li v-if='can("vacations.list")'>
                  <router-link to="/vacations"><i class='fa fa-calendar-day'></i> Vacations</router-link>
                </li>
                <li>
                  <router-link to="/hosts"><i class='fa fa-wifi'></i> Hosts</router-link>
                </li>
                <li>
                  <router-link to="/fingerprint-devices"><i class='fa fa-fingerprint'></i> Fingerprint
                    Devices</router-link>
                </li>
                <li>
                  <router-link to="/fingerprint-users"><i class='fa fa-fingerprint'></i> Fingerprint Users</router-link>
                </li>
                <li>
                  <router-link to="/vendors"><i class='fa fa-hospital'></i> Vendors</router-link>
                </li>
                <li>
                  <router-link to="/external-services"><i class='fa fa-external-link-alt'></i> External
                    Services</router-link>
                </li>
                <li>
                  <router-link to="/options"><i class='fa fa-list'></i> Options</router-link>
                </li>
                <li>
                  <router-link to="/url-tags"><i class='fa fa-list'></i> URl Tags</router-link>
                </li>
                <li>
                  <router-link to="/expense-types"><i class='fa fa-list'></i> Expense Types</router-link>
                </li>
                <li>
                  <router-link to="/payment-methods"><i class='fa fa-list'></i> Payment Methods</router-link>
                </li>
                <li>
                  <router-link to="/source-of-payments"><i class='fa fa-list'></i> Source of Payments</router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="tab-content" id="menu" v-else>
            <div class="tab-pane fade show active" id="dashboard" role="tabpanel">
              <ul class="metismenu tab-nav-menu">
                <li v-if='can("appointments.list")'>
                  <router-link to="/appointments"><i class='fa fa-list'></i> Appointments</router-link>
                </li>
                <li v-if='can("patient_services.list")'>
                  <router-link to="/patient-services"><i class="fas fa-stethoscope"></i> Patient Services</router-link>
                </li>
                <div v-if='can("patient_services.doctor_fees")'>
                  <li v-for='(doctor, index) in userDoctors()' :key='index'>
                    <router-link :to="{ name: 'DoctorAppointments', params: { id: doctor.id } }"><i
                        class='fa fa-calculator'></i> {{ doctor.name }}</router-link>
                  </li>
                </div>

              </ul>
            </div>
          </div>
        </div>
      </div>
      <!--**********************************
              Sidebar End
          ***********************************-->


      <!--**********************************
              Header start
          ***********************************-->
      <div class="header">
        <div class="header-content">
          <nav class="navbar navbar-expand">
            <div class="collapse navbar-collapse justify-content-between">
              <div class="header-left">
                <div class="search_bar dropdown" v-if='false'>
                  <span class="search_icon p-3 c-pointer" data-toggle="dropdown">
                    <i class="mdi mdi-magnify"></i>
                  </span>
                  <div class="dropdown-menu p-0 m-0">
                    <form>
                      <input class="form-control" type="search" placeholder="Search" aria-label="Search">
                    </form>
                  </div>
                </div>
              </div>

              <ul class="navbar-nav header-right">
                <li class="nav-item dropdown notification_dropdown">
                  <a class="nav-link bell dz-fullscreen" href="#">
                    <svg id="icon-full" viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2"
                      fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                      <path
                        d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3">
                      </path>
                    </svg>
                    <svg id="icon-minimize" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                      stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-minimize">
                      <path
                        d="M8 3v3a2 2 0 0 1-2 2H3m18 0h-3a2 2 0 0 1-2-2V3m0 18v-3a2 2 0 0 1 2-2h3M3 16h3a2 2 0 0 1 2 2v3">
                      </path>
                    </svg>
                  </a>
                </li>
                <li class="nav-item dropdown header-profile">
                  <a class="nav-link" href="#" role="button" data-toggle="dropdown">
                    <div class="header-info" v-if='user'>
                      <span>Hey, <strong>{{ user.name }} </strong></span>
                    </div>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">

                    <a @click.stop='logout' class="dropdown-item ai-icon">
                      <svg id="icon-logout" xmlns="http://www.w3.org/2000/svg" class="text-danger" width="18" height="18"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round">
                        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                        <polyline points="16 17 21 12 16 7"></polyline>
                        <line x1="21" y1="12" x2="9" y2="12"></line>
                      </svg>
                      <span class="ml-2">Logout </span>
                    </a>
                  </div>
                </li>
                <li class="nav-item right-sidebar">
                  <a class="nav-link bell ai-icon" href="#">
                    <svg id="icon-menu" viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2"
                      fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                      <rect x="3" y="3" width="7" height="7"></rect>
                      <rect x="14" y="3" width="7" height="7"></rect>
                      <rect x="14" y="14" width="7" height="7"></rect>
                      <rect x="3" y="14" width="7" height="7"></rect>
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <!--**********************************
              Header end ti-comment-alt
          ***********************************-->

      <!--**********************************
              Sidebar start
          ***********************************-->
      <div class="deznav" v-if='!userDoctor()'>
        <div class="deznav-scroll">
          <ul class="nav menu-tabs">
            <li class="nav-item">
              <a class="nav-link active" data-toggle="tab" href="#dashboard">
                <svg id="icon-home" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-box">
                  <path
                    d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z">
                  </path>
                  <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                  <line x1="12" y1="22.08" x2="12" y2="12"></line>
                </svg>
              </a>
            </li>
            <li class="nav-item" v-if='!userMultipleDoctors()'>
              <a class="nav-link" data-toggle="tab" href="#setup">
                <svg id="icon-home1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-home">
                  <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"
                    style="stroke-dasharray: 66, 86; stroke-dashoffset: 0;"></path>
                  <path d="M9,22L9,12L15,12L15,22" style="stroke-dasharray: 26, 46; stroke-dashoffset: 0;"></path>
                </svg>
              </a>
            </li>
          </ul>
        </div>
        <a href="page-login.html" class="logout-btn"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round" class="feather feather-log-out">
            <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
            <polyline points="16 17 21 12 16 7"></polyline>
            <line x1="21" y1="12" x2="9" y2="12"></line>
          </svg></a>
      </div>
      <!--**********************************
              Sidebar end
          ***********************************-->

      <!--**********************************
              Content body start
          ***********************************-->
      <div class="content-body">
        <div class="container-fluid">
          <slot />
        </div>
      </div>
      <!--**********************************
              Content body end
          ***********************************-->

      <!--**********************************
              Footer start
          ***********************************-->
      <div class="footer">
        <div class="copyright">
          <p>Copyright © Designed &amp; Developed by <a href="http://dexignlab.com/" target="_blank">DexignLab</a> 2020
          </p>
        </div>
      </div>
      <!--**********************************
              Footer end
          ***********************************-->

      <!--**********************************
            Support ticket button start
          ***********************************-->

      <!--**********************************
            Support ticket button end
          ***********************************-->


    </div>
    <!--**********************************
        Main wrapper end
    ***********************************-->

    <Modals :modalName='currentModal' :modalData='currentModalData' @saved='modalCallback' @closed='modalClosed'>
    </Modals>
  </div>
</template>
<script>

export default {
  data() {
    return {
      user: null,
      showSideMenu: false
    };
  },
  created() {
    this.user = this.loadUser();
  },
  methods: {
    logout() {
      this.apiRequest(apiClient => {
        apiClient.post('auth/logout').then(() => {
          localStorage.removeItem('isAuthenticated');
          localStorage.removeItem('user');
          this.$router.push({ name: 'Login' });
        });
      });
    }
  },
}
</script>

<style>
.recentOrderTable table tbody td {
  line-height: 30px;
}

.badge {
  font-weight: 300;
  font-size: 85%;
}

.tab-nav-menu li a i {
  transform: scale(1.0);
}

.fixed-content-box {
  width: 210px;
}

@media (min-width: 991px) {
  .content-body {
    margin-left: 280px !important;
  }
}

@media (max-width: 768px) {
  .content-body {
    margin-left: 3rem !important;
  }
}

.tab-nav-menu li a {
  padding: 8px 0;
  font-size: 14px;
}

.tab-nav-menu li ul li {
  list-style: none;
}

.tab-nav-menu li ul li a {
  font-size: 14px;
  padding: 8px 25px;
}

.fixed-content-box .tab-pane {
  padding: 15px;
}

.table th,
.table td {
  padding: 5px 5px;
}

.fixed-content-box.show {
  left: 5rem !important;
}

.fixed-content-box.show .head-name {
  visibility: hidden;
}

@media (max-width: 768px) {
  .fixed-content-box.show {
    left: 3.5rem !important;
  }
}

.fixed-content-box {
  overflow-y: auto;
}

@media (max-width: 991px) {
  .nav-header .hamburger {
    display: inline-block !important;
  }

  [data-sidebar-style="mini"] .nav-header .nav-control .hamburger {
    left: 0 !important;
  }
}

[data-sidebar-style="mini"] .nav-control,
[data-layout="horizontal"] .nav-control {
  display: inline-block !important;
  right: -3.188rem !important;
  z-index: 1000;
}
</style>