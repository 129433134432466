<template>
  <div class="card">
    <div class="card-body">
      <loader v-if="loading" />
      <b-form class="basic-form" @submit="submit" novalidate>
        <div>
          <label for="">
            <i class="fa fa-calendar"></i>
            Date
          </label>
          <b-datepicker v-model="purchase.date" />
        </div>
        <div class="form-group" v-if="!userBranch()">
          <label for="">
            <i class="fa fa-"></i>
            Branch
          </label>
          <BranchesSelect
            v-model="purchase.branch"
            v-on:input="(branch) => (purchase.branch_id = branch.id)"
          />
        </div>
        <b-form-group label="Supplier">
          <SuppliersSelect
            v-if="!purchase.id || purchase.payed"
            v-model="purchase.supplier"
            v-on:input="(supplier) => (purchase.supplier_id = supplier.id)"
          />
          <div v-else>
            {{ purchase.supplier.name }}
          </div>
          <FormGroupError :errors="errors.supplier_id" />
        </b-form-group>
        <div class="form-group">
          <b-form-checkbox
            v-if="!purchase.id"
            v-model="purchase.payed"
            :value="1"
            :unchecked-value="0"
          >
            payed
          </b-form-checkbox>
          <div v-else-if="purchase.payed"><b>Payed</b></div>
          <div v-else><b>Not Payed</b></div>
        </div>
        <b-form-group
          label="Source of Payment"
          v-if="purchase.payed || purchase.supplier_id"
        >
          <source-of-payments-select
            v-model="purchase.source_of_payment_id"
            :returnId="true"
          />
          <FormGroupError :errors="errors.source_of_payment_id" />
        </b-form-group>
        <b-form-group label="Expense Type">
          <expense-types-select
            v-model="purchase.expense_type_id"
            :filter="{ expense_group: expenseGroup }"
            :returnId="true"
          />
          <FormGroupError :errors="errors.expense_type_id" />
        </b-form-group>
        <b-form-group label="Inventory" v-if="!isSimple">
          <InventoriesSelect
            v-model="purchase.inventory"
            v-on:input="(inventory) => (purchase.inventory_id = inventory.id)"
          />
          <FormGroupError :errors="errors.inventory_id" />
        </b-form-group>
       
        <div v-if="!isSimple">
          <div
            class="form-row mb-2"
            v-for="(orderLine, index) in orderLines"
            :key="index"
          >
            <b-col>
              <b-form-group label="Product">
                <ProductsSelect
                  v-model="orderLine.product"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Quantity">
                <input
                  type="text"
                  v-model="orderLine.quantity"
                  class="form-control"
                  placeholder="Quantity"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Item Price">
                <input
                  type="text"
                  v-model="orderLine.item_price"
                  class="form-control"
                  placeholder="Price"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Brand">
                <ProductBrandsSelect
                  v-model="orderLine.brand"
                  :productId="orderLine.product.id"
                />
              </b-form-group>
            </b-col>
            <b-col align-self="center">
              <b-button variant="danger" @click="removeRow(index)">x</b-button>
            </b-col>
          </div>
        </div>
        
        <!-- excel file -->
        <div class="row" v-if='!isSimple'>
          <b-col cols="5">
            <b-form-group label="Upload Products File">
              <b-form-file
                v-model="excelFile"
                drop-placeholder="Drop file here..."
                accept=".xlsx, .xls"
                @input="handleExcelFileChange"
              ></b-form-file>
            </b-form-group>
          </b-col>
          <b-col align-self="center">
            <b-button
              variant="success"
              :disabled="!excelFile"
              @click="uploadExcelFile"
              v-if="!uploading"
              >Upload</b-button
            >

            <b-button v-if='uploading' variant="primary" :disabled='true'>
              <b-spinner small type="grow"></b-spinner>
              uploading...
          </b-button>
          </b-col>
        </div>
        <!-- excel file end -->
        <div class="row" v-if='!isSimple'>
            <b-col cols='5'>
                <b-form-group label='select products to add'>
                    <ProductsSelect v-model='toAddProducts' multiple :closeOnSelect='false' />
                </b-form-group>
            </b-col>
            <b-col align-self="center">
                <b-button variant='success' @click='addRows'>+ Add</b-button>
            </b-col>
            <b-col>
              <authorized-url v-if="can('products.export')"  class='btn btn-primary ml-2 float-right' url='products/export' target="_blank">
                <i class="fa fa-file-excel"></i>
                Download Products Sheet
              </authorized-url>
            </b-col>
        </div>
        <h3>Total: {{total}}</h3>
        <b-form-group label="Amount" description="Amount">
          <b-form-input
            type="number"
            v-model="purchase.total"
            required
            placeholder="Enter amount"
          >
          </b-form-input>
        </b-form-group>
        <div class="form-group">
          <label>Note</label>
          <textarea
            class="form-control"
            v-model="purchase.notes"
            cols="30"
            rows="2"
          ></textarea>
        </div>

        <b-form-group label="Upload Supporting Document">
          <FileUpload v-on:input="(value) => (this.fileIds = value)" />
          <b-form-checkbox v-model="replaceFiles" value="1">
            <b>Replace Files</b>
          </b-form-checkbox>
        </b-form-group>

        <div class="form-group">
          <b-form-checkbox
            v-model="purchase.is_draft"
            :value="1"
            :unchecked-value="0"
          >
            Save as Draft
          </b-form-checkbox>
        </div>

        <div class="form-group" v-if="false">
          <b-form-checkbox
            v-if="purchase.supplier"
            v-model="purchase.is_payed"
            :value="1"
            :unchecked-value="0"
            :disabled="purchase.is_draft ? true : false"
          >
            Payed to supplier
          </b-form-checkbox>
        </div>

        <FormErrors :hasErrors="hasErrors" :errors="errors"></FormErrors>

        <button v-if="!this.saving" type="submit" class="btn btn-primary">
          Save
        </button>

        <b-button v-if="this.saving" variant="primary" :disabled="true">
          <b-spinner small type="grow"></b-spinner>
          saving...
        </b-button>
      </b-form>
    </div>
  </div>
</template>
<script>
import BaseForm from "@/views/BaseForm";
import ProductsSelect from "../../components/ProductsSelect.vue";
import InventoriesSelect from "../../components/InventoriesSelect.vue";
import ExpenseTypesSelect from "../../components/ExpenseTypesSelect.vue";
import SourceOfPaymentsSelect from "../../components/SourceOfPaymentsSelect.vue";
import ProductBrandsSelect from "../../components/ProductBrandsSelect.vue";
import AuthorizedUrl from '../../components/AuthorizedUrl.vue';
export default {
  props: ["isSimple", "expenseGroup"],
  components: {
    ProductsSelect,
    InventoriesSelect,
    ExpenseTypesSelect,
    SourceOfPaymentsSelect,
    ProductBrandsSelect,
    AuthorizedUrl
  },
  extends: BaseForm,
  data: function() {
    return {
      purchase: {
        payed: 1,
      },
      orderLines: [],
      toAddProducts: [],
      fileIds: [],
      replaceFiles: 0,
      excelFile: null,
      uploading: false,
    };
  },
  computed: {
    total: function() {
      return this.orderLines.reduce((total, item) => {
        return total + parseFloat(item.quantity) * parseFloat(item.item_price);
      }, 0);
    },
  },
  methods: {
    load: function(id) {
      return this.apiRequest((apiClient) =>
        apiClient.get("purchases/" + id)
      ).then((response) => {
        this.purchase = response;
        this.orderLines = response.purchase_items;
        if (!this.purchase.inventory) {
          this.isSimple = true;
        }
      });
    },
    removeRow(index) {
      this.orderLines.splice(index, 1);
    },
    addRows() {
      if (this.toAddProducts.length > 0) {
        this.toAddProducts.forEach((item) => {
          this.orderLines.push({ product: item });
        });
        this.toAddProducts = [];
      } else {
        this.orderLines.push({});
      }
    },

    save: function(apiClient) {
      this.purchase.purchase_items = this.orderLines.map((item) => {
        return {
          product_id: item.product.id,
          quantity: item.quantity,
          item_price: item.item_price,
          brand_id: item.brand?item.brand.id:null
        };
      });
      var purchase = {
        replace_files: this.replaceFiles,
        file_ids: this.fileIds,
        ...this.purchase,
      };
      if (this.id) {
        return apiClient.put("purchases/" + this.id, purchase);
      } else {
        return apiClient.post("purchases", purchase);
      }
    },
    afterSave: function(response) {
      this.$emit("saved", response.id);
      // this.$router.push({name: 'Purchases'});
    },
    handleExcelFileChange: function(excelFile) {
      this.excelFile = excelFile;
    },
    uploadExcelFile: function() {
      if (this.excelFile) {
        const formData = new FormData();
        formData.append("file", this.excelFile);
        this.uploading = true;
        this.apiRequest((apiClient) =>
          apiClient.post("purchases/import-purchases", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
        ).then((response) => {
          this.uploading = false;
          this.orderLines = [...this.orderLines, ...response];
        });
      } else {
        console.log("file not in field");
      }
    },
  },
  watch: {
    "purchase.is_draft": function() {
      if (this.purchase.is_draft) {
        this.purchase.is_payed = 0;
      }
    },
  },
};
</script>
