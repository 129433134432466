<template>
    <div>
        <base-list :collection='collection' :loading='loading' :fields='fields' :modalObject='currentModalObject'
            @pageChanged='pageChanged'>

            <template v-slot:cell(patient)="data">
                <router-link :to="{ name: 'ViewPatient', params: { id: data.item.patient.id } }" class=''>
                    <h5>
                        {{ data.item.patient.name }}
                    </h5>
                </router-link>
            </template>
            <template v-slot:cell(branch)="data">

                <h5>
                    {{ data.item.branch.name }}
                </h5>

            </template>
            <template v-slot:cell(created_at)='data'>
                {{ data.item.date | date }}
            </template>
            <template v-slot:cell(is_closed)='data'>
                <div class='badge badge-light m-2'>
                    created by {{ data.item.created_by.name }}
                    at {{ data.item.created_at | time }}
                </div>
                <div v-if='data.item.is_closed' class='badge badge-success'>
                    <i class='fa fa-check'></i>
                    resolved:
                    {{ data.item.closed_at | datetime }}
                </div>
                <div v-else class='badge badge-light'>
                    <i class='fa fa-exclamation-circle'></i> unresolved
                </div>
            </template>
            <template v-slot:cell(actions)='data'>
                <button v-if='can("audit_notes.edit")' class='btn btn-xs btn-primary m-2'
                    @click="modal('auditNoteForm', { id: data.item.id }, 'refresh')">Edit</button>
                <button v-if='can("audit_notes.delete")' class='btn btn-danger btn-xs m-1'
                    @click="confirmAction('deleteAuditNote', data.item)">x</button>
            </template>
        </base-list>
    </div>
</template>

<script>
import BaseList from './BaseList.vue';
import BaseListMethods from './BaseListMethods';

export default {
    props: ['viewType'],
    components: { BaseList },
    extends: BaseListMethods,
    data() {
        return {
            fields: [
                { key: 'id', label: 'ID' },
                { key: 'created_at', label: 'Date' },
                { key: 'patient', label: 'Patient' },
                { key: 'branch', label: 'Branch' },
                { key: 'note', label: 'Note' },
                { key: 'resolution', label: 'Resolution' },
                { key: 'is_closed', label: 'Resolved' },
                { key: 'actions', label: 'Actions' },
            ]
        }
    },
    methods: {
        load: function (apiClient, cancelToken, filter) {
            return apiClient.get('audit-notes', filter, cancelToken);
        },
        deleteAuditNote(auditNote) {
            return this.apiRequest(apiClient => apiClient.delete('audit-notes/' + auditNote.id)).then(() => auditNote);
        },
    },
    watch: {

        // viewType: {
        // 	immediate: true,
        // 	handler: function () {
        // 	}
        // },
    }
}
</script>