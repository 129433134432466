<template>

    <b-row>
        <b-col>
            <SimpleListView title="Patients List" :collection="collection" :fields="fields" :loading="loading">
                <template v-slot:filter>
                    
                </template>

                <template v-slot:cell(name)='data'>
                    <div>
                        <div  class='badge ml-2' :class='data.item.payable_transaction.status=="finished" ? "badge-success":"badge-light"' v-if='data.item.payable_transaction'>
                            # {{ data.item.payable_transaction.id }}
                            {{ data.item.payable_transaction.title }}
                        </div>
                        <div  class='badge badge-light ml-2' v-else-if='data.item.transaction'>{{ data.item.transaction.title }}</div>
                        <div  class='badge badge-light ml-2'>{{ data.item.amount | decimal}}</div>
                    </div>
                </template>
                <template v-slot:cell(actions)='data'>
                    <button class='btn btn-primary' @click="itemClicked(data.item)">Select</button>
                </template>
                        
            </SimpleListView>
        </b-col>
        <b-col  v-if='multiple' cols='4'>
            <div>
                <h3>selected Payments</h3>
                <hr>
                <ul>
                    <li v-for='(cashPaymentPart, index) in selected' :key='index' class='m-2'>
                        <button @click='removeItem(index)' class='btn btn-xxs btn-danger m-3'>x</button>
                        <b>#{{ cashPaymentPart.id }}</b> - <b>{{ cashPaymentPart.cash_payment.date | date }}</b> - <b>{{ cashPaymentPart.amount | currency }}</b>
                        <div v-if='cashPaymentPart.payable_transaction'>
                            {{ cashPaymentPart.payable_transaction.title }}
                            -
                            {{ cashPaymentPart.payable_transaction.status }}
                        </div>
                        <hr>
                    </li>
                </ul>
                <button class='btn btn-primary' v-if='selected.length > 0' @click="finish(selected)">Add</button>
                <div class="alert alert-primary" v-else>please select some services</div>
            </div>
        </b-col>


    </b-row>
</template>

<script>
import BaseList from '@/views/BaseList';

export default {
    props: ['multiple', 'baseFilter'],
    extends: BaseList,
    methods: {
        load: function (apiClient, cancelToken) {
            return apiClient.get('cash-payment-parts', {...this.filter, ...this.baseFilter}, cancelToken);
        },
        itemClicked: function (item) {
            if (this.multiple) {
                this.selected.push(item);
            } else {
                this.finish(item);
            }
        },
        removeItem: function(index) {
            this.selected.splice(index, 1);
        },
        finish: function(item) {
            this.$emit('clickedItem', item);
        }
    },
    data: function() {
        return {
            filter: {},
            selected: [],
            fields: [
                { key: 'id', label: '#' },
                { key: 'name', label: 'Name' },
                { key: 'amount', label: 'amount' },
                { key: 'cash_payment.date', label: 'Date' },
                { key: 'actions', label: 'Actions' }
            ]
        };
    }
}
</script>

