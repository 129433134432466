<template>
    <div>
        <base-list :collection='collection' :loading='loading' :fields='fields' :modalObject='currentModalObject' @pageChanged='pageChanged'>
            
            <template v-slot:cell(card_file_group)='data'>
                <h4 v-if='data.item.card_file_group && data.item.card_file_group.files'>
                    <button
                        class='badge badge-success'
                        @click='modal("previewImages", {images: data.item.card_file_group.files.map(item => item.url+"?auth_token="+authToken), index: 0})'
                    >
                        {{ data.item.card_file_group.files.length }} files
                    </button>
                </h4>

                <div v-else class='badge badge-light'>No Files</div>
            </template>

            <template v-slot:cell(active)='data'>
                <div v-if='!data.item.active' class='badge badge-light'>
                    inactive
                </div>
                <div v-if='data.item.active' class='badge badge-warning'>
                    active
                </div>
            </template>
			<template v-slot:cell(actions)='data'>
				<button class='btn btn-xs btn-primary m-2' @click="modal('patientInsuranceForm', {id: data.item.id}, 'refresh')">Edit</button>
            </template>
        </base-list>
    </div>
</template>

<script>
import BaseList from './BaseList.vue';
import BaseListMethods from './BaseListMethods';

export default {
	props: ['viewType'],
    components: { BaseList },
    extends: BaseListMethods,
    data() {
        return {
			fields: [
                { key: 'id', label: 'ID' },
                { key: 'patient.name', label: 'Patient' },
                { key: 'insurance_provider.name', label: 'Insurance' },
                { key: 'expire_at', label: 'Expire At' },
                { key: 'active', label: 'status' },
                { key: 'card_file_group', label: 'Card' },
                { key: 'actions', label: 'Actions' },
			],
            authToken: localStorage.token
        }
    },
	methods: {
        load: function (apiClient, cancelToken, filter) {
			return apiClient.get('patient-insurances', filter, cancelToken);
		}
    },
    watch: {

		viewType: {
			immediate: true,
			handler: function () {
				if (this.viewType == 'patientView') {
					this.hideFields = ['patient.name'];
				}
			}
		},
    }
}
</script>