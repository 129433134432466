<template>
    <div>
        <loader v-if='loading'/>
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>
            <b-form-group label="Name">
                <b-form-input v-model="user.name" :state="states.name" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.name'/>
            </b-form-group>
            <b-form-group label="User Name">
                <b-form-input v-model="user.username" :state="states.username" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.username'/>
            </b-form-group>
            
            <b-form-group label="Role">
                <RolesSelect v-model='role'/>
                <FormGroupError :errors='errors.role_id'/>
            </b-form-group>
            
            <b-form-group label="Doctor">
                <DoctorsSelect v-model='doctors' :multiple='true' :closeOnSelect='false'/>
                <FormGroupError :errors='errors.doctor_id'/>
            </b-form-group>
            
            <b-form-group label="Branch">
                <BranchesSelect v-model='user.branches' :multiple='true'/>
                <FormGroupError :errors='errors.branch_id'/>
            </b-form-group>
            <b-form-group>
                <b-form-checkbox
                    v-model="settings.enable_leads_notifications"
                    value="1"
                    unchecked-value="0"
                    :inline='true'
                >
                    Enable Leads Notifications
                </b-form-checkbox>
            </b-form-group>

            <b-form-group label="Notify with leads from branches" v-if='settings.enable_leads_notifications == "1"'>
                <BranchesSelect v-model='settings.leads_from_branches' :multiple='true' :returnIds='true'/>
            </b-form-group>

            <b-form-group label="Password">
                <b-form-input v-model="user.password" :state="states.password" :id="dynamicID()" type="password" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.password'/>
            </b-form-group>

            <b-form-group label="Repeate Password">
                <b-form-input v-model="user.password_confirmation" :state="states.password_confirmation" :id="dynamicID()" type="password" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.password_confirmation'/>
            </b-form-group>
            <b-form-group>
                <b-form-checkbox
                    v-model="user.is_active"
                    value="1"
                    unchecked-value="0"
                    size="lg"
                >
                    Active
                </b-form-checkbox>
                <FormGroupError :errors='errors.is_active'/>
            </b-form-group>

            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
export default {
    extends: BaseForm,
    data: function () {
        return {
            user: {
            },
            settings: {},
            role: {},
            doctors: [],
            branch: {}
        }
    },
    methods: {
        load: function(id) {
            return this.apiRequest(apiClient => apiClient.get('users/'+id))
                .then(response => {
                    this.user = response;
                    this.settings = response.settings ? response.settings : {};
                    this.role = response.role;
                    this.doctors = response.doctors;
                    this.branch = response.branch;
                });
        },
        save: function (apiClient) {
            var user = {...this.user};
            user.role_id = this.role ? this.role.id : null;
            user.doctor_ids = this.doctors ? this.doctors.map(item => item.id) : null;
            user.branch_id = this.branch ? this.branch.id : null;
            user.branch_ids = user.branches ? user.branches.map(item => item.id) : [];
            var settings = this.settings ? this.settings : null;
            
            if (settings && settings.leads_from_branches) {
                settings= {
                    ...settings,
                    leads_from_branches: settings.leads_from_branches.map(item =>  Number.isInteger(item) ? item : item.id).filter(x => x !== null)
                };
            }
            user = {...user, settings: settings};
            if (this.id) {
                return apiClient.put('users/'+this.id, user);
            } else {
                return apiClient.post('users', user);
            }
        },
        afterSave: function () {
            this.$emit('saved', 1);
        }
    },
}
</script>