<template>
    <div v-if='patientService'>
        <b-form class='basic-form' @submit="submit" novalidate>            
            <div class='form-group' v-if='patientService.required_appointments && patientService.service.multiple_appointments'>
                <label for="">Number of appointments required</label>
                
                <b-row>
                    <b-col cols='2'>
                        <input class='form-control' type='number' v-model='patientService.required_appointments' />
                    </b-col>
                </b-row>
            </div>
            
            <div class=form-group>
                <label for="">
                    Appointments
                </label>

                <div v-for='(appointment, index) in patientService.appointments' :key='index' class='m-2'>
                    #{{ appointment.id }}

                    <div class='badge badge-lg badge-light'>
                        <i class='fa fa-calendar'></i>
                        {{ appointment.datetime | datetime }}
                    </div>
                    <div class='badge badge-lg badge-light ml-2'>
                        {{ appointment.duration }}
                        minutes
                    </div>
                    <b-form-checkbox
                        v-model="appointment.removed"
                        inline
                        class="ml-2"
                    >
                        <i class='fa fa-remove'></i>
                        remove
                    </b-form-checkbox>
                </div>

                <div v-for='(appointment, index) in appointments' :key='index' class='m-2'>
                    #{{ appointment.id }}

                    <div class='badge badge-lg badge-light'>
                        <i class='fa fa-calendar'></i>
                        {{ appointment.datetime | datetime }}
                    </div>
                    <div class='badge badge-lg badge-light ml-2'>
                        {{ appointment.duration }}
                        minutes
                    </div>
                    <div class='btn btn-primary btn-xs sharp ml-2' @click='removeAppointment(index)'>
                        <i class='fa fa-remove'></i>
                    </div>
                </div>
            </div>
            <div class=form-group>                
                <div v-for='(datetime, index) in dates' :key='index' class='m-2'>
                    <div class='badge badge-lg badge-light'>
                        <i class='fa fa-calendar'></i>
                        {{ datetime.date | datetime }}
                    </div>
                    <div class='badge badge-lg badge-light ml-2'>
                        {{ datetime.duration }}
                        minutes
                    </div>
                    <div class='btn btn-primary btn-xs sharp ml-2' @click='removeDate(index)'>
                        <i class='fa fa-remove'></i>
                    </div>
                </div>

                <div>
                    <b-button v-b-modal.modal-2 class='mr-2' variant='success'>+ New Appointment</b-button>
                    <b-button v-b-modal.modal-3 variant='success' size="sm" class='mr-2'>Select Appointment</b-button>
                </div>
                <div class='m-2'>
                </div>
            </div>
                        
            <b-alert v-model='this.requireAppointments' variant="danger">
                <ul>
                    <li>
                        Appointments are required for in progress or finished services 
                    </li>
                </ul>
            </b-alert>
            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button type="submit" class="btn btn-primary" :disabled='this.requireAppointments'>Save</button>
        </b-form>
        <b-modal :no-close-on-backdrop=true id="modal-3" size='xl' v-if='patientService' hide-footer  title="Select Appointment">
            <AppointmentsSelector @clickedItem='appointmentSelected'  :patientId='patientService.patient_id' :doctorId='patientService.doctor_id' :branchId='patientService.branch_id'></AppointmentsSelector>        
        </b-modal>
        <b-modal :no-close-on-backdrop=true id="modal-2"  v-if='patientService' hide-footer  title="Choose Date">
            <AppointmentPicker @datetimePicked='dateClicked'  :doctor_id='patientService.doctor_id' :branch_id='patientService.branch_id'></AppointmentPicker>        
        </b-modal>
    </div>

</template>
<script>
import AppointmentPicker from '../../components/AppointmentPicker';
import AppointmentsSelector from '../../components/AppointmentsSelector';
import BaseForm from '@/views/BaseForm';

export default {
    extends: BaseForm,
    components: {
        AppointmentPicker,
        AppointmentsSelector
    },
    data: function () {
        return {
            patientService: null,
            dates: [],
            appointments: [],
            statusOptions: []
        };
    },
    methods: {
        load: function(id) {
            return this.apiRequest(apiClient => apiClient.get('patient-services/'+id))
                .then(response => {
                    this.patientService = response;
                    this.statusOptions = ['pending', 'in progress', 'finished'];
                });
        },
        save: function (apiClient) {
            if (this.id) {
                var remainingAppointments = 
                    this.patientService.appointments
                        .filter(item => item.removed ? false : true)
                        .map(item => item.id);
                this.patientService.appointment_ids = [...this.appointments.map(item => item.id), ...remainingAppointments];
                this.patientService.new_appointments = this.dates;
                return apiClient.put('patient-services/'+this.id, this.patientService);
            } else {
                return apiClient.post('patient-services', this.patientService);
            }
        },
        afterSave: function (patientService) {
            this.$emit('saved', patientService);
        },
        dateClicked(item) {
            this.$bvModal.hide('modal-2');
            
            this.dates.push({date: item.date, duration: item.duration});
        },
        appointmentSelected(appointment) {
            this.$bvModal.hide('modal-3');
            this.appointments.push(appointment);
        },
        removeDate(i) {
            this.dates.splice(i, 1);
        },
        removeAppointment(i) {
            this.appointments.splice(i, 1);
        }
    },
    computed: {
        requireAppointments: function () {
            var remainingAppointments = 
                    this.patientService.appointments
                        .filter(item => item.removed ? false : true)
                        .map(item => item.id);
            var currentAppointments = [...this.appointments.map(item => item.id), ...remainingAppointments];
            var newAppointments = this.dates;
            var hasAppointments = false;
            if ((newAppointments.length + currentAppointments.length) > 0) {
                hasAppointments = true;
            }

            return (!hasAppointments && (this.patientService.status =="in progress" || this.patientService.status =="finished"));
        }
    }
}
</script>