<template>

        <ListView title="Inventory Items" :collection="collection" :fields="fields" :loading="loading" @pageChanged='pageChanged'>
            <template v-slot:actions>
               
            </template>

            <template v-slot:filter>
                <div class='form'>
                    <b-row>
                        <b-col cols="3">
                            <b-form-group label="Inventory">
                                <inventories-select v-model='filter.inventory_id' :returnId="true"/>
                            </b-form-group>
                        </b-col>
                        <b-col cols="3">
                            <b-form-group label="Product">
                                <products-select v-model='filter.product_id' :returnId="true"/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
            </template>
            <template v-slot:cell(stock_value)='data'>
                <div>
                    {{ data.item.product ? (parseFloat(data.item.product.stock_price) * parseFloat(data.item.quantity)) : 0 | decimal}}
                </div>
            </template>
        </ListView>
</template>

<script>
import BaseList from '@/views/BaseList';
import InventoriesSelect from '../../components/InventoriesSelect.vue';

export default {
  components: { InventoriesSelect },
    extends: BaseList,
    methods: {
        load: function (apiClient, cancelToken) {
            return apiClient.get('inventory-products', this.filter, cancelToken);
        }
    },
    data: function() {
        return {
            fields: [
                { key: 'id', label: '#' },
                { key: 'product.name', label: 'Product' },
                { key: 'product.stock_price', label: 'Stock Price' },
                { key: 'quantity', label: 'Quantity' },
                { key: 'stock_value', label: 'Stock Value' },
                { key: 'inventory.name', label: 'Inventory' },
            ]
        };
    }
}
</script>
