<template>
    <div>
        <base-list :collection='collection' :loading='loading' :fields='fields' :modalObject='currentModalObject'
            @pageChanged='pageChanged'>

            <template v-slot:cell(status)='data'>
                <div v-if='data.item.is_active' class='badge badge-success'>
                    Active
                </div>
                <div v-else class='badge badge-light'>
                    Inactive
                </div>
            </template>
            <template v-slot:cell(actions)='data'>
                <button class='btn btn-xs btn-primary m-2'
                    @click="modal('sourceOfPaymentForm', { id: data.item.id }, 'refresh')">Edit</button>
            </template>
        </base-list>
    </div>
</template>

<script>
import BaseList from './BaseList.vue';
import BaseListMethods from './BaseListMethods';

export default {
    props: ['viewType'],
    components: { BaseList },
    extends: BaseListMethods,
    data() {
        return {
            fields: [
                { key: 'id', label: 'ID' },
                { key: 'name', label: 'Name' },
                { key: 'status', label: 'Status' },
                { key: 'actions', label: 'Actions' },
            ]
        }
    },
    methods: {
        load: function (apiClient, cancelToken, filter) {
            return apiClient.get('source-of-payments', filter, cancelToken);
        }
    },
    watch: {

        // viewType: {
        // 	immediate: true,
        // 	handler: function () {
        // 	}
        // },
    }
}
</script>