<template>
  <div id="app" class="h-100">
    <component v-if='(isAuthenticated && user) || !isAuthenticated' :is="this.$route.meta.layout || 'MainLayout'">
      <router-view @showModal='modal' />
      <Modals ref='globalModal' />
    </component>
  </div>
</template>
<script>
import PerfectScrollbar from 'perfect-scrollbar';
import MainLayout from '@/layouts/MainLayout';
import Modals from '@/components/Modals';

export default {
  components: { MainLayout, Modals },
  data() {
    return {
      apiClient: null,
      user: null,
      isAuthenticated: false
    }
  },
  async created() {
    this.isAuthenticated = localStorage.isAuthenticated;
    if (localStorage.isAuthenticated) {
      this.apiClient.setToken(localStorage.token);
      this.$eventHub.$on('showModal', this.modal);
      await this.apiRequest(
        apiClient => apiClient.getNoCancel('me')
      ).then(
        user => {
          this.user = user;
          localStorage.user = JSON.stringify(user);

          this.$echo.private('users.' + user.id)
            .notification((notification) => {
              this.notify(notification.title, notification.message);
            });
        }
      );
    }

  },
  mounted: function () {
    $('.dz-scroll').each(function () {
      var scroolWidgetId = $(this).attr('id');
      new PerfectScrollbar('#' + scroolWidgetId, {
        wheelSpeed: 2,
        wheelPropagation: true,
        minScrollbarLength: 20
      });
    });
  },
  methods: {
    showErrors: function (error) {
      this.$bvToast.toast(error, {
        title: 'Error',
        autoHideDelay: 5000,
        noAutoHide: false,
        variant: 'danger',
        appendToast: true
      });
    },

    modal: function (data) {
      console.log('heere');
      this.$refs.globalModal.modal(data.name, data.data);
    }

  }
}
</script>

<style>
table {
  color: #000 !important;
}

.clickable {
  cursor: pointer;
}

.recentOrderTable table tbody td {
  line-height: 30px;
}

.badge {
  font-weight: 300;
  font-size: 85%;
}

.tab-nav-menu li a i {
  transform: scale(1.0);
}

.popover {
  border: 2px solid #ffffff;
}

.popover-body {
  color: #222;
}

.vc-popover-content-wrapper {
  z-index: 1000 !important;
}

.content-body .container-fluid {
  margin-bottom: 350px;
}

pre {
  white-space: pre-wrap;
  /* css-3 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}</style>