
<template>
    <transactions-list v-if='id' :entityId='id' entityType='Doctor'></transactions-list>
</template>

<script>
import TransactionsList from '../../Transactions/TransactionsList.vue'

export default {
    props: ['id'],
    components:{TransactionsList}
}
</script>