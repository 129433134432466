<template>
    <div>
        <b-form class='basic-form' @submit="submit" novalidate>
            <h3>Please select taken services</h3>
            <b-form-group label="Appointment Services">
                <div v-for='(patientService, index) in patientServices' :key='index'>
                    <b-row class='mt-2'>
                        <b-col cols='5'>
                            <b-form-checkbox v-model="selectedPatientServices" name="pservices" :value="patientService.id"
                                unchecked-value="false">
                                {{ patientService.name }}
                            </b-form-checkbox>
                        </b-col>
                        <b-col cols='3'
                            v-if="patientService.multiple_appointments && selectedPatientServices.indexOf(patientService.id) != -1">
                            <b-form-group label="Remaining appointments">
                                <b-form-input type="number" v-model='patientService.remaining_appointments' placeholder="">
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols='3' v-if="selectedPatientServices.indexOf(patientService.id) != -1">
                            <div v-if='!patientService.multiple_appointments || patientService.remaining_appointments == 0'
                                class='badge badge-success'>
                                finished
                            </div>
                            <div v-else class='badge badge-warning'>
                                in progress
                            </div>
                        </b-col>
                        <b-col>
                            <div class='btn btn-primary btn-xs m-1' @click='newExternalService(patientService)'>+ New
                                External Service</div>
                        </b-col>
                    </b-row>
                    <div class="ml-3" v-if='patientService.patient_external_services.length > 0'>
                        <h5 class="mt-3 mb-3">
                            External Services Delivered
                        </h5>
                        <b-row v-for='(patientExternalService, index) in patientService.patient_external_services'
                            :key='index'>
                            <b-col>
                                <b-form-checkbox v-model="patientService.external_services_delivered"
                                    name="externalServices" :value="patientExternalService.id" unchecked-value="false">
                                    {{ patientExternalService.external_service.name }}
                                </b-form-checkbox>
                            </b-col>
                            <b-col>
                                <div class='badge badge-light'>
                                    {{ patientExternalService.status }}
                                </div>
                            </b-col>
                        </b-row>

                    </div>
                </div>
            </b-form-group>
            <hr>
            <b-form-group label="Other Services">

                <div v-for='(patientService, index) in otherPatientServices' :key='index'>
                    <b-row class='mt-2'>
                        <b-col cols='5'>
                            <b-form-checkbox v-model="selectedPatientServices" name="pservices" :value="patientService.id"
                                unchecked-value="false">
                                {{ patientService.name }}
                            </b-form-checkbox>
                        </b-col>
                        <b-col cols='3'
                            v-if="patientService.multiple_appointments && selectedPatientServices.indexOf(patientService.id) != -1">
                            <b-form-group label="Remaining sessions"
                                :description='patientService.remaining_appointments == 0 ? "This service will be closed" : ""'>
                                <b-form-input type="number" v-model='patientService.remaining_appointments' placeholder="">
                                </b-form-input>
                            </b-form-group>
                        </b-col>

                        <b-col cols='3' v-if="selectedPatientServices.indexOf(patientService.id) != -1">
                            <div v-if='!patientService.multiple_appointments || patientService.remaining_appointments == 0'
                                class='badge badge-success'>
                                finished
                            </div>
                            <div v-else class='badge badge-warning'>
                                in progress
                            </div>
                        </b-col>
                        <b-col>
                            <div v-if='patientService.insurance_approval' :title='patientService.insurance_approval.status'
                                class='badge text-wrap mt-2'
                                :class='patientService.insurance_approval.status == "approved" ? "badge-success" : "badge-warning"'>
                                Approval #{{ patientService.insurance_approval.id }}
                            </div>
                        </b-col>
                        <b-col>
                            <div class='btn btn-primary btn-xs m-1' @click='newExternalService(patientService)'>+ New
                                External Service</div>
                        </b-col>

                    </b-row>
                    <div class="ml-3" v-if='patientService.patient_external_services.length > 0'>
                        <h5 class="mt-3">
                            External Services Delivered
                        </h5>
                        <b-row v-for='(patientExternalService, index) in patientService.patient_external_services'
                            :key='index' class='m-2'>
                            <b-col>
                                <b-form-checkbox v-model="selectedPatientServices" name="externalServices"
                                    :value="patientExternalService.id" unchecked-value="false">
                                    {{ patientExternalService.external_service.name }}
                                </b-form-checkbox>
                            </b-col>
                            <b-col>
                                <div class='badge badge-light'>
                                    {{ patientExternalService.status }}
                                </div>
                            </b-col>
                        </b-row>

                    </div>
                </div>

            </b-form-group>

            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Finish Appointment</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
        <Modals :modalName='currentModal' :modalData='currentModalData' @saved='modalCallback' @closed='modalClosed'>
        </Modals>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
export default {
    extends: BaseForm,
    props: ['appointment'],
    data: function () {
        return {
            patientServices: [],
            selectedPatientServices: [],
            otherPatientServices: [],
            selectedService: null
        }
    },
    mounted: function () {
        this.apiRequest((apiClient) => {
            var p1 = apiClient.get(
                'patient-services',
                {
                    non_reserved_services: 1,
                    not_appointment_id: this.appointment.id,
                    patient_id: this.appointment.patient_id,
                    branch_id: this.appointment.branch_id,
                    section_id: this.appointment.section_id,
                    active: 1
                }).then(response => {
                    this.otherPatientServices = response.data.filter(
                        item => {
                            return (
                                item.doctor_id == this.appointment.doctor_id
                                &&
                                item.available_appointments_count < item.required_appointments
                            );
                        }
                    ).map(item => {
                        item.remaining_appointments = item.required_appointments - item.finished_appointments_count - 1;
                        item.external_services_delivered = [];
                        return item;
                    });
                });

            var p2 = apiClient.get(
                'patient-services',
                {
                    appointment_id: this.appointment.id
                }).then(response => {
                    this.patientServices = response.data.map(item => {
                        item.remaining_appointments = item.required_appointments - item.finished_appointments_count - 1;
                        item.external_services_delivered = [];
                        return item;
                    });

                    this.selectedPatientServices = this.patientServices.map(item => item.id);
                });

            return Promise.all([p1, p2]).then(() => this.loading = false);
        });
    },
    methods: {
        newExternalService: function (patientService) {
            this.modal("patientExternalServiceForm", { patientServiceId: patientService.id }, (patientExternalService) => {
                debugger;
                patientService.patient_external_services.push(patientExternalService);
            })
        },
        save: function () {
            return this.apiRequest((apiClient) => {
                return apiClient.put(
                    'appointments/' + this.appointment.id + '/finish',
                    {
                        taken_patient_services: [...this.patientServices, ...this.otherPatientServices]
                            .filter(item => this.selectedPatientServices.indexOf(item.id) != -1 ? true : false)
                            .map(item => ({ id: item.id, remaining_appointments: item.remaining_appointments, external_services_delivered: item.external_services_delivered }))
                    }
                );
            });
        },
        afterSave: function (patientService) {
            this.$emit('saved', patientService);
        },
    }
}
</script>