<template>
    <div>
        <ListView title="InsuranceApprovals List" :collection="collection" @pageChanged='pageChanged' :fields="fields"
            :loading="loading">

            <template v-slot:filter>
                <div class='form'>
                    <div class='form-row'>
                        <div class='form-group col-md-4'>
                            <b-form-input v-model="filter.keyword" placeholder="search ..."></b-form-input>
                        </div>
                    </div>
                    <div class='form-row'>
                        <b-col>
                            <b-form-group label="Status">
                                <v-select v-model='filter.status' :options="['pending', 'approved']" :multiple='true' />

                            </b-form-group>
                        </b-col>
                    </div>

                    <div class='form-row'>
                        <b-col>
                            <b-form-group v-if='!patientId' label="Patient">
                                <patients-select v-model='filter.patient_id' returnId='true' />
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group v-if='!userBranch()' label="Branch">
                                <branches-select v-model='filter.branch_id' returnId='true' />
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group label="Section">
                                <sections-select v-model='filter.section_id' returnId='true' />
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group label="Insurance">
                                <insurance-providers-select v-model='filter.insurance_provider_id' returnId='true' />
                            </b-form-group>
                        </b-col>
                    </div>
                    <b-form-checkbox v-model="filter.finished" value="1" unchecked-value="0" :inline='true'>
                        Finished
                    </b-form-checkbox>

                    <b-form-group label="Date Range">
                        <my-date-range-picker v-model='dateRange' :style='{ display: "inline-block" }' />
                        <div class='btn btn-danger btn-xxs ml-2'
                            @click='() => dateRange = { startDate: null, endDate: null }'>x</div>
                    </b-form-group>
                </div>
            </template>

            <template v-slot:cell(patient.name)="data">

                <router-link :to="{ name: 'ViewPatient', params: { id: data.item.patient.id } }" class=''>
                    <h5>
                        {{ data.item.patient.name }}
                    </h5>
                </router-link>
            </template>

            <template v-slot:cell(card_file_group)='data'>
                <h4 v-if='data.item.patient_insurance.card_file_group && data.item.patient_insurance.card_file_group.files'>
                    <div v-for='(file, index) in data.item.patient_insurance.card_file_group.files' :key='index'>
                        <button class='badge badge-light m-1' @click='modal("fileForm", { file: file })'>
                            <i class='fa fa-image'></i>
                            {{ file.name || "no name" | str_limit(20) }}
                        </button>
                    </div>
                </h4>

                <div v-else class='badge badge-light'>No Files</div>
            </template>
            <template v-slot:cell(approval_file_group)='data'>

                <h4 v-if='data.item.approval_file_group && data.item.approval_file_group.files'>
                    <div v-for='(file, index) in data.item.approval_file_group.files' :key='index'>
                        <button class='badge badge-light m-1' @click='modal("fileForm", { file: file })'>
                            <i v-if='file.type_group == "pdf"' class='fa fa-file-pdf'></i>
                            <i v-if='file.type_group == "image"' class='fa fa-image'></i>
                            {{ file.name || "no name" | str_limit(20) }}
                        </button>
                    </div>
                </h4>

                <div v-else class='badge badge-light'>No Files</div>
            </template>

            <template v-slot:cell(insurance_provider_id)='data'>
                {{ data.item.insurance_provider.name }}
            </template>

            <template v-slot:cell(patient_services)='data'>
                <div v-for='(patientService, index) in data.item.patient_services' :key='index'>
                    <div class='badge m-2' :title='patientService.name' :class="serviceStatusBadges[patientService.status]">

                        <i class='fa fa-stethoscope'></i>
                        {{ patientService.name | str_limit(50) }}
                    </div>
                    <div class='badge badge-info'>Invoice # {{ patientService.invoice_id }}</div>

                    <div v-if='patientService.insurance_approval_id'>
                        <div class='badge badge-light'>
                            patient:
                            {{ patientService.patient_pay | currency }}
                        </div>
                        <div class='badge badge-light m-2'>
                            insurance:
                            {{ patientService.insurance_pay | currency }}
                        </div>
                    </div>
                    <div v-if='patientService.discount != 0'>
                        <div class='badge badge-primary m-2'>
                            {{ patientService.price | currency }}
                        </div>
                        <div class='badge badge-warning'>
                            Discount:
                            {{ patientService.discount | currency }}
                        </div>
                    </div>
                    <hr>
                </div>
            </template>
            <template v-slot:cell(status)='data'>
                <div v-if='data.item.status == "pending"' class='badge badge-warning'>
                    Pending
                </div>
                <div v-if='data.item.status == "approved"' class='badge badge-success'>
                    <i class='fa fa-check'></i>
                    Approved
                </div>
            </template>
            <template v-slot:cell(insurance_approval_emails)='data'>
                <div v-for='(email, index) in data.item.insurance_approval_emails' :key='index'>
                    <div class='badge badge-success'>
                        <i class='fa fa-check'></i>
                        {{ email.status }}
                    </div>
                    <div class='badge badge-success'>
                        {{ email.email }}
                    </div>

                    <div class='badge badge-light'>
                        by
                        {{ email.created_by.name }}
                    </div>
                    <div class='badge badge-light'>
                        <i class='fa fa-clock'></i>
                        {{ email.created_at | datetime }}
                    </div>

                    <hr>
                </div>
            </template>
            <template v-slot:cell(actions)='data'>
                <button v-if='can("insurance_approvals.edit")' class='btn btn-success btn-xs ml-2'
                    v-b-modal.insuranceApproval-modal @click="selectedInsuranceApprovalId = data.item.id">Edit</button>
                <button v-if='can("insurance_approvals.delete")' class='btn btn-danger btn-xs ml-2'
                    @click="deleteInsuranceApproval(data.item)">Delete</button>
                <authorized-url :url='data.item.url' target='_blank' class='btn btn-success m-2'>View Pdf</authorized-url>
                <authorized-url :url="data.item.url + '?download=1'" target='_blank'
                    class='btn btn-success m-2'>Download</authorized-url>

                <button class='btn btn-success m-2'
                    @click='modal("insuranceApprovalEmailForm", { insuranceApprovalId: data.item.id }, "refresh")'>
                    <i class='fa fa-send'></i>
                    Send
                </button>

                <button v-if='data.item.patient_services.length > 0' class='btn btn-success m-2'
                    @click='modal("invoiceForm", { patientId: data.item.patient_id, insuranceApprovalId: data.item.id, branchId: data.item.patient_services[0].branch_id })'>
                    <i class='fa fa-plus'></i>
                    Create Invoice
                </button>

                <button class='btn btn-success m-2'
                    @click='modal("insuranceApprovalPrintForm", { insuranceApproval: data.item })'>
                    <i class='fa fa-print'></i>
                    Print
                </button>

            </template>

        </ListView>
        <b-modal :no-close-on-backdrop=true id="insuranceApproval-modal" size='xl' hide-footer
            title="InsuranceApproval Form">
            <ServicesInsuranceForm @saved='insuranceApprovalSaved' :id='selectedInsuranceApprovalId'>
            </ServicesInsuranceForm>
        </b-modal>
        <LightGallery :images="selectedImages" :index="selectedImageIndex" :disable-scroll="true"
            @close="selectedImageIndex = null" />

        <Modals :modalName='currentModal' :modalData='currentModalData' @saved='modalCallback' @closed='modalClosed'>
        </Modals>
    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import ServicesInsuranceForm from '@/components/ServicesInsuranceForm';
import Modals from '../../components/Modals/Modals.vue';
import AuthorizedUrl from '../../components/AuthorizedUrl.vue';

export default {
    extends: BaseList,
    props: ['patientId'],
    components: { ServicesInsuranceForm, Modals, AuthorizedUrl },
    created() {
        this.user = this.loadUser();
    },
    methods: {
        load: function (apiClient, cancelToken) {
            if (this.patientId) {
                this.filter['patient_id'] = this.patientId;
            }
            return apiClient.get('insurance-approvals', this.filter, cancelToken);
        },
        insuranceApprovalSaved() {
            this.refresh();
            this.$bvModal.hide('insuranceApproval-modal');
        },
        deleteInsuranceApproval(insuranceApproval) {
            this.confirmAction(() => this.apiRequest(apiClient => apiClient.delete('insurance-approvals/' + insuranceApproval.id)));
        }
    },
    data: function () {
        return {
            user: null,
            dateRange: {},
            selectedImageIndex: null,
            selectedImageUrl: null,
            selectedImages: [],

            serviceStatusBadges: {
                'finished': 'badge-success',
                'in progress': 'badge-warning',
                'pending': 'badge-light',
                'virtual insurance': 'badge-info',
            },
            fields: [
                { key: 'id', label: '#' },
                { key: 'patient.name', label: 'Patient Name' },
                { key: 'insurance_provider_id', label: 'Insurance Provider' },
                { key: 'patient_services', label: 'Patient Services' },
                { key: 'card_file_group', label: 'Card' },
                { key: 'approval_file_group', label: 'Approval' },
                { key: 'status', label: 'Status' },
                { key: 'insurance_approval_emails', label: 'Emails' },
                { key: 'actions', label: '' }
            ],
            selectedInsuranceApprovalId: null
        };
    },
    watch: {
        dateRange: function () {
            var startDate = this.dateRange.startDate ? this.date(this.dateRange.startDate) : null;
            var endDate = this.dateRange.endDate ? this.date(this.dateRange.endDate) : null;


            if (startDate == this.filter.start_date && endDate == this.filter.end_date) {
                return;
            }

            this.filter = {
                ... this.filter,
                start_date: startDate,
                end_date: endDate
            };
        },
        filter: {
            handler() {
                var filter = this.filter;

                this.dateRange = {
                    startDate: filter.start_date ? filter.start_date : null,
                    endDate: filter.end_date ? filter.end_date : null
                };
            },
            deep: true
        }
    }
}
</script>
