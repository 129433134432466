<template>
    <div>

        <b-modal v-if='this.isCurrentModal("serviceProductsForm")' :no-close-on-backdrop=true size='xl' @close='closed()'
            hide-footer :visible="showModalView" title="Service Products Form">
            <service-products-form @saved='saved' v-bind='modalData' />
        </b-modal>

        <b-modal v-if='this.isCurrentModal("serviceProductsList")' :no-close-on-backdrop=true size='xl' @close='closed()'
            hide-footer :visible="showModalView" title="Service Products List">
            <service-products-list-table viewType="simple" @saved='saved' v-bind='modalData' />
        </b-modal>

        <b-modal v-if='this.isCurrentModal("finishAppointment")' :no-close-on-backdrop=true size='xl' @close='closed()'
            hide-footer :visible="showModalView" title="Finish Appointment">
            <FinishAppointmentForm @saved='saved' v-bind='modalData'></FinishAppointmentForm>
        </b-modal>
        <b-modal v-if='this.isCurrentModal("doctorView")' :no-close-on-backdrop=true size='xl' @close='closed()' hide-footer
            :visible="showModalView" title="Doctor">
            <DoctorView v-bind='modalData'></DoctorView>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("patientServiceAppointmentForm")' :no-close-on-backdrop=true size='xl'
            @close='closed()' hide-footer :visible="showModalView" title="Edit Patient Service">
            <patient-service-appointment-form @saved='saved' v-bind='modalData'></patient-service-appointment-form>
        </b-modal>


        <b-modal v-if='this.isCurrentModal("patientServiceForm")' :no-close-on-backdrop=true size='xl' @close='closed()'
            hide-footer :visible="showModalView" title="Edit Patient Service">
            <PatientServiceForm @saved='saved' v-bind='modalData'></PatientServiceForm>
        </b-modal>
        <b-modal v-if='this.isCurrentModal("patientServiceView")' :no-close-on-backdrop=true size='xl' @close='closed()'
            hide-footer :visible="showModalView" title="Patient Service">
            <PatientServiceView v-bind='modalData'></PatientServiceView>
        </b-modal>
        <b-modal v-if='this.isCurrentModal("patientInsuranceForm")' :no-close-on-backdrop=true size='l' @close='closed()'
            hide-footer :visible="showModalView" title="Patient Insurance">
            <PatientInsuranceForm @saved='saved' v-bind='modalData'></PatientInsuranceForm>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("fileGroupView")' :no-close-on-backdrop=true size='l' @close='closed()'
            hide-footer :visible="showModalView" title="Files">
            <file-group-view @saved='saved' v-bind='modalData'></file-group-view>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("fileForm")' :no-close-on-backdrop=true size='xl' @close='closed()' hide-footer
            :visible="showModalView" title="Files">
            <file-form @saved='saved' v-bind='modalData'></file-form>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("insuranceApprovalPrintForm")' :no-close-on-backdrop=true size='l'
            @close='closed()' hide-footer :visible="showModalView" title="Files">
            <insurance-approval-print-form @saved='saved' v-bind='modalData'></insurance-approval-print-form>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("fingerprintUserForm")' :no-close-on-backdrop=true size='l' @close='closed()'
            hide-footer :visible="showModalView" title="Fingerprint User">
            <fingerprint-user-form @saved='saved' v-bind='modalData'></fingerprint-user-form>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("patientNoteForm")' :no-close-on-backdrop=true size='l' @close='closed()'
            hide-footer :visible="showModalView" title="Patient Note">
            <patient-note-form @saved='saved' v-bind='modalData'></patient-note-form>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("medicalRecordForm")' :no-close-on-backdrop=true size='l' @close='closed()'
            hide-footer :visible="showModalView" title="Medical Record">
            <medical-record-form @saved='saved' v-bind='modalData'></medical-record-form>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("vendorForm")' :no-close-on-backdrop=true size='xl' @close='closed()' hide-footer
            :visible="showModalView" title="Vendor">
            <vendor-form @saved='saved' v-bind='modalData'></vendor-form>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("externalServiceForm")' :no-close-on-backdrop=true size='xl' @close='closed()'
            hide-footer :visible="showModalView" title="External Service">
            <external-service-form @saved='saved' v-bind='modalData'></external-service-form>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("externalServicesSelector")' :no-close-on-backdrop=true size='xl'
            @close='closed()' hide-footer :visible="showModalView" title="External Services">
            <external-services-selector @saved='saved' v-bind='modalData'></external-services-selector>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("medicalRecordsSelector")' :no-close-on-backdrop=true size='xl' @close='closed()'
            hide-footer :visible="showModalView" title="Medical Records">
            <medical-records-selector @saved='saved' v-bind='modalData'></medical-records-selector>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("patientExternalServiceForm")' :no-close-on-backdrop=true size='xl'
            @close='closed()' hide-footer :visible="showModalView" title="External Service">
            <patient-external-service-form @saved='saved' v-bind='modalData'></patient-external-service-form>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("patientExternalServiceView")' :no-close-on-backdrop=true size='xl'
            @close='closed()' hide-footer :visible="showModalView" title="External Service">
            <patient-external-service-view @saved='saved' v-bind='modalData'></patient-external-service-view>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("cashPaymentForm")' :no-close-on-backdrop=true size='xl' @close='closed()'
            hide-footer :visible="showModalView" title="Cash Payment">
            <cash-payment-form @saved='saved' v-bind='modalData'></cash-payment-form>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("workerPayableForm")' :no-close-on-backdrop=true size='xl' @close='closed()'
            hide-footer :visible="showModalView" title="Worker Payable">
            <worker-payable-form @saved='saved' v-bind='modalData'></worker-payable-form>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("workerForm")' :no-close-on-backdrop=true size='xl' @close='closed()' hide-footer
            :visible="showModalView" title="Worker Form">
            <worker-form @saved='saved' v-bind='modalData'></worker-form>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("optionForm")' :no-close-on-backdrop=true size='xl' @close='closed()' hide-footer
            :visible="showModalView" title="Option">
            <option-form @saved='saved' v-bind='modalData'></option-form>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("urlTagForm")' :no-close-on-backdrop=true size='xl' @close='closed()' hide-footer
            :visible="showModalView" title="Url Tag">
            <url-tag-form @saved='saved' v-bind='modalData'></url-tag-form>
        </b-modal>
        <b-modal v-if='this.isCurrentModal("salaryForm")' :no-close-on-backdrop=true size='xl' @close='closed()' hide-footer
            :visible="showModalView" title="Salary">
            <salary-form @saved='saved' v-bind='modalData'></salary-form>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("attendanceForm")' :no-close-on-backdrop=true size='xl' @close='closed()'
            hide-footer :visible="showModalView" title="Attendance">
            <attendance-form @saved='saved' v-bind='modalData'></attendance-form>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("editAppointmentForm")' :no-close-on-backdrop=true size='xl' @close='closed()'
            hide-footer :visible="showModalView" title="Edit Appointment">
            <edit-appointment-form @saved='saved' v-bind='modalData'></edit-appointment-form>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("selectPatientForm")' :no-close-on-backdrop=true size='xl' @close='closed()'
            hide-footer :visible="showModalView" title="Select Patient">
            <patients-selector @clickedItem='saved' v-bind='modalData'></patients-selector>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("mergePatientsForm")' :no-close-on-backdrop=true size='xl' @close='closed()'
            hide-footer :visible="showModalView" title="Select Patient">
            <merge-patients-form @saved='saved' v-bind='modalData'></merge-patients-form>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("appointmentsList")' :no-close-on-backdrop=true size='xl' @close='closed()'
            hide-footer :visible="showModalView" title="Appointments list">
            <appointments-list-table v-bind='modalData'></appointments-list-table>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("transactionsList")' :no-close-on-backdrop=true size='xl' @close='closed()'
            hide-footer :visible="showModalView" title="Transctions list">
            <transactions-list-table v-bind='modalData'></transactions-list-table>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("financialTransactionsList")' :no-close-on-backdrop=true size='xl'
            @close='closed()' hide-footer :visible="showModalView" title="Financial Transaction list">
            <financial-transactions-list-table v-bind='modalData'></financial-transactions-list-table>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("childAccountsList")' :no-close-on-backdrop=true size='xl' @close='closed()'
            hide-footer :visible="showModalView" title="Child Accounts list">
            <account-children-list-table v-bind='modalData'></account-children-list-table>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("servicesSelector")' :no-close-on-backdrop=true size='xl' @close='closed()'
            hide-footer :visible="showModalView" title="Services">
            <services-selector @clickedItem='saved' v-bind='modalData'></services-selector>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("servicesMap")' :no-close-on-backdrop=true size='xl' @close='closed()'
            hide-footer :visible="showModalView" title="Add Services">
            <service-map @saved='saved' v-bind='modalData'></service-map>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("patientServicesMapView")' :no-close-on-backdrop=true no-close-on-esc size='xl'
            @close='closed()' hide-footer :visible="showModalView" title="View Patient Services">
            <patient-services-map-view @saved='saved' v-bind='modalData'></patient-services-map-view>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("insuranceCollectionForm")' :no-close-on-backdrop=true size='xl'
            @close='closed()' hide-footer :visible="showModalView" title="Insurance Collection">
            <insurance-collection-form @saved='saved' v-bind='modalData'></insurance-collection-form>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("patientServicesSelector")' :no-close-on-backdrop=true size='xl'
            @close='closed()' hide-footer :visible="showModalView" title="Patient Services">
            <patient-services-selector @clickedItem='saved' v-bind='modalData'></patient-services-selector>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("transactionsSelector")' :no-close-on-backdrop=true size='xl' @close='closed()'
            hide-footer :visible="showModalView" title="Transactions">
            <transactions-selector @clickedItem='saved' v-bind='modalData'></transactions-selector>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("expenseTypeForm")' :no-close-on-backdrop=true @close='closed()' hide-footer
            :visible="showModalView" title="Expense Type">
            <expense-type-form @saved='saved' v-bind='modalData'></expense-type-form>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("paymentMethodForm")' :no-close-on-backdrop=true @close='closed()' hide-footer
            :visible="showModalView" title="Payment Method">
            <payment-method-form @saved='saved' v-bind='modalData'></payment-method-form>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("sourceOfPaymentForm")' :no-close-on-backdrop=true @close='closed()' hide-footer
            :visible="showModalView" title="Source of Payment">
            <source-of-payment-form @saved='saved' v-bind='modalData'></source-of-payment-form>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("invoiceForm")' :no-close-on-backdrop=true size='xl' @close='closed()'
            hide-footer :visible="showModalView" title="Invoice Form">
            <invoice-form @saved='saved' v-bind='modalData'></invoice-form>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("cashReturnForm")' :no-close-on-backdrop=true size='xl' @close='closed()'
            hide-footer :visible="showModalView" title="Cash Return Form">
            <cash-return-form @saved='saved' v-bind='modalData'></cash-return-form>
        </b-modal>
        <b-modal v-if='this.isCurrentModal("cashPaymentPartsSelector")' :no-close-on-backdrop=true size='xl'
            @close='closed()' hide-footer :visible="showModalView" title="Cash Payment Parts">
            <cash-payment-parts-selector @clickedItem='saved' v-bind='modalData'></cash-payment-parts-selector>
        </b-modal>
        <b-modal v-if='this.isCurrentModal("servicesInsuranceForm")' :no-close-on-backdrop=true size='xl' @close='closed()'
            hide-footer :visible="showModalView" title="Insurance Approval Form">
            <services-insurance-form @saved='saved' v-bind='modalData'></services-insurance-form>
        </b-modal>
        <b-modal v-if='this.isCurrentModal("appointmentForm")' :no-close-on-backdrop=true size='xl' @close='closed()'
            hide-footer :visible="showModalView" title="Appointment Form">
            <appointment-form @saved='saved' v-bind='modalData'></appointment-form>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("insuranceApprovalForm")' :no-close-on-backdrop=true size='xl' @close='closed()'
            hide-footer :visible="showModalView" title="Insurance Approval Form">
            <services-insurance-form @saved='saved' v-bind='modalData'></services-insurance-form>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("leadForm")' :no-close-on-backdrop=true size='xl' @close='closed()' hide-footer
            :visible="showModalView" title="Lead Form">
            <lead-form @saved='saved' v-bind='modalData'></lead-form>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("leadsSelector")' :no-close-on-backdrop=true size='xl' @close='closed()'
            hide-footer :visible="showModalView" title="Assign Lead to User">
            <leads-selector @saved='saved' v-bind='modalData'></leads-selector>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("patientForm")' :no-close-on-backdrop=true size='xl' @close='closed()'
            hide-footer :visible="showModalView" title="Patient Form">
            <patient-form @saved='saved' v-bind='modalData'></patient-form>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("insuranceApprovalEmailForm")' :no-close-on-backdrop=true size='xl'
            @close='closed()' hide-footer :visible="showModalView" title="Insurance Approval Email Form">
            <insurance-approval-email-form @saved='saved' v-bind='modalData'></insurance-approval-email-form>
        </b-modal>
        <b-modal v-if='this.isCurrentModal("auditNoteForm")' :no-close-on-backdrop=true size='xl' @close='closed()'
            hide-footer :visible="showModalView" title="Audit Note Form">
            <audit-note-form @saved='saved' v-bind='modalData'></audit-note-form>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("bodyPartGroupForm")' :no-close-on-backdrop=true size='xl' @close='closed()'
            hide-footer :visible="showModalView" title="Body Part Group Form">
            <body-part-group-form @saved='saved' v-bind='modalData'></body-part-group-form>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("fileUploadForm")' :no-close-on-backdrop=true size='xl' @close='closed()'
            hide-footer :visible="showModalView" title="Files Selector Form">
            <file-upload @saved='saved' v-bind='modalData'></file-upload>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("filesSelector")' :no-close-on-backdrop=true size='xl' @close='closed()'
            hide-footer :visible="showModalView" title="Files Selector Form">
            <files-selector @saved='saved' v-bind='modalData'></files-selector>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("importPricesForm")' :no-close-on-backdrop=true size='xl' @close='closed()'
            hide-footer :visible="showModalView" title="Import Service Prices">
            <import-prices-form @saved='saved' v-bind='modalData'></import-prices-form>
        </b-modal>



        <b-modal v-if='this.isCurrentModal("appointmentAssistantForm")' :no-close-on-backdrop=true size='xl'
            @close='closed()' hide-footer :visible="showModalView" title="Appointment Assistant">
            <appointment-assistant-form @saved='saved' v-bind='modalData'></appointment-assistant-form>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("purchaseForm")' :no-close-on-backdrop=true size='xl' @close='closed()'
            hide-footer :visible="showModalView" title="Purchase / Expense Form">
            <purchase-form @saved='saved' v-bind='modalData'></purchase-form>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("brandForm")' :no-close-on-backdrop=true size='l' @close='closed()' hide-footer
            :visible="showModalView" title="Brand">
            <brand-form @saved='saved' v-bind='modalData'></brand-form>
        </b-modal>


        <b-modal v-if='this.isCurrentModal("accountForm")' :no-close-on-backdrop=true size='l' @close='closed()' hide-footer
            :visible="showModalView" title="Account">
            <account-form @saved='saved' v-bind='modalData'></account-form>
        </b-modal>



        <LightGallery v-bind='modalData' :disable-scroll="true" @close="closed()"
            v-if='this.isCurrentModal("previewImages")' />
    </div>
</template>

<script>
import FinishAppointmentForm from '@/components/FinishAppointmentForm';
import DoctorView from '@/views/Doctors/DoctorView';
import BaseModal from '@/components/Modals/BaseModal';
import PatientServiceAppointmentForm from '@/views/Services/PatientServiceAppointmentForm.vue';
import PatientServiceView from '@/views/PatientServices/PatientServiceView.vue';
import PatientServiceForm from '@/views/Services/PatientServiceForm.vue';
import PatientInsuranceForm from '@/components/PatientInsuranceForm.vue';
import FileGroupView from '../FileGroupView.vue';
import FileForm from '../FileForm.vue';
import InsuranceApprovalPrintForm from '../InsuranceApprovalPrintForm.vue';
import FingerprintUserForm from '../../views/FingerprintUsers/FingerprintUserForm.vue';
import PatientNoteForm from '../../views/PatientNotes/PatientNoteForm.vue';
import MedicalRecordForm from '../../views/MedicalRecords/MedicalRecordForm.vue';
import VendorForm from '../../views/Vendors/VendorForm.vue';
import ExternalServiceForm from '../../views/ExternalServices/ExternalServiceForm.vue';
import ExternalServicesSelector from '../ExternalServicesSelector.vue';
import PatientExternalServiceForm from '../../views/PatientExternalServices/PatientExternalServiceForm.vue';
import PatientExternalServiceView from '../../views/PatientExternalServices/PatientExternalServiceView.vue';
import CashPaymentForm from '../../views/CashPayments/CashPaymentForm.vue';
import WorkerPayableForm from '../../views/WorkerPayables/WorkerPayableForm.vue';
import OptionForm from '../../views/Options/OptionForm.vue';
import WorkerForm from '../../views/Workers/WorkerForm.vue';
import SalaryForm from '../SalaryForm.vue';
import EditAppointmentForm from '@/views/Appointments/EditAppointmentForm.vue';
import AttendanceForm from '../AttendanceForm.vue';
import MergePatientsForm from '../MergePatientsForm.vue';
import PatientsSelector from '../PatientsSelector.vue';
import AppointmentsListTable from '../Lists/AppointmentsListTable.vue';
import ServicesSelector from '../ServicesSelector.vue';
import ServiceMap from '../../views/Services/ServiceMap.vue';
import InsuranceCollectionForm from '../../views/InsuranceProviders/InsuranceCollectionForm.vue';
import PatientServicesSelector from '../PatientServicesSelector.vue';
import ExpenseTypeForm from '@/views/ExpenseTypes/ExpenseTypeForm.vue';
import PaymentMethodForm from '@/views/PaymentMethods/PaymentMethodForm.vue';
import SourceOfPaymentForm from '@/views/SourceOfPayments/SourceOfPaymentForm.vue';
import InvoiceForm from '../InvoiceForm.vue';
import TransactionsSelector from '../TransactionsSelector.vue';
import CashPaymentPartsSelector from '../CashPaymentPartsSelector.vue';
import CashReturnForm from '@/views/CashReturns/CashReturnForm.vue';
import ServicesInsuranceForm from '../ServicesInsuranceForm.vue';
import AppointmentForm from '../AppointmentForm.vue';
import LeadForm from '../../views/Leads/LeadForm.vue';
import PatientForm from '../PatientForm.vue';
import InsuranceApprovalEmailForm from '../InsuranceApprovalEmailForm.vue';
import AuditNoteForm from '../../views/AuditNotes/AuditNoteForm.vue';
import FilesSelector from '../FilesSelector.vue';
import FileUpload from '../FileUpload.vue';
import BodyPartGroupForm from '../../views/BodyPartGroups/BodyPartGroupForm.vue';
import ImportPricesForm from '../ImportPricesForm.vue';
import PatientServicesMapView from '../../views/Patients/PatientServicesMapView.vue';
import UrlTagForm from '../../views/UrlTags/UrlTagForm.vue';
import MedicalRecordsSelector from '../MedicalRecordsSelector.vue';
import AppointmentAssistantForm from '@/views/Appointments/AppointmentAssistantForm.vue';
import PurchaseForm from '../../views/Purchase/PurchaseForm.vue';
import TransactionsListTable from '../Lists/TransactionsListTable.vue';
import FinancialTransactionsListTable from '../Lists/FinancialTransactionsListTable.vue';
import AccountChildrenListTable from '../Lists/AccountChildrenListTable.vue';
import LeadsSelector from '../LeadsSelector.vue';
import BrandForm from '../../views/Brands/BrandForm.vue';
import AccountForm from '../../views/Accounting/AccountForm.vue';
import ServiceProductsForm from '../../views/Services/ServiceProductsForm.vue';
import ServiceProductsListTable from '../Lists/ServiceProductsListTable.vue';
export default {
    extends: BaseModal,
    components: {
        FinishAppointmentForm,
        DoctorView,
        PatientServiceAppointmentForm,
        PatientServiceView,
        PatientServiceForm,
        PatientInsuranceForm,
        FileGroupView,
        FileForm,
        InsuranceApprovalPrintForm,
        FingerprintUserForm,
        PatientNoteForm,
        MedicalRecordForm,
        VendorForm,
        ExternalServiceForm,
        ExternalServicesSelector,
        PatientExternalServiceForm,
        CashPaymentForm,
        WorkerPayableForm,
        OptionForm,
        WorkerForm,
        SalaryForm,
        EditAppointmentForm,
        AttendanceForm,
        MergePatientsForm,
        PatientsSelector,
        AppointmentsListTable,
        ServicesSelector,
        ServiceMap,
        InsuranceCollectionForm,
        PatientServicesSelector,
        ExpenseTypeForm,
        PaymentMethodForm,
        SourceOfPaymentForm,
        InvoiceForm,
        TransactionsSelector,
        CashReturnForm,
        CashPaymentPartsSelector,
        ServicesInsuranceForm,
        AppointmentForm,
        LeadForm,
        LeadsSelector,
        PatientForm,
        InsuranceApprovalEmailForm,
        AuditNoteForm,
        FilesSelector,
        FileUpload,
        BodyPartGroupForm,
        PatientExternalServiceView,
        ImportPricesForm,
        PatientServicesMapView,
        UrlTagForm,
        MedicalRecordsSelector,
        AppointmentAssistantForm,
        PurchaseForm,
        TransactionsListTable,
        FinancialTransactionsListTable,
        AccountChildrenListTable,
        BrandForm,
        AccountForm,
        ServiceProductsForm,
        ServiceProductsListTable
    },
    props: ['modalName', 'modalData'],
    methods: {
        isCurrentModal(modalName) {
            return this.modalName == modalName;
        }
    },
    computed: {
        showModalView() {
            return this.modalName ? true : false;
        }
    }
}
</script>