<template>
    <div>
        <loader v-if='loading'/>
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>
            <b-form-group label="Name">
                <b-form-input v-model="worker.name" :state="states.name" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.name'/>
            </b-form-group>

            <b-form-group label="Branch">
                <branches-select v-model='worker.branch_id' :returnId='true'/>
            </b-form-group>
            <b-form-group label="Salary">
                <b-form-input v-model="worker.salary" :state="states.salary" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.salary'/>
            </b-form-group>
            
            <div class='mb-3'>
                <b-form-checkbox
                    v-model="worker.is_active"
                    :value="1"
                    unchecked-value="0"
                >
                Active
                </b-form-checkbox>
            </div>

            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
export default {
    extends: BaseForm,
    data: function () {
        return {
            worker: {
            }
        }
    },
    methods: {
        load: function(id) {
            return this.apiRequest(apiClient => apiClient.get('workers/'+id))
                .then(response => this.worker = response);
        },
        save: function (apiClient) {
            if (this.id) {
                return apiClient.put('workers/'+this.id, this.worker);
            } else {
                return apiClient.post('workers', this.worker);
            }
        },
        afterSave: function () {
            this.$emit('saved', 1);
        }
    },
}
</script>