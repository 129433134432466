<template>
    <div>
        <loader v-if='loading'/>

        <div v-if='doctor'>            
            <h3><i class='fa fa-user-md'></i> {{ doctor.name }}</h3>
            <div class='profile-tab m-3'>
                <b-nav  class='custom-tab-1'>
                    <b-nav-item :active='isCurrentRoute("DoctorAppointments")' :to="{ name: 'DoctorAppointments', params: {id: doctor.id}}">Appointments</b-nav-item>
                    <b-nav-item :active='isCurrentRoute("DoctorServices")' :to="{ name: 'DoctorServices', params: {id: doctor.id}}">Patient Services</b-nav-item>
                    <b-nav-item :active='isCurrentRoute("DoctorTransactions")' v-if='can("patient_services.doctor_fees")' :to="{ name: 'DoctorTransactions', params: {id: doctor.id}}">Transactions</b-nav-item>
                </b-nav>
            </div>
            <router-view></router-view>
        </div>
    </div>
</template>
<script>
import BaseForm from '@/views/BaseForm';

export default {
    extends: BaseForm,
    components:{
    },
    methods: {
        load: function(id) {
            return this.apiRequest(
                (apiClient) => apiClient.get('doctors/'+id)
            ).then(
                doctor => this.doctor = doctor
            );
        },
        isCurrentRoute(name) {
            return this.$route.name == name;
        }
    },
    data: function () {
        return {
            doctor: null
        }
    },
    watch: {
        id: {
            immediate: true,
            handler: function ()
            {
                this.loading = true;
                this.load(this.id)
                    .then(() => this.loading = false);
            }
        }
    }
}
</script>