<template>
  <div>
    <b-row>
        <b-col v-for="(item, index) in selectedFiles" :key='index' cols='4'>
            <div class='m-3'>
                <div style='width:100%; height:200px; background-color: #eee;' class='p-1'>
                    <object
                        v-if='item.type_group == "pdf"'
                        :data="item.url"
                        :type="item.type"
                        width="100%"
                        style="height: 200px;"
                        internalinstanceid="5"
                    >
                        
                    </object>
                    <b-img-lazy style='max-width: 100%; max-height: 100%;' blank center v-if='item.type_group == "image"' blankColor="#777" :src="item.url" alt=""/>   
                </div>
                <div class='m-2' :title="item.name">{{ item.name | str_limit(20)}}</div>
                <div class='mt-3'>
                    <a :href="item.url" class='btn btn-primary btn-xxs m-1' target='_blank'><i class='fa fa-external-link-alt'></i></a>

                    <button class='btn btn-danger btn-xxs m-1' @click="removeSelectedFile(index)">remove</button>
                </div>
            </div>
        </b-col>
    </b-row>
    <vue-dropzone ref='dropzone' id='1' @vdropzone-success='addFile' @paste.native.prevent="pasteMe($event)" contenteditable="true" :options="dropzoneOptions"></vue-dropzone>


    <div class="text-center" v-if='uploading'>
        <loading-progress
            :indeterminate="true"
            :counter-clockwise="false"
            :hide-background="false"
            rotate
            size="52"
            fillDuration="2"
            rotationDuration="10"
        /> uploading
    </div>
    
    <b-button v-if='!uploadOnly' variant='primary' @click="modal('filesSelector', {multiple: true}, 'addSelectedFiles')">Select Files</b-button>
    
    <drive-picker-button :config="gConfig" @picked="showDetails" class='m-2' >
      <i class='fab fa-google-drive'></i> google drive 
    </drive-picker-button>

    <Modals :modalName='currentModal' :modalData='currentModalData' @saved='modalCallback' @closed='modalClosed'>
    </Modals>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import DrivePickerButton from './DrivePickerButton.js'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

const baseUrl = process.env.VUE_APP_BASE_URL;

export default {
  props: ['uploadOnly'],
  components: {
    vueDropzone: vue2Dropzone,
    DrivePickerButton
  },
  created() {
    this.user = this.loadUser();

    this.gConfig = {
      // The Browser API key obtained from the Google API Console.
      developerKey: 'AIzaSyAVxpH78co8BHPjRmZ32RWDFkdsMJZn7gc',

      // The Client ID obtained from the Google API Console. Replace with your own Client ID.
      clientId: '155245747956-nre6r5r02rthjiq3stjn4s3pcv1imi1b.apps.googleusercontent.com',

      // Scope to use to access user's drive.
      scope: 'https://www.googleapis.com/auth/drive'
    }
  }, 
  data: function () {
      return {
          uploading: false,
          user: {},
          gConfig: {},
          dropzoneOptions: {
              url: baseUrl + '/files',
              thumbnailWidth: 150,
              resizeWidth: 1200,
              resizeHeight: 1200,
              // maxFilesize: 0.5,
              resizeMimeType: 'image/jpeg',
              resizeQuality: 0.8,
              withCredentials: true,
              addRemoveLinks: true,
              acceptedFiles: 'image/*, application/pdf',
              headers: {'Authorization': 'Bearer ' + localStorage.token}
          },
          fileIds: [],
          selectedFiles: [],
      }
  },
  methods: {
    addFile(file, response) {
      this.fileIds.push(response.id);
      this.$emit('input', this.fileIds);
    },
    addSelectedFiles(files) {
      files.forEach(file => {
        this.selectedFiles.push(file);
        this.fileIds.push(file.id);
      });

      this.$emit('input', this.fileIds);
    },
    removeSelectedFile(i) {
      var fileId = this.selectedFiles[i].id;      
      var fileIdIndex = this.fileIds.indexOf(fileId);
      this.fileIds.splice(fileIdIndex, 1);
      
      this.selectedFiles.splice(i, 1);

      this.$emit('input', this.fileIds);
    },
    pasteMe(event) {
      console.log(event);
      this.$refs.dropzone.dropzone.paste(event);
    },
    showDetails (data, token) {
     if(data.action === 'picked') {
        this.apiRequest(apiClient => {
          var files = data.docs.map(item => ({id: item.id, mime_type: item.mimeType, name: item.name}));
          this.uploading = true;
          apiClient.post('files/from-drive', {files: files, token: token})
            .then(files => {
              this.addSelectedFiles(files);
              this.uploading = false;
            }).catch(() => {
              this.uploading = false;
            });
        });
     }
    }
  }
}
</script>