<template>
	<div>
		<ListView title="Appointments List" :hideHeader='hideHeader() ? true : false' :hasFilterActions='true'>
			<template v-slot:actions v-if='patientId ? false : true'>
				<router-link :to="{name: 'CreatePatient'}" class="btn btn-primary btn-sm">
					<i class="fa fa-plus"></i>
					New Patient
				</router-link>
			</template>			

			<template v-slot:filter>
				
				<div class='form'>
					<div class='form-row'>
						<b-col>
							<b-form-group label="Status">
								<b-form-radio-group
									v-model="filter.status"
									:options="['pending', 'finished', 'no show', 'cancelled', 'all']"
								></b-form-radio-group>
							</b-form-group>
						</b-col>
					</div>
					<div class='form-row'>
						<div class='form-group col-md-4'>
							<label for="">Time</label>
							<TimeRangeSlider v-model="timeRange"/>
						</div>
					</div>
					<div class='form-row'  v-if='!patientId'>
						<div class='form-group col-md-4'>
							<label for="">Patient</label>
							<b-form-input v-model='filter.keyword' placeholder="#ID - name - phone"></b-form-input>
						</div>
					</div>
					<div class='form-row'>
						<div class='col-3'>
							<div class='form-group m-2'>
								<label>Branch</label>
								<BranchesSelect v-model='filter.branch_id' :returnId='true'/>
							</div>
						</div>
						<div class='col-3' v-if='!doctorId'>
							<div class='form-group m-2'>
								<label>Section</label>
								<SectionsSelect v-model='filter.section_id' :returnId='true'/>
							</div>
						</div>
						<div class='col-3' v-if='!doctorId'>
							<div class='form-group m-2'>
								<label>Doctor</label>
								<DoctorsSelect v-model='filter.doctor_id' :returnId='true'/>
							</div>
						</div>
						<div class='col-3' v-if='!doctorId'>
							<div class='form-group m-2'>
								<label>Assistant</label>
								<DoctorsSelect v-model='filter.assistant_id' :is_assistant='1' :returnId='true'/>
							</div>
						</div>
					</div>
					<div class="row">
						<b-col>
							<b-form-group label="Confirmation"
								class="ml-2"
							>
								<b-form-radio-group
									v-model="filter.confirmation"
									:options="['All', 'confirmed', 'not confirmed']"
									name="radios-btn-default"
									buttons
									button-variant="primary"
								></b-form-radio-group>
							</b-form-group>
						</b-col>
					</div>
					<div class='row'>
						<div class='col-lg-5'>
							<b-form-group label="Date"
								class="ml-2"
							>
								<b-form-radio-group
									v-model="filter.list_type"
									:options="['All', 'Today', 'Tommorow', 'Past', 'Upcoming', 'Custom']"
									name="radios-btn-default"
									buttons
									button-variant="primary"
								></b-form-radio-group>
							</b-form-group>
							<v-date-picker v-if="filter.list_type == 'Custom'" v-model='dateRange' value="range" is-range />
						</div>

						<div class='col-lg-4'>
							<b-form-group label="Date Type" class="ml-2">
								<b-form-radio-group
									v-model="filter.date_type"
									:options="['Creation Time', 'Appointment Time']"
									name="radios-btn-default"
									buttons
									button-variant="primary"
								></b-form-radio-group>
							</b-form-group>
						</div>
					</div>
				</div>
			</template>

            <template v-slot:filter_actions>
                <authorized-url v-if='can("appointments.export")' class='btn btn-primary ml-2 float-right' url='appointments/export' :params='filter' target="_blank">
                    <i class="fa fa-file-excel"></i>
                    Export
                </authorized-url>
            </template>
			<template v-slot:table>
				<appointments-list-table :filter='filter' :viewType='viewType'></appointments-list-table>
			</template>
		</ListView>

	</div>

</template>

<script>
import BaseList from '@/views/BaseList';
import AuthorizedUrl from '../../components/AuthorizedUrl.vue';

export default {
  components: { AuthorizedUrl },
    extends: BaseList,
	props: ['patientId', 'doctorId', 'pushHistory'],
    data: function() {
        return {
			filter: {},
			timeRange: [0, 24],
			defaultFilter: {
				page: 1,
				list_type: 'All',
				date_type: 'Appointment Time',
				time_range: []
			},
			hideFields: [],
            viewType: null,
			dateRange: null,
			token:localStorage.token
        };
	},
	methods: {
		hideHeader: function () {
			return this.patientId || this.doctorId ? true : false;
		}
	},
	watch: {
		dateRange: function () {
			var startDate = this.dateRange ? this.date(this.dateRange.start) : '';
			var endDate = this.dateRange ? this.date(this.dateRange.end) : ''; 

			if (startDate == this.filter.start_date && endDate == this.filter.end_date) {
				return;
			} 
			
			this.filter = {
				... this.filter,
				start_date: startDate,
				end_date: endDate
			};
		},
		timeRange: function () {
			var startTime = this.timeRange[0] ? this.timeRange[0] : 0;
			var endTime = this.timeRange[1] ? this.timeRange[1] : 24; 

			if (startTime == this.filter.start_time && endTime == this.filter.end_time) {
				return;
			} 
			
			this.filter = {
				... this.filter,
				start_time: startTime,
				end_time: endTime
			};
		},
		patientId: {
			immediate: true,
			handler: function () {
				if (this.patientId) {
					this.viewType = 'patientView';
					this.defaultFilter.patient_id = this.patientId;
					this.filter.patient_id = this.patientId;
				}
			}
		},
		doctorId: {
			immediate: true,
			handler: function () {
				if (this.doctorId) {
					this.defaultFilter.doctor_id = this.doctorId;
					this.filter.list_type = 'Today';
					this.defaultFilter.list_type = 'Today';
					this.viewType = 'doctorView';
					this.filter.doctor_id = this.doctorId;
				}
			}
		},
        filter: {
            handler() {
				var filter = this.filter;
				if (filter.start_date && filter.end_date) {
					this.dateRange = {
						start: filter.start_date,
						end: filter.end_date
					}
				}

				if (filter.start_time && filter.end_time) {
					this.timeRange = [
						filter.start_time,
						filter.end_time
					];
				}
            },
            deep: true
        }
	}
}
</script>

<style>
	.modal.right .modal-dialog {
		position: fixed;
		margin: auto;
		width: 320px;
		height: 100%;
		-webkit-transform: translate3d(0%, 0, 0);
		    -ms-transform: translate3d(0%, 0, 0);
		     -o-transform: translate3d(0%, 0, 0);
		        transform: translate3d(0%, 0, 0);
	}

	.modal.left .modal-content,
	.modal.right .modal-content {
		height: 100%;
		overflow-y: auto;
	}
	
	.modal.left .modal-body,
	.modal.right .modal-body {
		padding: 15px 15px 80px;
	}

/*Left*/
	.modal.left.fade .modal-dialog{
		left: -320px;
		-webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
		   -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
		     -o-transition: opacity 0.3s linear, left 0.3s ease-out;
		        transition: opacity 0.3s linear, left 0.3s ease-out;
	}
	
	.modal.left.fade.in .modal-dialog{
		left: 0;
	}
        
/*Right*/
	.modal.right.fade .modal-dialog {
		right: -320px;
		-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
		   -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
		     -o-transition: opacity 0.3s linear, right 0.3s ease-out;
		        transition: opacity 0.3s linear, right 0.3s ease-out;
	}
	
	.modal.right.fade.show .modal-dialog {
		right: 0;
	}

/* ----- MODAL STYLE ----- */
	.modal-content {
		border-radius: 0;
		border: none;
	}

	.modal-header {
		border-bottom-color: #EEEEEE;
		background-color: #FAFAFA;
	}

</style>