<template>
    <div class='card'>
        <div class='card-header'>
            <h5 class="card-title">Patient Payments</h5>
            
            <router-link :to="{name: 'PatientPaymentForm'}" class="btn btn-primary btn-sm">
                <i class="fa fa-plus"></i>
                New Payment
            </router-link>
        </div>
        <div class='card-body'>
            <div class="table-responsive recentOrderTable">
                <b-table striped hover :items="items" :fields="fields">
                    <template v-slot:cell(actions)>
                        <a href="" class='btn btn-sm btn-danger'>
                            delete
                        </a>
                    </template>

                </b-table>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    data: function() {
        return {
            items: [
                {
					name: "Mahmoud Magdy",
					date: '2020-10-11',
					amount: 50,
					balance: 200
				},
                {
					name: "Osama Omar",
					date: '2020-10-11',
					amount: 120,
					balance: 300
				},
            ],
            fields: [
                { key: 'name', label: 'Patient' },
                { key: 'date', label: 'Date' },
                { key: 'amount', label: 'Amount' },
                { key: 'actions', label: 'Actions' }
            ]
        };
    }
}
</script>
