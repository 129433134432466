<template>

    <b-row>
        <b-col>
            <SimpleListView title="Patients List" :collection="collection" :fields="fields" :loading="loading">
                <template v-slot:filter>
                    <div class='form'>
                        <div class='form-group'>
                            <b-form-input v-model="filter.keyword" placeholder="#name"></b-form-input>
                        </div>
                    </div>
                </template>

                <template v-slot:cell(actions)='data'>
                    <button class='btn btn-primary' @click="itemClicked(data.item)">Select</button>
                </template>
                        
            </SimpleListView>
        </b-col>
        <b-col  v-if='multiple' cols='4'>
            <div>
                <h3>selected services</h3>
                <hr>
                <ul>
                    <li v-for='(service, index) in selected' :key='index' class='m-2'>
                        <button @click='removeItem(index)' class='btn btn-xxs btn-danger m-3'>x</button>
                        <b>#{{ service.id }}</b> - <b>{{ service.name }}</b>

                        <hr>
                    </li>
                </ul>
                <button class='btn btn-primary' v-if='selected.length > 0' @click="finish(selected)">Add</button>
                <div class="alert alert-primary" v-else>please select some services</div>
            </div>
        </b-col>


    </b-row>
</template>

<script>
import BaseList from '@/views/BaseList';

export default {
    props: ['multiple'],
    extends: BaseList,
    methods: {
        load: function (apiClient, cancelToken) {
            return apiClient.get('external-services', this.filter, cancelToken);
        },
        itemClicked: function (item) {
            if (this.multiple) {
                if (!this.selected.includes(item)) {
                    this.selected.push(item);
                }
            } else {
                this.finish(item);
            }
        },
        removeItem: function(index) {
            this.selected.splice(index, 1);
        },
        finish: function(item) {
            this.$emit('saved', item);
        }
    },
    data: function() {
        return {
            filter: {},
            selected: [],
            fields: [
                { key: 'id', label: '#' },
                { key: 'name', label: 'Name' },
                { key: 'actions', label: '' }
            ]
        };
    }
}
</script>

