<template>
    <div>
        <v-select
            @search="fetchOptions"
            @open="onOpen"
            :filterable='filterable'
            :options="options"
            :label='label'
            :value='myValue'
            v-on:input='updateValue'
            :multiple='multiple'
            :closeOnSelect='closeOnSelect'
        >

            <template #selected-option="{ name, id, phone }">
                {{id}} - {{ name }} 
                <div class='badge badge-light' v-if='can("patients.view_contact_details") && phone'>
                    <i class='fa fa-phone'></i>
                    {{ phone }}
                </div>
            </template>
            

            <template #option="{ name, id, phone}">
                {{id}} - {{ name }} 
                <div class='badge badge-light' v-if='can("patients.view_contact_details") && phone'>
                    <i class='fa fa-phone'></i>
                    {{ phone }}
                </div>
            </template>
            
        </v-select>
    </div>
</template>

<script>
import BaseRemoteSelect from './BaseRemoteSelect.vue';

export default {
    props: ['doctorId'],
    extends: BaseRemoteSelect,
    data: function() {
        return {
            label: 'name'
        }
    },
    methods: {
        load(apiClient, search, cancelToken) {
            var filter = {
                keyword: search,
                doctor_id: this.doctorId
            };

            return apiClient.get('patients', filter, cancelToken);
        },
        loadSingle(apiClient, id) {
            return apiClient.get('patients/'+id);
        }
    },
}
</script>
