<template>
    <div class="card">
        <div class="card-header">
            <h4 class="card-title">Transaction Form</h4>
        </div>
        <div class="card-body">
            <b-form class='basic-form' @submit="submit">
                <b-form-group label="Title" description="Title">
                    <b-form-input type="text" v-model='title' required placeholder="Enter Title">
                    </b-form-input>
                </b-form-group>

                <b-form-group label="Date" v-if='can("accounts.list")'>
                    <b-form-datepicker v-model="date" today-button reset-button class="mb-2">
                    </b-form-datepicker>
                </b-form-group>
                <div class='form-group'>
                    <label for="">
                        <i class='fa fa-'></i>
                        Branch
                    </label>
                    <BranchesSelect v-model='defaultBranch' />
                </div>

                <div class='form-row mb-2' v-for='(transaction, index)  in transactions' :key='index'>
                    <b-col>
                        <b-form-group label="Amount" description="Amount">
                            <b-form-input type="number" step="0.01" v-model='transaction.amount' required placeholder="Enter Amount">
                            </b-form-input>
                        </b-form-group>
                    </b-col>

                    <b-col>
                        <div class="form-group">
                            <label for="">Account</label>
                            <FinancialAccountsSelect v-model='transaction.financial_account' />
                        </div>
                    </b-col>

                    <b-col>
                        <div class="form-group">
                            <label for="">Branch</label>
                            <branches-select v-model='transaction.branch_id' :returnId="true" />
                        </div>
                    </b-col>
                    <b-col>
                        <b-form-group label="description" description="description">
                            <b-form-input type="text" v-model='transaction.name' required placeholder="Enter Title">
                            </b-form-input>
                        </b-form-group>
                    </b-col>

                    <b-col align-self="center" cols="1">
                        <b-button variant='danger' @click='removeRow(index)'>x</b-button>
                    </b-col>
                </div>
                <div class='form-row mb-2'>
                    <b-col align-self="center">
                        <b-button variant='success' @click='addTransaction'>+ Add</b-button>
                    </b-col>
                </div>
                <div class='form-group'>
                    <label>Note</label>
                    <textarea class='form-control' cols="30" rows="2"></textarea>
                </div>
                <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>
                <button type="submit" class="btn btn-primary">Save</button>
            </b-form>
        </div>
    </div>
</template>
<script>
import BaseForm from '@/views/BaseForm'
import BranchesSelect from '../../components/BranchesSelect.vue';
export default {
    components: { BranchesSelect },
    extends: BaseForm,
    data: function () {
        return {
            date: null,
            defaultBranch: null,
            title: null,
            transactions: [{
                branch_id: null,
            }, {
                branch_id: null,
            }],
        };
    },
    methods: {
        load: function (id) {
            return this.apiRequest(apiClient => apiClient.get('financial-operations/' + id))
                .then(response => {
                    this.date = response.date;
                    this.title = response.title;
                    this.transactions = response.financial_transactions;
                });
        },
        save: function (apiClient) {
            var financialOperation = {
                date: this.date,
                title: this.title,
                default_branch: this.defaultBranch ? this.defaultBranch.id : null,
                transactions: this.transactions.map(item => {
                    item.financial_account_id = item.financial_account.id;
                    return item;
                }),
            };
            if (this.id) {
                return apiClient.put('financial-operations/' + this.id, financialOperation);
            } else {
                return apiClient.post('financial-operations', financialOperation);
            }
        },
        afterSave: function () {
            this.$router.push({ name: 'TransactionsList' });
        },
        addTransaction: function () {
            this.transactions.push({
                branch_id: this.defaultBranch.id,
            });
        },
        removeRow(index) {
            this.transactions.splice(index, 1);
        },
    }
}
</script>