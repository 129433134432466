<template>
    <div>
        <base-list :collection='collection' :loading='loading' :fields='fields' :modalObject='currentModalObject' @pageChanged='pageChanged'>
            
            <template v-slot:cell(original_service)='data'>
                <div>
                    <div v-if='viewType !="patientView"' class='m-1'>
                        <router-link :to="{ name: 'ViewPatient', params: {id: data.item.patient.id}}">
                            <h5>
                                <i class='fa fa-user'></i>
                            {{ data.item.patient.name }}
                            </h5>
                        </router-link>
                        <div class='badge badge-light mt-1' v-if='can("patients.view_contact_details")'>
                            <i class='fa fa-phone-alt'></i> {{ data.item.patient.phone }}
                        </div>
                    </div>
                    <div class='badge badge-light m-2' v-if='data.item.patient_service'>
                    {{ data.item.patient_service.name | str_limit(50)}}
                    </div>
                </div>
            </template>

            <template v-slot:cell(price)='data'>
                <div>
                    {{ data.item.price | decimal }}
                </div>
            </template>
            <template v-slot:cell(external_service)='data'>
                <div>
                    <div class='badge badge-light m-2'>
                    {{ data.item.external_service.name | str_limit(50) }}
                    </div>
                    <div class='badge badge-light m-2'>
                    {{ data.item.vendor.name | str_limit(50) }}
                    </div>
                </div>
            </template>
            <template v-slot:cell(status)='data'>
                <div>
                    <div v-if='data.item.status == "pending"' class='badge badge-light m-2'>
                    {{ data.item.status }}
                    </div>

                    <div v-if='["requested", "received"].includes(data.item.status)' class='badge badge-warning m-2'>
                    {{ data.item.status }}
                    </div>

                    <div v-if='data.item.status == "delivered"' class='badge badge-success m-2'>
                    {{ data.item.status }}
                    </div>

                    <div v-if='data.item.status == "cancelled"' class='badge badge-danger m-2'>
                    {{ data.item.status }}
                    </div>
                </div>
            </template>
			<template v-slot:cell(actions)='data'>
				<button class='btn btn-xs btn-primary m-2' @click="modal('patientExternalServiceView', {id: data.item.id}, 'refresh')">view</button>
				<button class='btn btn-xs btn-primary m-2' @click="modal('patientExternalServiceForm', {id: data.item.id}, 'refresh')">Edit</button>
				<button v-if='data.item.status == "pending"' class='btn btn-xs btn-danger m-2' @click="confirmAction('deletedPatientExternalService', data.item, 'refresh')">Delete</button>
            </template>
        </base-list>
    </div>
</template>

<script>
import BaseList from './BaseList.vue';
import BaseListMethods from './BaseListMethods';

export default {
	props: ['viewType'],
    components: { BaseList },
    extends: BaseListMethods,
    data() {
        return {
			fields: [
                { key: 'id', label: 'ID' },
                { key: 'original_service', label: 'Original Service' },
                { key: 'external_service', label: 'External Service' },
                { key: 'price', label: 'Price' },
                { key: 'status', label: 'Status' },
                { key: 'actions', label: 'Actions' },
			]
        }
    },
	methods: {
        load: function (apiClient, cancelToken, filter) {
			return apiClient.get('patient-external-services', filter, cancelToken);
        },
        deletedPatientExternalService: function (item) {
            return this.apiRequest(apiClient => apiClient.delete('patient-external-services/'+item.id));
        }
    },
    watch: {

		viewType: {
			immediate: true,
			handler: function () {
			}
		},
    }
}
</script>