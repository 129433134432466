<script>

export default {
    props: ['pushHistory'],
    mounted: function () {
        this.refresh();
    },
    data: function () {
        return {
            loading: false,
            viewType: null,
            collection: {},
            filter: { page: 1 },
            defaultFilter: { page: 1 },
            cancelTokenSource: null
        };
    },
    methods: {
        refresh: function () {
            if (!this['load']) {
                return;
            }
            this.apiRequest((apiCLient, cancelTokenSource) => {
                this.cancelTokenSource = cancelTokenSource
                this.loading = true;
                return this.load(apiCLient, cancelTokenSource);
            }, this.cancelTokenSource)
                .then((response) => {

                    if (response) {
                        this.loading = false;
                        this.collection = response;
                    }
                });

        },
        pageChanged(page) {
            this.filter = { ...this.filter, page: page };
        },
        confirmAction(methodName, data) {
            this.confirm().then(
                accept => {
                    if (accept) {
                        if (typeof methodName === 'string' || methodName instanceof String) {
                            this[methodName](data).then(() => this.refresh());
                        } else {
                            methodName.call(data).then(() => this.refresh());
                        }
                    }
                }
            );
        }
    },
    watch: {
        filter: {
            handler() {
                if (this.pushHistory) {
                    var r;
                    if (JSON.stringify(this.filter) == JSON.stringify(this.defaultFilter)) {
                        r = this.pushQuery({});
                    } else {
                        r = this.pushQuery(this.filter);
                    }

                    if (r) {
                        this.refresh();
                    }
                } else {
                    this.refresh();
                }
            },
            deep: true
        },
        $route: {
            immediate: true,
            deep: true,
            handler: function () {
                var isEmpty = Object.keys(this.$route.query).length === 0 ? true : false;
                if (isEmpty) {
                    this.filter = { ...this.defaultFilter };
                } else {
                    this.filter = { ...this.$route.query };
                }
            }
        }
    }
}
</script>
