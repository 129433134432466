<template>
    <div>
        <base-list :collection='collection' :loading='loading' :fields='fields' :modalObject='currentModalObject' @pageChanged='pageChanged'>
                
        </base-list>
    </div>
</template>

<script>
import BaseList from './BaseList.vue';
import BaseListMethods from './BaseListMethods';

export default {
	props: ['viewType'],
    components: { BaseList },
    extends: BaseListMethods,
    data() {
        return {
			fields: [
            ]
        }
    },
	methods: {
        load: function (apiClient, cancelToken, filter) {
			return apiClient.get('financial-transactions', filter, cancelToken);
		},
    },
	watch: {
	}
}
</script>