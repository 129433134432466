var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-select',{attrs:{"filterable":_vm.filterable,"options":_vm.options,"label":_vm.label,"value":_vm.myValue,"multiple":_vm.multiple,"closeOnSelect":_vm.closeOnSelect},on:{"search":_vm.fetchOptions,"open":_vm.onOpen,"input":_vm.updateValue},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var name = ref.name;
var id = ref.id;
var phone = ref.phone;
return [_vm._v(" "+_vm._s(id)+" - "+_vm._s(name)+" "),(_vm.can("patients.view_contact_details") && phone)?_c('div',{staticClass:"badge badge-light"},[_c('i',{staticClass:"fa fa-phone"}),_vm._v(" "+_vm._s(phone)+" ")]):_vm._e()]}},{key:"option",fn:function(ref){
var name = ref.name;
var id = ref.id;
var phone = ref.phone;
return [_vm._v(" "+_vm._s(id)+" - "+_vm._s(name)+" "),(_vm.can("patients.view_contact_details") && phone)?_c('div',{staticClass:"badge badge-light"},[_c('i',{staticClass:"fa fa-phone"}),_vm._v(" "+_vm._s(phone)+" ")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }