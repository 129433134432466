<template>
    <div>
        <b-form class='basic-form' @submit="submit" novalidate>

            <b-form-group label="Prices File">
                <b-form-file v-model="file" :state="Boolean(file)" placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."></b-form-file>
            </b-form-group>


            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>
            <button v-if='!this.saving' type="submit" class="btn btn-primary">Import</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                importing...
            </b-button>
        </b-form>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'

export default {
    extends: BaseForm,
    data: function () {
        return {
            file: null
        }
    },
    methods: {

        save: function (apiClient) {
            let formData = new FormData();
            formData.append('file', this.file);
            return apiClient.postFormData('service-prices/import', formData);
        },
        afterSave: function () {
            this.$emit('saved');
        },
    },
}
</script>