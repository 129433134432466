<template>
    <div>
        <base-list :collection='collection' :loading='loading' :fields='fields' :modalObject='currentModalObject'
            @pageChanged='pageChanged'>

            <template v-slot:cell(patient.name)="data">

                <router-link :to="{ name: 'ViewPatient', params: { id: data.item.patient.id } }" class=''>
                    <h5>
                        {{ data.item.patient.name }}
                    </h5>
                </router-link>
                <div class='badge badge-light' v-if='can("patients.view_contact_details")'>
                    <i class='fa fa-phone-alt'></i> {{ data.item.patient.phone }}
                </div>
            </template>


            <template v-slot:cell(patient_services)='data'>
                <div v-for='(patientService, index) in data.item.patient_services' :key='index'>
                    <b-row>
                        <b-col>
                            <div class='badge badge-success m-2'>

                                <i class='fa fa-stethoscope'></i>
                                {{ patientService.name }}
                            </div>
                            <div class='badge badge-success'>
                                {{ patientService.patient_pay | currency }}
                            </div>
                            <div v-if='patientService.insurance_approval_id'>
                                <div class='badge badge-light'>
                                    patient:
                                    {{ patientService.patient_pay | currency }}
                                </div>
                                <div class='badge badge-light m-2'>
                                    insurance:
                                    {{ patientService.insurance_pay | currency }}
                                </div>
                            </div>
                            <div v-if='patientService.discount != 0'>
                                <div class='badge badge-primary m-2'>
                                    {{ patientService.price | currency }}
                                </div>
                                <div class='badge badge-warning'>
                                    Discount:
                                    {{ patientService.discount | currency }}
                                </div>
                            </div>
                        </b-col>
                        <b-col>
                            <div v-for='(appointment, index) in patientService.appointments' :key='index'>
                                <div v-if='appointment.status == "finished"' class='mt-1'>
                                    <div class='badge badge-success' v-if='appointment.status == "finished"'>
                                        <i class='fa fa-calendar'></i>
                                        {{ appointment.datetime | datetime }}
                                    </div>
                                    <br>
                                    <div class='badge badge-success mt-1'>
                                        <i class='fa fa-check'></i>
                                        finished
                                    </div>
                                </div>

                                <div v-if='appointment.status == "pending"' class='mt-1'>
                                    <div class='badge badge-light'>
                                        <i class='fa fa-calendar'></i>
                                        {{ appointment.datetime | datetime }}
                                    </div>
                                    <br />
                                    <div class='badge badge-light mt-1'>
                                        <i class='fa fa-spinner'></i>
                                        pending
                                    </div>
                                </div>

                                <div v-if='appointment.status == "no show"' class='mt-1'>
                                    <div class='badge badge-light'>
                                        <i class='fa fa-calendar'></i>
                                        {{ appointment.datetime | datetime }}
                                    </div>
                                    <br />
                                    <div class='badge badge-warning mt-1'>
                                        no show
                                    </div>
                                </div>

                                <div v-if='appointment.status == "cancelled"' class='mt-1'>
                                    <div class='badge badge-danger'>
                                        <i class='fa fa-calendar'></i>
                                        {{ appointment.datetime | datetime }}
                                    </div>
                                    <br />
                                    <div class='badge badge-danger mt-1'>
                                        cancelled
                                    </div>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                    <hr>
                </div>
            </template>

            <template v-slot:cell(actions)='data'>
                <div>
                    <button v-if='!data.item.is_claimed' @click='confirmAction("claimInvoice", data.item, "refresh")'
                        class='btn btn-primary m-2'><i class='fa fa-paper-plane'></i> Claim Invoice</button>
                    <button v-else @click='confirmAction("unclaimInvoice", data.item, "refresh")'
                        class='btn btn-danger m-2'><i class='fa fa-ban'></i> Claimed at {{ data.item.claimed_at }}</button>
                </div>

                <authorized-url :url='data.item.url' target='_blank' class='btn btn-success m-2'>View Pdf</authorized-url>
                <authorized-url :url="data.item.url + '?download=1'" target='_blank'
                    class='btn btn-success m-2'>Download</authorized-url>
                <button @click='confirmAction("deleteInvoice", data.item, "refresh")'
                    class='btn btn-danger m-2'>Delete</button>

            </template>
        </base-list>
    </div>
</template>

<script>
import BaseList from './BaseList.vue';
import BaseListMethods from './BaseListMethods';
import AuthorizedUrl from '../../components/AuthorizedUrl.vue';

export default {
    props: ['viewType'],
    components: { BaseList, AuthorizedUrl },
    extends: BaseListMethods,
    data() {
        return {
            fields: [
                { key: 'id', label: '#' },
                { key: 'patient.name', label: 'Patient' },
                { key: 'branch.name', label: 'Branch' },
                { key: 'patient_services', label: 'Services' },
                { key: 'actions', label: '' }
            ]
        }
    },
    methods: {
        load: function (apiClient, cancelToken, filter) {
            return apiClient.get('invoices', filter, cancelToken);
        },
        deleteInvoice(invoice) {
            return this.apiRequest(apiClient => apiClient.delete('invoices/' + invoice.id)).then(() => invoice);
        },
        claimInvoice(invoice) {
            return this.apiRequest(apiClient => apiClient.post('invoices/claim/' + invoice.id)).then(() => invoice);
        },
        unclaimInvoice(invoice) {
            return this.apiRequest(apiClient => apiClient.post('invoices/unclaim/' + invoice.id)).then(() => invoice);
        }
    },
    watch: {

        // viewType: {
        // 	immediate: true,
        // 	handler: function () {
        // 	}
        // },
    }
}
</script>