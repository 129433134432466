<template>
    <div>
        <b-form class='basic-form' @submit="submit" novalidate>

            <b-form-group label="Date">
                <v-date-picker v-model="invoiceDate">
                    <template v-slot="{ inputValue, inputEvents }">
                        <input class="bg-white border px-2 py-1 rounded" :value="inputValue" v-on="inputEvents" />
                    </template>
                </v-date-picker>

            </b-form-group>
            <h3>Please select services</h3>
            <b-form-group label="Services">
                <div v-for='(patientService, index) in patientServices' :key='index'>
                    <b-row>
                        <b-col cols='5'>
                            <b-form-checkbox v-model="selectedPatientServices" name="pservices" :value="patientService.id"
                                unchecked-value="false" size="lg">
                                #{{ patientService.id }}
                                -
                                {{ patientService.name }}
                            </b-form-checkbox>
                        </b-col>
                        <b-col cols='3'>
                            <div v-for='(appointment, index) in patientService.appointments' :key='index' class='m-2'>
                                <div v-if='appointment.status == "pending"' class='badge badge-light'>
                                    {{ appointment.datetime | datetime }}
                                </div>
                                <div v-else class='badge badge-success'>
                                    {{ appointment.datetime | datetime }}
                                </div>
                            </div>
                        </b-col>
                        <b-col cols='3'>
                            <div v-if='patientService.status == "finished"' class='badge badge-success'>
                                finished
                            </div>
                            <div v-else-if='patientService.status == "in progress"' class='badge badge-warning'>
                                in progress
                            </div>
                            <div v-else-if='patientService.status == "pending"' class='badge badge-light'>
                                pending
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </b-form-group>

            <div class='form-group'>
                <label>Note</label>
                <textarea v-model='notes' class='form-control'></textarea>
            </div>

            <b-form-checkbox v-model="isClaimed" :value="1" unchecked-value="0" size="lg">
                Sent to insurance company
            </b-form-checkbox>
            <b-form-group label="Claimed at" v-if="isClaimed == 1">
                <v-date-picker v-model="claimed_at">
                    <template v-slot="{ inputValue, inputEvents }">
                        <input class="bg-white border px-2 py-1 rounded" :value="inputValue" v-on="inputEvents" />
                    </template>
                </v-date-picker>
            </b-form-group>
            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Create Invoice</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
import moment from 'moment';

export default {
    extends: BaseForm,
    props: ['patientId', 'branchId', 'insuranceApprovalId'],
    data: function () {
        return {
            invoiceDate: '',
            patientServices: [],
            selectedPatientServices: [],
            notes: '',
            isClaimed: 0,
            claimed_at: ''
        }
    },
    mounted: function () {
        this.loading = true;
        this.apiRequest((apiClient) => {
            var filter = {
                patient_id: this.patientId,
                not_invoiced: 1,
                status: ["finished", "in progress", "pending"]
            };

            if (this.insuranceApprovalId) {
                filter.insuance_approval_id = this.insuranceApprovalId;
            } else {
                filter.branch_id = this.branchId;
            }

            return apiClient.get('patient-services', filter)
                .then(response => {
                    this.patientServices = response.data;
                    this.invoiceDate = moment().format('YYYY-MM-DD');
                });
        });
    },
    methods: {
        save: function () {
            return this.apiRequest((apiClient) => {
                return apiClient.post(
                    'invoices',
                    {
                        patient_id: this.patientId,
                        branch_id: this.branchId,
                        date: this.date(this.invoiceDate),
                        notes: this.notes,
                        is_claimed: this.isClaimed,
                        claimed_at: this.claimed_at,
                        patient_service_ids: this.selectedPatientServices
                    }
                );
            });
        },
        afterSave: function (invoice) {
            this.$emit('saved', invoice);
        },
    },
    watch: {
        selectedPatientServices() {
            var maxDateTime = null;
            this.patientServices.forEach(
                patientService => {
                    if (this.selectedPatientServices.indexOf(patientService.id) == -1) {
                        return;
                    }
                    patientService.appointments.forEach(
                        appointment => {
                            var dateTime = moment(appointment.datetime);
                            if (!maxDateTime) {
                                maxDateTime = dateTime;
                            } else if (dateTime.diff(maxDateTime) > 0) {
                                maxDateTime = dateTime;
                            }
                        }
                    );
                }
            );
            this.invoiceDate = maxDateTime ? maxDateTime.format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
        }
    }
}
</script>