<template>
    <div>
        <div class='card'>
            <div class="card-body m2">
                
            <div class='form'>
                <div class='row'>
                    <div class='col-3'>
                        <div class='form-group m-2'>
                            <label>Branch</label>
                            <BranchesSelect v-model='filter.branch'/>
                        </div>
                        <div class='form-group m-2'>
                            <label>Section</label>
                            <SectionsSelect v-model='filter.section'/>
                        </div>
                        <div class='form-group m-2'>
                            <label>Doctor</label>
                            <DoctorsSelect v-model='filter.doctor'/>
                        </div>

                        <b-form-group label="Status">
                            <b-form-radio-group
                                v-model="filter.status"
                                :options="['all', 'busy', 'free']"
                                name="radio-inline"
                            ></b-form-radio-group>
                        </b-form-group>
                    </div>
                    <div class='col-3'>
                        <v-date-picker v-model='filter.date_range' value="range" is-range />
                    </div>
                </div>
            </div>
            </div>

        </div>
            <router-link to="/appointments/create" class='btn btn-primary m-2'><i class='fa fa-plus'></i> New Appointment </router-link>
        <loader v-if='loading' />
        <b-card v-if='!loading'>
            <div class="table-responsive recentOrderTable">
                <b-table-simple hover small caption-top>
                    <tr v-for="(date, mainIndex) in dates" :key="mainIndex">
                        <td>
                            <b>
                                <i class='fa fa-calendar'></i>  
                                {{ date.date | date }}
                            </b>
                        </td>
                        <td>
                            <div  v-for="(item, index) in date.items" :key="index" >
                                <b-row align-v="center">
                                    <b-col>
                                        <div v-if='index==0 || date.items[index-1].doctor_id != item.doctor_id'>
                                            <strong class='m-2' >
                                                <i class='fa fa-user-md'></i>
                                                Dr. {{ doctor(item.doctor_id).name }}
                                            </strong>
                                            <div class='badge badge-light m-2'>
                                                <i class='fa fa-cube'></i>
                                                {{ doctor(item.doctor_id).section.name }}
                                            </div>
                                        </div>
                                    </b-col>
                                    <b-col cols=2>
                                        <b>
                                        {{ branch(item.branch_id).name }}
                                        </b>
                                    </b-col>
                                    <b-col>
                                        <div v-if='item.times.length > 0'>
                                            <div class='row mt-1' v-for='(time, timeIndex) in item.times' :key='timeIndex'>
                                                <div 
                                                    class='badge badge-success mr-1'
                                                    :class="time.is_working_hour? (time.is_free ? 'badge-primary' : 'badge-success') : 'badge-danger'"
                                                    :title='!time.is_working_hour? "!! Out of working hours" : ""'
                                                    @click='modal("appointmentsList", {filter: {"list_type": "Custom", "start_date":time.from, "end_date":time.to, "date_type": "Appointment Time", "doctor_id": item.doctor.id, "branch_id": item.branch.id}})'
                                                >
                                                    <strong>
                                                        {{ time.from | time }}
                                                        -
                                                        {{ time.to | time }}
                                                    </strong>
                                                </div>
                                                <div v-if='time.is_free' class='badge badge-primary ml-1' >
                                                    <strong>
                                                        Free
                                                    </strong>
                                                </div>
                                                <div v-else class='badge badge-success clickable' title='view'  @click='modal("appointmentsList", {filter: {"list_type": "Custom", "start_date":time.from, "end_date":time.to, "date_type": "Appointment Time", "doctor_id": item.doctor_id, "branch_id": item.branch_id}})'>
                                                    <strong>
                                                        {{ time.number_of_appointments }} appointments
                                                    </strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <div class='badge badge-light'>
                                                Doctor not working this day
                                            </div>
                                        </div>
                                    </b-col>
                                    <b-col>
                                        <h4 class="m2">
                                            <i class='fa fa-calendar'></i>  
                                            {{ date.date | date }}
                                        </h4>
                                    </b-col>
                                </b-row>
                                <hr/>
                            </div>
                        </td>
                    </tr>
                </b-table-simple>
            </div>
        </b-card>

		<Modals :modalName='currentModal' :modalData='currentModalData' @saved='modalCallback' @closed='modalClosed'>
		</Modals>
    </div>


</template>

<script>
import BaseList from '../BaseList.vue';

export default {
    extends: BaseList,
    methods: {
        load(apiClient, cancelToken) {
            
            var filter = {
                start_date : this.filter.date_range ? this.date(this.filter.date_range.start) : '', 
                end_date : this.filter.date_range ?  this.date(this.filter.date_range.end) : '', 
                doctor_id : this.filter.doctor ? this.filter.doctor.id : '', 
                branch_id : this.filter.branch ? this.filter.branch.id : '', 
                section_id : this.filter.section ? this.filter.section.id : '', 
                status:this.filter.status,
                hide_vacations: 1
            }
            
            return apiClient.get('calendar', filter, cancelToken).then(r => {
                this.doctors = r.doctors;
                this.branches = r.branches;
                this.dates = r.dates;
                return this.dates;
            });
        },
        chooseTime(time, doctor) {
            console.log(time)
            console.log(doctor);
        },
        doctor: function (doctorId) {
            return this.doctors.find(item => item.id == doctorId);
        },
        branch: function (branchId) {
            return this.branches.find(item => item.id == branchId);
        }
    },
    data: function () {
        return {
            dates: [],
            doctors: [],
            branches: [],
            collection: [],
            myDate: []
        }
    }
}
</script>