<template>
    <PatientServicesList v-if='id' :doctorId='id'></PatientServicesList>
</template>

<script>
import PatientServicesList from '@/views/Services/PatientServicesList';

export default {
    props: ['id'],
    components:{PatientServicesList}
}
</script>