<template>
    <div>
        <loader v-if='loading'/>
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>
            <b-form-group label="Name">
                <b-form-input v-model="insuranceProvider.name" :state="states.name" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.name'/>
            </b-form-group>
            <b-form-group label="Email">
                <b-form-input v-model="insuranceProvider.email" :state="states.email" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.email'/>
            </b-form-group>
            
            <div class='mb-3'>
                <b-form-checkbox
                    v-model="insuranceProvider.is_active"
                    :value="1"
                    unchecked-value="0"
                >
                Active
                </b-form-checkbox>
            </div>

            <div v-for='(section, index) in sections' :key='index'>
                <h5>{{ section.name }} Prices</h5>
                <hr>
                <hr>
                <b-form-group 
                    label-cols-sm="4"
                    label-cols-lg="3"
                    content-cols-sm="5"
                    content-cols-lg="5"
                    description="if empty it will be same as default service price"
                    :label="service.name"
                    v-for='service in section.services' :key='service.id'>
                    <b-form-input type="text" v-model='servicePrices[service.id].price'>
                    </b-form-input>
                </b-form-group>
                
            </div>

            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
export default {
    extends: BaseForm,
    data: function () {
        return {
            insuranceProvider: {
            },
            sections: [],
            servicePrices: []
        }
    },
    mounted: function() {
        this.apiRequest((apiClient) => {
            return apiClient.get('sections', {no_paging: true})
                .then(response => {
                    response.forEach(section => {
                        section.services = section.services.filter(item => item.is_active ? true : false);
                        section.services.forEach(service => {
                            this.servicePrices[service.id] = {price: null, service_id: service.id};
                        })
                    })
                    
                    this.sections = response;
                    this.refreshServicePrices();
                });
        });
    },
    methods: {
        load: function(id) {
            return this.apiRequest(apiClient => apiClient.get('insurance-providers/'+id))
                .then(response => {
                    this.insuranceProvider = response;
                    this.refreshServicePrices();
                });
        },
        save: function (apiClient) {
            var insuranceProvider = {
                name: this.insuranceProvider.name,
                email: this.insuranceProvider.email,
                is_active: this.insuranceProvider.is_active,
                service_prices: [ ...this.servicePrices ].filter(item => item != null)
            };
            if (this.id) {
                return apiClient.put('insurance-providers/'+this.id, insuranceProvider);
            } else {
                return apiClient.post('insurance-providers', insuranceProvider);
            }
        },
        afterSave: function () {
            this.$emit('saved', 1);
        },
        refreshServicePrices: function () {
            if (this.insuranceProvider && this.insuranceProvider.service_prices) {
                this.insuranceProvider.service_prices.forEach(item => {
                    var servicePrice = this.servicePrices[item.service_id];
                    if (servicePrice) {
                        servicePrice.price = item.price;
                    }
                });
            }
        }
    }
}
</script>