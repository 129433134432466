<template>
    <div>
        <loader v-if='loading'/>
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>
            
            <b-form-group label="Select Payable" v-if='!entityId && !this.editMode'>
                <payables-select v-model='payable' /> 
            </b-form-group>
            
            <b-form-group v-if='!this.editMode' label="Branch">
                <branches-select v-model='cashReturn.branch_id' :returnId='true'/>
            </b-form-group>
            <h3>
                <div class='badge badge-light'>
                    Total: 
                    {{ total | currency }}
                </div>
            </h3>

            <b-row>
                <b-col cols=4>
                    <b-form-group label="Payment Method"
                        v-if="['Patient', 'InsuranceProvider'].includes(cashReturn.entity_type)">
                        <payment-methods-select v-model='paymentMethod' />
                        <FormGroupError :errors='errors.payment_method_id' />
                    </b-form-group>
                    <b-form-group label="Source of Payment" v-else>
                        <source-of-payments-select v-model='sourceOfPayment' />
                        <FormGroupError :errors='errors.payment_method_id' />
                    </b-form-group>
                </b-col>
            </b-row>
            <ul>
                <li v-for='(cashPaymentPart, index) in cashPaymentParts' :key='index' class='m-2'>
                    <b-button variant='danger' class='m-2 btn btn-danger btn-xs sharp' @click='removeCashPaymentPart(index)'>x</b-button>
                    <div>
                        <div  class='badge ml-2' :class='cashPaymentPart.payable_transaction.status=="finished" ? "badge-success":"badge-light"' v-if='cashPaymentPart.payable_transaction'>
                            # {{ cashPaymentPart.payable_transaction.id }}
                            {{ cashPaymentPart.payable_transaction.title }}
                        </div>
                        <div  class='badge badge-light ml-2' v-else-if='cashPaymentPart.transaction'>{{ cashPaymentPart.transaction.title }}</div>
                        <br>
                        <b>#{{ cashPaymentPart.id }}</b> - <b>{{ cashPaymentPart.cash_payment.date | date }}</b> - <b>{{ cashPaymentPart.original_amount ? cashPaymentPart.original_amount : cashPaymentPart.amount | currency }}</b> - refund <b>{{ cashPaymentPart.amount | currency }}</b>
                    </div>
                    <b-form-group label="Amount">
                        <b-form-input v-model="cashPaymentPart.amount" type="text" placeholder="">
                        </b-form-input>
                    </b-form-group>
                    <hr>
                </li>
            </ul>

            <div class="m-2">
                <b-button variant='primary' v-if='cashReturn.entity_type && cashReturn.entity_id && cashReturn.branch_id' @click='modal("cashPaymentPartsSelector", {multiple: true, baseFilter:{entity_type: cashReturn.entity_type, entity_id: cashReturn.entity_id, branch_id: cashReturn.branch_id, down_payment: 1}}, "addCashPaymentParts")'>Select Down Payments</b-button>
            </div>
            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>

		<Modals :modalName='currentModal' :modalData='currentModalData' @saved='modalCallback' @closed='modalClosed'>
		</Modals>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
import PaymentMethodsSelect from '../../components/PaymentMethodsSelect.vue';
import SourceOfPaymentsSelect from '../../components/SourceOfPaymentsSelect.vue';

export default {
    components: { PaymentMethodsSelect, SourceOfPaymentsSelect },
    props: ['entityType', 'entityId'],
    extends: BaseForm,
    data: function () {
        return {
            editMode: false,
            payable: null,
            paymentMethod: null,
            sourceOfPayment: null,
            cashReturn: {
            },
            cashPaymentParts: []
        }
    },
    mounted() {
        this.cashReturn.entity_type = this.entityType;
        this.cashReturn.entity_id = this.entityId;
    },
    methods: {
        load: function(id) {
            this.editMode = true;
            return this.apiRequest(apiClient => apiClient.get('cash-payments/'+id))
                .then(response => {
                    this.cashReturn = response;
                    this.cashPaymentParts = response.return_cash_payment_parts;
                    if (response.payment_method_type == 'PaymentMethod') {
                        this.paymentMethod = response.payment_method;
                    } else {
                        this.sourceOfPayment = response.payment_method;
                    }
                });
        },
        save: function (apiClient) {
            var cashReturn = {
                ... this.cashReturn,
                cash_payment_parts: this.cashPaymentParts.map(item => ({id: item.id, amount: item.amount})),
                payment_method_id: ['Patient', 'InsuranceProvider'].includes(this.cashReturn.entity_type) ? this.paymentMethod.id : this.sourceOfPayment.id,
            }
            
            if (this.id) {
                return apiClient.put('cash-returns/'+this.id, cashReturn);
            } else {
                return apiClient.post('cash-returns', cashReturn);
            }
        },
        afterSave: function () {
            this.$emit('saved', 1);
        },
        addCashPaymentParts(cashPaymentParts) {
            cashPaymentParts = cashPaymentParts.map(item => {
                item.original_amount = item.amount;
                return item;
            });
            this.cashPaymentParts = [...this.cashPaymentParts, ...cashPaymentParts];
        },
        addCashPaymentPart(cashPaymentPart) {
            cashPaymentPart.original_amount = cashPaymentPart.amount;
            this.cashPaymentParts.push(cashPaymentPart)
        },
        removeCashPaymentPart(index) {
            this.cashPaymentParts.splice(index, 1);
        }
    },
    watch: {
        payable: function () {
            this.cashReturn.entity_type = this.payable.type;
            this.cashReturn.entity_id = this.payable.id;
        }
    },
    computed: {
        total: function () {
            return this.cashPaymentParts.reduce((total, cashPaymentPart) => parseFloat(cashPaymentPart.amount) + total, 0);
        }
    }
}
</script>