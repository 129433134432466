<template>
    <div>
        <base-list :collection='collection' :loading='loading' :fields='fields' :modalObject='currentModalObject' @pageChanged='pageChanged'>

			<template v-slot:cell(doctor.name)="data">
				<div class='badge badge-light m-2'>
					<i class='fa fa-user-md'></i>
					{{ data.item.doctor.name }}
				</div>
				<div v-if='data.item.assistant' class='badge badge-light m-2'>
					Assistant:
					<i class='fa fa-user-md'></i> 
					{{ data.item.assistant.name }}
				</div>
				<div>
					<div class='badge badge-light m-1'>
						<i class='fa fa-cubes'></i>
						{{ data.item.section.name }}
					</div>

					<div class='badge badge-light m-1' v-if='data.item.clinic'>
						<i class='fa fa-cube'></i>
						{{ data.item.clinic.name }}
					</div>
					<br>
					<div class='badge badge-light m-1'>
						{{ data.item.branch.name }}
					</div>
				</div>
			</template>

			<template v-slot:cell(datetime)="data">
				<div>
					<div class='badge m-1' :class="getStatusClass(data.item.status)">
						<i class='fa fa-calendar'></i>
						{{ data.item.datetime | datetime }}
					</div>
					<br>
                    <div v-if='data.item.confirmed_at' class='badge badge-success badge-sm m-2'>
                        <i class='fa fa-check'></i>
                        confirmed at:
                        {{ data.item.confirmed_at | datetime }}
                    </div>
					<br>
                	<div class='badge m-1' :class="getStatusClass(data.item.status)">
						<i class='fa fa-history'></i>
						{{ data.item.duration }} minutes
					</div>
					<br>
					<div class='badge m-1' :class="getStatusClass(data.item.status)">
						{{ data.item.status }}
					</div>
					<br>
					<b-button v-if='data.item.note' v-b-popover.hover.top="data.item.note" variant="warning" class='btn-xs mt-2'>
						<i class='fa fa-sticky-note'></i>
						Note
					</b-button>

					<div>
						<span style='font-size:10px; color: #aaa'>
							created at {{ data.item.created_at | datetime }}
						</span>
					</div>
				</div>
			</template>

			<template v-slot:cell(services)="data">
				<div v-if='viewType !="patientView"' class='m-1'>
					<router-link :to="{ name: 'ViewPatient', params: {id: data.item.patient.id}}">
						<h5>
							<i class='fa fa-user'></i>
						{{ data.item.patient.name }}
						</h5>
					</router-link>
					<div class='badge badge-light mt-1' v-if='can("patients.view_contact_details")'>
						<i class='fa fa-phone-alt'></i> {{ data.item.patient.phone }}
					</div>
				</div>
				<div 
					v-for='(patientService, index) in data.item.patient_services' 
					:key='index' 
				>
					<div class='badge m-1 text-wrap'
						:class="serviceStatusBadges[patientService.status]"
						:title='patientService.status'
						v-b-popover.click.top="patientService.status"
					>
						<i class='fa fa-stethoscope'></i>
						{{ patientService.service.name }}
					</div>
					<br>
					<div v-if='patientService.body_part' class='badge m-1'
						:class="serviceStatusBadges[patientService.status]"
					>
						{{ patientService.body_part }}
					</div>
				</div>
			</template>
			<template v-slot:cell(actions)='data'>
				<button v-if='data.item.status == "pending"' class='btn btn-xs btn-primary m-1' @click="modal('finishAppointment', {appointment: data.item}, 'refresh')">Finish Appointment</button>
				<br>
				<button v-if='can("appointments.edit")' @click="modal('editAppointmentForm', {id: data.item.id}, 'refresh')" class='btn btn-xs ml-1 btn-success'>
					Edit
				</button>
				<button v-if='can("appointments.set_assistant")' @click="modal('appointmentAssistantForm', {id: data.item.id}, 'refresh')" class='btn btn-xs ml-1 btn-success'>
					Set Assistant
				</button>
				<button v-if='data.item.status=="pending" && can("appointments.delete")' class='btn btn-danger btn-xs m-1' @click="confirmAction('deleteAppointment', data.item)">x</button>
			</template>
        </base-list>
    </div>
</template>

<script>
import BaseListMethods from './BaseListMethods';

export default {
	extends: BaseListMethods,
	props: ['viewType'],
    data: function () {
		return {
			selectedId: null,
			selectedAppointment: null,
			serviceStatusBadges: {
				'finished': 'badge-success',
				'in progress': 'badge-warning',
				'pending': 'badge-light',
			},
			statusClass: {
				'finished': 'badge-success',
				'cancelled': 'badge-danger',
				'pending': 'badge-light',
				'no show': 'badge-warning',
			},
			hideFields: [],
			fields: [
				{ key: 'id', label: '#ID' },
				{ key: 'datetime', label: 'Date' },
				{ key: 'doctor.name', label: 'Doctor' },
				{ key: 'services', label: 'Services' },
				{ key: 'assistant.name', label: 'Assistant' },
				{ key: 'actions', label: 'Actions' },
			]
		};
	},
	methods: {
        load: function (apiClient, cancelToken, filter) {
			return apiClient.get('appointments', filter, cancelToken);
		},
		deleteAppointment(appointment) {
			return this.apiRequest(apiClient => apiClient.delete('appointments/'+appointment.id)).then(() => appointment);
		},
		filterFields() {
			this.fields = this.fields.filter(item => this.hideFields.indexOf(item.key) > -1 ? false : true);
		},
		getStatusClass(status) {
			if (this.statusClass[status]) {
				return this.statusClass[status];
			}
			return "badge-light";
		}
	},
	watch: {
		viewType: {
			immediate: true,
			handler: function () {
				if (this.viewType == 'doctorView') {
					this.hideFields = ['doctor.name'];
				}
				if (this.viewType == 'patientView') {
					this.hideFields = ['patient.name'];
				}
			}
		},
		hideFields:{
			immediate: true,
			handler: function () {
				this.filterFields();
			}
		} 
	}
}
</script>