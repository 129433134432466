<template>
    <div>

        <ListView title="Products List" :collection="collection" :fields="fields" @pageChanged='pageChanged' :loading="loading" hasFilterActions='ture'>
            <template v-slot:actions>
                <b-button v-b-modal.product-modal @click="selectedProductId = null" variant='primary' class='mr-2'>
                    Create Product
                </b-button>
            </template>

            <template v-slot:filter>
                <div class='form'>
                    <div class='form-row'>
                        <div class='form-group col-md-4'>
                            <b-form-input v-model="filter.keyword" placeholder="search ..."></b-form-input>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:filter_actions>
                <authorized-url  class='btn btn-primary ml-2 float-right' url='products/export' target="_blank">
                    <i class="fa fa-file-excel"></i>
                    Export
                </authorized-url>
            </template>
            

            <template v-slot:cell(actions)='data'>
                <b-button v-b-modal.product-modal class='mr-2' @click="selectedProductId = data.item.id">Edit</b-button>
            </template>

            <template v-slot:cell(brands)='data'>
                {{ data.item.brands.map((brand) => brand.name).join(', ') }}
            </template>
            
                    
            <template v-slot:cell(stock_value)='data'>
                <div>
                    {{ data.item ? (parseFloat(data.item.stock_price) * parseFloat(data.item.stock_quantity)) : 0 | decimal}}
                </div>
            </template>
        </ListView>
        
        <b-modal :no-close-on-backdrop=true id="product-modal" hide-footer  title="Product Form">
            <ProductForm @saved='productSaved' :id='selectedProductId'></ProductForm>        
        </b-modal>
    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import ProductForm from './ProductForm';
import axios from 'axios'
import AuthorizedUrl from '../../components/AuthorizedUrl.vue';

export default {
    extends: BaseList,
    components: {ProductForm, AuthorizedUrl},
    methods: {
        load: function (apiClient, cancelToken) {
            return apiClient.get('products', this.filter, cancelToken);
        },
        productSaved() {
            this.refresh();
            this.$bvModal.hide('product-modal');
        },
        exportProductsToExcel() {
            const api = process.env.VUE_APP_BASE_URL
            axios({
                method: 'post',
                url: `${api}/products/export`,
                responseType: 'blob', // Set the response type to blob
                headers: {
                    Authorization: 'Bearer 23|lOSkeHyPcCqzh2I1qZQ27guOLO6DJcOikTkByz9x'
                }
            }).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Products.xlsx');
                document.body.appendChild(link);
                link.click();
            });
        }
        
    },
    data: function () {
        return {
            fields: [
                { key: 'id', label: '#' },
                { key: 'name', label: 'Name' },
                { key: 'sku_code', label: 'Sku Code' },
                { key: 'bar_code', label: 'Bar Code' },
                { key: 'stock_price', label: 'Stock Price' },
                { key: 'stock_quantity', label: 'Stock Quantity' },
                { key: 'stock_value', label: 'Stock Value' },
                { key: 'brands', label: 'Brands'},
                { key: 'actions', label: '' }
            ],
            selectedProductId: null
        };
    }
}
</script>
