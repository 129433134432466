<template>

    <div>
        
    <b-row>
        <b-col style='max-height:500px; overflow:auto;'>
            <b-row>
                <b-col v-for="(item, index) in collection.data" :key='index' cols='4'>
                    <div class='m-3'>
                        <div style='width:100%; height:200px; background-color: #eee;' class='p-1'>
                            <object
                                v-if='item.type_group == "pdf"'
                                :data="item.url+'?auth_token='+authToken"
                                :type="item.type"
                                width="100%"
                                style="height: 200px;"
                                internalinstanceid="5"
                            >
                                
                            </object>
                            <b-img-lazy style='max-width: 100%; max-height: 100%;' blank center v-if='item.type_group == "image"' blankColor="#777" :src="item.url+'?auth_token='+authToken" alt=""/>   
                        </div>
                        <div class='m-2' :title="item.name">{{ item.name | str_limit(20)}}</div>
                        <div class='mt-3'>
                            <a :href="item.url+'?auth_token='+authToken" class='btn btn-primary btn-xxs m-1' target='_blank'><i class='fa fa-external-link-alt'></i></a>

                            <button class='btn btn-primary btn-xxs m-1' @click="itemClicked(item)">Select</button>
                        </div>
                    </div>
                </b-col>
            </b-row>
            {{ page }}
            <div class='row justify-content-md-center' v-if='meta.last_page != 1'>
                <b-pagination
                    v-model="page"
                    :total-rows="meta.total"
                    :per-page="meta.per_page"
                    @input="page => pageChanged(page)"
                    ></b-pagination>
            </div>
        </b-col>
        <b-col  v-if='multiple' cols='4'>
            <div style='max-height:500px; overflow:auto;'>
                <h3>selected files</h3>
                <hr>
                <ul>
                    <li v-for='(item, index) in selected' :key='index' class='m-2'>
                        <div class='m-3'>
                            <div style='width:100%; height:200px; background-color: #eee;' class='p-1'>
                                <object
                                    v-if='item.type_group == "pdf"'
                                    :data="item.url+'?auth_token='+authToken"
                                    :type="item.type"
                                    width="100%"
                                    style="height: 200px;"
                                    internalinstanceid="5"
                                >
                                    
                                </object>
                                <b-img-lazy style='max-width: 100%; max-height: 100%;' blank center v-if='item.type_group == "image"' blankColor="#777" :src="item.url+'?auth_token='+authToken" alt=""/>   
                            </div>

                            <div class='mt-3'>
                                <button @click='removeItem(index)' class='btn btn-xxs btn-danger m-3'>x</button>
                                <a :href="item.url+'?auth_token='+authToken" class='btn btn-primary btn-xxs m-1' target='_blank'><i class='fa fa-external-link-alt'></i></a>

                            </div>
                        </div>
                        <hr>
                    </li>
                </ul>
            </div>
            <div class='m-3'>
                <button class='btn btn-primary' v-if='selected.length > 0' @click="finish(selected)">Add</button>
                <div class="alert alert-primary" v-else>please select some services</div>
            </div>
        </b-col>


    </b-row>
    </div>
</template>

<script>
import BaseListMethods from './Lists/BaseListMethods';

export default {
    props: ['multiple'],
    extends: BaseListMethods,
    methods: {
        load: function (apiClient, cancelToken, filter) {
            return apiClient.get('files', filter, cancelToken);
        },
        itemClicked: function (item) {
            if (this.multiple) {
                this.selected.push(item);
            } else {
                this.finish(item);
            }
        },
        removeItem: function(index) {
            this.selected.splice(index, 1);
        },
        finish: function(item) {
            this.$emit('saved', item);
        }
    },
    data: function() {
        return {
            authToken: localStorage.token,
            selected: [],
            fields: [
                { key: 'id', label: '#' },
                { key: 'name', label: 'Name' },
                { key: 'preview', label: 'Preview' },
                { key: 'actions', label: '' }
            ]
        };
    },
    watch:{
        modalObject:{
            deep: true,
            handler() {
                if (this.modalObject) {
                    this.currentModal = this.modalObject.currentModal;
                    this.currentModalData = this.modalObject.currentModalData;
                    this.modalCallback = this.modalObject.modalCallback;
                    this.modalClosed = this.modalObject.modalClosed;
                }
            }
        },
        meta: {
            immediate: true,
            handler: function () {
                if (this.meta.current_page) {
                    this.page = this.meta.current_page;
                } else {
                    this.page = 1;
                }
            }
        }
    },
    computed: {
        meta: function() {
            var meta = this.collection.meta ? this.collection.meta : {};
            return meta;
        }
    },
}
</script>

