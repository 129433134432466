
<script>
export default {
    // inject: ['apiRequest'],
    props: ['value', 'multiple', 'closeOnSelect', 'reloadOnOpen', 'id', 'returnId'],
    data: function() {
        return {
            options: [],
            label: 'name',
            filterable: false,
            selectedOption: null,
            cancelToken: null,
            loading: false,
            lastSearch: null,
            optionsData: [],
            myValue: null
        }
    },
    methods: {
        fetchOptions (search, force) {
            if (this.lastSearch == search && !force) {
                return;
            }
            this.lastSearch = search;
            this.loading = true;
            return this.apiRequest((apiClient, cancelToken) => {
                if (this.cancelToken) {
                    apiClient.cancel(this.cancelToken);
                }
                
                this.cancelToken = cancelToken;
                return this.load(apiClient, search, cancelToken);
            }).then(response => {
                    if (!response) {
                        return;
                    }
                    this.options = response.data;
                    this.cancelToken = null;
                    this.loading = false;
                });
        },
        updateValue: function (val) {
            if (this.multiple && this.returnId) {
                val = val ? val : [];
                this.$emit('input', val.map(item => item.id ? item.id : item))
            } else if (this.returnId && val && val.id) {
                this.$emit('input', val.id)
            } else {
                this.$emit('input', val)
            }
        },
        onOpen() {
            this.fetchOptions('');
        }
    },
    watch: {
        value: {
            immediate: true,
            handler() {
                if (this.multiple) {
                    if (this.value) {
                        var values = [];
                        this.value.forEach(item => {
                            if (
                                !this['loadSingle'] ||
                                item[this.label] ||
                                ((typeof item === 'object') && !item.id)
                            ) {
                                values.push(item);
                                return;
                            }

                            var id = (typeof item === 'object') ? item.id : item;
                            var foundValue = this.options.find(option => option.id == id ? true : false);
                            if (foundValue) {
                                values.push(foundValue);
                            } else {
                                this.apiRequest(apiClient => this.loadSingle(apiClient, id).then(response => {
                                    values.push(response);
                                }));
                            }
                        });

                        this.myValue = values;
                    }
                } else {
                    if (
                        !this['loadSingle'] ||
                        !this.value ||
                        this.value[this.label] ||
                        ((typeof this.value === 'object') && !this.value.id)
                    ) {
                        this.myValue = this.value;
                        return;
                    }

                    var id = (typeof this.value === 'object') ? this.value.id : this.value;
                    var foundValue = this.options.find(item => item.id == id ? true : false);
                    if (foundValue) {
                        this.myValue = foundValue;
                    } else {
                        this.apiRequest(apiClient => this.loadSingle(apiClient, id).then(response => {
                            this.myValue = response;
                        }));
                    }
                }
            }
        }
    }
}
</script>
