<template>
    <div>
        <ListView title="WorkerPayables List" :modalObject='currentModalObject' >
            <template v-slot:actions>
                <b-button @click="modal('workerPayableForm', {}, 'refreshTable')" variant='primary' class='mr-2'>
                    Create WorkerPayable
                </b-button>
                <b-button @click="modal('salaryForm', {}, 'refreshTable')" variant='primary' class='mr-2'>
                    Create Salaries
                </b-button>
                
            </template>

            <template v-slot:filter>
                <div class='form'>
                    <div class='form-row'>
                        <div class='form-group col-md-4'>
                            <b-form-input v-model="filter.keyword" placeholder="search ..."></b-form-input>
                        </div>
                    </div>
                </div>
            </template>

			<template v-slot:table>
				<WorkerPayablesListTable :filter='filter' ref='tableList' :viewType='viewType'></WorkerPayablesListTable>
			</template>
                    
        </ListView>
    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import WorkerPayablesListTable from '@/components/Lists/WorkerPayablesListTable';

export default {
    extends: BaseList,
    components: {WorkerPayablesListTable},
    methods: {
        refreshTable () {
            this.$refs.tableList.refresh();
        }
    }
}
</script>
