<template>
    <div>
        <v-select @search="fetchOptions" @open="onOpen" :filterable='filterable' :options="options" :label='label'
            :value='myValue' v-on:input='updateValue' :loading="loading">
            <template #selected-option="{ name, number, branch, accountable_type, accountable }">
                <span v-if="number">
                    {{ number }}
                </span>
                {{ name }}
                <div v-if='!branch_id && branch' class='badge badge-light ml-2'>
                    {{ branch.name }}
                </div>
                <span v-if="accountable_type && accountable">
                    <span v-if='accountable_type == "Patient"'>
                        <div class='badge badge-light ml-2'>
                            <i class='fa fa-user'></i>
                            {{ accountable.name }}
                        </div>

                        <div class='badge badge-light ml-2'>Patient</div>
                    </span>
                    <span v-if='accountable_type == "Doctor"'>
                        <div class='badge badge-light ml-2'>
                            <i class='fa fa-user-md'></i>
                            {{ accountable.name }}
                        </div>

                        <div class='badge badge-light ml-2'>Doctor</div>
                    </span>
                    <span v-if='accountable_type == "InsuranceProvider"'>
                        <div class='badge badge-light ml-2'>
                            <i class='fa fa-id-card-alt'></i>
                            {{ accountable.name }}
                        </div>

                        <div class='badge badge-light ml-2'>Insurance Provider</div>
                    </span>
                    <span v-if='accountable_type == "Supplier"'>
                        <div class='badge badge-light ml-2'>
                            <i class='fa fa-shopping-cart'></i>
                            {{ accountable.name }}
                        </div>

                        <div class='badge badge-light ml-2'>Supplier</div>
                    </span>
                </span>
            </template>
            <template #option="{ name, number, branch, accountable_type, accountable }">
                <span v-if="number">
                    {{ number }}
                </span>
                {{ name }}
                <div v-if='!branch_id && branch' class='badge badge-light ml-2'>
                    {{ branch.name }}
                </div>
                <span v-if="accountable_type && accountable">
                    <span v-if='accountable_type == "Patient"'>
                        <div class='badge badge-light ml-2'>
                            <i class='fa fa-user'></i>
                            {{ accountable.name }}
                        </div>

                        <div class='badge badge-light ml-2'>Patient</div>
                    </span>
                    <span v-if='accountable_type == "Doctor"'>
                        <div class='badge badge-light ml-2'>
                            <i class='fa fa-user-md'></i>
                            {{ accountable.name }}
                        </div>

                        <div class='badge badge-light ml-2'>Doctor</div>
                    </span>
                    <span v-if='accountable_type == "InsuranceProvider"'>
                        <div class='badge badge-light ml-2'>
                            <i class='fa fa-id-card-alt'></i>
                            {{ accountable.name }}
                        </div>

                        <div class='badge badge-light ml-2'>Insurance Provider</div>
                    </span>
                    <span v-if='accountable_type == "Supplier"'>
                        <div class='badge badge-light ml-2'>
                            <i class='fa fa-shopping-cart'></i>
                            {{ accountable.name }}
                        </div>

                        <div class='badge badge-light ml-2'>Supplier</div>
                    </span>
                </span>

            </template>
        </v-select>
    </div>
</template>

<script>
import BaseRemoteSelect from './BaseRemoteSelect.vue';

export default {
    extends: BaseRemoteSelect,
    props: ['branch_id', 'balance_type'],
    data: function () {
        return {
            label: 'name'
        }
    },
    methods: {
        load(apiClient, search, cancelToken) {
            var filter = {
                keyword: search
            };

            if (this.branch_id) {
                filter.branch_id = this.branch_id;
            }

            if (this.balance_type) {
                filter.balance_type = this.balance_type;
            }

            return apiClient.get('financial-accounts', filter, cancelToken);
        },
        loadSingle(apiClient, id) {
            return apiClient.get('financial-accounts/' + id);
        }
    },
    watch: {
        branch_id: function () {
            this.fetchOptions('', true);
        }
    }
}
</script>
