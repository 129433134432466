<template>
  <div>
    <loader v-if="loading" />
    <b-form v-if="!loading" class="basic-form" @submit="submit" novalidate>
      <b-form-group label="Name">
        <b-form-input v-model="product.name" :state="states.name" type="text" placeholder="">
        </b-form-input>
        <FormGroupError :errors="errors.name" />
      </b-form-group>
      <b-form-group label="Brand">
        <BrandsSelect multiple :closeOnSelect="false" v-on:input="(brand) => associateBrand(brand)" />
      </b-form-group>
      <FormGroupError :errors="errors.name" />
      <b-form-group label="Bar Code">
        <b-form-input v-model="product.bar_code" :state="states.bar_code" type="text" placeholder="">
        </b-form-input>
        <FormGroupError :errors="errors.bar_code" />
      </b-form-group>
      <b-form-group label="Sku Code">
        <b-form-input v-model="product.sku_code" :state="states.sku_code" type="text" placeholder="">
        </b-form-input>
        <FormGroupError :errors="errors.sku_code" />
      </b-form-group>

      <b-form-group label="Stock Price">
        <b-form-input v-model="product.stock_price" :state="states.stock_price" type="text" placeholder="">
        </b-form-input>
        <FormGroupError :errors="errors.stock_price" />
      </b-form-group>

      <FormErrors :hasErrors="hasErrors" :errors="errors"></FormErrors>

      <button v-if="!this.saving" type="submit" class="btn btn-primary">
        Save
      </button>

      <b-button v-if="this.saving" variant="primary" :disabled="true">
        <b-spinner small type="grow"></b-spinner>
        saving...
      </b-button>
    </b-form>
  </div>
</template>

<script>
import BaseForm from "@/views/BaseForm";
import BrandsSelect from "../../components/BrandsSelect.vue";
export default {
  extends: BaseForm,
  data: function () {
    return {
      product: {},
      brandIds: [],
    };
  },
  methods: {
    load: function (id) {
      return this.apiRequest((apiClient) =>
        apiClient.get("products/" + id)
      ).then((response) => (this.product = response));
    },
    save: function (apiClient) {
      console.log(this.brandIds);
      if (!this.brandIds) {
        if (this.id) {
          return apiClient.put("products/" + this.id, this.product);
        } else {
          return apiClient.post("products", this.product);
        }
      } else if (this.id) {
        return apiClient.put("products/" + this.id, {
          product: this.product,
          brandIds: this.brandIds,
        });
      } else {
        return apiClient.post("products/with-brand", {
          product: this.product,
          brandIds: this.brandIds,
        });
      }
    },
    afterSave: function () {
      this.$emit("saved", 1);
    },
    associateBrand(brand) {
      for (let i = 0; i <= brand.length; i++) {
        if (brand[i] && this.brandIds.includes(brand[i].id)) {
          continue;
        } else {
          this.brandIds.push(brand[i].id);
        }
      }
    },
  },
  components: { BrandsSelect },
};
</script>
