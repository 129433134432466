<template>
    <div>
        <loader v-if='loading'/>
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>
            <b-form-group label="Name">
                <b-form-input v-model="brand.name" :state="states.name" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.name'/>
            </b-form-group>
            
            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
export default {
    extends: BaseForm,
    data: function () {
        return {
            brand: {
            }
        }
    },
    methods: {
        load: function(id) {
            return this.apiRequest(apiClient => apiClient.get('brands/'+id))
                .then(response => this.brand = response);
        },
        save: function (apiClient) {
            if (this.id) {
                return apiClient.put('brands/'+this.id, this.brand);
            } else {
                return apiClient.post('brands', this.brand);
            }
        },
        afterSave: function () {
            this.$emit('saved', 1);
        }
    },
}
</script>