<template>

    <b-row>
        <b-col>
            <SimpleListView title="Patients Services List" :collection="collection" :fields="fields" :loading="loading">
                
                <template v-slot:filter>
                </template>
                <template v-slot:cell(description)='data'>
                    <div :title='data.item.description'>
                        {{ data.item.description | str_limit(100) }}
                    </div>
                </template>

                <template v-slot:cell(title)='data'>
                    <div :title='data.item.title'>
                        {{ data.item.title | str_limit(50) }}
                    </div>
                </template>
            
                <template v-slot:cell(created_by)='data'>
                    <div class='badge badge-light'>
                        <i class='fa fa-user'></i>
                        {{ data.item.created_by.name }}
                    </div>
                    <br>
                    <div class='badge badge-light mt-2'>
                    {{ data.item.created_at | datetime }}
                    </div>
                </template>

                <template v-slot:cell(file_group)='data'>
                    <h4 v-if='data.item.file_group && data.item.file_group.files'>
                        <div 
                            v-for='(file,index) in data.item.file_group.files' :key='index'
                        >
                            <button
                                class='badge badge-light m-1'
                                @click='modal("fileForm", {file: file})'
                            >
                                <i class='fa fa-image'></i>
                                {{ file.name || "no name" | str_limit(20) }}
                            </button>
                        </div>
                    </h4>

                    <div v-else class='badge badge-light'>No Files</div>
                </template>
                <template v-slot:cell(actions)='data'>
                    <button class='btn btn-primary' @click="itemClicked(data.item)">Select</button>
                </template>
                        
            </SimpleListView>
        </b-col>

        <b-col  v-if='multiple' cols='4'>
            <div>
                <h3>selected Records</h3>
                <hr>
                <ul>
                    <li v-for='(medicalRecord, index) in selected' :key='index' class='m-2'>
                        <button @click='removeItem(index)' class='btn btn-xxs btn-danger m-3'>x</button>
                        <b>#{{ medicalRecord.id }}</b> - <b>{{ medicalRecord.title }}</b>

                        <hr>
                    </li>
                </ul>
                <button class='btn btn-primary' v-if='selected.length > 0' @click="finish(selected)">Add</button>
                <div class="alert alert-primary" v-else>please select some records</div>
            </div>
        </b-col>

    </b-row>
</template>

<script>
import BaseList from '@/views/BaseList';

export default {
    extends: BaseList,
    props: ['baseFilter', 'multiple'],
    methods: {
        load: function (apiClient, cancelToken) {
            return apiClient.get('medical-records', {... this.filter, ...this.baseFilter}, cancelToken);
        },
        itemClicked: function (item) {
            if (this.multiple) {
                this.selected.push(item);
            } else {
                this.finish(item);
            }
        },
        removeItem: function(index) {
            this.selected.splice(index, 1);
        },
        finish: function(item) {
            this.$emit('clickedItem', item);
            this.$emit('saved', item);
        }
    },
    data: function() {
        return {
			serviceStatusBadges: {
				'finished': 'badge-success',
				'in progress': 'badge-warning',
				'pending': 'badge-light',
				'virtual insurance': 'badge-info',
			},
            selected: [],
            fields: [
                { key: 'id', label: '#' },
                { key: 'title', label: 'Title' },
                { key: 'description', label: 'Description' },
                { key: 'file_group', label: 'Files' },
                { key: 'created_by', label: 'Created By' },
                { key: 'actions', label: '' }
            ]
        };
    }
}
</script>

