<template>
    <div>
        <div class='text-right mb-2' v-if='totals'>
            <h3>
                Total Cost
                {{ totals.total_cost | currency }}
            </h3>
        </div>
        <base-list :collection='collection' :loading='loading' :fields='fields' :modalObject='currentModalObject'
            @pageChanged='pageChanged'>

            <template #cell(service_name)='data'>
                <b-button v-if="viewType != 'simple'" size="xs" class="px-3" variant="primary"
                    @click="modal('serviceProductsForm', { id: data.item.service_id }, 'refresh')">
                    {{ data.item.service.name }}
                </b-button>
                <span v-else>{{ data.item.service.name }}</span>
            </template>
            <template #cell(product_name)='data'>
                <b-button v-if="viewType != 'simple'" size="xs" class="px-3" variant="secondary"
                    @click="modal('serviceProductsList', { filter: { product_id: data.item.product_id } }, '')">
                    {{ data.item.product.name }}
                </b-button>
                <span v-else>{{ data.item.product.name }}</span>
            </template>

            <template #cell(service_price)='data'>
                {{ data.item.service.price | currency }}
            </template>


            <template #cell(product_stock_price)='data'>
                {{ data.item.product.stock_price | currency }}
            </template>

            <template #cell(cost)='data'>
                {{ (data.item.product.stock_price * data.item.amount) | currency }}
            </template>
        </base-list>
    </div>
</template>

<script>
import BaseList from './BaseList.vue';
import BaseListMethods from './BaseListMethods';

export default {
    props: ['viewType'],
    components: { BaseList },
    extends: BaseListMethods,
    created() {
        this.loadTotals();
    },
    data() {
        return {
            fields: [
                { key: 'id', label: '#' },
                { key: 'service_name', label: 'Service' },
                { key: 'product_name', label: 'Product' },
                { key: 'amount', label: 'Amount' },
                { key: 'product_stock_price', label: 'Stock Price' },
                { key: 'cost', label: 'Cost' },
                { key: 'service_price', label: 'Service Price' },
            ],
            totals: null
        }
    },
    methods: {
        load: function (apiClient, cancelToken) {
            return apiClient.get('service-products', this.filter, cancelToken);
        }
        ,
        loadTotals() {
            return this.apiRequest(
                (apiClient, cancelTokenSource) => {
                    this.totalsCancelToken = cancelTokenSource;
                    return apiClient.get('service-products/totals', this.filter, cancelTokenSource)
                }, this.totalsCancelToken
            )
                .then(response => this.totals = response);
        }
    },
    watch: {
        filter: {
            handler() {
                this.loadTotals();
            },
            deep: true
        },
        // viewType: {
        // 	immediate: true,
        // 	handler: function () {
        // 	}
        // },
    }
}
</script>