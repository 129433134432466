<template>
    <div>
        <div v-if='collection'>
            <loader v-if='loading'/>

            <div v-for='(note, index) in collection.data' :key='index'>
                <div class='alert alert-primary' @click="modal('patientNoteForm', {id: note.id}, 'refresh')">
                    <small class="d-block">{{ note.created_at | datetime }} By {{ note.created_by.name }} </small>
                    <p class='text m-2'>
                        <pre>{{ note.text }}</pre>
                    </p>
                </div>
            </div>
        </div>

		<Modals :modalName='currentModal' :modalData='currentModalData' @saved='modalCallback' @closed='modalClosed'>
		</Modals>
    </div>
</template>

<script>
import BaseListMethods from './BaseListMethods';

export default {
	props: ['viewType'],
    extends: BaseListMethods,
	methods: {
        load: function (apiClient, cancelToken, filter) {
			return apiClient.get('patient-notes', filter, cancelToken);
		}
    }
}
</script>