<template>
    <div class='card'>
        <div class='card-body'>
            <h2>New Appointment</h2>
            <hr>
            <AppointmentForm @saved='saved'></AppointmentForm>        

        </div>
        
    </div>
</template>

<script>
import AppointmentForm from '@/components/AppointmentForm';

export default {
    components: {
        AppointmentForm
    },
    methods: {
        saved: function() {
            this.$router.push({name: 'Appointments'});
        }
    }
}
</script>