<template>
    <div>

        <ListView title="Sections List" :collection="collection" :fields="fields" :loading="loading">
            <template v-slot:actions>
                <b-button v-if='can("sections.create")'  v-b-modal.section-modal @click="selectedId = null" variant='primary' class='mr-2'>
                    Create Section
                </b-button>
            </template>

            <template v-slot:filter>
                <div class='form'>
                    <div class='form-row'>
                        <div class='form-group col-md-4'>
                            <b-form-input v-model="filter.name" placeholder="search ..."></b-form-input>
                        </div>
                    </div>
                </div>
            </template>

            <template v-slot:cell(actions)='data'>
                <b-button  v-if='can("sections.edit")' v-b-modal.section-modal class='mr-2' @click="selectedId = data.item.id">Edit</b-button>
            </template>
                    
                    
        </ListView>
        
        <b-modal :no-close-on-backdrop=true id="section-modal" hide-footer  title="Create Section">
            <SectionForm @saved='sectionCreated' :id='selectedId'></SectionForm>        
        </b-modal>
    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import SectionForm from './SectionForm';

export default {
    extends: BaseList,
    components: {SectionForm},
    methods: {
        load: function (apiClient, cancelToken) {
            return apiClient.get('sections', this.filter, cancelToken);
        },
        sectionCreated() {
            this.refresh();
            this.$bvModal.hide('section-modal');
        }
    },
    data: function() {
        return {
            fields: [
                { key: 'id', label: '#' },
                { key: 'name', label: 'Name' },
                { key: 'actions', label: '' }
            ],
            selectedId: null
        };
    }
}
</script>
