<template>
    <div>
         <Doughnut
            :chart-options="chartOptions"
            :chart-data="chartData"
        />
    </div>
</template>

<script>
import { Doughnut } from 'vue-chartjs/legacy';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale
} from 'chart.js'

import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, ChartDataLabels)

export default {
    props: ['filter'],
    components: { Doughnut },
    mounted: function () {
        this.refresh();
    },
    methods: {
        load: function (apiClient, cancelToken, filter) {
			return apiClient.get('leads/summary', filter, cancelToken);
		},
        refresh: function () {
            this.loading = true;

            this.apiRequest((apiCLient, cancelTokenSource) => {
                this.cancelTokenSource = cancelTokenSource
                return this.load(apiCLient, cancelTokenSource, {...this.filter});
            }, this.cancelTokenSource)
                .then((response) => {
                    if (response) {
                        this.data = response.data;
                        this.loading = false;
                    }
                });
        }
    },
    data: function () {
        return {
            loading: false,
            data: null,
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    datalabels: {
                        formatter: (value, categories) => {

                            let sum = 0;
                            let dataArr = categories.chart.data.datasets[0].data;
                            dataArr.map(data => {
                                sum += data;
                            });
                            let percentage = (value*100 / sum).toFixed(2)+"%";
                            return percentage;


                        },
                        color: '#000',
                    }
                }
            }
        };
    },
    watch: {
        filter: {
            handler() {
                this.refresh();
            },
            deep: true
        },
    },
    computed: {
        chartData: function () {
            var labels = ['pending', 'booked', 'follow up', 'not interested'];
            var backgroundColors = ['#ecf906', '#60c23d', '#c936b8', '#c33c50'];
            var data = [0, 0, 0, 0];
            if (this.data) {
                this.data.forEach(x => {
                    var index = labels.indexOf(x.status);
                    data[index] = x.count;
                });
            }

            return {
                labels: labels,
                datasets: [
                    {
                        label: 'Status',
                        backgroundColor: backgroundColors,
                        data: data
                    }
                ]
            };
        },
    }
}
</script>