<template>
    <div>
        
    </div>
</template>
<script>
export default {
    created() {
        var doctor = this.userDoctor();
        if (doctor) {
            this.$router.push({
                name: 'DoctorAppointments',
                params: {id: doctor.id}});
        } else if (this.userMultipleDoctors()) {
            this.$router.push({name: 'Appointments'});
        } else {
            this.$router.push({name: 'Calendar'});
        }
    }
}
</script>