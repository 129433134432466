<template>

    <SimpleListView title="Appointments List" :collection="collection" :fields="fields" :loading="loading">
        <template v-slot:filter>
            <div class='form'>
                <div class='form-group'>
                    <b-form-checkbox size='lg' v-model='filter.upcoming' value='1'>
                        <b>Upcoming</b>
                    </b-form-checkbox>
                </div>
            </div>
        </template>
        <template v-slot:cell(datetime)='data'>
            {{ data.item.datetime | datetime }}
        </template>
        <template v-slot:cell(actions)='data'>
            <button class='btn btn-primary' @click="$emit('clickedItem', data.item)">Select</button>
        </template>
                
        <template v-slot:cell(services)="data">
            <div 
                v-for='(patientService, index) in data.item.patient_services' 
                :key='index' 
            >
                <div class='badge badge-success badge-lg m-2'
                    :class="serviceStatusBadges[patientService.status]"
                    :title='patientService.status'
                    v-b-popover.click.top="patientService.status"
                >
                    <i class='fa fa-stethoscope'></i>
                    {{ patientService.service.name }}
                </div>
            </div>
        </template>
    </SimpleListView>
</template>

<script>
import BaseList from '@/views/BaseList';

export default {
    props: ['doctorId', 'patientId', 'branchId'],
    extends: BaseList,
    methods: {
        load: function (apiClient, cancelToken) {
            this.filter.patient_id = this.patientId;
            this.filter.doctor_id = this.doctorId;
            this.filter.branch_id = this.branchId;

            this.filter.date_type = 'Appointment Time';
            return apiClient.get('appointments', this.filter, cancelToken);
        }
    },
    data: function() {
        return {
            hideFinished: true,
			serviceStatusBadges: {
				'finished': 'badge-success',
				'in progress': 'badge-warning',
				'pending': 'badge-light',
			},
            fields: [
                { key: 'id', label: '#' },
                { key: 'datetime', label: 'Date Time' },
                { key: 'services', label: 'Services' },
                { key: 'status', label: 'Status' },
                { key: 'actions', label: '' }
            ]
        };
    }
}
</script>

