<template>
    <div>
        <a :href="!isMobile() ? 'https://web.whatsapp.com/send?phone=+2'+phone : 'whatsapp://send?phone=+2'+phone" target="_blank">
            <slot></slot>
        </a>
            
    </div>
</template>

<script>
export default {
    props: ['phone']
}
</script>