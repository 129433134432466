<template>
    <div>
        <b-form class='basic-form' @submit="submit" novalidate>
            <div class='form-group'>
                <label for="">
                    <i class='fa fa-user-md'></i>
                    Doctor
                </label>
                <DoctorsSelect v-model='appointmentDoctor' working='1'/>
            </div>
            
            <div class='form-group' v-if='appointmentDoctor && appointmentDoctor.branches.length > 1'>
                <label for="">
                    <i class='fa fa-'></i>
                    Branch
                </label>
                <BranchesSelect v-model='branch' :doctorId='appointmentDoctor.id'/>
            </div>

            <div class='form-group' v-if='appointmentDoctor && branch'>
                <label for="">
                    <i class='fa fa-'></i>
                    Clinic
                </label>
                <ClinicsSelect v-model='clinic' :sectionId='appointmentDoctor.section_id' :branchId='branch.id'/>
            </div>
            <div class='form-group'>
                <label>
                    <i class='fa fa-user'></i>
                    Patient
                </label>
                <div>
                    <div v-if='appointmentPatient' class='badge badge-lg badge-light ml-2'>
                        {{ appointmentPatient.name}}
                    </div>
                </div>
                <div class='m-2'>
                    <b-button v-b-modal.modal-1 variant='success' size="sm" class='mr-2'>Select Patient</b-button>
                    <b-button v-b-modal.modal-3 variant='success' size="sm">+ New Patient</b-button>
                </div>
            </div>
            <div v-if='appointmentPatient' >

                <b-form-group
                    label="Patient Services"
                >
                        <b-form-checkbox-group
                                id="checkbox-group-1"
                                v-model="selectedPatientServices"
                                :options="patientServicesOptions"
                                stacked
                                size="lg"
                            ></b-form-checkbox-group>
                </b-form-group>
                
            </div>

            <div v-for='(service, index) in services' :key='index'>
                <strong>
                    {{ service.name }}
                </strong>
            </div>
            <div class='mt-3 mb-3' v-if='appointmentDoctor'>
                <b-button variant='primary' class='btn btn-primary btn ml-2' v-b-modal.modal-services>Add Service</b-button>
            </div>
            <div class=form-group>
                <label for="">
                    <i class='fa fa-calendar'></i> Time
                </label>
                
                <div v-for='(datetime, index) in dates' :key='index' class='m-2'>
                    <div class='badge badge-lg badge-light'>
                        <i class='fa fa-calendar'></i>
                        {{ datetime.date | datetime }}
                    </div>
                    <div class='badge badge-lg badge-light ml-2'>
                        {{ datetime.duration }}
                        minutes
                    </div>
                    <div class='btn btn-primary btn-xs sharp ml-2' @click='removeDate(index)'>
                        <i class='fa fa-remove'></i>
                    </div>
                </div>

                <div class='m-2'>
                    <b-button v-b-modal.modal-2 class='mr-2' variant='success' :disabled='!this.appointmentDoctor || !this.branch'>+ Add</b-button>
                </div>
            </div>
            
            <div class='form-group'>
                <label>Note</label>
                <textarea v-model='appointment.note' class='form-control'></textarea>
            </div>

            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
        
        <b-modal :no-close-on-backdrop=true id="modal-1" hide-footer  title="Select Patient">
            <PatientsSelector @clickedItem='getClickedItem'></PatientsSelector>        
        </b-modal>

        <b-modal :no-close-on-backdrop=true id="modal-2" size='lg' hide-footer  title="Choose Date">
            <AppointmentPicker @datetimePicked='dateClicked' :branch_id='branch ? branch.id:null'  :doctor_id='appointmentDoctor ? appointmentDoctor.id:null'></AppointmentPicker>        
        </b-modal>

        <b-modal :no-close-on-backdrop=true id="modal-3" hide-footer  title="Create Patient">
            <PatientForm @saved='createdPatient'></PatientForm>        
        </b-modal>

        <b-modal :no-close-on-backdrop=true id="modal-services" hide-footer  title="Select Service" v-if='appointmentDoctor'>
            <ServicesSelector @clickedItem='serviceSelected' :forCreate='true' :sectionId='appointmentDoctor.section_id' ></ServicesSelector>        
        </b-modal>
    </div>
</template>

<script>
import PatientForm from '@/components/PatientForm';
import PatientsSelector from '@/components/PatientsSelector';
import AppointmentPicker from '@/components/AppointmentPicker';
import BaseForm from '@/views/BaseForm';
import ServicesSelector from '@/components/ServicesSelector';

export default {
    extends: BaseForm,
    props: ['patient', 'doctor', 'patientId', 'doctorId', 'branchId'],
    components: {
        PatientForm,
        PatientsSelector,
        AppointmentPicker,
        ServicesSelector
    },
    mounted() {
        
        if (this.patientId) {
            this.apiRequest(apiClient => {
                return apiClient.get('patients/'+this.patientId);
            })
            .then(patient => {
                this.appointmentPatient = patient;
                this.loadPatientServices();
            });
        } else if (this.patient) {
            this.appointmentPatient = this.patient;
            this.loadPatientServices();
        }
        
        if (this.doctor || this.doctorId) {
            this.apiRequest(apiClient => {
                return apiClient.get('doctors/'+ (this.doctor ? this.doctor.id : this.doctorId));
            })
            .then(doctor => {
                this.appointmentDoctor = doctor;
            });
        }

        if (this.branchId) {
            this.apiRequest(apiClient => {
                return apiClient.get('branches/'+ this.branchId);
            })
            .then(branch => {
                this.branch = branch;
            });
        }
    },
    data: function () {
        return {
            branch: null,
            appointmentPatient: null,
            appointmentDoctor: null,
            dates: [
            ],
            patientServicesOptions: [],
            selectedPatientServices: [],
            services: [],
            clinic: null
        }
    },
    methods: {
        load: function(id) {
            return this.apiRequest(apiClient => apiClient.get('patients/'+id))
                .then(response => {
                    this.appointmentPatient = response;
                });
        },
        save: function (apiClient) {
            if (this.id) {
                return apiClient.put('appointments/'+this.id, this.appointment);
            } else {
                return apiClient.post('appointments', this.appointment);
            }
        },
        afterSave: function (appointments) {
            this.$emit('saved', appointments);
        },
        getClickedItem(patient) {
            this.$bvModal.hide('modal-1');
            this.appointmentPatient = patient;
        },
        loadPatientServices() {
            if (!this.appointmentDoctor || ! this.appointmentPatient) {
                return;
            }
            this.patientServicesOptions = this.appointmentPatient.active_services ?
                this.appointmentPatient.active_services
                    .filter(
                        item => {
                            return (
                                item.doctor_id == this.appointmentDoctor.id
                                &&
                                item.available_appointments_count < item.required_appointments
                            );
                        }
                    )
                    .map(
                        item => {
                            return {text: item.id + " - " +item.name, value: item.id}
                        }
                    ) : [];
        },
        dateClicked(item) {
            this.$bvModal.hide('modal-2');
            
            this.dates.push({date: item.date, duration: item.duration});
            // this.dates.push(
            //     {
            //         date: 'Wednesday 12 Oct, 2020',
            //         time: '6 PM - 6:30 PM'
            //     }
            // );
        },
        removeDate(i) {
            this.dates.splice(i, 1);
        },
        createdPatient(patient) {
            this.$bvModal.hide('modal-3');
            this.appointmentPatient = patient;
        },
        serviceSelected(service) {
            this.$bvModal.hide('modal-services');
            
            this.services.push(service);
        },
    },
  watch: {
      appointmentPatient () {
            this.loadPatientServices();
      },
      appointmentDoctor() {
        this.loadPatientServices();
        this.services = [];
        if (this.appointmentDoctor.branches.length == 1) {
            this.branch = this.appointmentDoctor.branches[0];
        } else {
            var foundBranch = this.appointmentDoctor.branches.find(item => item.id == this.branch.id);
            if (!foundBranch) {
                this.branch = null;
            }
        }
      }
  },
  computed: {
      isValid: function () {
          return this.appointmentPatient && (this.dates.length > 0);
      },
      appointment: function() {
          return {
              patient_id: this.appointmentPatient ? this.appointmentPatient.id : null,
              branch_id: this.branch ? this.branch.id : null,
              clinic_id: this.clinic ? this.clinic.id : null,
              doctor_id: this.appointmentDoctor ? this.appointmentDoctor.id : null,
              dates: this.dates.length > 0 ? this.dates.map(item => ({date: this.datetime(item.date), duration: item.duration})) : [],
              patientServiceIds: this.selectedPatientServices,
              service_ids: this.services.map(item => item.id),
              note: this.note
          };
      }
  }
}
</script>