<template>
    <div>
    </div>
</template>
<script>
export default {
    props: ['filter'],
    mounted: function () {
        this.refresh();
    },
    data: function() {
        return {
            loading: false, 
            loadingPromise: Promise.resolve(),
            collection: {},
            page: 1,
            cancelTokenSource: null,
            hideFields: [],
            fields: []
        };
    },
    methods: {
        filterFields: function () {
			this.fields = this.fields.filter(item => this.hideFields.indexOf(item.key) > -1 ? false : true);
        },
        refresh: function (item) {
            if (item && item.id) {
                var foundItemIndex = this.collection.data.findIndex(singleItem => singleItem.id == item.id);
                var data = [...this.collection.data];
                if (foundItemIndex >= 0) {
                    data[foundItemIndex]._loading = true;
                    this.collection.data = data;
                }
                this.apiRequest((apiCLient, cancelTokenSource) => {
                    this.cancelTokenSource = cancelTokenSource
                    return this.load(apiCLient, cancelTokenSource, {...this.filter, in_ids: [item.id], page: this.page});
                })
                .then((response) => {
                    if (response) {
                        var foundItemIndex = this.collection.data.findIndex(singleItem => singleItem.id == item.id);
                        var data = [...this.collection.data];
                        if (response.data.length > 0) {
                            if (foundItemIndex >= 0) {
                                var x = response.data.find(sItem => sItem.id == item.id);
                                if (x) {
                                    data[foundItemIndex] = x;
                                } else {
                                    data.splice(foundItemIndex, 1);
                                }
                            } else {
                                data.push(response.data[0]);
                            }
                        } else {
                            data.splice(foundItemIndex, 1);
                        }

                        this.collection.data = data;
                    }
                });
                return;
            }

            this.loading = true;
            this.loadingPromise = 
                this.apiRequest((apiCLient, cancelTokenSource) => {
                    this.cancelTokenSource = cancelTokenSource
                    return this.load(apiCLient, cancelTokenSource, {...this.filter, page: this.page});
                }, this.cancelTokenSource)
                    .then((response) => {
                        if (response) {
                            this.collection = response;
                            this.loading = false;
                        }
                    });
        },
        pageChanged(page) {
            this.page = page;
            this.refresh();
        },
        confirmAction(methodName, data) {
            this.confirm().then(
                accept => {
                    if (accept) {
                        if (typeof methodName === 'string' || methodName instanceof String) {
                            this[methodName](data).then((item) => this.refresh(item));
                        } else {
                            methodName.call(data).then((item) => this.refresh(item));
                        }
                    }
                }
            );
        }
    },
    watch: {
        filter: {
            handler() {
                this.page = 1;
                this.refresh();
            },
            deep: true
        },
		hideFields:{
			immediate: true,
			handler: function () {
				this.filterFields();
			}
		}
    }
}
</script>