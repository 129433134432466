<template>
    <div class="card">
        
        <div class="card-header">
            <h5 class="card-title">Income Statement</h5>
        </div>
        <div class="card-body">
            <div class='form'>
                <div class='form-row'>
                    <div class='col-3'>
                        <div class='form-group m-2'>
                            <label>Branch</label>
                            <BranchesSelect v-model='filter.branch_id' :returnId='true' />
                        </div>
                    </div>
                    <div class='col-2'>
                      <b-form-group label="Start Date">
                        <v-date-picker v-model="dateRange.start">
                          <template v-slot="{ inputValue, inputEvents }">
                            <input class="bg-white border px-2 py-1 rounded" :value="inputValue" v-on="inputEvents" />
                          </template>
                        </v-date-picker>
                      </b-form-group>
                    </div>
                    <div class='col-2'>
                        <b-form-group label="End Date">
                        <v-date-picker v-model="dateRange.end">
                          <template v-slot="{ inputValue, inputEvents }">
                            <input class="bg-white border px-2 py-1 rounded" :value="inputValue" v-on="inputEvents" />
                          </template>
                        </v-date-picker>
                      </b-form-group>
                    </div>
                </div>

                <div>
                    <b-form-checkbox v-model="filter.hide_zero_balance" value="1" unchecked-value="0" :inline='true'>
                        Hide zero balance
                    </b-form-checkbox>
                </div>
                <div class="form-row">
                    <div>
                        <b-button variant="primary" class="btn-xs m-3" @click="expandAll">
                            + Expand All
                        </b-button>
                    </div>
                </div>
            </div>


            <div class="row">
                <authorized-url v-if='can("financial_accounts.export") || 1' class='btn btn-primary ml-2 float-right'
                    url='financial-accounts/export' :params='{...filter, no_parent: 1, statement: "INCOME STATEMENT", full_tree: 1}' target="_blank">
                    <i class="fa fa-file-excel"></i>
                    Export
                </authorized-url>
            </div>

            <div class="m-3">
                <h4 v-if="filter.start_date">
                    From : {{ filter.start_date | date }}
                </h4>
                <h4 v-if="filter.end_date">
                    To : {{ filter.end_date | date }}
                </h4>
            </div>
            <div v-for="(account, index) in incomeStatement.gross_profit_accounts" :key="index">
                <b-row class="p-2">
                    <b-col>
                        <b-row>
                            <b-col cols="6" center>
                                <b>
                                    {{ account.name }}
                                    <b-badge variant="light" class="ml-1">{{ account.number }}</b-badge>
                                </b>
                            </b-col>
                            <b-col  cols="2">{{ filter.branch_id ? account.branch_balance : account.balance_v | decimal}}</b-col>
                            <b-col  cols="4">{{ account.accumulated_balance | decimal}}</b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="1">
                    </b-col>
                </b-row>
                <accounts-tree ref="tree" :parentAccount="account" :filter="filter"/>
            </div>
            <b-row class="p-2 totals-row">
                <b-col>
                    <b-row>
                        <b-col cols="6" center>
                            <b>
                                Gross Profit
                            </b>
                        </b-col>
                        <b-col  cols="2">{{ incomeStatement.total_gross_profit.balance | decimal}}</b-col>
                        <b-col  cols="4">{{ incomeStatement.total_gross_profit.accumulated_balance | decimal}}</b-col>
                    </b-row>
                </b-col>
                <b-col cols="1">
                </b-col>
            </b-row>

            <div v-for="(account, index) in incomeStatement.expenses_accounts" :key="index">
                <b-row class="p-2">
                    <b-col>
                        <b-row>
                            <b-col cols="6" center>
                                <b>
                                    <b-badge variant="light" class="ml-1">{{ account.number }}</b-badge>
                                    {{ account.name }}
                                </b>
                            </b-col>
                            <b-col  cols="2">{{ filter.branch_id ? account.branch_balance : account.balance_v | decimal}}</b-col>
                            <b-col  cols="4">{{ account.accumulated_balance | decimal}}</b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="1">
                    </b-col>
                </b-row>
                <accounts-tree ref="tree" :parentAccount="account" :filter="filter"/>
            </div>
            <b-row class="p-2 totals-row">
                <b-col>
                    <b-row>
                        <b-col cols="6" center>
                            <b>
                                Net Income
                            </b>
                        </b-col>
                        <b-col  cols="2">{{ incomeStatement.total_net_income.balance | decimal}}</b-col>
                        <b-col  cols="4">{{ incomeStatement.total_net_income.accumulated_balance | decimal}}</b-col>
                    </b-row>
                </b-col>
                <b-col cols="1">
                </b-col>
            </b-row>
        </div>
    </div>
</template>
  
<style scoped>
.hoverable:hover {
    background-color: #eee;
}
.totals-row {
    font-weight: bold;
    background-color: #eee;
}
</style>
<script>
import BaseList from '@/views/BaseList';
import AccountsTree from './AccountsTree.vue';
import AuthorizedUrl from '../../components/AuthorizedUrl.vue';
// import moment from 'moment';

export default {
  components: { AccountsTree, AuthorizedUrl },
    extends: BaseList,
    data: function () {
        return {
            incomeStatement: null,
            incomeTotal: 0,
            expensesTotal: 0,
			dateRange: {
                start: null,
                end: null,
            },
            // defaultFilter: {
            //     start_date: moment().startOf('year').format('YYYY-MM-DD'),
            //     end_date: moment().endOf('year').format('YYYY-MM-DD')
            // },
            defaultTotals: {
                balance: 0,
                accumulated_balance: 0,
                ending_balance: 0,
            },
        };
    },
    computed: {
        netIncome() {
            if (!this.$refs.grossProfit || !this.$refs.expenses) {
                return this.defaultTotals;
            } 
            return {
                balance: this.$refs.grossProfit.totals.balance - this.$refs.expenses.totals.balance,
                accumulated_balance: this.$refs.grossProfit.totals.accumulated_balance - this.$refs.expenses.totals.accumulated_balance
             };
        },
    },
    watch: {
		dateRange: {
            handler () {
                var startDate = this.dateRange && this.dateRange.start ? this.date(this.dateRange.start) : '';
                var endDate = this.dateRange && this.dateRange.end ? this.date(this.dateRange.end) : ''; 

                if (startDate == this.filter.start_date && endDate == this.filter.end_date) {
                    return;
                } 
                
                this.filter = {
                    ... this.filter,
                    start_date: startDate,
                    end_date: endDate
                };
            },
            deep: true
        },
        filter: {
            handler() {
				var filter = this.filter;

                this.dateRange = {
                    start: filter.start_date,
                    end: filter.end_date
                }
            },
            deep: true
        }
    },
  methods: {
    load(apiClient, cancelToken) {
        return apiClient.get('financial-accounts/income-statement', this.filter, cancelToken)
                .then((response) => {
                    this.incomeStatement = response;
                });
    },
    expandAll: function () {
        this.$refs.tree
            .forEach(item => {
                item.expandAll();
            });
    },
  },
};
</script>