<template>
    <div>
        <div class="table-responsive recentOrderTable">

            <div class='text-right m-2' v-if='meta.total'>
                <h3>
                    count: {{ meta.total ? meta.total : ''}}
                </h3>
            </div>
            <b-table hover :items="collection.data" :fields="fields"
                responsive
                :tbody-tr-class="rowClass"
                :busy="loading">
                <template #table-busy>
                    <loader/>
                </template>
                <template v-for="slotName in Object.keys($scopedSlots)" v-slot:[slotName]="slotScope">
                    <slot :name="slotName" v-bind="slotScope"></slot>
                </template>
            </b-table>

            <div class='row justify-content-md-center' v-if='meta.last_page != 1'>
                <b-pagination
                    v-model="currentPage"
                    :total-rows="meta.total"
                    :per-page="meta.per_page"
                    @input="$emit('pageChanged', currentPage)"
                    ></b-pagination>
            </div>
            <Modals :modalName='currentModal' :modalData='currentModalData' @saved='modalCallback' @closed='modalClosed'>
            </Modals>
        </div>
    </div>
</template>

<script>
export default {
    props: ["collection", "fields", "loading", "modalObject"],
    watch:{
        modalObject:{
            deep: true,
            handler() {
                if (this.modalObject) {
                    this.currentModal = this.modalObject.currentModal;
                    this.currentModalData = this.modalObject.currentModalData;
                    this.modalCallback = this.modalObject.modalCallback;
                    this.modalClosed = this.modalObject.modalClosed;
                }
            }
        },
        meta: {
            immediate: true,
            handler: function () {
                if (this.meta.current_page) {
                    this.currentPage = this.meta.current_page;
                } else {
                    this.currentPage = 1;
                }
            }
        }
    },
    data: function() {
        return {
            currentPage: 1,
        };
    },
    methods: {
        rowClass: function (item, type) {
            if (item && type === 'row') {
                if (item._loading === true) {
                    return 'loading'
                }
            }
        }
    },
    computed: {
        meta: function() {
            var meta = this.collection.meta ? this.collection.meta : {};
            return meta;
        }
    },
}
</script>

<style>
    tr.loading {
        position: relative;
    }

    tr.loading td {
        opacity: 0.1;
    }

    tr.loading:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center; 
         
        width: 40px;
        height: 40px;
        margin: 5px auto;
        background-color: #333;

        border-radius: 100%;  
        -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
        animation: sk-scaleout 1.0s infinite ease-in-out;
        
    }

    @-webkit-keyframes sk-scaleout {
    0% { -webkit-transform: scale(0) }
    100% {
        -webkit-transform: scale(1.0);
        opacity: 0;
    }
    }

    @keyframes sk-scaleout {
    0% { 
        -webkit-transform: scale(0);
        transform: scale(0);
    } 100% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
        opacity: 0;
    }
    }
</style>>
