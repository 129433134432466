<template>
    <div>
        <loader v-if='loading'/>
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>
            
            <b-row>
                <b-col>
                    <b-form-group label="From">
                        <b-form-input v-model="workingHour.from" type="time" placeholder="HH:mm"/>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group label="To">
                        <b-form-input v-model="workingHour.to" type="time" placeholder="HH:mm"/>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-form-group label="Branch">
                <v-select :options="branches"  :state="states.branch_id" :reduce="branch => branch.id" label='name' v-model='workingHour.branch_id'></v-select>
                <FormGroupError :errors='errors.branch_id'/>
            </b-form-group>

            <b-form-group label="Days">
                <v-select :options="days" multiple :reduce="day => day.value" v-model='workingHour.week_days'></v-select>
            </b-form-group>
        
            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
export default {
    extends: BaseForm,
    props: ['workerId'],
    data: function () {
        return {
            branches: [],
            workingHour: 
                {
                    from: '',
                    to: '',
                    week_days: [1, 2, 3, 4, 5, 6]
                },
            days: [
                {label: 'Saturday', value: 1},
                {label: 'Sunday', value: 2},
                {label: 'Monday', value: 3},
                {label: 'Tuesday', value: 4},
                {label: 'Wednesday', value: 5},
                {label: 'Thuresday', value: 6},
                {label: 'Friday', value: 7},
            ]
        }
    },
    mounted() {
        this.apiRequest(apiClient => apiClient.get('branches'))
            .then(response => {
                this.branches = response.data;
            });

        this.workingHour.worker_id = this.workerId;
    },
    methods: {
        load: function(id) {
            return this.apiRequest(apiClient => apiClient.get('working-hours/'+id))
                .then(response => this.workingHour = response);
        },
        save: function (apiClient) {
            if (this.id) {
                return apiClient.put('working-hours/'+this.id, this.workingHour);
            } else {
                return apiClient.post('working-hours', this.workingHour);
            }
        },
        afterSave: function () {
            this.$emit('saved', 1);
        }
    },
}
</script>