<template>
    <div>
        <base-list :collection='collection' :loading='loading' :fields='fields' :modalObject='currentModalObject' @pageChanged='pageChanged'>
        </base-list>
    </div>
</template>

<script>
import BaseListMethods from './BaseListMethods';

export default {
	extends: BaseListMethods,
	props: ['viewType'],
    data: function () {
		return {
			fields: [
                { key: 'doctor_name', label: 'Doctor' },
                { key: 'branch_name', label: 'Branch' },
                { key: 'service_name', label: 'Service' },
                { key: 'number_of_services', label: 'Number Of Services' },
                { key: 'total_price', label: 'Total Price' },
			]
		};
	},
	methods: {
        load: function (apiClient, cancelToken, filter) {
			return apiClient.get('patient-services/summary', filter, cancelToken);
		}
	}
}
</script>