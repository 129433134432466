<template>
    <div>
        <ListView title="Leads List" :modalObject='currentModalObject'>
            <template v-slot:actions>
                <b-button @click="modal('leadForm', {}, 'refreshTable')" variant='primary' class='mr-2'>
                    Create Lead
                </b-button>
                <b-button @click="modal('leadsSelector', {}, 'refreshTable')" variant='primary' class='mr-2'>
                    Assign Lead To User
                </b-button>
            </template>

            <template v-slot:filter>
                <div class='form'>
                    <div class='form-row'>
                        <div class='form-group col-md-4'>
                            <b-form-input v-model="filter.keyword" placeholder="search ..."></b-form-input>
                        </div>
                    </div>

                    <div class='form-row'>
                        <b-col>
                            <b-form-group label="Status">
                                <b-form-radio-group v-model="filter.status"
                                    :options="['all', 'pending', 'follow up', 'booked', 'not interested']"></b-form-radio-group>
                            </b-form-group>
                        </b-col>
                    </div>
                    <div class='form-row'>
                        <div class='col-3'>
                            <div class='form-group m-2'>
                                <label>Branch</label>
                                <BranchesSelect v-model='filter.branch_id' :returnId='true' />
                            </div>
                        </div>
                        <div class='col-3'>
                            <div class='form-group m-2'>
                                <label>Assigned user</label>
                                <users-select v-model='filter.user_id' :returnId='true' />
                            </div>
                        </div>
                        <div class='col-3'>
                            <div class='form-group m-2'>
                                <label>Section</label>
                                <SectionsSelect v-model='filter.section_id' :returnId='true' />
                            </div>
                        </div>
                    </div>

                    <b-form-group>
                        <b-form-checkbox v-model="filter.summary" value="1" unchecked-value="0" :inline='true'>
                            Summary
                        </b-form-checkbox>

                        <b-form-checkbox v-model="filter.unassigned" value="1" unchecked-value="0" :inline='true'>
                            Unassigned
                        </b-form-checkbox>
                    </b-form-group>
                </div>
            </template>

            <template v-slot:table>
                <leads-chart v-if='filter.summary == 1' :filter='filter' ref='tableList' :viewType='viewType'></leads-chart>
                <LeadsListTable v-else :filter='filter' ref='tableList' :viewType='viewType'></LeadsListTable>
            </template>

        </ListView>
    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import LeadsListTable from '@/components/Lists/LeadsListTable.vue';
import LeadsChart from '../../components/Charts/LeadsChart.vue';
import UsersSelect from '../../components/UsersSelect.vue';

export default {
    extends: BaseList,
    components: { LeadsListTable, LeadsChart, UsersSelect },
    data: function () {
        return {
            defaultFilter: { status: 'pending' }
        }
    },
    methods: {
        refreshTable() {
            this.$refs.tableList.refresh();
        }
    }
}
</script>
