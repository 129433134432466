import Vue from "vue";
import App from "./App.vue";

import router from "./router";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'vue-progress-path/dist/vue-progress-path.css'
import VueProgress from 'vue-progress-path'
import VueMobileDetection from "vue-mobile-detection";
import VueCompositionAPI from '@vue/composition-api'
import vueDebounce from 'vue-debounce';
import '@/globalComponents'

import 'bootstrap-vue/dist/bootstrap-vue.css'

import Cleave from 'cleave.js';
import "cleave.js/dist/addons/cleave-phone.eg";

import VCalendar from 'v-calendar';
import VueUploadComponent from 'vue-upload-component'

import VueCookies from 'vue-cookies'
import LightGallery from 'vue-light-gallery';
import ClinicApiClient from '@/api/ClinicClient';
import VueNativeNotification from 'vue-native-notification'
import VueEcho from 'vue-echo-laravel';
import axios from 'axios';
import VueHtmlToPaper from 'vue-html-to-paper';


const GlobalApiClient = new ClinicApiClient;
Vue.GlobalApiClient = Vue.prototype.GlobalApiClient = GlobalApiClient;
Vue.prototype.$eventHub = new Vue();

Vue.use(LightGallery);
Vue.use(VueCookies);
Vue.use(VueCompositionAPI);
Vue.use(VueMobileDetection);
Vue.use(vueDebounce);
const printOptions = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css'
  ]
}
Vue.use(VueHtmlToPaper, printOptions);

Vue.component('file-upload', VueUploadComponent);

window.Cleave = Cleave;
window.Pusher = require('pusher-js');

Vue.config.productionTip = false;


// Vue.use(VueCleaveDirective);

// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

if ('Notification' in window) {
  // API supported
  Vue.use(VueNativeNotification, {
    // Automatic permission request before
    // showing notification (default: true)
    requestOnNotify: true
  });
  Vue.notification.requestPermission();
} else {
  // API not supported
}

const headers = {
  'Content-Type': 'application/json',
  Authorization: window.localStorage.getItem('token')
    ? `Bearer ${window.localStorage.getItem('token')}`
    : '',
  'Access-Control-Allow-Credentials': true,
};

Vue.use(VueEcho, {
  broadcaster: 'pusher',
  key: process.env.VUE_APP_MIX_PUSHER_APP_KEY,
  cluster: process.env.VUE_APP_MIX_PUSHER_APP_CLUSTER,
  forceTLS: true,
  authorizer: (channel) => {
    return {
      authorize: (socketId, callback) => {
        axios
          .post(
            process.env.VUE_APP_BASE_URL + '/broadcasting/auth',
            {
              socket_id: socketId,
              channel_name: channel.name,
            },
            { headers: headers }
          )
          .then((response) => {
            callback(false, response.data);
          })
          .catch((error) => {
            callback(true, error);
          });
      },
    };
  },
});


Vue.use(VueProgress, {
  defaultShape: 'circle',
})

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, { timezone: 'UTC' });



Vue.component('v-select', vSelect);

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
