<template>
    <div>
        <base-list :collection='collection' :loading='loading' :fields='fields' :modalObject='currentModalObject' @pageChanged='pageChanged'>
        </base-list>
    </div>
</template>

<script>
import BaseListMethods from './BaseListMethods';

export default {
	extends: BaseListMethods,
    props: ['isExpenses', 'expenseGroup'],
    data: function () {
		return {
			fields: [
			]
		};
	},
    isExpenses: function () {
        this.refresh();
    },
    expenseGroup: function () {
        this.refresh();
    },
	methods: {
        load: function (apiClient, cancelToken, filter) {
            var f = {expenses: this.isExpenses ? 1 : 0 , ...filter, expense_group: this.isExpenses ? this.expenseGroup : null};
			return apiClient.get('purchases/summary', f, cancelToken);
		}
	}
}
</script>