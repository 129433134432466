<template>
    <div>
        <base-list :collection='collection' :loading='loading' :fields='fields' :modalObject='currentModalObject' @pageChanged='pageChanged'>
            
			<template v-slot:cell(actions)='data'>
				<button class='btn btn-xs btn-primary m-2' @click="modal('workerPayableForm', {id: data.item.id}, 'refresh')">Edit</button>
				<button class='btn btn-xs btn-danger m-2' @click="deleteWorkerPayable(data.item)">Delete</button>
            </template>
        </base-list>
    </div>
</template>

<script>
import BaseList from './BaseList.vue';
import BaseListMethods from './BaseListMethods';

export default {
	props: ['viewType'],
    components: { BaseList },
    extends: BaseListMethods,
    data() {
        return {
			fields: [
                { key: 'id', label: 'ID' },
                { key: 'worker.name', label: 'Name' },
                { key: 'date', label: 'Date' },
                { key: 'amount', label: 'Amount' },
                { key: 'type', label: 'Type' },
                { key: 'actions', label: 'Actions' },
			]
        }
    },
	methods: {
        load: function (apiClient, cancelToken, filter) {
			return apiClient.get('worker-payables', filter, cancelToken);
		},

        deleteWorkerPayable(workerPayable) {
            this.confirmAction(
                () => {
                    return this.apiRequest(apiClient => apiClient.delete('worker-payables/'+workerPayable.id));
                }, workerPayable
            );
        }
    },
    watch: {

		// viewType: {
		// 	immediate: true,
		// 	handler: function () {
		// 	}
		// },
    }
}
</script>