<template>
    <div>
        <ListView title="PaymentMethods List" :modalObject='currentModalObject'>
            <template v-slot:actions>
                <b-button @click="modal('paymentMethodForm', {}, 'refreshTable')" variant='primary' class='mr-2'>
                    Create Payment Method
                </b-button>

            </template>

            <template v-slot:filter>
                <div class='form'>
                    <div class='form-row'>
                        <div class='form-group col-md-4'>
                            <b-form-input v-model="filter.keyword" placeholder="search ..."></b-form-input>
                        </div>
                    </div>
                </div>
            </template>

            <template v-slot:table>
                <PaymentMethodsListTable :filter='filter' ref='tableList' :viewType='viewType'>
                </PaymentMethodsListTable>
            </template>

        </ListView>
    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import PaymentMethodsListTable from '@/components/Lists/PaymentMethodsListTable.vue';

export default {
    extends: BaseList,
    components: { PaymentMethodsListTable },
    methods: {
        refreshTable() {
            this.$refs.tableList.refresh();
        }
    }
}
</script>
