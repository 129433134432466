<template>
    <div>
        <loader v-if='loading'/>
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>

            
            <b-form-group v-if='id'
                label="Status"
            >
                    <b-form-radio-group
                            id="checkbox-group-1"
                            v-model="patientExternalService.status"
                            :options="statusOptions"
                            size="lg"
                        ></b-form-radio-group>
            </b-form-group>
            <b-form-group label="External Service" >
                <ExternalServicesSelect v-if='!id' v-model='externalService'/>
                <div v-else>
                    <b>
                    {{ externalService.name }}
                    </b>
                </div>
                <FormGroupError :errors='errors.external_service_id'/>
            </b-form-group>
            <b-form-group label="Vendor" v-if='externalService'>
                <VendorsSelect v-if='!id' v-model='vendor' :filter='{external_service_id: externalService.id}'/>
                <div v-else>
                    <b>
                    {{ vendor.name }}
                    </b>
                </div>
                <FormGroupError :errors='errors.vendor_id'/>
            </b-form-group>
            
            <b-form-group label="Price" v-if='vendor && externalService'>
                {{ this.price }}
            </b-form-group>

            <b-form-group label="Description">
                <b-form-textarea v-model='patientExternalService.description'/>
                <FormGroupError :errors='errors.vendor_id'/>
            </b-form-group>
            
            
            
            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
export default {
    extends: BaseForm,
    props: ['patientServiceId'],
    data: function () {
        return {
            patientExternalService: {
            },
            statusOptions: [
                'pending', 'requested', 'received', 'delivered', 'cancelled'
            ],
            externalService: null,
            vendor: null,
            price: null
        }
    },
    mounted: function () {
        // this.apiRequest(apiClient => apiClient.get('patient-services/'+this.patientServiceId))
        //     .then(response => this.patientService = response);
    },
    methods: {
        load: function(id) {
            return this.apiRequest(apiClient => apiClient.get('patient-external-services/'+id))
                .then(response => {
                    this.patientExternalService = response;
                    this.externalService = response.external_service;
                    this.vendor = response.vendor;
                    this.price = response.price; 
                });
        },
        save: function (apiClient) {
            var patientExternalService = {
                ... this.patientExternalService,
                vendor_id: this.vendor.id,
                external_service_id: this.externalService.id,
            };
            if (this.id) {
                return apiClient.put('patient-external-services/'+this.id, patientExternalService);
            } else {
                patientExternalService.patient_service_id = this.patientServiceId;
                return apiClient.post('patient-external-services', patientExternalService);
            }
        },
        afterSave: function (item) {
            this.$emit('saved', item);
        },
        loadPrice: function () {
            if (!this.id && this.externalService && this.vendor) {
                var externalServiceVendor = this.externalService.external_service_vendors.find(item => item.vendor_id == this.vendor.id);
                this.price = externalServiceVendor.price;
            }
        }
    },
    watch: {
        externalService() {
            this.loadPrice();
        },
        vendor() {
            this.loadPrice();
        },
    }
}
</script>