<template>
    <div>
        <ul>
            <li v-if="showHeaders">
                <b-row class="p-2">
                    <b-col>
                        <b-row>
                            <b-col cols="6">Account</b-col>
                            <b-col  cols="2">Current Balance</b-col>
                            <b-col  cols="4">Period Balance</b-col>
                            <!-- <b-col  cols="2">Ending Balance</b-col> -->
                        </b-row>
                    </b-col>
                    <b-col cols="1">
                        Actions
                    </b-col>
                </b-row>
            </li>

            <div>
                <li v-for="account in accounts" :key="account.id">
                    <div class="p-2 hoverable">
                        <b-row>
                            <b-col>
                                <b-row class="clickable"  @click="accountClicked(account)">
                                    <b-col cols="6" >
                                        <div :style="{ paddingLeft: level * 20 + 'px' }" v-b-tooltip.hover :title="account.balance_type">
                                            <span v-if="account.has_children">
                                                <i v-if='!hasVisibleChildren(account.id)' class="fa fa-plus"></i>
                                                <i v-else class="fa fa-minus"></i>
                                            </span>
                                            <b-badge variant="light" class="ml-1">{{ account.number }}</b-badge>
                                            {{ account.name }} 
                                        </div>
                                    </b-col>
                                    <b-col cols="2">
                                        <div v-if="filter.branch_id">
                                            {{ account.branch_balance  | decimal }}
                                        </div>
                                        <div v-else>
                                            {{ account.balance_v | decimal }}
                                        </div>
                                    </b-col>
                                    <b-col cols="4">
                                        {{ account.accumulated_balance | decimal }}
                                        <span v-if="parentAccount" class="badge badge-light ml-2" v-b-tooltip.hover title="percentage to parent account">
                                            {{ Math.round(1000000 * account.accumulated_balance / parentAccount.accumulated_balance) / 10000 }} %
                                        </span>
                                        <span class="badge badge-light ml-2"  v-b-tooltip.hover title="percentage to current balance">
                                            {{ Math.round(1000000 * account.accumulated_balance / (filter.branch_id ? account.branch_balance : account.balance_v)) / 10000 }} %<br>
                                        </span>
                                    </b-col>
                                    <!-- <b-col cols="2">
                                        {{ account.ending_balance * (account.balance_type == 'DEBIT' ? -1 : 1) | decimal }}
                                    </b-col> -->
                                </b-row>
                            </b-col>
                            <b-col cols="1">
                                <b-link class='m-1 clickable'
                                    @click="modal('financialTransactionsList', { filter: { financial_account_id: account.id, ...filter } })">
                                    View
                                </b-link>

                                <b-link class='m-1 clickable' v-if='can("accounts.edit")' @click="modal('accountForm', { id: account.id })">
                                    Edit
                                </b-link>
                            </b-col>
                        </b-row>
                        
                        
                    </div>
                    <div v-if="account.has_children && (hasVisibleChildren(account.id))">
                        <accounts-tree :parentAccount="account" ref="node" :level="level+1" :key="account.id" :filter="filter"></accounts-tree>
                    </div>
                </li>

                <div v-if='loading' style="width:200px;">
                    <loader style="width:100px;"/>
                </div>
                <div class="hoverable clickable" style="background-color: #ddd;" v-else-if="hasNext" @click="loadMore">
                    <div class="mx-auto" style="width: 200px;">
                            + show more
                    </div>
                </div>
            </div>

            <li v-if="showTotals">
                <b-row class="p-2 totals-row">
                    <b-col>
                        <b-row>
                            <b-col cols="6" center>
                                <b>
                                    {{ totalsTitle }}
                                </b>
                            </b-col>
                            <b-col  cols="2">{{ totals.balance_v | decimal}}</b-col>
                            <b-col  cols="4">{{ totals.accumulated_balance | decimal}}</b-col>
                            <!-- <b-col  cols="2">{{ -totals.ending_balance | decimal}}</b-col> -->
                        </b-row>
                    </b-col>
                    <b-col cols="1">
                    </b-col>
                </b-row>
            </li>

        </ul>
        
		<Modals :modalName='currentModal' :modalData='currentModalData' @saved='modalCallback' @closed='modalClosed'>
		</Modals>
    </div>
</template>
<style scoped>
    .hoverable:hover {
        background-color: #eee;
    }
    .totals-row {
        font-weight: bold;
        background-color: #eee;
    }
</style>
<script>
import BaseListMethods from '@/components/Lists/BaseListMethods';

export default {
    name: 'AccountsTree',
    extends: BaseListMethods,
    data() {
        return {
            accounts: [],
            visibleChildren: [],
            hasNext: true,
        };
    },
    props: {
        parentAccount: {
            type: Object,
            default: null
        },
        level: {
            type: Number,
            default: 0
        },
        showHeaders: {
            type: Boolean,
            default: false
        },
        showTotals: {
            type: Boolean,
            default: false
        },
        totalsTitle: {
            type: String,
            default: 'Net'
        },
        initialTotals: {
            type: Object,
            default: () => {
                return {
                    balance_v: 0,
                    accumulated_balance: 0,
                    ending_balance: 0,
                };
            }
        },
    },
    computed: {
        totals() {
            return {
                balance_v: this.accounts.reduce((a, b) => a + parseFloat(b.balance_v), 0) + this.initialTotals.balance_v,
                accumulated_balance: this.accounts.reduce((a, b) => a + parseFloat(b.accumulated_balance), 0) + this.initialTotals.accumulated_balance,
                ending_balance: this.accounts.reduce((a, b) => a + parseFloat(b.ending_balance), 0) + this.initialTotals.ending_balance,
            };
        }
    },
    methods: {
        load(apiClient, cancelToken, filter) {
            const parentId = this.parentAccount ? this.parentAccount.id : null;
            const requestFilter = {
                parent_id: parentId,
                no_parent: parentId == null ? 1 : 0,
                ...filter
            };
        
            return apiClient.get('financial-accounts', requestFilter, cancelToken)
                .then((response) => {
                    const data = response.data;
                    if (this.page > 1) {
                        this.accounts = [...this.accounts, ...data];
                    } else {
                        this.accounts = data;
                    }
                    this.hasNext = response.meta.last_page > this.page;
                    return response;
                });
        },
        hasVisibleChildren(accountId) {
            return this.visibleChildren.includes(accountId);
        },
        accountClicked: function (account) {
            if (account.has_children) {
                this.toggleChildren(account.id);
            } else {
                this.modal('financialTransactionsList', { filter: { financial_account_id: account.id, ...this.filter } });
            }
        },
        toggleChildren(accountId) {
            if (this.hasVisibleChildren(accountId)) {
                const index = this.visibleChildren.indexOf(accountId);
                this.visibleChildren.splice(index, 1);
            } else {
                this.visibleChildren.push(accountId);
            }
        },
        loadMore() {
            this.page++;
            this.refresh();
        },
        expandAll() {
            this.loadingPromise.then(() => {
                this.accounts.forEach(account => {
                    if (account.has_children) {
                        this.visibleChildren.push(account.id);
                    }
                });
                
                this.$nextTick(() => {
                    var key = 'node';
                    if (this.$refs[key]) {
                        this.$refs[key].forEach(item => {
                            item.expandAll();
                        });
                    }
                });
            });
        },
    }
}
</script>
