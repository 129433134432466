<template>
    <div>
        <v-select @search="fetchOptions" @open="onOpen" :filterable='filterable' :options="options" :label='label'
            :value='myValue' v-on:input='updateValue' :multiple='multiple' :closeOnSelect='closeOnSelect'>
        </v-select>
    </div>
</template>

<script>
import BaseRemoteSelect from './BaseRemoteSelect.vue';

export default {
    extends: BaseRemoteSelect,
    data: function () {
        return {
            label: 'name'
        }
    },
    methods: {
        load(apiClient, search, cancelToken) {
            var filter = {
                keyword: search
            };

            return apiClient.get('source-of-payments', filter, cancelToken);
        },
        loadSingle(apiClient, id) {
            return apiClient.get('source-of-payments/' + id);
        }
    }
}
</script>
