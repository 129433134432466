<template>
    <div class="card col-6">
        <div class='card-header'>
            <h4 class="card-title">Patient Service Form</h4>
        </div>
        <div class="card-body">
            <b-form class='basic-form' @submit="checkForm">
                <div class='form-group'>
                    <label for="">Doctor</label>
                    <v-select :options="doctors">
<!-- 
                        <template slot="option" slot-scope="option">
                            <div class='m-2'>
                                <span class='badge badge-light mr-2'>
                                    {{ option.section }}
                                </span>
                                <i class='fa fa-user-md'></i>
                                {{ option.name }}
                            </div>
                            <div class='badge badge-light badge-sm'>
                                5th settlement branch
                            </div>
                        </template> -->
                    </v-select>
                </div>
                <div class='form-group'>
                    <label for="">Patient</label>
                    <v-select :options="patients"></v-select>
                </div>
                <div class='form-group'>
                    <label for="">Insurance</label>
                    <v-select :options="insurances"></v-select>
                </div>
                <div class='form-group'>
                    <label for="">Services</label>
                    <v-select multiple :options="services"></v-select>
                </div>
                
                <div class='form-group'>
                    <label>Note</label>
                    <textarea class='form-control' cols="30" rows="2"></textarea>
                </div>
                <button type="submit" class="btn btn-primary">Save</button>
            </b-form>
        </div>
    </div>
</template>
<script>

export default {
    data: function () {
        return {
            patients: [
                'Mahmoud Magdy',
                'Omar Osama'
            ],
            doctors: [
                'DR. Hossam',
                'DR. Hoda'
            ],
            services: [
                'Checkup',
                'Crown'
            ],
            insurances: [
                'Egypt Isurance',
                'AXA'
            ],
            dates: [
            ],
        };
    },
    methods: {
        checkForm: function (e) {
            e.preventDefault();
            this.saving = true;
            this.$router.push({name: 'TreatmentPlansList'});
        },
    },
}
</script>