<template>
    <div>
        <base-list :collection='collection' :loading='loading' :fields='fields' :modalObject='currentModalObject' @pageChanged='pageChanged'>
            

            <template v-slot:cell(working_hours)='data'>
                
                <div v-for='(workingHour, index) in data.item.working_hours' :key='index'>
                    <div v-if='(!filter.branch_id || workingHour.branch_id == filter.branch_id) && matchSelectedDate(workingHour.week_days)'>
                        <div class='badge badge-light m-2'>
                        {{ workingHour.branch.name }}
                        </div>
                        <br>
                        <div class='badge badge-xs badge-primary mr-2'>
                            {{ workingHour.from | textTime}}
                            -
                            {{ workingHour.to | textTime}}
                        </div>
                    </div>
                </div>
            </template>

            <template v-slot:cell(fingerprint_users)='data'>
                <div v-for='(fingerprintUser, index) in data.item.fingerprint_users' :key='index'>
                    <div class='badge badge-success m-2'>
                        {{ fingerprintUser.fp_user_id }}
                        -
                        {{ fingerprintUser.fp_user.name }}
                    </div>
                    <br>
                    <div class='badge badge-success m-2'>
                        <i class="fa fa-fingerprint"></i>
                        {{ fingerprintUser.fingerprint_device.name }}
                    </div>
                    <br>
                    <div class='badge badge-success m-2'>
                        {{ fingerprintUser.fingerprint_device.branch.name }}
                        Branch 
                    </div>
                </div>
            </template>

            <template v-slot:cell(attendance)='data'>
                <div v-for='(action, index) in data.item.attendance' :key='index'>
                    <div class='badge badge-light m-2'>
                        {{ action.branch.name }}
                    </div>
                    <div>
                        <div class='badge badge-success m-2' v-if='action.check_in_fp_action'>
                            {{ action.check_in_fp_action.fp_user_id }}
                            <i class="fa fa-fingerprint"></i>
                            {{ action.check_in_fp_action.fingerprint_device.name }}
                        </div>
                        <div class='badge badge-success m-2' v-if='action.check_out_fp_action'>
                            {{ action.check_out_fp_action.fp_user_id }}
                            <i class="fa fa-fingerprint"></i>
                            {{ action.check_out_fp_action.fingerprint_device.name }}

                        </div>
                    </div>

                    <h4>
                        <div class='badge badge-success mr-2' v-if='action.check_in'>check in: {{action.check_in | time}} </div>

                        <div class='badge badge-success' v-if='action.check_out'>check out: {{action.check_out | time}} </div>
                    </h4>

                    <h4>
                        <div class='badge mr-2' :class='action.check_in_diff >= 0 ? "badge-success" : "badge-danger"' v-if='action.check_in'>
                            check in diff: {{action.check_in_diff | timediff}} 
                        </div>

                        <div class='badge' :class='action.check_out_diff >= 0 ? "badge-success" : "badge-danger"' v-if='action.check_out'>
                            check out diff: {{action.check_out_diff | timediff}}
                        </div>
                    </h4>
                    <hr>
                </div>
            </template>
                    
			<template v-slot:cell(name)='data' class='badge badge-success'>
                {{ data.item.name }}
                <br>
                <div v-if='data.item.is_active' class='badge badge-success'>
                    active
                </div>
                <div v-else class='badge badge-light'>
                    inactive
                </div>
            </template>
        </base-list>
    </div>
</template>

<script>
import BaseList from './BaseList.vue';
import BaseListMethods from './BaseListMethods';

export default {
	props: ['viewType'],
    components: { BaseList},
    extends: BaseListMethods,
    data() {
        return {
          	fields: [
                { key: 'id', label: 'ID' },
                { key: 'name', label: 'Name' },
                { key: 'fingerprint_users', label: 'Fingerprint' },
                { key: 'working_hours', label: 'Working Hours' },
                { key: 'attendance', label: 'Attendance' },
                { key: 'is_active', label: 'Status' },
			],
            weekDays: [
                'Saturday',
                'Sunday',
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thuresday',
                'Friday',
            ]
        }
    },
	methods: {
        load: function (apiClient, cancelToken, filter) {
			return apiClient.get('workers', filter, cancelToken);
		},
        matchSelectedDate: function (weekDays) {
           var dayNumber = this.dayNumber(this.filter.date);
           return weekDays.indexOf(dayNumber) != -1 ? true : false;
        }
    },
    watch: {

		// viewType: {
		// 	immediate: true,
		// 	handler: function () {
		// 	}
		// },
    }
}
</script>