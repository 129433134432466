export default {
  template: `<div @click="handleButtonClick" class='btn btn-success' :disabled="!pickerApiLoaded && !authApiLoaded"><slot/></div>`,
  props: ['config'],
  data() {
    return {
      picked: {},
      pickerApiLoaded: false,
      authApiLoaded: false,
      oauthToken: ''
    }
  },
  methods: {
    handleAuthResult(authResult) {
      if (authResult && !authResult.error) {
        this.oauthToken = authResult.access_token
        this.createPicker()
      } else {
        return console.warn(authResult.details)  
      }      
    },
    handleButtonClick() {       
        window.gapi.auth.authorize({
          client_id: this.config.clientId,
          scope: this.config.scope,
          'immediate': false
        }, this.handleAuthResult)          
    },
    createPicker () {
      if (this.pickerApiLoaded && this.oauthToken) {
        var view = new google.picker.View(google.picker.ViewId.DOCS);
        view.setMimeTypes("image/png,image/jpeg,image/jpg,application/pdf");
        const picker = new google.picker.PickerBuilder().
            addView(view).
            addView(new google.picker.DocsUploadView()).
            setOAuthToken(this.oauthToken).
            setAppId('model-link-197517').
            setDeveloperKey(this.config.developerKey).
            setCallback(this.pickerCallback).
            build()         
        picker.setVisible(true);        

      }
    },
    pickerCallback(data) {       
      if (data[google.picker.Response.ACTION] == google.picker.Action.PICKED) { 
        this.$emit("picked", data, this.oauthToken);
      }           
    },
  },
  mounted () {
    if(!window.gapi)
      return console.warn('Google API not loaded')
    window.gapi.load('auth', () => this.authApiLoaded = true)
    window.gapi.load('picker', () => {      
      this.pickerApiLoaded = true
    })
  }    
}