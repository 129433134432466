<template>
    <div>
        <b-form @submit="submit" novalidate>
            <b-form-group label="Name">
                <b-form-input v-model="file.name" :state="states.name" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.name'/>
            </b-form-group>
            <authorized-url :url="file.url" class='btn btn-primary m-3' target='_blank'>
                <i class='fa fa-external-link-alt'></i> open
            </authorized-url>
            
            <object
                  v-if='file.type_group == "pdf"'
                  :data="file.url+'?auth_token='+authToken"
                  :type="file.type"
                  width="100%"
                  style="min-height: 500px;"
                  internalinstanceid="5"
                >
                
                </object>
            <b-img v-if='file.type_group == "image"' class='p-4' fluid :src="file.url+'?auth_token='+authToken" alt=""/>
            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
    </div>
</template>
<script>
import BaseForm from '@/views/BaseForm';
import AuthorizedUrl from './AuthorizedUrl.vue';
export default {
  components: { AuthorizedUrl },
    props: ['file'],
    extends: BaseForm,
    data: function() {
        return {
            authToken: localStorage.token
        }
    },
    methods: {
        save: function (apiClient) {
            if (this.file.id) {
                return apiClient.put('files/'+this.file.id, this.file);
            } else {
                return apiClient.post('files', this.file);
            }
        },
        afterSave: function () {
            this.$emit('saved', 1);
        }
    },
    
}
</script>