<template>
    <div>
        <b-form class='basic-form' v-if='patientServices' @submit="submit" novalidate>

            <b-form-group label="Patient Insurance">
                <PatientInsurancesSelect v-if='!id' v-model='patientInsurance' :filter='{ for_approval: 1 }'
                    :patientId='patientId ? patientId : insuranceApproval.patient_id' />
                <b v-else>
                    <span v-if='patientInsurance'>
                        {{ patientInsurance.insurance_provider.name }}
                    </span>
                </b>
            </b-form-group>
            <b-form-group label="Date">
                <v-date-picker v-model="insuranceApproval.date">
                    <template v-slot="{ inputValue, inputEvents }">
                        <input class="bg-white border px-2 py-1 rounded" :value="inputValue" v-on="inputEvents" />
                    </template>
                </v-date-picker>

            </b-form-group>
            <div class='form-group'>
                <label>Note</label>
                <textarea v-model='insuranceApproval.notes' class='form-control'></textarea>
            </div>

            <div class='form-group'>
                <label>Insurance Pay Limit</label>
                <b-form-input v-model='insuranceApproval.insurance_pay_limit'></b-form-input>
            </div>

            <div class='mb-3'>
                <b-form-checkbox size='lg' v-model='isApproved' value='1' :disabled='disableEdit'>
                    <b>approved</b>
                </b-form-checkbox>

                <b-form-checkbox size='lg' v-model='isFinished' value='1'>
                    <b>finished</b>
                </b-form-checkbox>

                <b-form-checkbox size='lg' v-model='isSent' value='1'>
                    <b>sent</b>
                </b-form-checkbox>
            </div>
            <b-form-group label="Upload Approval Document" v-if='isApproved'>
                <FileUpload v-on:input='value => this.insuranceFileIds = value' />
                <b-form-checkbox v-model='replaceFiles' value='1'>
                    <b>Replace Files</b>
                </b-form-checkbox>
            </b-form-group>

            <div v-for='(patientService, index) in patientServices' :key='index'>
                <h4>
                    <b-form-checkbox v-model="patientService.is_included" value='1' :disabled='disableEdit'>
                        <div for="" class='badge badge-info'>
                            {{ patientService.id }}
                            {{ patientService.name }}
                        </div>
                        <div for="" class='badge badge-success ml-2'>
                            <span v-if='totalPriceLoading'> loading ... </span>
                            <span v-else>
                                Main Price: {{ patientService.price }}
                            </span>
                        </div>
                        <div v-if='patientService.deduction' class='badge badge-warning ml-2'>
                            Deduction:
                            {{ patientService.deduction }}
                        </div>
                        <div v-if='patientService.extra_fees' class='badge badge-warning ml-2'>
                            Extra Fees:
                            {{ patientService.extra_fees }}
                        </div>
                        <div v-if='patientService.deduction || patientService.extra_fees' class='badge badge-warning ml-2'>
                            Insurance Price:
                            {{ patientService.price + patientService.extra_fees - patientService.deduction }}
                        </div>
                        <div v-if='patientService.discount' class='badge badge-warning ml-2'>
                            Discount:
                            {{ patientService.discount }}
                        </div>

                        <div v-if='patientService.discount' class='badge badge-warning ml-2'>
                            Patient Price:
                            {{ patientService.price + patientService.extra_fees - patientService.deduction -
                                patientService.discount }}
                        </div>
                    </b-form-checkbox>
                </h4>
                <b-form-row>
                    <b-col cols=2 v-if='false'>
                        <b-form-group label="Discount">
                            <b-form-input v-model="patientService.discount" type='number' :state="states.discount"
                                placeholder="Discount">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols=8 v-if='isApproved && patientService.is_included'>
                        <b-form-group label="Required From Patient" description="amount that should be payed by patient">
                            <b-row>
                                <b-col cols=4>
                                    <b-input-group append="%">
                                        <b-form-input max='100'
                                            :value="Math.round(10000 * patientService.patient_pay / patientService.final_price) / 100"
                                            @input='value => patientService.patient_pay = value * patientService.final_price / 100'
                                            :state="states.patient_pay || patientService.patient_pay <= patientService.final_price"
                                            type="number" placeholder="">
                                        </b-form-input>
                                    </b-input-group>
                                </b-col>
                                <b-col cols=4>
                                    <b-input-group append="EGP">
                                        <b-form-input v-model="patientService.patient_pay" :max='patientService.final_price'
                                            :min='0'
                                            :state="states.patient_pay || patientService.patient_pay <= patientService.final_price"
                                            type="number" placeholder="">
                                        </b-form-input>
                                    </b-input-group>
                                </b-col>
                            </b-row>
                        </b-form-group>

                        <FormGroupError :errors='["patient pay is not valid"]' />
                    </b-col>
                </b-form-row>
                <hr>
            </div>
            <h3>Remaining: {{ remainingAmount }}</h3>
            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>

        </b-form>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'

export default {
    extends: BaseForm,
    props: ['patientId', 'patientInsuranceId'],
    data: function () {
        return {
            isApproved: false,
            isFinished: false,
            isSent: false,
            patientServices: [],
            cardFileIds: [],
            insuranceFileIds: [],
            totalPriceLoading: false,
            totalPriceCancelToken: null,
            insuranceProvider: null,
            insuranceApproval: {},
            replaceFiles: 0,
            patientInsurance: null,
            disableEdit: false
        }
    },
    mounted() {
        if (this.patientInsuranceId) {
            this.apiRequest(apiClient => {
                return apiClient.get('patient-insurances/' + this.patientInsuranceId);
            })
                .then(patientInsurance => {
                    this.patientInsurance = patientInsurance;
                });
        }
    },
    methods: {
        load: function (id) {
            return this.apiRequest(apiClient => apiClient.get('insurance-approvals/' + id))
                .then(response => {
                    this.patientServices = response.patient_services.map(item => {
                        item['is_included'] = 1;
                        return item;
                    });
                    this.insuranceProvider = response.insurance_provider;
                    this.patientInsurance = response.patient_insurance;
                    this.isApproved = response.status == 'approved' ? 1 : 0;
                    this.disableEdit = this.isApproved && !this.can('insurance_approvals.edit_after_approval') ? true : false;
                    this.isFinished = response.is_finished ? 1 : 0;
                    this.isSent = response.sent_at ? 1 : 0;
                    this.loadPatientServices(response.patient_id);
                    this.insuranceApproval = response;
                });
        },
        loadPatientServices(patientId) {
            this.apiRequest(
                apiClient => apiClient.get(
                    'patient-services',
                    {
                        patient_id: patientId,
                        no_approval: 1,
                        patient_insurance_id: this.patientInsurance.id
                    })
            ).then(activeServices => {
                this.patientServices = this.patientServices.concat(activeServices.data.map(item => {
                    item['is_included'] = 0;
                    return item;
                }));

            });
        },
        save: function (apiClient) {
            var insuranceApproval = {
                card_file_ids: this.cardFileIds,
                approval_file_ids: this.insuranceFileIds,
                patient_services: this.patientServices.filter(item => item.is_included ? true : false),
                insurance_provider_id: this.patientInsurance.insurance_provider_id,
                patient_insurance_id: this.patientInsurance.id,
                patient_id: this.patientId,
                is_approved: this.isApproved ? 1 : 0,
                is_finished: this.isFinished ? 1 : 0,
                is_sent: this.isSent ? 1 : 0,
                replace_files: this.replaceFiles,
                notes: this.insuranceApproval.notes,
                insurance_pay_limit: this.insuranceApproval.insurance_pay_limit,
                date: this.date(this.insuranceApproval.date),
            };
            if (this.id) {
                return apiClient.put('insurance-approvals/' + this.id, insuranceApproval);
            } else {
                return apiClient.post('insurance-approvals', insuranceApproval);
            }
        },
        afterSave: function (patientService) {
            this.$emit('saved', patientService);
        },

    },
    computed: {
        remainingAmount: function () {
            var totalInsurancePay = this.patientServices.filter(item => item.is_included ? true : false)
                .reduce((total, patientService) => total + parseFloat(patientService.final_price) - parseFloat(patientService.patient_pay), 0);

            return parseFloat(this.insuranceApproval.insurance_pay_limit) - totalInsurancePay;
        }
    },
    watch: {
        patientInsurance: function () {
            if (this.id) {
                return;
            }

            if (this.patientInsurance) {
                this.insuranceProvider = this.patientInsurance.insurance_provider;
                this.loadPatientServices(this.patientId);
            } else {
                this.patientServices = [];
            }
        },
    }
}
</script>