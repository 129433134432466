<template>
    <b-row>
        <b-col>


            <h3 class="mt-3">Leads List</h3>
            <SimpleListView title="Leads List" :collection="collection" :fields="fields" :loading="loading">
                <template v-slot:filter>
                    <div class='form'>
                        <div class='form-group'>
                            <b-form-input v-model="filter.keyword" placeholder="#name"></b-form-input>
                        </div>
                    </div>
                    <div class='form-row'>
                        <b-col>
                            <b-form-group label="Status">
                                <b-form-radio-group v-model="filter.status"
                                    :options="['all', 'pending', 'follow up', 'booked', 'not interested']"></b-form-radio-group>
                            </b-form-group>
                        </b-col>
                    </div>
                    <div class='form'>
                        <div class='form-group'>
                            <b-form-checkbox v-model="filter.unassigned" value="1" unchecked-value="0" :inline='true'>
                                Unassigned
                            </b-form-checkbox>
                        </div>
                    </div>
                    <div class='form-row'>
                        <div class='col-md-4'>
                            <div class='form-group m-2'>
                                <label>Branch</label>
                                <BranchesSelect v-model='filter.branch_id' :returnId='true' />
                            </div>
                        </div>
                        <div class='col-md-4'>
                            <div class='form-group m-2'>
                                <label>Assigned user</label>
                                <users-select v-model='filter.user_id' :returnId='true' />
                            </div>
                        </div>
                        <div class='col-md-4'>
                            <div class='form-group m-2'>
                                <label>Section</label>
                                <SectionsSelect v-model='filter.section_id' :returnId='true' />
                            </div>
                        </div>
                    </div>
                </template>

                <template v-slot:cell(user)='data'>
                    <div class='badge badge-secondary' v-if="data.item.user">
                        <i class='fa fa-user'></i>
                        {{ data.item.user.name }}
                    </div>
                </template>

                <template v-slot:cell(is_insured)='data'>
                    <div v-if='data.item.insurance_provider' class='badge badge-warning'>
                        {{ data.item.insurance_provider.name }}
                    </div>
                    <div v-if='data.item.is_insured' class='badge badge-success'>
                        Insured
                    </div>
                </template>

                <template v-slot:cell(section)='data'>
                    {{ data.item.section ? data.item.section.name + " / " : "" }}

                    {{ data.item.nearst_branch ? data.item.nearst_branch.name : "" }}
                </template>

                <template v-slot:cell(actions)='data'>
                    <button class='btn btn-primary' @click="itemClicked(data.item)">Select</button>
                </template>

            </SimpleListView>
        </b-col>
        <b-col cols-md='4'>
            <div>
                <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>
                <b-form-group label="Assign to">
                    <users-select v-model="user_id" returnId="1"></users-select>
                </b-form-group>
                <h3>selected leads</h3>
                <hr>
                <ul>
                    <li v-for='lead in selected' :key='lead.id' class='m-2'>
                        <button @click='removeItem(lead)' class='btn btn-xxs btn-danger m-3'>x</button>
                        <b>#{{ lead.id }}</b> - <b>{{ lead.name }}</b>

                        <hr>
                    </li>
                </ul>
                <button class='btn btn-primary' v-if='selected.length > 0' @click="finish()">Assign</button>
                <div class="alert alert-primary" v-else>please select some leads</div>
            </div>
        </b-col>


    </b-row>
</template>

<script>
import BaseList from '@/views/BaseList';
import UsersSelect from './UsersSelect.vue';
export default {
    components: { UsersSelect },
    extends: BaseList,
    methods: {
        load: function (apiClient, cancelToken) {
            return apiClient.get('leads', this.filter, cancelToken);
        },
        itemClicked: function (item) {
            if (!this.selected.find(i => i.id == item.id)) {
                this.selected.push(item);
            }
        },
        removeItem: function (index) {
            this.selected.splice(index, 1);
        },
        finish() {
            this.apiRequest(function (apiClient) {
                return apiClient.post('leads/assign_user', {
                    leads: this.selected.map(i => i.id),
                    user_id: this.user_id,
                })
            }).then(() => {
                this.$emit('saved', {});
            }).catch(e => {
                if (e.response.data.errors) {
                    this.hasErrors = true;
                    this.errors = e.response.data.errors;
                }
            });
        },
    },
    data: function () {
        return {
            errors: [],
            hasErrors: false,
            user_id: null,
            filter: {},
            selected: [],
            fields: [
                { key: 'id', label: '#' },
                { key: 'name', label: 'Name' },
                { key: 'phone', label: 'Phone' },
                { key: 'nationality', label: 'Nationality' },
                { key: 'is_insured', label: 'Insurance' },
                { key: 'section', label: 'Section / Branch' },
                { key: 'user', label: 'Assigned User' },
                { key: 'actions', label: '' }
            ]
        };
    },
}
</script>

