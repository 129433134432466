<template>
    <div v-if='patientService'>
        <b-form class='basic-form' @submit="submit" novalidate>
            <div v-if='patientService.status =="pending"'>
                <div class='form-group'>
                    <label for="">
                        <i class='fa fa-user-md'></i>
                        Doctor
                    </label>
                    <DoctorsSelect v-model='currentDoctor' working='1' :section_id='patientService.service.section_id'/>
                </div>
                <div class='form-group' v-if='currentDoctor'>
                    <label for="">
                        <i class='fa fa-'></i>
                        Branch
                    </label>
                    <div  v-if='currentDoctor.branches.length > 1'>
                        <BranchesSelect v-model='serviceBranch' :doctorId='currentDoctor.id' />
                    </div>
                    <div v-else >
                        {{ serviceBranch.name }}
                    </div>
                </div>
            </div>
            <div v-else>
                <div class='form-group'>
                    <label for="">
                        <i class='fa fa-user-md'></i>
                        Doctor
                    </label>
                    <div>
                    {{ currentDoctor.name }}
                    </div>
                </div>
                <div class='form-group' v-if='currentDoctor'>
                    <label for="">
                        <i class='fa fa-'></i>
                        Branch
                    </label>
                    <div>
                    {{ serviceBranch.name }}
                    </div>
                </div>
            </div>
            <div class='form-group'>
                <label for="">Patient</label>
                <div>
                    <strong>
                        {{ patientService.patient.name }}
                    </strong>
                </div>
            </div>
            <div class='form-group'>
                <label for="">Service</label>
                <div>
                    <strong>
                        {{ patientService.service.name }}
                    </strong>
                </div>                    
            </div>

            <b-form-group label="Body Part">
                <v-select :options="bodyParts" v-model='patientService.body_parts' :multiple='patientService.service.multiple_body_parts ? true : false'></v-select>
            </b-form-group>
            <div class='form-group' v-if='patientService.required_appointments'>
                <label for="">Number of appointments required</label>
                <div>
                    <strong>
                        {{ patientService.required_appointments }}
                    </strong>
                </div>
            </div>
            
            <b-form-group
                label="Status"
                v-if='patientService.status !="virtual insurance"'
            >
                    <b-form-radio-group
                            id="checkbox-group-1"
                            v-model="patientService.status"
                            :options="statusOptions"
                            size="lg"
                        ></b-form-radio-group>
            </b-form-group>
            
            <div class='form-group' v-if='patientService.required_appointments && patientService.service.multiple_appointments'>
                <label for="">Number of appointments required</label>
                
                <b-row>
                    <b-col cols='2'>
                        <input class='form-control' type='number' v-model='patientService.required_appointments' />
                    </b-col>
                </b-row>
            </div>
            <div class=form-group>
                <label for="">
                    Appointments
                </label>

                <div v-for='(appointment, index) in patientService.appointments' :key='index' class='m-2'>
                    #{{ appointment.id }}

                    <div class='badge badge-lg badge-light'>
                        <i class='fa fa-calendar'></i>
                        {{ appointment.datetime | datetime }}
                    </div>
                    <div class='badge badge-lg badge-light ml-2'>
                        {{ appointment.duration }}
                        minutes
                    </div>
                    <b-form-checkbox
                        v-model="appointment.removed"
                        inline
                        class="ml-2"
                        :disabled='isDoctorChanged'
                    >
                        <i class='fa fa-remove'></i>
                        remove
                    </b-form-checkbox>
                </div>

                <div v-for='(appointment, index) in appointments' :key='index' class='m-2'>
                    #{{ appointment.id }}

                    <div class='badge badge-lg badge-light'>
                        <i class='fa fa-calendar'></i>
                        {{ appointment.datetime | datetime }}
                    </div>
                    <div class='badge badge-lg badge-light ml-2'>
                        {{ appointment.duration }}
                        minutes
                    </div>
                    <div class='btn btn-primary btn-xs sharp ml-2' @click='removeAppointment(index)'>
                        <i class='fa fa-remove'></i>
                    </div>
                    
                </div>
            </div>
            <div class=form-group>                
                <div v-for='(datetime, index) in dates' :key='index' class='m-2'>
                    <div class='badge badge-lg badge-light'>
                        <i class='fa fa-calendar'></i>
                        {{ datetime.date | datetime }}
                    </div>
                    <div class='badge badge-lg badge-light ml-2'>
                        {{ datetime.duration }}
                        minutes
                    </div>
                    <div class='btn btn-primary btn-xs sharp ml-2' @click='removeDate(index)'>
                        <i class='fa fa-remove'></i>
                    </div>
                </div>

                <div>
                    <b-button v-b-modal.modal-2 class='mr-2' variant='success'>+ New Appointment</b-button>
                    <b-button v-b-modal.modal-3 variant='success' size="sm" class='mr-2'>Select Appointment</b-button>
                </div>
                <div class='m-2'>
                </div>
            </div>
            
            <div class='form-group'>
                <label for="">Price</label>
                <div>
                    <strong>
                        {{ patientService.price | currency}}
                    </strong>
                </div>
            </div>
            
            <div class='form-group'>
                <label>Extra Fees</label>

                <b-row>
                    <b-col cols='2'>
                        <input class='form-control' type='number' v-model='patientService.extra_fees' />
                    </b-col>
                </b-row>
            </div>
            
            <div class='form-group'>
                <label>Deduction</label>

                <b-row>
                    <b-col cols='2'>
                        <input class='form-control' type='number' v-model='patientService.deduction' />
                    </b-col>

                    <b-col cols='10'>
                        <b-form-textarea v-model='patientService.deduction_note' cols="30" rows="1"/>
                    </b-col>
                </b-row>
            </div>

            <div class='form-group'>
                <label>Patient Discount</label>
                <b-row>
                    <b-col cols='2'>
                        <input class='form-control' type='number' v-model='patientService.discount' />
                    </b-col>

                    <b-col cols='10'>
                        <b-form-textarea v-model='patientService.discount_note' cols="30" rows="1"/>
                    </b-col>
                </b-row>
            </div>

            <div class='form-group'>
                <label for="">Final Price</label>
                <div>
                    <strong>
                        {{ finalPrice | currency}}
                    </strong>
                </div>
            </div>
            
            <div v-if="patientService.insurance_approval">
                <div class='form-group' >
                    <label>Insurance Approval</label>
                    <div>
                        <strong>{{ patientService.insurance_approval.insurance_provider.name }}</strong>
                        <div class='badge badge-light m-2'>
                            {{ patientService.insurance_approval.status }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group" v-if='patientService.status != "virtual insurance" || patientService.insurance_children_patient_services.length > 0'>
                <label>Insurance Substitued By</label>
                <div v-for='(insurancePatientService, index) in patientService.insurance_children_patient_services' :key='index' >
                    <div class='badge text-wrap badge-info mt-2'>
                        #{{ insurancePatientService.id }}
                        - {{ insurancePatientService.name }}
                    </div>
                    <div class='badge text-wrap badge-info ml-2' v-if='insurancePatientService.insurance_approval'>
                        Approval # {{ insurancePatientService.insurance_approval.id }}
                        {{ insurancePatientService.insurance_approval.date }}
                    </div>
                    <div v-else class='badge text-wrap badge-danger ml-2'>
                        no approval
                    </div>
                    <b-button @click="removeChildPatientService(index)" class='ml-2 btn-xxs btn-danger'>x remove</b-button>
                </div>
            </div>


            <div class="form-group" v-if='patientService.status == "virtual insurance" || patientService.insurance_patient_services.length > 0'>
                <label>Substitute insurance For</label>
                <div v-for='(insurancePatientService, index) in patientService.insurance_patient_services' :key='index' >
                    <div class='badge text-wrap badge-info mt-2'>
                        #{{ insurancePatientService.id }}
                        - {{ insurancePatientService.name }}
                    </div>
                    <b-button @click="removeParentPatientService(index)" class='ml-2 btn-xxs btn-danger'>x remove</b-button>
                </div>
                <b-button variant='primary' 
                    v-if='patientService.status == "virtual insurance"' 
                    class='btn btn-primary btn m-2 align-right' 
                    @click="modal('patientServicesSelector', {baseFilter: {not_ids: patientService.insurance_patient_services.map(item => item.id), patient_id: patientService.patient_id, branch_id: patientService.branch_id, section_id: patientService.section_id}}, 'addInsurancePatientService')">
                    Select Existing Patient Service
                </b-button>
            </div>

            <b-form-group label="Patient Insurance" v-if='!patientService.insurance_approval_id'>
                <PatientInsurancesSelect  v-model='patientInsurance' :patientId='patientService.patient_id' />
            </b-form-group>
            <div v-if="patientService.patient_insurance">
                <strong>{{ patientService.patient_insurance.insurance_provider.name }}</strong>
                <b-form-group label="Required From Patient" description="amount that should be payed by patient">
                    <b-row>
                        <b-col cols=4>
                            <b-input-group append="%">
                                <b-form-input max='100' :value="Math.round(10000 * parseFloat(patientService.patient_pay) / parseFloat(this.finalPrice)) / 100" @input='value => {patientService.patient_pay = value * this.finalPrice / 100; percentage = value;}' :state="states.patient_pay || patientService.patient_pay <= this.finalPrice" type="number" placeholder="">
                                </b-form-input>
                            </b-input-group>
                        </b-col>
                        <b-col cols=4>
                            <b-input-group append="EGP">
                                <b-form-input v-model="patientService.patient_pay" :max='this.finalPrice' :min='0' :state="states.patient_pay || patientService.patient_pay <= this.finalPrice" type="number" placeholder="">
                                </b-form-input>
                            </b-input-group>
                        </b-col>
                    </b-row>
                </b-form-group>

                <div class='form-group' v-if='false'>
                    <label>Required from patient</label>
                    <input class='form-control' type='number' v-model='patientService.patient_pay' />
                </div>
                <div class='form-group'>
                    <label>Required from insurance</label>
                    <div>
                        <strong>{{ (parseInt(finalPrice * 100) - parseInt(patientService.patient_pay * 100)) / 100 }}</strong>
                    </div>

                </div>
                <div class='form-group'>
                    <label>Paid by insurance</label>
                    <div>
                         <strong>{{ patientService.paid_by_insurance }}</strong>
                    </div>
                </div>
            </div>
            <div>
                <div class='form-group'>
                    <label>Paid by patient</label>
                    <div>
                    <strong>{{ patientService.paid_by_patient }}</strong>
                    </div>
                </div>
            </div>

            <div class='form-group' v-if='can("patient_services.doctor_fees")'>
                <label for="">Doctor fees % </label>
                <b-row>
                    <b-col cols='2'>
                        <input class='form-control' type='number' v-model='patientService.doctor_fees_percentage' />
                    </b-col>
                    <b-col>
                        <div class="badge badge-lg badge-secondary">
                            {{ finalPrice *  patientService.doctor_fees_percentage/100 | currency }}
                        </div>
                    </b-col>
                </b-row>
            </div>
            

            <div class='form-group' v-if='can("patient_services.doctor_fees")'>
                <label for="">Fixed Doctor fees</label>
                <b-row>
                    <b-col cols='2'>
                        <input class='form-control' type='number' v-model='patientService.doctor_fixed_fees' />
                    </b-col>
                </b-row>
            </div>

            <b-alert v-model='this.requireAppointments' variant="danger">
                <ul>
                    <li>
                        Appointments are required for in progress or finished services 
                    </li>
                </ul>
            </b-alert>
            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>
            <button type="submit" class="btn btn-primary" v-if='!this.saving' :disabled='this.requireAppointments'>Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
        <b-modal :no-close-on-backdrop=true id="modal-3" size='xl' v-if='patientService && serviceBranch && currentDoctor' hide-footer  title="Select Appointment">
            <AppointmentsSelector @clickedItem='appointmentSelected'  :patientId='patientService.patient_id' :doctorId='currentDoctor.id' :branchId='serviceBranch.id'></AppointmentsSelector>        
        </b-modal>
        <b-modal :no-close-on-backdrop=true id="modal-2"  v-if='patientService && serviceBranch && currentDoctor' hide-footer  title="Choose Date">
            <AppointmentPicker @datetimePicked='dateClicked'  :doctor_id='currentDoctor.id' :branch_id='serviceBranch.id'></AppointmentPicker>        
        </b-modal>
		<Modals :modalName='currentModal' :modalData='currentModalData' @saved='modalCallback' @closed='modalClosed'>
		</Modals>
    </div>

</template>
<script>
import AppointmentPicker from '../../components/AppointmentPicker';
import AppointmentsSelector from '../../components/AppointmentsSelector';
import BaseForm from '@/views/BaseForm';
// import DentistryBodyParts from '@/components/BodyParts/DentistryBodyParts';
// import PhysicalTherapyBodyParts from '@/components/BodyParts/PhysicalTherapyBodyParts';

export default {
    extends: BaseForm,
    components: {
        AppointmentPicker,
        AppointmentsSelector
    },
    data: function () {
        return {
            patientService: null,
            patientInsurance: {},
            serviceBranch: null,
            bodyParts: [],
            dates: [],
            currentDoctor: null,
            appointments: [],
            originalInsurance: null,
            originalPrice: null,
            percentage: 100,
            statusOptions: []
        };
    },
    methods: {
        load: function(id) {
            return this.apiRequest(apiClient => apiClient.get('patient-services/'+id))
                .then(response => {
                    this.percentage = 100 * parseFloat(response.patient_pay) / parseFloat(response.final_price);

                    this.patientService = response;
                    this.patientInsurance = response.patient_insurance;
                    this.originalInsurance = this.patientInsurance;
                    this.originalPrice = this.patientService.price;
                    this.currentDoctor = response.doctor;
                    this.serviceBranch = response.branch;

                    if (!response.service.multiple_appointments) {
                        this.statusOptions = ['pending', 'finished'];
                    } else {
                        this.statusOptions = ['pending', 'in progress', 'finished'];
                    }
                    
                    // var bodyPartsImages = this.patientService.service.section.body_parts_images;
                    // this.bodyPartsImage = bodyPartsImages ? bodyPartsImages[0] : null;

                    // if (this.bodyPartsImage == "Adult Body") {
                    //     this.bodyParts = PhysicalTherapyBodyParts;
                    // } else if (this.bodyPartsImage == "Adults Teeth") {
                    //     this.bodyParts = DentistryBodyParts;
                    // } else if (this.bodyPartsImage == "Kids Teeth") {
                    //     this.bodyParts = DentistryBodyParts;
                    // }

                    this.bodyParts = [];

                    this.apiRequest((apiClient, cancelTokenSource) => {
                        this.cancelTokenSource = cancelTokenSource;
                        return apiClient.get('body-part-groups', {section_id: response.section_id}, cancelTokenSource);
                    }, this.cancelTokenSource)
                    .then(response => {
                        var bodyParts = [];
                        response.data.forEach(item => bodyParts = [...bodyParts, ...item.body_parts])
                        this.bodyParts = bodyParts;
                    });
                });
        },
        save: function (apiClient) {
            if (this.id) {
                var remainingAppointments = 
                    this.patientService.appointments
                        .filter(item => item.removed ? false : true)
                        .map(item => item.id);
                this.patientService.appointment_ids = [...this.appointments.map(item => item.id), ...remainingAppointments];
                this.patientService.new_appointments = this.dates;
                this.patientService.doctor_id = this.currentDoctor.id;
                this.patientService.branch_id = this.serviceBranch.id;
                this.patientService.patient_insurance_id = this.patientInsurance ? this.patientInsurance.id : null;
                this.patientService.insurance_patient_services_ids = this.patientService.insurance_patient_services.map(item => item.id);
                this.patientService.insurance_children_patient_services_ids = this.patientService.insurance_children_patient_services.map(item => item.id);
                return apiClient.put('patient-services/'+this.id, this.patientService);
            } else {
                return apiClient.post('patient-services', this.patientService);
            }
        },
        removeChildPatientService(index) {
            this.patientService.insurance_children_patient_services.splice(index, 1);
        },
        removeParentPatientService(index) {
            this.patientService.insurance_patient_services.splice(index, 1);
        },
        addInsurancePatientService(ps)
        {
            this.patientService.insurance_patient_services.push(ps);
        },
        afterSave: function (patientService) {
            this.$emit('saved', patientService);
        },
        dateClicked(item) {
            this.$bvModal.hide('modal-2');
            
            this.dates.push({date: item.date, duration: item.duration});
        },
        appointmentSelected(appointment) {
            this.$bvModal.hide('modal-3');
            this.appointments.push(appointment);
        },
        removeDate(i) {
            this.dates.splice(i, 1);
        },
        removeAppointment(i) {
            this.appointments.splice(i, 1);
        },
        reloadPrices: function () {
            var insurancePart = '';
            if (this.patientInsurance) {
                insurancePart = '/'+this.patientInsurance.insurance_provider_id;
            }
            
            this.apiRequest(
                apiClient => {
                    return apiClient.get(
                        'service-prices/find-price/'+this.patientService.service_id + insurancePart
                    )
                    .then(response => {
                        this.patientService.price = response.price;
                        this.patientService.patient_pay = (this.percentage / 100)* this.finalPrice;
                    });
                }
            );
        }
    },
    computed: {
        requireAppointments: function () {
            var remainingAppointments = 
                    this.patientService.appointments
                        .filter(item => item.removed ? false : true)
                        .map(item => item.id);
            var currentAppointments = [...this.appointments.map(item => item.id), ...remainingAppointments];
            var newAppointments = this.dates;
            var hasAppointments = false;
            if ((newAppointments.length + currentAppointments.length) > 0) {
                hasAppointments = true;
            }

            return (!hasAppointments && (this.patientService.status =="in progress" || this.patientService.status =="finished"));
        },
        isDoctorChanged: function () {
            if (!this.patientService) {
                return false;
            }
            return this.patientService.doctor.id != this.currentDoctor.id;
        },
        finalPrice: function () {
            var patientService = this.patientService;
            if (patientService) {
                return patientService.price
                    + parseFloat((patientService.extra_fees ? patientService.extra_fees : 0))
                    - (patientService.discount ? patientService.discount : 0)
                    - (patientService.deduction ? patientService.deduction : 0);
            } else {
                return 0;
            }
        }
    },
    watch: {
        isDoctorChanged: function() {
            var removed = false;
            if (this.isDoctorChanged) {
                removed = true;
            }
            this.patientService.appointments.forEach(appointment => {
                appointment.removed = removed;
            });
        },
        currentDoctor: function (){
            if (this.currentDoctor) {
                var serviceBranch = null;
                if (this.serviceBranch) {
                    serviceBranch = this.currentDoctor.branches.find(item => item.id == this.serviceBranch.id);
                }
                
                if (this.currentDoctor.branches.length == 1) {
                    this.serviceBranch = this.currentDoctor.branches[0];
                } else if (serviceBranch) {
                    this.serviceBranch = serviceBranch;
                }

            }
        },
        patientInsurance: function () {
            this.patientService.patient_insurance = this.patientInsurance;
            if (
                (!this.originalInsurance && !this.patientInsurance)
                || (this.originalInsurance && this.patientInsurance && this.originalInsurance.id == this.patientInsurance.id)
            ) {
                this.patientService.price = this.originalPrice;
                this.patientService.patient_pay = (this.percentage / 100)* this.finalPrice;
            } else {
                this.reloadPrices();
            }
        }
    }
}
</script>