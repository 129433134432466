<template>
    <AppointmentsList v-if='id' :doctorId='id'></AppointmentsList>
</template>

<script>
import AppointmentsList from '@/views/Appointments/AppointmentsList';

export default {
    props: ['id'],
    components:{AppointmentsList}
}
</script>