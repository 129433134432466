<template>
    <div>
        <loader v-if='loading' />
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>
            <b-form-group label="Name">
                <b-form-input v-model="service.name" :state="states.name" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.name' />
            </b-form-group>

            <b-form-group label="Section">
                <v-select :options="sections" :reduce="section => section.id" label='name'
                    v-model='service.section_id'></v-select>
                <FormGroupError :errors='errors.section_id' />
            </b-form-group>

            <b-form-group label="Average Duration" description="average duration in minutes">
                <b-form-input v-model="service.avg_duration" :state="states.avg_duration" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.avg_duration' />
            </b-form-group>

            <b-form-group label="Price" description="price">
                <b-form-input v-model="service.price" :state="states.price" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.price' />
            </b-form-group>

            <b-form-checkbox v-model="service.multiple_appointments" value="1" unchecked-value="0" size="lg">
                Enable Multiple Appointment
            </b-form-checkbox>
            <FormGroupError :errors='errors.multiple_appointments' />

            <b-form-checkbox v-model="service.multiple_body_parts" value="1" unchecked-value="0" size="lg">
                Enable Multiple Body Parts
            </b-form-checkbox>
            <FormGroupError :errors='errors.multiple_body_parts' />

            <b-form-checkbox v-model="service.body_parts_required" value="1" unchecked-value="0" size="lg">
                Require Body Parts
            </b-form-checkbox>
            <FormGroupError :errors='errors.body_parts_required' />

            <b-form-checkbox v-model="service.is_active" value="1" unchecked-value="0" size="lg">
                Active
            </b-form-checkbox>
            <FormGroupError :errors='errors.is_active' />

            <b-form-checkbox v-model="service.require_external_service" value="1" unchecked-value="0" size="lg">
                Require External Services
            </b-form-checkbox>
            <FormGroupError :errors='errors.require_external_service' />

            <b-row v-for="(serviceProduct, index) in service.service_products" :key='index'>
                <b-col cols='8'>
                    <b-form-group label="Material">
                        <ProductsSelect v-model='serviceProduct.product' />
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group label="Amount" description="amount">
                        <b-form-input v-model="serviceProduct.amount" type="text" placeholder="">
                        </b-form-input>
                    </b-form-group>
                </b-col>
                <b-col align-self="center">
                    <b-button variant='danger' @click="deleteProductItem(index)">x</b-button>
                </b-col>
            </b-row>
            <div class="m-2">
                <b-button variant='success' @click='addProductItem'>+</b-button>
            </div>
            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
export default {
    extends: BaseForm,
    data: function () {
        return {
            service: {
                require_external_service: false,
                service_products: [{}],
                branch_id: 1
            },
            sections: [],
            products: [],
            branch: 'unset'
        }
    },
    mounted() {
        this.apiRequest(apiClient => apiClient.get('branches'))
            .then(response => {
                this.branches = response.data;
            });

        this.apiRequest(apiClient => apiClient.get('sections'))
            .then(response => {
                this.sections = response.data;
            });
    },
    methods: {
        load: function (id) {
            return this.apiRequest(apiClient => apiClient.get('services/' + id))
                .then(response => this.service = response);
        },
        save: function (apiClient) {
            var service = { ...this.service };

            service.service_products = service.service_products.map(item => {
                return {
                    product_id: item.product.id,
                    amount: item.amount,
                }
            });

            if (this.id) {
                return apiClient.put('services/' + this.id, service);
            } else {
                return apiClient.post('services', service);
            }
        },
        afterSave: function () {
            this.$emit('saved', 1);
        },
        addProductItem() {
            if (!this.service.service_products) {
                this.service.service_products = [];
            }
            this.service.service_products.push({});
        },
        deleteProductItem(index) {
            this.service.service_products.splice(index, 1);
        }
    },
}
</script>