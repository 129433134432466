<template>
    <div class="auth-form">
        <div class='row justify-content-md-center m-5'>
            <img class="logo-abbr" src="../../images/cdc-logoGrey.png" alt="">
        </div>

        <b-form class='basic-form' @submit="submit" novalidate>
            <div class="form-group">
                <label class="mb-1"><strong>Username</strong></label>
                <input type="username" v-model='form.username' class="form-control" value="">
            </div>
            <div class="form-group">
                <label class="mb-1"><strong>Password</strong></label>
                <input type="password" v-model='form.password' class="form-control" value="">
            </div>
            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <div class="text-center">
                <button v-if='!this.saving' type="submit" class="btn btn-primary btn-block">Sign Me In</button>

                <b-button v-if='this.saving' variant="primary" class="btn btn-primary btn-block" :disabled='true'>
                    <b-spinner small type="grow"></b-spinner>
                    checking...
                </b-button>
            </div>

        </b-form>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'

export default {
    extends: BaseForm,
    data() {
        return {
            form: {}
        };
    },
    methods: {
        save: function (apiClient) {
            return apiClient.post('auth-tokens', this.form);
            // return apiClient.post('auth/login', this.form);
        },
        afterSave: function (response) {
            this.apiClient.setToken(response.token);

            var user = response.user;
            localStorage.isAuthenticated = true;
            localStorage.token = response.token;
            localStorage.user = JSON.stringify(user);
            this.$router.push(this.$route.query.redirect || '/');
            this.$emit('saved', 1);

            this.$echo.private('users.' + user.id)
                .notification((notification) => {
                    this.notify(notification.title, notification.message);
                });
        }
    },
}
</script>