<template>
    <div class='card'>

        <div class='card-header'>
            <h5 class="card-title">Purchased Items List</h5>
            <router-link :to="{name: 'PurchaseForm'}" class="btn btn-primary btn-sm">
                <i class="fa fa-plus"></i>
                New Purchase
            </router-link>
        </div>
        <div class='card-body'>
            <div class="table-responsive recentOrderTable">
                <b-table striped hover :items="items" :fields="fields">
                </b-table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: function() {
        return {
            items: [
                {
                    date: '2020-10-05',
                    purchase_id: 10,
                    item: 'Compose',
                    supplier: 'Medical Tools',
                    quantity: 100,
                    price: '10,000.00'
                },
                {
                    date: '2020-10-06',
                    purchase_id: 15,
                    item: 'Compose',
                    supplier: 'Medical Tools',
                    quantity: 500,
                    price: '50,000.00'
                }
            ],
            fields: [
                { key: 'date', label: 'Date' },
                { key: 'purchase_id', label: 'Purchase ID' },
                { key: 'item', label: 'Item' },
                { key: 'supplier', label: 'Supplier' },
                { key: 'quantity', label: 'Quantity' },
                { key: 'price', label: 'Price' }
            ]
        };
    }
}
</script>