<template>
    <div>
        <loader v-if='loading'/>
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>
            <b-form-group label="Name">
                <b-form-input v-model="doctor.name" :state="states.name" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.name'/>
            </b-form-group>

            <b-form-group label="Fixed Fees">
                <b-form-input v-model="doctor.fixed_fees" :state="states.fixed_fees" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.fixed_fees'/>
            </b-form-group>

            <b-form-group label="Fees Percentage">
                <b-form-input v-model="doctor.fees_percentage" :state="states.fees_percentage" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.fees_percentage'/>
            </b-form-group>

            <b-form-group label="Section">
                <SectionsSelect v-model='section'/>
                <FormGroupError :errors='errors.section_id'/>
            </b-form-group>

            <b-form-group label="Inventory">
                <InventoriesSelect v-model='inventory'/>
                <FormGroupError :errors='errors.inventory_id'/>
            </b-form-group>
            
            <b-form-group>
                <b-form-checkbox v-model='doctor.is_assistant' value='1' unchecked-value="0">
                    Assistant
                </b-form-checkbox>
            </b-form-group>

            <div class='m-2' v-if='doctor.section_id'>
                <b-button variant='primary' class='btn btn-primary btn ml-2 align-right' @click="modal('servicesSelector', {multiple: true, sectionId: doctor.section_id}, 'addServices')">Add Service Fee</b-button>
            </div>

            <div v-for='(doctorServiceFee, index) in doctorServiceFees' :key='index'>
                <b-row class='mt-4'>
                    <b-col>
                        <b-form-group label="Service">
                            <div>
                                <i class='fa fa-stethoscope'></i>
                                {{ doctorServiceFee.service.name | str_limit(50) }}
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group label="Fixed Fees">
                            <b-form-input type='number' placeholder="Fixed Fees" v-model='doctorServiceFee.fixed_fees'>
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group label="Percentage">
                            <b-form-input type='number' placeholder="Fees Percentage" v-model='doctorServiceFee.fees_percentage'>
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col>
                    <b-button variant='danger' class='m-2 btn btn-danger btn-xs sharp' @click='removeServiceFee(index)'>
                        <i class='fa fa-remove'></i>
                    </b-button>
                    </b-col>
                </b-row>
            </div>

            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
		<Modals :modalName='currentModal' :modalData='currentModalData' @saved='modalCallback' @closed='modalClosed'>
		</Modals>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
export default {
    extends: BaseForm,
    data: function () {
        return {
            doctor: {
            },
            doctorServiceFees: [],
            section: null,
            inventory: null
        }
    },
    methods: {
        load: function(id) {
            return this.apiRequest(apiClient => apiClient.get('doctors/'+id))
                .then(response => {
                    this.doctor = response;
                    this.doctorServiceFees = response.service_fees;
                    this.section = response.section;
                    this.inventory = response.inventory;
                });
        },
        save: function (apiClient) {
            var doctor = {...this.doctor, service_fees: this.doctorServiceFees};
            if (this.id) {
                return apiClient.put('doctors/'+this.id, doctor);
            } else {
                return apiClient.post('doctors', doctor);
            }
        },
        afterSave: function (response) {
            this.$emit('saved', response);
        },
        addServices(services) {
            services.forEach(service => {
                this.doctorServiceFees.push({
                    service: service,
                    service_id: service.id,
                    fixed_fees: null,
                    fees_percentage: null
                });
            });
        },
        removeServiceFee(index) {
            this.doctorServiceFees.splice(index, 1);
        },
    },
    watch: {
        section() {
            this.doctor.section_id = this.section.id;
        },
        inventory() {
            this.doctor.inventory_id = this.inventory.id;
        }
    }
}
</script>