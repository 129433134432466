<template>
    <div>
        <b-form class='basic-form' @submit="submit" novalidate>
            
            <b-form-group label="Name">
                <b-form-input v-model="patient.name" :state="states.name" type="text" placeholder="example: Omar Mohamed Osama">
                </b-form-input>
                <FormGroupError :errors='errors.name'/>
            </b-form-group>
            <b-form-group label="Phone">
                <b-form-input
                    v-model="patient.phone" type='text' v-debounce:300ms="loadParents" :state="states.phone" placeholder="Phone Number">
                </b-form-input>
            </b-form-group>
            <loader v-if='parentPatientsLoading'/>
            <div v-else-if='parentPatients.length > 0'>
                <b-form-group label='Related to'>
                <div v-for='(parentPatient, index) in parentPatients' :key='index'>
                    <b-form-radio v-model="patient.parent_id" :value='parentPatient.id' type="radio" name='parent' size="lg">
                        {{ parentPatient.name }}
                        <ul>
                            <li v-for='(child, index2) in parentPatient.children' :key='index2'>
                                <div class='badge badge-success'>
                                {{ child.relation_with_parent }}
                                </div>
                                {{ child.name }}

                            </li>
                        </ul>
                    </b-form-radio>
                </div>
                </b-form-group>
                <b-form-group label="Relation with parent" v-if='patient.parent_id'>
                    <v-select v-model='patient.relation_with_parent' :options="parentRelations" /> 
                </b-form-group>
            </div>

             <b-form-group label="Gender">
                <b-form-radio-group
                    v-model="patient.gender"
                    :options="['male', 'female']"
                    name="radio-inline"
                ></b-form-radio-group>
            </b-form-group>
            <b-form-group label="Email">
                <b-form-input v-model="patient.email"  :state="states.email" type='email' placeholder="example@gmail.com">
                </b-form-input>
                <FormGroupError :errors='errors.email'/>
            </b-form-group>
            <b-form-group label="Nationality">
                <countries-select v-model='patient.country_code'/>
            </b-form-group>

            <b-form-group label="Age">
                <b-form-input type='text' 
                    v-model="patient.age" :state="states.age" placeholder="Age">
                </b-form-input>
            </b-form-group>

            <b-form-group label="Birth Date">
                <v-date-picker v-model="patient.birth_date" :masks='{input: "DD-MM-YYYY"}'>
                    <template v-slot="{ inputValue, inputEvents }">
                        <input
                        class="bg-white border px-2 py-1 rounded"
                        :value="inputValue"
                        v-on="inputEvents"
                        />
                    </template>
                </v-date-picker>
                
            </b-form-group>
            <b-form-group label="contact">
                <b-form-input type='text' placeholder="Contact">
                </b-form-input>
            </b-form-group>

            <b-form-group label='patient default insurance' v-if='id'>
                <patient-insurances-select v-model='patient.patient_insurance_id' :patientId="this.id" :returnId='true'/>
            </b-form-group>

            <div v-else>
                <b-form-group label="Insurance">
                    <InsuranceProvidersSelect v-model='patientInsurance.insurance_provider_id' :returnId='true'/>
                </b-form-group>
                <div v-if='patientInsurance.insurance_provider_id'>
                    <b-form-group label="Expire Date">
                        <v-date-picker v-model="patientInsurance.expire_at" :masks='{input: "DD-MM-YYYY"}'>
                            <template v-slot="{ inputValue, inputEvents }">
                                <input
                                class="bg-white border px-2 py-1 rounded"
                                :value="inputValue"
                                v-on="inputEvents"
                                />
                            </template>
                        </v-date-picker>
                        
                    </b-form-group>

                    <b-form-group label="Insurance ID">
                        <FileUpload v-on:input='value => patientInsurance.card_file_ids = value'/>
                    </b-form-group>

                </div>

            </div>


            <b-form-group label="How do patient know about us?">
                <options-select v-model='patient.knowledge_option_id' :returnId='true'/>
            </b-form-group>
            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
import OptionsSelect from './OptionsSelect.vue';
export default {
  components: { OptionsSelect },
    extends: BaseForm,
    props: ['lead'],
    data: function () {
        return {
            patient: {
            },
            patientInsurance: {

            },
            parentRelations: ['daughter', 'son', 'mother', 'father', 'other'],
            parentPatients: [],
            parentPatientsLoading: false
        }
    },
    methods: {
        loadParents: function() {
            if (!this.patient.phone) {
                return;
            }
            this.parentPatientsLoading = true;

            this.apiRequest(apiClient => {
                var filter = {phone: this.patient.phone, is_parent: 1, with_children: 1};
                if (this.patient.id) {
                    filter.not_ids = [this.patient.id];
                }
                return apiClient.get('patients', filter);
            }).then(response => {
                this.parentPatients = response.data;
                this.parentPatientsLoading= false;
                if (this.patient.parent_id) {
                    var parentPatient = this.parentPatients.find(item => item.id = this.patient.parent_id);
                    if (!parentPatient) {
                        this.patient.parent_id = null;
                        this.patient.relation_with_parent = null;
                    }
                }
            });
        },
        load: function(id) {
            return this.apiRequest(apiClient => apiClient.get('patients/'+id))
                .then(response => {
                    this.patient = response,
                    this.loadParents();
                });
        },
        save: function (apiClient) {
            var patient = { ...this.patient};
            patient.birth_date = patient.birth_date ? this.date(patient.birth_date) : null;
            if (this.id) {
                return apiClient.put('patients/'+this.id, patient);
            } else {
                return apiClient.post('patients', {... patient, patient_insurance: this.patientInsurance});
            }
        },
        afterSave: function (patient) {
            this.$emit('saved', patient);
        }
    },
    watch: {
        lead: {
            immediate: true,
            handler: function () {
                if (this.lead) {
                    this.patient.lead_id = this.lead.id;
                    this.patient.name = this.lead.name;
                    this.patient.phone = this.lead.phone;
                    this.patient.knowledge_option_id = this.lead.knowledge_option_id;
                }
            }
        }
    }
}
</script>