<template>
    <div class='row justify-content-center'>
        <div class='card col-9'>
            <div class="card-header">
                <h4 class="card-title">Patient Form</h4>
            </div>
            
            <div class="card-body">
                <PatientForm @saved='afterSave' :id='id'></PatientForm>        
            </div>            
        </div>
    </div>
</template>

<script>
import PatientForm from '@/components/PatientForm';

export default {
    props: ['id'],
    components: {
        PatientForm
    },
    data: function () {
        return {
            patient: {
            }
        }
    },
    methods: {
        afterSave: function (patient) {
            this.$router.push({name: 'ViewPatient',  params: {id: patient.id}});
        }
    },
}
</script>