<template>
    <div>
        <b-form class='basic-form' v-if='patientService' @submit="submit" novalidate>
            <b-form-group label="Service">
                <label for="">
                    <b>
                    {{ patientService.service.name }}
                    </b>
                </label>
            </b-form-group>

            <b-form-group label="Insurance">
                <InsuranceProvidersSelect v-model='selectedInsuranceProvider'/>
            </b-form-group>

            <b-form-group label="Discount">
                <b-form-input
                    v-model="patientService.discount" type='number' :state="states.discount" placeholder="Discount">
                </b-form-input>
            </b-form-group>
            <b-form-group label="Total Required">
                <span v-if='totalPriceLoading'>
                    loading ...
                </span>
                <span v-if='!totalPriceLoading'>
                {{ totalPrice }}
                </span>
            </b-form-group>
            
            <b-form-row v-if='selectedInsuranceProvider.id'>
                <b-col>
                <b-form-group :disabled='selectedService ? false : true' label="Required From Patient" description="amount that should be payed by patient">
                    <b-form-input v-model="patientService.patient_pay" :state="states.patient_pay" type="text" placeholder="">
                    </b-form-input>
                    <FormGroupError :errors='errors.patient_pay'/>
                </b-form-group>
                </b-col>
                <b-col>
                <b-form-group :disabled='selectedService ? false : true' label="Required From Insurance" description="amount that should be payed by insurance">
                    <b-form-input v-model="patientService.insurance_pay" :state="states.insurance_pay" type="text" placeholder="">
                    </b-form-input>
                    <FormGroupError :errors='errors.insurance_pay'/>
                </b-form-group>
                </b-col>
            </b-form-row>
            <b-form-group label="ID">
                <FileUpload v-on:input='value => this.cardFileIds = value'/>
            </b-form-group>
            <b-form-group label="Insurance">
                <FileUpload v-on:input='value => this.insuranceFileIds = value'/>
            </b-form-group>
            
            
            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>
            {{ cardFileIds }}
            {{ insuranceFileIds }}
            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'

export default {
    extends: BaseForm,
    data: function () {
        return {
            patientService: null,
            selectedService: {},
            selectedInsuranceProvider: {},
            totalPrice: '',
            totalPriceLoading: false,
            totalPriceCancelToken: null,
            cardFileIds: [],
            insuranceFileIds: [],
        }
    },
    methods: {
        load: function(id) {
            return this.apiRequest(apiClient => apiClient.get('patient-services/'+id))
                .then(response => {
                    this.patientService = response;
                    this.totalPrice = response.price;
                });
        },
        save: function (apiClient) {
            var patientService = {};
            patientService.discount = this.patientService.discount;
            if (this.selectedInsuranceProvider) {
                this.patientService.insurance_provider_id = this.selectedInsuranceProvider.id;
                patientService.insurance_pay = this.patientService.insurance_pay;
                patientService.patient_pay = this.patientService.patient_pay;
            }
            
            return apiClient.put('patient-services/'+this.id, this.patientService);
        },
        afterSave: function (patientService) {
            this.$emit('saved', patientService);
        },
        calculateTotals: function () {
            if (this.selectedInsuranceProvider.id) {
                this.totalPriceLoading = true;
                this.apiRequest((apiClient, cancelToken) => {
                    if (this.totalPriceCancelToken) {
                        apiClient.cancel(this.totalPriceCancelToken);
                    }

                    this.totalPriceCancelToken = cancelToken;
                    return apiClient.get('service-prices',{
                            insurance_provider_id: this.selectedInsuranceProvider.id,
                            service_id: this.patientService.service_id,
                        }).then(response => {
                            if(response.data[0]) {
                                this.totalPrice = response.data[0].price;
                            } else {
                                this.totalPrice = this.selectedService.price;
                            }
                            this.totalPriceCancelToken = null;
                            this.totalPriceLoading = false;
                        });
                });
            } else if (this.selectedService) {
                this.totalPrice = this.selectedService.price;
            }

        }
    },
    watch: {
        selectedInsuranceProvider: function () {
            this.calculateTotals();
        },
        selectedService: function () {
            this.calculateTotals();
        }
    }
}
</script>