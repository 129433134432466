<template>

    <SimpleListView title="Patients Services List" :collection="collection" :fields="fields" :loading="loading">
        
        <template v-slot:filter>
            <div class='form'>
                <div class='form-row'>
                    <div  class='form-group col-md-4'>
                        <b-form-input v-model="filter.keyword" placeholder="#ID - name - phone"></b-form-input>
                    </div>
                </div>
            </div>
            <div class='form'>
                <div class='form-row'>
                    <b-col>
                        <b-form-group label="Status">
                            <b-form-radio-group
                                v-model="filter.status"
                                :options="['in progress', 'finished', 'all']"
                            ></b-form-radio-group>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-checkbox
                            v-model="filter.insured"
                            value="1"
                            unchecked-value="0"
                        >
                            Insured
                        </b-form-checkbox>
                    </b-col>
                </div>
                
                <div class='form-row'>
                    <b-col>
                        <b-form-group label="Patient">
                            <patients-select v-model='filter.patient_id' returnId='true'/>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group label="Section">
                            <sections-select v-model='filter.section_id' returnId='true'/>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group label="Doctor">
                            <doctors-select v-model='filter.doctor_id' :section_id='filter.section_id' returnId='true'/>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group label="Service">
                            <services-select v-model='filter.service_id' :section_id='filter.section_id' returnId='true'/>
                        </b-form-group>
                    </b-col>
                </div>
            </div>
        
            <div>
                <b-form-group label="Order By" class="ml-2">
                    <b-form-radio-group
                        v-model="filter.order_by"
                        :options="['Creation Time', 'Appointment Time']"
                        name="radios-btn-default"
                        buttons
                        button-variant="primary"
                    ></b-form-radio-group>
                </b-form-group>
            </div>
        </template>
        <template v-slot:cell(name)='data'>
            <router-link v-if='viewType != "patientView"' :to="{ name: 'ViewPatient', params: {id: data.item.patient_id}}" class='badge'>
                <h5>
                <i class='fa fa-user'></i>
                {{ data.item.patient.name }}
                </h5>
            </router-link>
            <br>
            <div class='badge text-wrap mt-1'
                :title='data.item.name + " - "+ data.item.status'
                :class="serviceStatusBadges[data.item.status]"
            >
                <i class='fa fa-stethoscope'></i>
                {{ data.item.service.name}}
            </div>
            <br/>
            <div class='badge text-wrap mt-2'
                v-if='data.item.body_part'
                :title='data.item.name + " - "+ data.item.status'
                :class="serviceStatusBadges[data.item.status]"
            >
                {{ data.item.body_part}}
            </div>
            <div>
                <div class='badge badge-light m-1'>
                    <i class='fa fa-user-md'></i>
                    Dr. {{ data.item.doctor.name }}
                </div>
            </div>
            <div class='m-1'>
                <div v-if='data.item.branch' class='badge badge-light'>
                    {{ data.item.branch ? data.item.branch.name : '' }}
                </div>
            </div>
        </template>
        
        <template v-slot:cell(invoice_id)='data'>

                <div v-if='data.item.insurance_approval' :title='data.item.insurance_approval.status'  class='badge text-wrap mt-2' :class='data.item.insurance_approval.status=="approved" ? "badge-success": "badge-warning"'>
                    Approval #{{ data.item.insurance_approval.id }}
                </div>
            <div v-if='data.item.invoice_id' class="badge badge-light">Invoice #{{ data.item.invoice_id }}</div>
        </template>

        <template v-slot:cell(actions)='data'>
            <button class='btn btn-primary' @click="$emit('clickedItem', data.item)">Select</button>
        </template>
                
    </SimpleListView>
</template>

<script>
import BaseList from '@/views/BaseList';

export default {
    extends: BaseList,
    props: ['baseFilter', 'filter_optional'],
    mounted() {
        this.filter = {...this.baseFilter};
    },
    methods: {
        load: function (apiClient, cancelToken) {
            var filter = {};
            if (this.filter_optional) {
                filter = {...this.baseFilter, ... this.filter};
            } else {
                filter = {... this.filter, ...this.baseFilter};
            }
            return apiClient.get('patient-services', filter, cancelToken);
        }
    },
    data: function() {
        return {
			serviceStatusBadges: {
				'finished': 'badge-success',
				'in progress': 'badge-warning',
				'pending': 'badge-light',
				'virtual insurance': 'badge-info',
			},
            fields: [
                { key: 'id', label: '#' },
                { key: 'name', label: 'Name' },
                { key: 'paid_bt_insurance', label: 'Paid' },
                { key: 'insurance_pay', label: 'Required' },
                { key: 'invoice_id', label: 'Invoice' },
                { key: 'actions', label: '' }
            ]
        };
    }
}
</script>

