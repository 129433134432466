<template>
    <div>
        <b-form v-if='appointment' class='basic-form' @submit="submit" novalidate>
            
            <div class='form-group'>
                <label for="">
                    <i class='fa fa-'></i>
                    Branch
                </label>
                <div>
                    <strong>
                    {{ appointment.branch.name }}
                    </strong>
                </div>
            </div>

            <div class='form-group'>
                <label for="">
                    <i class='fa fa-user-md'></i>
                    Doctor
                </label>
                <div>
                    <strong>
                    {{ appointment.doctor.name }}
                    </strong>
                </div>
            </div>
            <div class='form-group'>
                <label>
                    <i class='fa fa-user'></i>
                    Patient
                </label>
                <div>
                    <strong>
                    {{ appointment.patient.name }}
                    </strong>
                </div>
            </div>

            <div class='form-group' v-if='appointment.doctor_id && appointment.branch_id'>
                <label for="">
                    <i class='fa fa-'></i>
                    Clinic
                </label>
                <ClinicsSelect v-model='appointment.clinic_id' :returnId='true' :sectionId='appointment.section_id' :branchId='appointment.branch_id'/>
            </div>
            
            <b-form-checkbox
                v-model="appointment.is_confirmed"
                :value="1"
                unchecked-value="0"
                size="lg"
            >
                confirmed
            </b-form-checkbox>
            <b-form-group
                label="Status"
            >
                    <b-form-radio-group
                            v-model="appointment.status"
                            :options="statusOptions"
                            size="lg"
                        ></b-form-radio-group>
            </b-form-group>
            
            <b-form-group>
                <slot name="label"><i class="fas fa-stethoscope"></i> Patient Services </slot> 
                <b-form-checkbox-group
                        v-model="selectedPatientServices"
                        :options="patientServicesOptions"
                        stacked
                        size="lg"
                    ></b-form-checkbox-group>
            </b-form-group>
            
            <div 
                v-for='(patientService, index) in patientServices'
                    :key='index'
            >
                <b-row>
                    <b-col cols='5'>
                        <b-form-checkbox
                            v-model="selectedPatientServices"
                            name="pservices"
                            :value="patientService.id"
                            unchecked-value="false"
                            size="lg"
                        >
                        {{ patientService.id }}
                        -
                        {{ patientService.name }}
                        </b-form-checkbox>
                    </b-col>
                    <b-col
                        cols='3'
                    >
                        <b-form-group
                            label="Appointments"
                            v-if="selectedPatientServices.indexOf(patientService.id) != -1"
                        >
                            <div class='badge badge-light'>
                                {{ patientService.available_appointments_count }} / {{ patientService.required_appointments  }}
                                reserved
                            </div>
                            <div class='badge badge-success ml-2'>
                            {{ patientService.finished_appointments_count }} finished
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col
                        cols='3'
                    >
                        <div v-if='serviceStatus(patientService) == "finished"' class='badge badge-success'>
                            finished
                        </div>
                        <div v-else-if='serviceStatus(patientService) == "in progress"' class='badge badge-warning'>
                            in progress
                        </div>
                        <div v-else class='badge badge-light'>
                            pending
                        </div>
                    </b-col>
                </b-row>
            </div>
            <h3 v-if='this.services.length > 0'>new Services</h3>
            <div v-for='(service, index) in services' :key='index'>
                <h3>
                    <div class='badge badge-light'>
                        <i class="fa fa-stethoscope"></i>
                        
                        {{ service.name }}
                    </div>
                    <div @click="removeService(index)" class='btn btn-xxs btn-danger m-2'>
                        <i class='fa fa-remove'></i>
                    </div>
                </h3>
            </div>
            <div class='mt-3 mb-3' v-if='appointment.doctor'>
                <b-button variant='primary' class='btn btn-primary btn ml-2' v-b-modal.modal-services>Add Service</b-button>
            </div>
            <div class=form-group>
                <label for="">
                    <i class='fa fa-calendar'></i> Time
                </label>
                <div class='m-2'>
                    <div class='badge badge-lg badge-light'>
                        <i class='fa fa-calendar'></i>
                        {{ appointment.datetime | datetime }}
                    </div>
                    <div class='badge badge-lg badge-light ml-2'>
                        {{ appointment.duration }}
                        minutes
                    </div>
                    <div class='btn btn-primary btn-xs sharp ml-2' v-b-modal.modal-2>
                        <i class='fa fa-edit'></i>
                    </div>
                </div>
            </div>

            <div class='form-group'>
                <label>Note</label>
                <textarea v-model='appointment.note' class='form-control'></textarea>
            </div>
            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
        
        <b-modal v-if='appointment' :no-close-on-backdrop=true id="modal-2" size='lg' hide-footer  title="Choose Date">
            <AppointmentPicker @datetimePicked='dateClicked' :doctor_id='appointment.doctor_id' :duration='appointment.duration'></AppointmentPicker>        
        </b-modal>
        <b-modal :no-close-on-backdrop=true id="modal-services" hide-footer  title="Select Service" v-if='appointment'>
            <ServicesSelector @clickedItem='serviceSelected' :forCreate='true' :sectionId='appointment.doctor.section_id' ></ServicesSelector>        
        </b-modal>
    </div>
</template>

<script>
import AppointmentPicker from '../../components/AppointmentPicker';
import BaseForm from '@/views/BaseForm';
import ServicesSelector from '../../components/ServicesSelector';

export default {
    extends: BaseForm,
    components: {
        AppointmentPicker,
        ServicesSelector
    },
    
    data: function () {
        return {
            appointment: null,
            selectedPatientServices: [],
            patientServicesOptions: [],
            patientServices: [],
            statusOptions: ['pending', 'finished', 'no service', 'no show'],
            services: []
        }
    },
    methods: {
        serviceStatus(patientService) {
            if (this.selectedPatientServices.indexOf(patientService.id) == -1 || this.appointment.status == "pending" || this.appointment.status == "no show") {
                return patientService.status;
            }

            if ((!patientService.multiple_appointments || patientService.remaining_appointments <= 0) && this.appointment.status =="finished") {
                return "finished";
            } else if (this.appointment.status =="finished") {
                return "in progress";
            }
        },
        load: function(id) {
            return this.apiRequest(apiClient => apiClient.get('appointments/'+id))
                .then(response => {
                    this.appointment = response;
                    
                    var patientServices = this.appointment.patient.active_services ? this.appointment.patient.active_services : [];
                    patientServices = patientServices.filter(
                        item => {
                            return (
                                item.doctor_id == this.appointment.doctor_id
                                &&
                                item.available_appointments_count < item.required_appointments
                            );
                        }
                    );

                    patientServices = patientServices.map(item => {
                            item.remaining_appointments = item.required_appointments - item.finished_appointments_count - 1;
                            return item;
                        });

                    var appointmentServices = this.appointment.patient_services ? this.appointment.patient_services : [];
                    appointmentServices = appointmentServices.map(item => {
                            item.remaining_appointments = item.required_appointments - item.finished_appointments_count;
                            return item;
                        });

                    //remove duplicates
                    this.patientServices = [...appointmentServices, ...patientServices].filter(
                        (v, i, self) => {
                            return self.findIndex(item => item.id == v.id) === i;
                        }
                    );

                    this.selectedPatientServices = this.appointment.patient_services ? 
                         this.appointment.patient_services.map(item => item.id) : [];
                });
        },
        save: function (apiClient) {
            if (this.id) {

                return apiClient.put('appointments/'+this.id, {
                    is_confirmed: this.appointment.is_confirmed,
                    datetime: this.appointment.datetime,
                    duration: this.appointment.duration,
                    status: this.appointment.status,
                    clinic_id: this.appointment.clinic_id,
                    patientServiceIds: this.selectedPatientServices,
                    note: this.appointment.note,
                    service_ids: this.services.map(item => item.id),
                });
            }
        },
        afterSave: function (appointments) {
            this.$emit('saved', appointments);
            // this.$router.push({name: 'Appointments'});
        },
        getClickedItem(patient) {
            this.$bvModal.hide('modal-1');
            this.patient = patient;
        },
        dateClicked(item) {
            this.$bvModal.hide('modal-2');
            
            this.appointment.datetime = this.datetime(item.date);
            this.appointment.duration = item.duration;
        },
        serviceSelected(service) {
            this.$bvModal.hide('modal-services');
            
            this.services.push(service);
        },
        removeService(index) {
            this.services.splice(index, 1);
        }
    }
}
</script>