<template>
    <div>
        <ListView title="BodyPartGroups List" :modalObject='currentModalObject' >
            <template v-slot:actions>
                <b-button @click="modal('bodyPartGroupForm', {}, 'refreshTable')" variant='primary' class='mr-2'>
                    Create BodyPartGroup
                </b-button>

            </template>

            <template v-slot:filter>
                <div class='form'>
                    <div class='form-row'>
                        <div class='form-group col-md-4'>
                            <b-form-input v-model="filter.keyword" placeholder="search ..."></b-form-input>
                        </div>
                    </div>
                </div>
            </template>

			<template v-slot:table>
				<BodyPartGroupsListTable :filter='filter' ref='tableList' :viewType='viewType'></BodyPartGroupsListTable>
			</template>
                    
        </ListView>
    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import BodyPartGroupsListTable from '@/components/Lists/BodyPartGroupsListTable';

export default {
    extends: BaseList,
    components: {BodyPartGroupsListTable},
    methods: {
        refreshTable () {
            this.$refs.tableList.refresh();
        }
    }
}
</script>
