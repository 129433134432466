<template>
    <div>
        <loader v-if='loading'/>
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>
            <b-form-group label="Text">
                <b-form-textarea v-model="patientNote.text" :state="states.text" type="text" placeholder="">
                </b-form-textarea>
                <FormGroupError :errors='errors.text'/>
            </b-form-group>
            
            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
export default {
    extends: BaseForm,
    props: ['patientId'],
    data: function () {
        return {
            patientNote: {
            }
        }
    },
    methods: {
        load: function(id) {
            return this.apiRequest(apiClient => apiClient.get('patient-notes/'+id))
                .then(response => this.patientNote = response);
        },
        save: function (apiClient) {
            if (this.id) {
                return apiClient.put('patient-notes/'+this.id, this.patientNote);
            } else {
                var patientNote = {... this.patientNote, patient_id: this.patientId}
                return apiClient.post('patient-notes', patientNote);
            }
        },
        afterSave: function () {
            this.$emit('saved', 1);
        }
    },
}
</script>