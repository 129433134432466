<template>
    <div> 
        <ListView title="Patients List" :collection="collection"  @pageChanged='pageChanged' :fields="fields" :loading="loading" hasFilterActions='true'>
            <template v-slot:actions>
                <router-link :to="{name: 'CreatePatient'}" class="btn btn-primary btn-sm">
                    <i class="fa fa-plus"></i>
                    New Patient
                </router-link>
            </template>

            <template v-slot:filter>
                <div class='form'>
                    <div class='form-row'>
                        <div class='form-group col-md-4'>
                            <b-form-input v-model="filter.name" placeholder="#ID - name - phone"></b-form-input>
                        </div>
                    </div>
                </div>
                <div class='form-row'>
                    <div class='col-3'>
                        <div class='form-group m-2'>
                            <label>Branch</label>
                            <BranchesSelect v-model='filter.branch_id' :returnId='true'/>
                        </div>
                    </div>
                </div>
            </template>

            <template v-slot:filter_actions>
                <authorized-url v-if='can("patients.export")' class='btn btn-primary ml-2 float-right' url='patients/export' :params='filter' target="_blank">
                    <i class="fa fa-file-excel"></i>
                    Export
                </authorized-url>
            </template>

			<template v-slot:cell(name)="data">
				
            	<router-link :to="{ name: 'ViewPatient', params: {id: data.item.id}}" class=''>
					<h5>
					{{ data.item.name }}
					</h5>
                    <div class='badge badge-light' v-if='can("patients.view_contact_details")'>
                        <i class='fa fa-phone'></i> {{ data.item.phone }}
                    </div>
				</router-link>
			</template>

            <template v-slot:cell(balance)='data'>
                <div v-for='(financialAccount, index) in data.item.financial_accounts' :key='index'>
                    <div class='badge badge-light'>
                        {{ financialAccount.branch ? financialAccount.branch.name : '' }}
                    </div>
                    <div v-if='financialAccount.balance < 0' class='badge badge-danger'>
                        {{ financialAccount.balance | currency}}
                    </div>
                    <div v-if='financialAccount.balance > 0' class='badge badge-success'>
                        {{ financialAccount.balance | currency}}
                    </div>
                    <div v-if='financialAccount.balance == 0' class='badge badge-light'>
                        {{ 0 | currency }}
                    </div>
                </div>
            </template>
            <template v-slot:cell(appointments)='data'>
                <div v-for='(appointment, index) in data.item.active_appointments' :key='index' >
                    <div class='badge badge-success badge-sm m-2'>
                        <i class='fa fa-calendar'></i>
                        {{ appointment.datetime | datetime }}
                    </div>
                </div>
            </template>

            <template v-slot:cell(last_appointment)='data'>
                <div v-if='data.item.last_appointment' class='badge badge-success badge-sm m-2'>
                    <i class='fa fa-calendar'></i>
                    {{ data.item.last_appointment.datetime | datetime }}
                </div>
                <span v-else class='badge badge-light'>No visit before</span>

            </template>

            <template v-slot:cell(services)="data">
                <div v-for='(patientService, index) in data.item.active_services' :key='index' >
                    <span class='badge badge-success badge-sm m-2' 
                        :class="serviceStatusBadges[patientService.status]"
                        :title='patientService.status'
                        v-b-modal.patient-service-modal @click="selectedPatientService = patientService.id"
                    >
                        <i class='fa fa-stethoscope'></i>
                        {{ patientService.service.name }}
                    </span>
                    <span v-if='patientService.remaining_appointments_count' class='badge badge-warning badge-sm m-2'>
                        {{ patientService.remaining_appointments_count }} remaining
                    </span>
                    <span v-if='patientService.finished_appointments_count' class='badge badge-success badge-sm m-2'>
                        <i class='fa fa-check'></i>
                        {{ patientService.finished_appointments_count }} finished
                    </span>

                </div>
            </template>
            
            <template v-slot:cell(insurance)='data'>

                <div v-for='(patientInsurance, index) in data.item.active_patient_insurances' :key='"a"+index' >
                    <span class='badge badge-success badge-sm m-2' 
                        :class='patientInsurance.is_active =="pending" ? "badge-warning" : "badge-success"'
                    >
                        <i class='fas fa-info'></i>
                        {{ patientInsurance.insurance_provider.name }}
                    </span>
                </div>
                <div v-for='(insuranceApproval, index) in data.item.active_insurance_approvals' :key='index' >
                    <span class='badge badge-success badge-sm m-2' 
                        :class='insuranceApproval.status =="pending" ? "badge-warning" : "badge-success"'
                        :title='insuranceApproval.status'
                    >
                        <i class='fas fa-clipboard-list'></i>
                        {{ insuranceApproval.insurance_provider.name }}
                    </span>
                </div>
            </template>
            <template v-slot:cell(actions)='data'>
                <!-- <button class='btn btn-primary btn-xs ml-2' v-b-modal.service-insurance-modal @click="selectedPatient = data.item">New Insurance Approval</button>
                <router-link :to="{ name: 'AppointmentForm', params: {patient: data.item}}" class='btn btn-success btn-xs m-2'>+ New Appointment</router-link> -->
                <router-link :to="{ name: 'EditPatient', params: {id: data.item.id}}" class='btn btn-success btn-xs m-2'>Edit</router-link>
                <!-- <router-link :to="{ name: 'ViewPatient', params: {id: data.item.id}}" class='btn btn-primary btn-xs'>Open</router-link> -->
                <!-- <button class='btn btn-primary btn-xs ml-2' v-b-modal.patient-payment-modal @click="selectedPatient = data.item">Add Payment</button> -->
            </template>
                    
        </ListView>
        
        <b-modal :no-close-on-backdrop=true id="patient-payment-modal" hide-footer  title="Patient Payment Form">
            <PatientPaymentForm @saved='patientPaymentSaved' :patient='selectedPatient'></PatientPaymentForm>        
        </b-modal>
        <b-modal :no-close-on-backdrop=true id="patient-service-modal" size='lg' hide-footer  title="Patient Service Form">
            <PatientServiceForm @saved='patientServiceSaved' :id='selectedPatientService'></PatientServiceForm>        
        </b-modal>
        <b-modal :no-close-on-backdrop=true id="service-insurance-modal" size='xl' hide-footer  title="Patient Service Insurance Form">
            <ServicesInsuranceForm @saved='serviceInsuranceSaved' :id='selectedInsuranceId' :patientId='selectedPatient?selectedPatient.id : null'></ServicesInsuranceForm>        
        </b-modal>
    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import PatientPaymentForm from '@/components/PatientPaymentForm';
import PatientServiceForm from '@/components/PatientServiceForm';
import ServicesInsuranceForm from '@/components/ServicesInsuranceForm';
import AuthorizedUrl from '../../components/AuthorizedUrl.vue';

export default {
    extends: BaseList,
    props: ['pushHistory'],
    components: {PatientPaymentForm, PatientServiceForm, ServicesInsuranceForm, AuthorizedUrl},
    
    methods: {
        load: function (apiClient, cancelToken) {
            return apiClient.get('patients', {...this.filter, with_details: 1}, cancelToken);
        },
        patientPaymentSaved() {
            this.$bvModal.hide('patient-payment-modal');
            this.refresh();
        },
        patientServiceSaved() {
            this.$bvModal.hide('patient-service-modal');
            this.refresh();
        },
        serviceInsuranceSaved() {
            this.$bvModal.hide('service-insurance-modal');
            this.refresh();
        },
		resolveQuery: function (query) {
			this.filter = query;
		}
    },
    data: function() {
        return {
            clickableImages: [],
            selectedPatient: null,
            selectedPatientService: null,
            selectedInsuranceId: null,
			serviceStatusBadges: {
				'finished': 'badge-success',
				'in progress': 'badge-warning',
				'pending': 'badge-light',
			},
            fields: [
                { key: 'id', label: '#' },
                { key: 'name', label: 'Name' },
                { key: 'balance', label: 'Balance' },
                { key: 'appointments', label: 'Next Appointments' },
                { key: 'services', label: 'Active Services' },
                { key: 'insurance', label: 'Insurance' },
                { key: 'last_appointment', label: 'Last Visit' },
                { key: 'actions', label: '' }
            ]
        };
    }
}
</script>
