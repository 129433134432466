<template>
    <div>
        <loader v-if='loading' />
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>

            <b-form-group label="Date" v-if='can("accounts.list")'>
                <b-form-datepicker v-model="cashPayment.date" today-button reset-button class="mb-2">
                </b-form-datepicker>
            </b-form-group>

            <div v-if='!entityId && !this.editMode'>
                <b-form-group label="Select Payable Type">
                    <v-select v-model='cashPayment.entity_type' :options="payableTypes" />
                </b-form-group>
                <b-form-group label="Select Payable" v-if='cashPayment.entity_type'>
                    <payables-select v-model='payable' :payableType="cashPayment.entity_type" />
                </b-form-group>
            </div>

            <b-form-group v-if='!this.editMode' label="Branch">
                <branches-select v-model='cashPayment.branch_id' :returnId='true' />
            </b-form-group>

            <b-row>
                <b-col cols=4>
                    <b-form-group label="Payment Method"
                        v-if="['Patient', 'InsuranceProvider'].includes(cashPayment.entity_type)">
                        <payment-methods-select v-model='paymentMethod' />
                        <FormGroupError :errors='errors.payment_method_id' />
                    </b-form-group>
                    <b-form-group label="Source of Payment" v-else>
                        <source-of-payments-select v-model='sourceOfPayment' />
                        <FormGroupError :errors='errors.payment_method_id' />
                    </b-form-group>
                </b-col>
                <b-col cols=4 v-if='paymentMethod && paymentMethod.has_due_date'>
                    <b-form-group label="Due Date">
                        <b-form-datepicker v-model="cashPayment.due_date" today-button reset-button class="mb-2">
                        </b-form-datepicker>
                        <FormGroupError :errors='errors.due_date' />
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols=4>
                    <b-form-group label="Amount">
                        <b-form-input v-model="cashPayment.amount" :state="states.amount" type="text" placeholder="">
                        </b-form-input>
                        <FormGroupError :errors='errors.amount' />
                    </b-form-group>
                </b-col>
            </b-row>


            <b-row>
                <b-col cols=4>
                    <b-form-group label="Min Date">
                        <b-form-datepicker v-model="cashPayment.min_date" today-button reset-button class="mb-2">
                        </b-form-datepicker>
                    </b-form-group>
                </b-col>
                <b-col cols=4>
                    <b-form-group label="Max Date">
                        <b-form-datepicker v-model="cashPayment.max_date" today-button reset-button class="mb-2">
                        </b-form-datepicker>
                    </b-form-group>
                </b-col>
            </b-row>

            <div class='form-group'>
                <b-form-group
                    description="this will make sure that this payment will automatically cover any transactions in the given date range if payment amount is larger than current required amount">
                    <b-form-checkbox v-model="cashPayment.auto_settlement" :value=1 :unchecked-value=0>
                        Automatically settle finished transactions
                    </b-form-checkbox>
                </b-form-group>
                <b-form-group v-if='can("accounts.list")'
                    description="This will setlle all transactions remaining amount, payment amount entered should cover all transactions">
                    <b-form-checkbox v-model="cashPayment.settle_all" :value=1 :unchecked-value=0>
                        Settle All Tranasctions
                    </b-form-checkbox>
                </b-form-group>
                <b-form-group description="This will setlle only collected amount not remaining amount">
                    <b-form-checkbox v-model="cashPayment.collected_only" :value=1 :unchecked-value=0>
                        Settle Collected Only
                    </b-form-checkbox>
                </b-form-group>
            </div>

            <div
                v-if='!cashPayment.settle_all && cashPayment.branch_id && cashPayment.entity_type && cashPayment.entity_id'>
                <h2>
                    Remaining:
                    <div class='badge badge-light'>
                        {{ remainingAmount | decimal }}
                    </div>

                </h2>
                <div class="m-2">
                    <b-button variant='primary'
                        @click='modal("transactionsSelector", { multiple: true, baseFilter: { entity_type: cashPayment.entity_type, entity_id: cashPayment.entity_id, not_payment: 1, unpaid: 1 } }, "addTransaction")'>Add
                        Transaction</b-button>
                </div>
            </div>

            <b-row v-for='(cashPaymentPart, index) in cashPaymentParts' :key='index'>
                <b-col>
                    <b-form-group label="Transaction">
                        <div>
                            <div class='badge badge-light ml-2'>
                                {{ cashPaymentPart.payable_transaction.id }}
                                /
                                {{ cashPaymentPart.payable_transaction.transactionable_id }}
                            </div>
                            <div class='badge badge-light ml-2'>
                                {{ cashPaymentPart.payable_transaction.transactionable_type }}
                            </div>
                            <div class='badge badge-light ml-2'>
                                {{ cashPaymentPart.payable_transaction.title }}
                            </div>
                            <div class='badge badge-light ml-2'>
                                {{ cashPaymentPart.payable_transaction.amount_paid }}
                                /
                                {{ cashPaymentPart.payable_transaction.amount }}
                            </div>
                        </div>
                    </b-form-group>
                </b-col>
                <b-col>
                    <div v-if='can("accounts.list")'>
                        <div class='badge badge-light m-2'>
                            customer: {{ cashPaymentPart.payable_transaction.collected_from_customer }} / {{
                                cashPaymentPart.payable_transaction.required_from_customer }}
                        </div>
                        <div class='badge badge-light m-2'>
                            {{ 100 * parseFloat(cashPaymentPart.payable_transaction.collected_from_customer) /
                                parseFloat(cashPaymentPart.payable_transaction.required_from_customer) }} %
                        </div>
                        <br>
                        <div class='badge badge-light m-2'>
                            supplier: {{ cashPaymentPart.payable_transaction.paid_to_supplier }} / {{
                                cashPaymentPart.payable_transaction.required_to_supplier }}
                        </div>
                        <div class='badge badge-light m-2'>
                            {{ 100 * parseFloat(cashPaymentPart.payable_transaction.paid_to_supplier) /
                                parseFloat(cashPaymentPart.payable_transaction.required_to_supplier) }} %
                        </div>
                    </div>
                </b-col>
                <b-col>
                    <b-form-group label="Amount">
                        <b-form-input v-model="cashPaymentPart.amount" type="text" placeholder="">
                        </b-form-input>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-button variant='danger' class='m-2 btn btn-danger btn-xs sharp'
                        @click='removeCashPaymentPart(index)'>
                        <i class='fa fa-remove'></i>
                    </b-button>
                </b-col>
            </b-row>

            <hr>
            <div class='m-2' v-if='cashPayment.settle_all'>
                <b>Transaction Summary</b>
                <div class='m-2'>
                    <b-form-group label="Filter">
                        <b-form-checkbox v-model="transactionsFilter.unpaid" value="1" unchecked-value="0" :inline='true'>
                            Unpaid
                        </b-form-checkbox>

                        <b-form-checkbox v-model="transactionsFilter.collected" value="1" unchecked-value="0"
                            :inline='true'>
                            Collected
                        </b-form-checkbox>

                        <b-form-checkbox v-model="transactionsFilter.not_payment" value="1" unchecked-value="0"
                            :inline='true'>
                            Not Payment
                        </b-form-checkbox>

                    </b-form-group>
                </div>
                <div>

                    <b-form-group label="Group By">
                        <b-form-checkbox v-model="transactionsFilter.group_by_entity_type" value="1" unchecked-value="0"
                            :inline='true'>
                            Group By Entity Type
                        </b-form-checkbox>
                        <b-form-checkbox v-model="transactionsFilter.group_by_entity_id" value="1" unchecked-value="0"
                            :inline='true'>
                            Group By Entity
                        </b-form-checkbox>
                    </b-form-group>

                    <b-form-group label="Group By Date">
                        <b-form-radio-group v-model="transactionsFilter.group_by_period"
                            :options="['day', 'month', 'year', 'none']"></b-form-radio-group>
                    </b-form-group>
                </div>

                <div v-if='can("accounts.list") && totals'>
                    <div class='badge badge-light m-2'>
                        Number Of Transactions
                        {{ totals.number_of_transactions }}
                    </div>

                    <div class='badge badge-light m-2'>
                        Total Transactions Amount:
                        {{ totals.total | decimal }}
                    </div>
                    <div class='badge badge-light m-2'
                        :title='"collected: " + totals.collected_for_supplier + " paid: " + totals.amount_paid'>
                        Required:
                        {{ totals.collected_for_supplier - totals.amount_paid | decimal }}
                    </div>
                    <div class='badge badge-light m-2'>
                        Not Collected:
                        {{ totals.amount_remaining - (totals.collected_for_supplier - totals.amount_paid) | decimal }}
                    </div>
                </div>

                <transactions-summary-list-table v-if='can("accounts.list")'
                    :filter='transactionsFilter'></transactions-summary-list-table>
            </div>


            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>

        <Modals :modalName='currentModal' :modalData='currentModalData' @saved='modalCallback' @closed='modalClosed'>
        </Modals>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
import BranchesSelect from '../../components/BranchesSelect.vue';
import PaymentMethodsSelect from '../../components/PaymentMethodsSelect.vue';
import SourceOfPaymentsSelect from '../../components/SourceOfPaymentsSelect.vue';
import PayablesSelect from '../../components/PayablesSelect.vue';
import TransactionsSummaryListTable from '../../components/Lists/TransactionsSummaryListTable.vue';
export default {
    components: { PaymentMethodsSelect, SourceOfPaymentsSelect, BranchesSelect, PayablesSelect, TransactionsSummaryListTable },
    extends: BaseForm,
    props: ['entityType', 'entityId', 'branchId', 'minDate', 'maxDate', 'transactionIds', 'transactionsStatus', 'settleAll', 'collectedOnly'],
    data: function () {
        return {
            status: ['finished'],
            paymentMethod: null,
            sourceOfPayment: null,
            cashPayment: {
                entity_type: null,
                entity_id: null,
                min_date: null,
                max_date: null,
                collected_only: null,
                settle_all: null,
            },
            transactionsFilter: { unpaid: 1, group_by_period: 'month', not_payment: 1 },
            editMode: false,
            payable: null,
            cashPaymentParts: [],
            totals: [],
            payableTypes: ['Patient', 'Doctor', 'Supplier', 'Vendor', 'InsuranceProvider'],
            transactions: [],
        }
    },
    mounted() {
        var cashPayment = {};
        cashPayment.entity_type = this.entityType;
        cashPayment.entity_id = this.entityId;
        cashPayment.min_date = this.minDate;
        cashPayment.max_date = this.maxDate;
        cashPayment.settle_all = this.settleAll;
        cashPayment.collected_only = this.collectedOnly;
        cashPayment.branch_id = this.branchId;
        cashPayment.payment_method = 'cash';
        if (this.transactionsStatus) {
            this.status = this.transactionsStatus;
        }
        this.cashPayment = { ...this.cashPayment, ...cashPayment };
        this.loadTotals();
        if (this.transactionIds && this.transactionIds.length) {
            this.apiRequest(apiClient => apiClient.get('transactions', { ids: this.transactionIds }))
                .then(response => {
                    response.data.forEach(item => this.addTransaction(item));
                });
        }

    },
    methods: {
        load: function (id) {
            this.editMode = true;
            return this.apiRequest(apiClient => apiClient.get('cash-payments/' + id))
                .then(response => {
                    this.cashPayment = response;
                    if (response.payment_method_type == 'PaymentMethod') {
                        this.paymentMethod = response.payment_method;
                    } else {
                        this.sourceOfPayment = response.payment_method;
                    }

                    this.cashPaymentParts = response.cash_payment_parts.filter(item => item.payable_transaction ? true : false);
                });
        },
        save: function (apiClient) {

            var cashPayment = {
                date: this.cashPayment.date,
                automatically_settle: this.cashPayment.automatically_settle,
                amount: this.cashPayment.amount,
                auto_settlement: this.cashPayment.auto_settlement,
                cash_payment_parts: this.cashPaymentParts.map(item => ({
                    id: item.id ? item.id : null,
                    amount: item.amount,
                    payable_transaction_id: item.payable_transaction ? item.payable_transaction.id : null
                })),
                entity_type: this.cashPayment.entity_type,
                entity_id: this.cashPayment.entity_id,
                status: this.status,
                branch_id: this.cashPayment.branch_id,
                payment_method_id: ['Patient', 'InsuranceProvider'].includes(this.cashPayment.entity_type) ? this.paymentMethod.id : this.sourceOfPayment.id,
                due_date: this.cashPayment.due_date,
                collected_only: this.cashPayment.collected_only,
                min_date: this.cashPayment.min_date ? this.date(this.cashPayment.min_date) : null,
                max_date: this.cashPayment.max_date ? this.date(this.cashPayment.max_date) : null,
                settle_all: this.cashPayment.settle_all
            };
            if (this.id) {
                return apiClient.put('cash-payments/' + this.id, cashPayment);
            } else {
                return apiClient.post('cash-payments', cashPayment);
            }
        },
        afterSave: function () {
            this.$emit('saved', 1);
        },
        loadTotals() {
            if (!this.can('accounts.list') && !this.cash_payment.entity_type && !this.cash_payment.entity_id) {
                return;
            }

            var filter = {
                status: this.status,
                not_payment: 1,
                start_date: this.cashPayment.min_date ? this.cashPayment.min_date : null,
                end_date: this.cashPayment.max_date ? this.cashPayment.max_date : null,
                entity_type: this.cashPayment.entity_type ?? null,
                entity_id: this.cashPayment.entity_id ?? null,
                unpaid: 1,
                collected_only: this.cashPayment.collected_only,
            };

            return this.apiRequest(
                (apiClient, cancelTokenSource) => {
                    this.totalsCancelToken = cancelTokenSource;
                    return apiClient.get('transactions/totals', filter, cancelTokenSource)
                }, this.totalsCancelToken
            )
                .then(response => this.totals = response);

        },

        addTransaction(transaction) {
            var amount = transaction.amount_remaining;
            if (amount > this.remainingAmount) {
                amount = this.remainingAmount;
            }

            var cashPaymentPart = this.cashPaymentParts.find(item => item.payable_transaction.id == transaction.id);
            if (cashPaymentPart) {
                cashPaymentPart.amount = parseFloat(cashPaymentPart.amount) + parseFloat(amount);
            } else {
                this.cashPaymentParts.push({ payable_transaction: transaction, amount: amount })
            }
        },
        removeCashPaymentPart(index) {
            this.cashPaymentParts.splice(index, 1);
        }
    },
    watch: {
        'payable': function () {
            this.cashPayment.entity_id = this.payable.id;
        },
        'cashPayment': {
            deep: true,
            handler:
                function () {
                    this.loadTotals();
                    this.transactionsFilter = {
                        ...this.transactionsFilter,
                        entity_type: this.cashPayment.entity_type,
                        entity_id: this.cashPayment.entity_id,
                        start_date: this.cashPayment.min_date,
                        end_date: this.cashPayment.max_date,
                        status: this.status
                    };
                },
        }
    },
    computed: {

        remainingAmount: function () {
            var totalPayments = this.cashPaymentParts.reduce((total, cashPaymentPart) => parseFloat(cashPaymentPart.amount) + total, 0);
            return parseFloat(this.cashPayment.amount) - totalPayments;
        }
    }
}
</script>