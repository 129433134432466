<template>
    <div>
        <loader v-if='loading'/>
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>
            <b-form-group label="Name">
                <b-form-input v-model="bodyPartGroup.name" :state="states.name" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.name'/>
            </b-form-group>
            <b-form-group>
                <sections-select v-model='sections' :multiple='true'/>
            </b-form-group>
            <b-row v-for='(bodyPart, index) in bodyPartGroup.body_parts' :key='index'>
                <b-col>
                    <b-form-input v-model="bodyPartGroup.body_parts[index]" type="text" placeholder=""/>
                </b-col>

                <b-col>
                    <b-button variant='danger' class='m-2 btn btn-danger btn-xs sharp' @click='removeBodyPart(index)'>
                        <i class='fa fa-remove'></i>
                    </b-button>
                </b-col>
            </b-row>

            <div class='m-2'>
                <b-button variant='success' @click='addBodyPart'>+</b-button>
            </div>

            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
import SectionsSelect from '../../components/SectionsSelect.vue';
export default {
  components: { SectionsSelect },
    extends: BaseForm,
    data: function () {
        return {
            bodyPartGroup: {
                body_parts: []
            },
            sections: []
        }
    },
    methods: {
        load: function(id) {
            return this.apiRequest(apiClient => apiClient.get('body-part-groups/'+id))
                .then(response => {
                    this.bodyPartGroup = response;
                    this.sections = response.sections;
                });
        },
        save: function (apiClient) {
            var bodyPartGroup = {...this.bodyPartGroup, section_ids: this.sections.map(item => item.id)};
            if (this.id) {
                return apiClient.put('body-part-groups/'+this.id, bodyPartGroup);
            } else {
                return apiClient.post('body-part-groups', bodyPartGroup);
            }
        },
        afterSave: function () {
            this.$emit('saved', 1);
        },
        removeBodyPart(index) {
            this.bodyPartGroup.body_parts.splice(index, 1);
        },
        addBodyPart() {
            this.bodyPartGroup.body_parts.push("");
        },
    },
}
</script>