<template>
    <div>
        <v-select
            @search="fetchOptions"
            @open="onOpen"
            :filterable='filterable'
            :options="options"
            :label='label'
            :value='myValue'
            v-on:input='updateValue'
        >
        </v-select>
    </div>
</template>

<script>
import BaseRemoteSelect from './BaseRemoteSelect.vue';

export default {
    props: ['section_id'],
    extends: BaseRemoteSelect,
    data: function() {
        return {
            label: 'name'
        }
    },
    methods: {
        load(apiClient, search, cancelToken) {
            var filter = {
                keyword: search
            };

            if (this.section_id) {
                filter.section_id = this.section_id; 
            }
            
            return apiClient.get('services', filter, cancelToken);
        },
        loadSingle(apiClient, id) {
            return apiClient.get('services/'+id);
        }
    },
    watch: {
        section_id: function () {
            this.fetchOptions('', true);
        }
    }
}
</script>
