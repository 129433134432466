<template>
    <div>
        <v-select
            @search="fetchOptions"
            @open="onOpen"
            :filterable='filterable'
            :options="options"
            :label='label'
            :value='myValue'
            v-on:input='updateValue'
            :loading="loading"
            :multiple='multiple'
            :closeOnSelect='closeOnSelect'
        >
            <template #option="{ name, section }">
                {{ name }} 
                <div class='badge badge-light ml-2'>
                    {{ section.name }}
                </div>
            </template>
        </v-select>
    </div>
</template>

<script>
import BaseRemoteSelect from './BaseRemoteSelect.vue';

export default {
    extends: BaseRemoteSelect,
    props: ['section_id', 'working', 'is_assistant'],
    data: function() {
        return {
            label: 'name'
        }
    },
    methods: {
        load(apiClient, search, cancelToken) {
            var filter = {
                keyword: search
            };

            if (this.section_id) {
                filter.section_id = this.section_id; 
            }

            if (this.working) {
                filter.working_only = 1;
            }

            if (this.is_assistant) {
                filter.is_assistant = 1;
            } else {
                filter.is_assistant = 0;
            }
            
            return apiClient.get('doctors', filter, cancelToken);
        },
        loadSingle(apiClient, id) {
            return apiClient.get('doctors/'+id);
        }
    },
    watch: {
        section_id: function () {
            this.fetchOptions('', true);
        }
    }
}
</script>
