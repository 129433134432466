<template>
    <div>

        <ListView title="Vacations List" :collection="collection" :fields="fields" :loading="loading">
            <template v-slot:actions>
                <b-button v-b-modal.vacation-modal @click="selectedVacationId = null" variant='primary' class='mr-2'>
                    Create Vacation
                </b-button>

            </template>

            <template v-slot:filter>
                <div class='form'>
                    <div class='form-row'>
                        <div class='form-group col-md-4'>
                            <b-form-input v-model="filter.keyword" placeholder="search ..."></b-form-input>
                        </div>
                    </div>
                </div>
            </template>

            <template v-slot:cell(actions)='data'>
                <b-button v-b-modal.vacation-modal class='mr-2' @click="selectedVacationId = data.item.id">Edit</b-button>
            </template>
                    
        </ListView>
        
        <b-modal :no-close-on-backdrop=true id="vacation-modal" hide-footer  title="Vacation Form">
            <VacationForm @saved='vacationSaved' :id='selectedVacationId'></VacationForm>        
        </b-modal>
    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import VacationForm from './VacationForm';

export default {
    extends: BaseList,
    components: {VacationForm},
    methods: {
        load: function (apiClient, cancelToken) {
            return apiClient.get('vacations', this.filter, cancelToken);
        },
        vacationSaved() {
            this.refresh();
            this.$bvModal.hide('vacation-modal');
        }
    },
    data: function() {
        return {
            fields: [
                { key: 'id', label: '#' },
                { key: 'name', label: 'Name' },
                { key: 'actions', label: '' }
            ],
            selectedVacationId: null
        };
    }
}
</script>
