<template>
  <div>
    <div id="svgTemplate" v-html='svgContent' @click="handleClick" @dblclick="handleDblClick">
      
    </div>
  </div>
</template>

<script>
export default {
  props: ['value', 'svgContent', 'selectable'],
  methods: {
    handleClick(e) {
      var target = e.target;
      if (target.matches('.icon, .icon *')) {
        var c = target.closest('.icon');

        var elems = c.querySelectorAll('title');
        var selected = Array.from(elems).map(item => item.textContent)
        
        this.$emit('clickedItem', selected[0]);

        if (this.selectable) {
          c.classList.toggle("selected");
        }
        this.updateInput();
      }
    },
    handleDblClick(e) {
      var target = e.target;
      if (target.matches('.icon, .icon *')) {
        var c = target.closest('.icon');
        var key= c.childNodes[0].textContent;
        // var key = c.getAttribute('data-key');
        this.$emit('clickedItem', key);
      }
    },
    updateInput() {
         var elems = this.$el.querySelectorAll('.icon.selected title');
         var selected = Array.from(elems).map(item => item.textContent)
         this.value = selected;
         this.$emit('input', selected);
    },
    select() {
      var elems = this.$el.querySelectorAll('.icon.selected title');
      var selected = Array.from(elems).map(item => item.textContent)
      this.$emit('clickedItem', selected);
    },
    clearSelection() {
      var elems = this.$el.querySelectorAll('.icon');
      console.log(elems);
      elems.forEach(item => item.classList.remove('selected'))
    },
    updateSelection() {
      var selected = this.value;
      
      this.$nextTick(() => {
        var elems = this.$el.querySelectorAll('.icon title');
        debugger;
        Array.from(elems).forEach(item => {
          var c = item.closest('.icon');
          if (selected.includes(item.textContent)) {
            c.classList.add("selected");
          } else {
            c.classList.remove("selected");
          }
        });
      });
    }
  },

  watch: {
      value: {
          immediate: true,
          handler() {
            this.updateSelection();
          }
      },
      svgContent: {
          immediate: true,
          handler() {
            this.updateSelection();
          }
      }
  }
};
</script>


<style>

.icon:hover, .icon:hover path{
  fill: red !important;
  cursor: pointer;
}
.icon.selected, .icon.selected path {
  fill: green !important;
}

.icon.selected:hover, .icon.selected:hover path {
  fill: #11ff11 !important;
}
svg text {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
svg text::selection {
    background: none;
}
</style>