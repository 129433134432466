<template>
    <div class="card">
        <div class="card-header">
            <h4 class="card-title">Expense Form</h4>
        </div>
        <div class="card-body">
            <b-form class='basic-form' @submit="checkForm">
                <b-form-group label="Amount" description="Amount">
                    <b-form-input type="text" required placeholder="Enter amount">
                    </b-form-input>
                </b-form-group>
                <div class='form-group'>
                    <label>Description</label>
                    <textarea class='form-control' cols="30" rows="2"></textarea>
                </div>
                <div class='form-group'>
                    <label>Type</label>
                    <v-select :options="options"></v-select>
                </div>
                <div class='form-group'>
                    <label>Attachments</label>
                    <input class='form-control' type="file" multiple>
                </div>
                <button type="submit" class="btn btn-primary">Save</button>
            </b-form>
        </div>
    </div>
</template>
<script>
export default {
    data: function () {
        return {
            options: [
                'General',
                'Tools'
            ]
        };
    },
    methods: {
        checkForm: function (e) {
            e.preventDefault();
            this.saving = true;
            this.$router.push({name: 'ExpensesList'});
        }
    },
}
</script>