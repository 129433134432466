<template>
    <ListView title="Transactions List" :hideHeader='financialAccountId ? true : false' :hasFilterActions='true'>
        <template v-slot:actions>
            <router-link v-if='!financialAccountId' :to="{ name: 'FinancialOperationForm' }" class="btn btn-primary btn-sm">
                <i class="fa fa-plus"></i>
                New Transaction
            </router-link>
        </template>

        <template v-slot:filter_actions>
            <authorized-url v-if='can("financial_transactions.export")' class='btn btn-primary ml-2 float-right'
                url='financial-transactions/export' :params='filter' target="_blank">
                <i class="fa fa-file-excel"></i>
                Export
            </authorized-url>
        </template>
        <template v-slot:filter>
            <div class='form'>
                <b-row>

                    <b-col cols='4'>
                        <b-form-group label="Branch">
                            <branches-select v-model='filter.branch_id' :returnId='true' />
                        </b-form-group>
                    </b-col>
                    <b-col cols='4'>
                        <b-form-group label="Account">
                            <FinancialAccountsSelect v-model='filter.financial_account_id' :returnId='true' />
                        </b-form-group>
                    </b-col>
                    <b-col cols='4'>
                        <b-form-group label="Date Range">
                            <my-date-range-picker v-model='dateRange' :style='{ display: "inline-block" }' />
                            <div class='btn btn-danger btn-xxs ml-2'
                                @click='() => dateRange = { startDate: null, endDate: null }'>x</div>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>

                    <b-col cols='4'>
                        <b-form-group label="Account Type">
                            <v-select :options="accountTypes" v-model='filter.account_type'></v-select>
                        </b-form-group>
                    </b-col>
                    <b-col cols='4'>
                        <b-form-group label="Accountable Type">
                            <v-select :options="accountableTypes" v-model='filter.accountable_type'></v-select>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group>
                            <b-form-checkbox v-model="filter.summary" value="1" unchecked-value="0" :inline='true'>
                                Summary
                            </b-form-checkbox>
                        </b-form-group>
                        <b-form-group label="Summary Options" v-if='filter.summary == 1'>
                            <b-form-checkbox v-model="filter.group_by_branch" value="1" unchecked-value="0" :inline='true'>
                                Group By Branch
                            </b-form-checkbox>
                            <b-form-checkbox v-model="filter.group_by_acccount_type" value="1" unchecked-value="0"
                                :inline='true'>
                                Group By Type
                            </b-form-checkbox>
                            <b-form-checkbox v-model="filter.group_by_accountable_type" value="1" unchecked-value="0"
                                :inline='true'>
                                Group By Accountable Type
                            </b-form-checkbox>

                            <b-form-group label="Group By">
                                <b-form-radio-group v-model="filter.group_by_period"
                                    :options="['day', 'month', 'year', 'none']"></b-form-radio-group>
                            </b-form-group>

                        </b-form-group>
                    </b-col>
                </b-row>
            </div>
        </template>
        <template v-slot:table>

            <div v-if='totals'>
                <h3>
                    <div class='badge badge-light m-2'>
                        Total Credit:
                        {{ totals.credit | decimal }}
                    </div>
                    <div class='badge badge-light m-2'>
                        Total Debit:
                        {{ totals.debit | decimal }}
                    </div>
                </h3>
            </div>
            <financial-transactions-summary-list-table v-if='filter.summary == "1"' :filter='filter' ref='tableList' />
            <financial-transactions-list-table v-else :filter='filter' ref='tableList' :viewType='viewType' />
        </template>
    </ListView>
</template>

<script>
import BaseList from '@/views/BaseList';
import BranchesSelect from '../../components/BranchesSelect.vue';
import FinancialTransactionsListTable from '../../components/Lists/FinancialTransactionsListTable.vue';
import FinancialTransactionsSummaryListTable from '../../components/Lists/FinancialTransactionsSummaryListTable.vue';
import AuthorizedUrl from '../../components/AuthorizedUrl.vue';

export default {
    components: { BranchesSelect, FinancialTransactionsListTable, FinancialTransactionsSummaryListTable, AuthorizedUrl },
    extends: BaseList,
    props: ['financialAccountId'],
    mounted() {
        if (this.financialAccountId) {
            this.hideFields.push('financial_account');
            this.hideFields.push('financial_account.branch.name');
        }
        this.loadTotals();
        // this.filterFields();
    },
    methods: {
        loadTotals() {
            return this.apiRequest(
                (apiClient, cancelTokenSource) => {
                    this.totalsCancelToken = cancelTokenSource;
                    return apiClient.get('financial-transactions/totals', this.filter, cancelTokenSource)
                }, this.totalsCancelToken
            )
                .then(response => this.totals = response);
        }
    },
    data: function () {
        return {
            dateRange: {},
            hideFields: [],
            accountableTypes: ['Patient', 'InsuranceProvider', 'Worker', 'Supplier'],
            accountTypes: ['REVENUE', 'AR', 'AP', 'DR', 'DE', 'INVENTORY', 'CASH', 'COGS', 'CUSTOM'],
            totals: null,
        };
    },
    watch: {
        financialAccountId: {
            immediate: true,
            handler: function () {
                if (this.financialAccountId) {
                    this.viewType = 'accountView';
                    this.defaultFilter.financial_account_id = this.financialAccountId;
                    this.filter.financial_account_id = this.financialAccountId;
                }
            }
        },
        dateRange: function () {
            var startDate = this.dateRange.startDate ? this.date(this.dateRange.startDate) : null;
            var endDate = this.dateRange.endDate ? this.date(this.dateRange.endDate) : null;


            if (startDate == this.filter.start_date && endDate == this.filter.end_date) {
                return;
            }

            this.filter = {
                ... this.filter,
                start_date: startDate,
                end_date: endDate
            };
        },
        filter: {
            handler() {
                var filter = this.filter;

                this.dateRange = {
                    startDate: filter.start_date ? filter.start_date : null,
                    endDate: filter.end_date ? filter.end_date : null
                };
                this.loadTotals();
            },
            deep: true
        }
    }
}
</script>