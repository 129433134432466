<template>
    <div>
        <b-form class='basic-form' @submit="submit" novalidate>
            
            <div v-if='patientServices.length'>
                <b-table-simple hover small caption-top responsive>
                    <colgroup><col></colgroup>
                    <colgroup><col><col></colgroup>
                    <b-thead head-variant="dark">
                        <b-tr>
                            <b-th>ID</b-th>
                            <b-th>Service</b-th>
                            <b-th>Status</b-th>
                            <b-th>required</b-th>
                            <b-th>paid</b-th>
                            <b-th>to pay</b-th>
                            <b-th>remaining</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr v-for='(patientService, index) in patientServices' :key='index'>
                            <b-th>{{ patientService.id }} </b-th>
                            <b-th>
                                {{ patientService.name }}
                            </b-th>
                            <b-th>
                                <div v-if='patientService.status=="finished"' class='badge badge-success'>finished</div>
                                <div v-if='patientService.status=="in progress"' class='badge badge-warning'>in progress</div>
                                <div v-if='patientService.status=="pending"' class='badge badge-light'>pending</div>
                            </b-th>
                            <b-th>{{ patientService.patient_pay }}</b-th>
                            <b-td>{{ patientService.paid_by_patient }}</b-td>
                            
                            <b-td>
                                <b-row>
                                    <b-col cols=3>
                                        <b-form-checkbox @change='updateServicePayment(patientService)' v-if='patientService.status !="finished"' v-model='patientService.all_paid' value="1">
                                            All Paid
                                        </b-form-checkbox>
                                    </b-col>
                                    <b-col cols=5>
                                        <b-form-input 
                                        
                                            :state='
                                                (patientService.patient_pay < parseFloat(patientService.paid_by_patient) + parseFloat(patientService.payment | 0)) 
                                                || (parseFloat(patientService.paid_by_patient) + parseFloat(patientService.payment | 0) < 0) 
                                                ? false : null
                                            '
                                            v-model='patientService.payment' type="number" placeholder="payment">
                                        </b-form-input>

                                    </b-col>

                                </b-row>
                                
                                

                            </b-td>
                            <b-td>
                                {{ patientService.patient_pay - patientService.paid_by_patient - patientService.payment}}
                            </b-td>
                        </b-tr>
                    </b-tbody>
                    <b-tfoot foot-variant="dark">
                        <b-tr v-if='balance.finished_services.count'>
                            <b-td colspan='3'  class="text-center">
                                <b>Finished Service</b> 
                            </b-td>
                            <b-td>
                                {{ balance.finished_services.required }}
                            </b-td>
                            <b-td>
                                {{ balance.finished_services.paid }}
                            </b-td>
                            <b-td>
                                {{ balance.finished_services.payment | currency}}
                            </b-td>
                            <b-td>
                                {{ balance.finished_services.remaining | currency}}
                            </b-td>
                        </b-tr>
                        <b-tr v-if='balance.pending_services.count'>
                            <b-td colspan='3'  class="text-center">
                                <b>Pending Services</b> 
                            </b-td>
                            <b-td>
                                {{ balance.pending_services.required }}
                            </b-td>
                            <b-td>
                                {{ balance.pending_services.paid }}
                            </b-td>
                            <b-td>
                                {{ balance.pending_services.payment | currency}}
                            </b-td>
                            <b-td>
                                {{ balance.pending_services.remaining | currency}}
                            </b-td>
                        </b-tr>
                        <b-tr v-if='balance.in_progress_services.count'>
                            <b-td colspan='3'  class="text-center">
                                <b>In Progress Services</b> 
                            </b-td>
                            <b-td>
                                {{ balance.in_progress_services.required }}
                            </b-td>
                            <b-td>
                                {{ balance.in_progress_services.paid }}
                            </b-td>
                            <b-td>
                                {{ balance.in_progress_services.payment | currency}}
                            </b-td>
                            <b-td>
                                {{ balance.in_progress_services.remaining | currency}}
                            </b-td>
                        </b-tr>
                        <b-tr v-if='balance.total.count'>
                            <b-td colspan='3'  class="text-center">
                                <b>Total</b> 
                            </b-td>
                            <b-td>
                                {{ balance.total.required }}
                            </b-td>
                            <b-td>
                                {{ balance.total.paid }}
                            </b-td>
                            <b-td>
                                {{ balance.total.payment | currency}}
                            </b-td>
                            <b-td :variant='-remainingBalance > balance.total.remaining ? "danger": ""'>
                                {{ balance.total.remaining | currency}}
                            </b-td>
                        </b-tr>
                        <tr>
                            <b-td colspan="100%" class='text-right'>
                                
                            </b-td>
                        </tr>
                    </b-tfoot>
                </b-table-simple>
                
            </div>
            <div>
                <b-form-group label='Total Balance'>
                    <strong>{{ patientBalance | currency }}</strong>
                </b-form-group>

                <b-form-group label="Payment Method">
                    <v-select :options="paymentMethods" v-model='paymentMethod'></v-select>
                    <FormGroupError :errors='errors.payment_method'/>
                </b-form-group>
                <b-form-group label='Total Payment'>
                    <b-form-input v-model="total_payment" :state="states.total_payment" type="text" placeholder="">
                    </b-form-input>
                    <FormGroupError :errors='errors.total_payment'/>
                </b-form-group>

                <b-form-group label='Remaining Balance'>
                    <strong>{{ remainingBalance | currency }}</strong>
                    <br/>
                    <div class='badge'  :class='-remainingBalance > balance.total.balance ? "badge-danger" : "badge-light"'>
                        Balance should be greater than or equal
                        <br/>
                        {{ -balance.total.balance }}
                    </div>
                </b-form-group>
            </div>
            
            
            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
export default {
    extends: BaseForm,
    props: ['branchId'],
    data: function () {
        return {
            total_payment: null,
            finishedServices: [],
            pendingServices: [],
            inProgressServices: [],
            patientServices: [],
            patient: null,
            patientBalance: 0,
            paymentMethod: null,
            paymentMethods: ['visa', 'cash']
        }
    },
    methods: {
        load(id) {
            this.apiRequest(apiClient => {
                return apiClient.get('financial-accounts/',{patient_id: id, branch_id: this.branchId});
            }).then(response => this.patientBalance = response.data[0] ? response.data[0].balance : 0);

            return this.apiRequest(apiClient => {
                return apiClient.get('patient-services',{patient_id: id, branch_id: this.branchId, unpaid: true});
            }).then(response => {
                var patientServices = response.data.map(item => {item.payment = 0; return item;});
                this.finishedServices = patientServices.filter(item => item.status == 'finished' ? true : false);
                this.pendingServices = patientServices.filter(item => item.status == 'pending' ? true : false);
                this.inProgressServices = patientServices.filter(item => item.status == 'in progress' ? true : false);
                this.patientServices = patientServices;
            });
        },
        save: function () {
            return this.apiRequest((apiClient) => {
                var paymentData = {
                    branch_id: this.branchId,
                    payment_method: this.paymentMethod,
                    total_amount: this.total_payment,
                    service_payments: this.patientServices.map(item => ({
                        patient_service_id: item.id,
                        paid_by_patient: parseFloat(item.paid_by_patient) + (item.payment | 0)
                    }))
                };

                return apiClient.post('patients/'+this.id+'/payments', paymentData);
            });
        },
        afterSave: function (transactions) {
            this.$emit('saved', transactions);
        },
        updateServicePayment: function (patientService) {
            patientService.payment = patientService.paid_by_patient ? parseFloat(patientService.patient_pay) - parseFloat(patientService.paid_by_patient)
                                    : parseFloat(patientService.patient_pay);
        }
    },
    computed: {
        totalRequired() {
            return this.patientServices.reduce((total, patientService) => {
                var payment = 0;
                if (patientService.status == 'finished') {
                    payment = parseFloat(patientService.patient_pay - patientService.paid_by_patient);
                } else {
                    payment = parseFloat(patientService.payment | 0);
                }
                return total + payment;
            }, 0);
        },
        remainingBalance() {
            return parseFloat(this.patientBalance) + parseFloat(this.total_payment ? this.total_payment : 0) 
        },
        balance() {
            var balance = this.patientServices.reduce((balance, patientService) => {
                        var key = 'pending_services';
                        if (patientService.status == 'finished') {
                            key = 'finished_services';
                        } else if (patientService.status == 'in progress') {
                            key = 'in_progress_services';
                        }
                        
                        balance[key].required += parseFloat(patientService.patient_pay);
                        balance[key].paid += parseFloat(patientService.paid_by_patient);

                        if (patientService.status == 'finished') {
                            balance[key].payment = balance[key].required - balance[key].paid;
                        } else {
                            balance[key].payment += patientService.payment ? parseFloat(patientService.payment)  : 0;
                        }
                        balance[key].remaining =  balance[key].required - balance[key].paid - balance[key].payment;
                        balance[key].count++;

                        return balance;
                }, {
                    finished_services: {
                        count: 0,
                        required: 0,
                        paid: 0,
                        remaining: 0,
                        payment: 0
                    },
                    pending_services: {
                        count: 0,
                        required: 0,
                        paid: 0,
                        remaining: 0,
                        payment: 0
                    },
                    in_progress_services: {
                        count: 0,
                        required: 0,
                        paid: 0,
                        remaining: 0,
                        payment: 0
                    },
                });


            balance.total = {
                count: balance.finished_services.count + balance.pending_services.count + balance.in_progress_services.count,
                required: balance.finished_services.required + balance.pending_services.required + balance.in_progress_services.required,
                paid: balance.finished_services.paid + balance.pending_services.paid + balance.in_progress_services.paid,
                remaining: balance.pending_services.remaining + balance.in_progress_services.remaining,
                payment: balance.finished_services.payment + balance.pending_services.payment + balance.in_progress_services.payment,
                balance: -balance.pending_services.paid - balance.pending_services.payment + balance.in_progress_services.remaining,
            };
            return balance;
        }
    }
}
</script>