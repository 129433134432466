<template>
    <div>
        <div class='row'>
            <div class='col-10'>
                <button v-if='can("appointments.create")' class='btn btn-primary btn-xs ml-2' v-b-modal.appointment-modal>
                    + <i class='fa fa-calendar'></i>
                    Appointment
                </button>
                <button v-if='can("patient_services.create")' class='btn btn-primary btn-xs ml-2'
                    v-b-modal.add-service-modal>
                    + <i class='fa fa-stethoscope'></i>
                    Services
                </button>
                <span class='m-2'></span>

                <button class='btn btn-primary btn-xs ml-2'
                    @click='modal("patientInsuranceForm", { "patientId": patient.id }, "refresh")'>
                    + <i class="fas fa-id-card-alt"></i> Patient Insruance
                </button>
                <button v-if='can("insurance_approvals.create")' class='btn btn-primary btn-xs ml-2'
                    v-b-modal.service-insurance-modal @click='selectedInsuranceId = null'>
                    + <i class='fas fa-clipboard-list'></i>
                    Insurance Approval
                </button>
                <span class='m-2'></span>
                <button v-if='can("patients.add_payment")' class='btn btn-primary btn-xs ml-2'
                    @click="modal('cashPaymentForm', { entityType: 'Patient', entityId: patient.id }, 'refresh')">
                    + <i class='fa fa-credit-card'></i> Payment
                </button>
                <button v-if='can("patients.add_payment")' class='btn btn-primary btn-xs ml-2'
                    @click="modal('cashReturnForm', { entityType: 'Patient', entityId: patient.id }, 'refresh')">
                    + <i class='fa fa-undo'></i> <i class='fa fa-credit-card'></i> Cash Return
                </button>
                <button class='btn btn-primary btn-xs ml-2' @click='addInvoice'>
                    + <i class="fas fa-file-invoice"></i> Invoice
                </button>
                <br>
                <button class='btn btn-primary btn-xs ml-2'
                    @click='modal("medicalRecordForm", { patientId: patient.id }, "refresh")'>
                    + <i class='fa fa-book-medical'></i> Medical Record
                </button>
                <button class='btn btn-primary btn-xs ml-2'
                    @click='modal("patientNoteForm", { patientId: patient.id }, "refresh")'>
                    + <i class="fas fa-notes-medical"></i> Note
                </button>
                <button class='btn btn-primary btn-xs ml-2' v-if='can("audit_notes.create")'
                    @click='modal("auditNoteForm", { patientId: patient.id }, "refresh")'>
                    + <i class="fas fa-clipboard"></i> Audit Note
                </button>
                <span class='m-2'></span>
                <router-link :to="{ name: 'EditPatient', params: { id: patient.id } }" class='btn btn-primary btn-xs m-2'>
                    <i class="fas fa-edit"></i> Edit Patient
                </router-link>

                <div>
                    <div class='card' v-if='can("appointments.list")'>
                        <div class='card-body recentOrderTable'>
                            <h4 class="text">
                                <i class='fa fa-calendar'></i>
                                Remaining Appointments
                            </h4>
                            <hr>
                            <b-table hover :items="patient.active_appointments" :fields="appointmentsFields">
                                <template v-slot:cell(doctor)='data'>
                                    <strong class='m-2'>
                                        <i class='fa fa-user-md'></i>
                                        {{ data.item.doctor.name }}
                                    </strong>
                                    <br>
                                    <strong v-if='data.item.assistant' class='m-2'>
                                        Assistant:
                                        <i class='fa fa-user-md'></i>
                                        {{ data.item.assistant.name }}
                                    </strong>
                                    <div>
                                        <div class='badge badge-light m-1'>
                                            <i class='fa fa-cubes'></i>
                                            {{ data.item.section.name }}
                                        </div>
                                        <div class='badge badge-light m-1' v-if='data.item.clinic'>
                                            <i class='fa fa-cube'></i>
                                            {{ data.item.clinic.name }}
                                        </div>
                                        <br>
                                        <div class='badge badge-light m-1'>
                                            {{ data.item.branch.name }}
                                        </div>
                                    </div>
                                </template>

                                <template v-slot:cell(datetime)='data'>
                                    <div>
                                        <h4>
                                            <div class='badge m-1'
                                                :class="data.item.status == 'finished' ? 'badge-success' : 'badge-light'">
                                                <i class='fa fa-calendar'></i>
                                                {{ data.item.datetime | datetime }}
                                            </div>
                                        </h4>
                                        <div class='badge m-1'
                                            :class="data.item.status == 'finished' ? 'badge-success' : 'badge-light'">
                                            <i class='fa fa-history'></i>
                                            {{ data.item.duration }} minutes
                                        </div>
                                        <br>
                                        <div class='badge'
                                            :class="data.item.status == 'finished' ? 'badge-success' : 'badge-light'">
                                            {{ data.item.status }}
                                        </div>
                                        <br>
                                    </div>
                                </template>
                                <template v-slot:cell(patient_services)='data'>
                                    <div v-for='(patientService, index) in data.item.patient_services' :key='index'
                                        class='badge badge-success badge-lg m-2'>
                                        <i class='fa fa-stethoscope'></i>
                                        {{ patientService.service.name }}
                                    </div>
                                </template>
                                <template v-slot:cell(actions)='data'>
                                    <button v-if='can("appointments.finish")' class='btn btn-primary btn-xs ml-2'
                                        @click="modal('finishAppointment', { appointment: data.item }, 'refresh')">
                                        Finish Appointment
                                    </button>
                                    <button v-if='can("appointments.set_assistant")'
                                        @click="modal('appointmentAssistantForm', { id: data.item.id }, 'refresh')"
                                        class='btn btn-xs ml-1 btn-success'>
                                        <i class='fa fa-user-md'></i>
                                        Set Assistant
                                    </button>
                                </template>
                            </b-table>

                        </div>
                    </div>
                    <div class='card' v-if='can("patient_services.list")'>
                        <div class='card-header'>

                            <h4 class="text">
                                <i class='fa fa-stethoscope'></i> Active Services
                            </h4>
                            <div>
                                <b-button variant="primary" class='m-2' @click="handleReordering">
                                    <i v-if="!reorderingStatus" class="fa fa-refresh mr-1" aria-hidden="true"></i>
                                    <i v-else class="fa fa-check mr-1" aria-hidden="true"></i>
                                    {{ !reorderingStatus ? 'Open reorder services' : 'Save new orders' }}
                                </b-button>
                                <b-button variant='primary'
                                    @click="modal('patientServicesMapView', { patientId: patient.id })"><i
                                        class="fa fa-image"></i> View</b-button>
                            </div>

                        </div>
                        <div class='card-body'>
                            <patient-services-list-table @reorder="reorder($event)" :reorderingStatus="reorderingStatus"
                                viewType='patientActiveView' :filter='patientServicesFilter'></patient-services-list-table>
                        </div>
                    </div>

                    <div class='card' v-if='can("insurance_approvals.list")'>
                        <div class='card-body'>
                            <h4 class="text">
                                <i class='fa fa-stethoscope'></i> Active Insurance Approvals
                            </h4>
                            <hr>
                            <b-table hover :items="patient.active_insurance_approvals" :fields="insuranceApprovalsFields">
                                <template v-slot:cell(card_file_group)='data'>
                                    <div v-if='data.item.card_file_group && data.item.card_file_group.files'>

                                        <div v-for='(file, index) in data.item.card_file_group.files' :key='index'>
                                            <b-img thumbnail :src="file.url" alt="Image 1"
                                                @click='selectedImages = data.item.card_file_group.files.map(item => item.url); selectedImageIndex = index;'></b-img>
                                        </div>

                                    </div>
                                </template>
                                <template v-slot:cell(approval_file_group)='data'>
                                    <div v-if='data.item.approval_file_group && data.item.approval_file_group.files'>

                                        <div v-for='(file, index) in data.item.approval_file_group.files' :key='index'>
                                            <b-img thumbnail :src="file.url" alt="Image 1"
                                                @click='selectedImages = data.item.approval_file_group.files.map(item => item.url); selectedImageIndex = index;'></b-img>
                                        </div>

                                    </div>
                                </template>

                                <template v-slot:cell(insurance_provider_id)='data'>
                                    {{ data.item.insurance_provider.name }}
                                </template>

                                <template v-slot:cell(patient_services)='data'>
                                    <div class='badge badge-success m-2'
                                        v-for='(patientService, index) in data.item.patient_services' :key='index'>

                                        <i class='fa fa-stethoscope'></i>
                                        {{ patientService.name }}
                                    </div>
                                </template>
                                <template v-slot:cell(actions)='data'>
                                    <button v-if='can("insurance_approvals.edit")' class='btn btn-success btn-xs ml-2'
                                        v-b-modal.service-insurance-modal
                                        @click="selectedInsuranceId = data.item.id">Edit</button>
                                    <a :href="data.item.url" target='_blank' class='btn btn-success m-2'>View Pdf</a>
                                    <a :href="data.item.url + '?download=1'" class='btn btn-success m-2'>Download</a>
                                </template>
                            </b-table>

                        </div>
                    </div>
                </div>
            </div>
            <div class='col-2'>
                <div class='mt-4'>
                    <div class="mt-2 row" v-if='patient.default_patient_insurance'>
                        <div class='col'>
                            <h3>Insurance</h3>
                            <h4>
                                <div class='badge badge-warning'>{{
                                    patient.default_patient_insurance.insurance_provider.name }}</div>

                                <span class='badge badge-success badge-sm m-2'>
                                    expire at:
                                    {{ patient.default_patient_insurance.expire_at | date }}
                                </span>
                            </h4>
                        </div>
                    </div>
                    <div class="mt-2 row" v-if='financialAccounts[0]'>
                        <div class='col'>
                            <h3>Balance</h3>
                            <h3>
                                <div v-if='financialAccounts[0].balance < 0' class='badge badge-danger'>
                                    {{ financialAccounts[0].balance | currency }}
                                </div>
                                <div v-if='financialAccounts[0].balance > 0' class='badge badge-success'>
                                    {{ financialAccounts[0].balance | currency }}
                                </div>
                                <div v-if='financialAccounts[0].balance == 0' class='badge badge-dark'>
                                    {{ 0 | currency }}
                                </div>
                            </h3>
                        </div>
                    </div>
                    <div class='mt-2 row'>
                        <div class='col-3'>
                            <strong>
                                Name
                            </strong>
                        </div>
                        <div class='col'>
                            {{ patient.name }}
                        </div>
                    </div>
                    <div class='mt-2 row' v-if='can("patients.view_contact_details")'>
                        <div class='col-3'>
                            <strong>
                                Phone
                            </strong>
                        </div>
                        <div class='col'>
                            <a :href="'tel:' + patient.phone">
                                {{ patient.phone }}
                            </a>
                        </div>
                    </div>
                    <div class='mt-2 row'>
                        <div class='col-3'>
                            <strong>
                                Gender
                            </strong>
                        </div>
                        <div class='col'>
                            {{ patient.gender }}
                        </div>
                    </div>
                    <div class='mt-2 row'>
                        <div class='col-3'>
                            <strong>
                                Country
                            </strong>
                        </div>
                        <div class='col'>
                            <div v-if='patient.country_code'>
                                {{ this.countryName(patient.country_code) }}
                            </div>
                        </div>
                    </div>
                    <div class='mt-2 row'>
                        <div class='col-3'>
                            <strong>
                                Email
                            </strong>
                        </div>
                        <div class='col'>
                            <a :href="'email:' + patient.email">
                                {{ patient.email }}
                            </a>
                        </div>
                    </div>
                </div>
                <div class='mt-5'>
                    <h5>Latest Notes</h5>
                    <hr>
                    <patient-notes-custom-list :filter='notesFilter' />
                </div>
            </div>
        </div>

        <b-modal :no-close-on-backdrop=true id="modal-1" hide-footer title="Request Service">
            <RequestServiceForm @saved='newRequestedService'></RequestServiceForm>
        </b-modal>

        <b-modal :no-close-on-backdrop=true size='xl' id="finish-appointment-modal" hide-footer title="Finish Appointment">
            <FinishAppointmentForm @saved='appointmentFinished' :appointment='selectedAppointment'></FinishAppointmentForm>
        </b-modal>

        <b-modal :no-close-on-backdrop=true id="image-modal" hide-footer hide-header>
            <b-img :src="selectedImageUrl"></b-img>
        </b-modal>

        <b-modal :no-close-on-backdrop=true id="service-insurance-modal" size='xl' hide-footer
            title="Patient Service Insurance Form">
            <ServicesInsuranceForm @saved='serviceInsuranceSaved' :id='selectedInsuranceId' :patientId='patient.id'>
            </ServicesInsuranceForm>
        </b-modal>

        <b-modal :no-close-on-backdrop=true id="patient-payment-modal" size='xl' hide-footer title="Patient Payment Form">
            <PatientPaymentForm @saved='patientPaymentSaved' :id='patient.id' :branchId='branch ? branch.id : null'>
            </PatientPaymentForm>
        </b-modal>

        <b-modal :no-close-on-backdrop=true id="add-service-modal" size='xl' hide-footer title="Add Services">
            <ServiceMap @saved='servicesSaved' :patientId='patient.id'></ServiceMap>
        </b-modal>

        <b-modal :no-close-on-backdrop=true id="branch-selector-modal" size='xl' hide-footer title="Select Branch">
            <BranchesSelector @clickedItem='branch => this.branchSelected(branch)'></BranchesSelector>
        </b-modal>

        <b-modal :no-close-on-backdrop=true id="appointment-modal" size='xl' hide-footer title="New Appointment">
            <AppointmentForm @saved='appointmentSaved' :patient='patient'></AppointmentForm>
        </b-modal>

        <b-modal :no-close-on-backdrop=true id="patient-service-appointment-modal" size='xl' hide-footer
            title="Edit Patient Service">
            <PatientServiceAppointmentForm @saved='patientServiceSaved' :id='selectedPatientServiceId'>
            </PatientServiceAppointmentForm>
        </b-modal>

        <b-modal :no-close-on-backdrop=true id="add-invoice-modal" size='xl' hide-footer title="Create Invoice">
            <InvoiceForm @saved='invoiceCreated' :patientId='patient.id' :branchId='branch ? branch.id : null'>
            </InvoiceForm>
        </b-modal>

        <LightGallery :images="selectedImages" :index="selectedImageIndex" :disable-scroll="true"
            @close="selectedImageIndex = null" />

        <Modals :modalName='currentModal' :modalData='currentModalData' @saved='modalCallback' @closed='modalClosed' />
    </div>
</template>

<script>
import RequestServiceForm from '../../components/RequestServiceForm';
import FinishAppointmentForm from '../../components/FinishAppointmentForm';
import ServicesInsuranceForm from '@/components/ServicesInsuranceForm';
import PatientPaymentForm from '@/components/PatientPaymentForm';
import ServiceMap from '@/views/Services/ServiceMap';
import BranchesSelector from '@/components/BranchesSelector';
import AppointmentForm from '@/components/AppointmentForm';
import PatientServiceAppointmentForm from '@/views/Services/PatientServiceAppointmentForm';
import InvoiceForm from '@/components/InvoiceForm';
import PatientServicesListTable from '../../components/Lists/PatientServicesListTable.vue';
import PatientNotesCustomList from '../../components/Lists/PatientNotesCustomList.vue';

export default {
    components: {
        RequestServiceForm,
        FinishAppointmentForm,
        ServicesInsuranceForm,
        PatientPaymentForm,
        ServiceMap,
        BranchesSelector,
        AppointmentForm,
        PatientServiceAppointmentForm,
        InvoiceForm,
        PatientServicesListTable,
        PatientNotesCustomList,
    },
    props: ['patient'],
    data: function () {
        return {
            reorderingStatus: false,
            orderedList: [],
            selectedInsuranceId: null,
            selectedAppointment: null,
            selectedImageIndex: null,
            selectedImageUrl: null,
            selectedDoctor: null,
            selectedPatientServiceId: null,
            branch: null,
            patientServiceFormMode: null,
            patientServicesFilter: { patient_id: this.patient.id, active: 1, order_by: "position" },
            selectedImages: [],
            notesFilter: { patient_id: this.patient.id, limit: 10 },
            financialAccounts: [],
            requestedServices: [
                {
                    title: 'Requested crown from "Medical" by Dr. Hossam',
                    status: 0
                }
            ],
            appointmentsFields: [
                { key: 'id', label: 'ID' },
                { key: 'datetime', label: 'Date Time' },
                { key: 'doctor', label: 'Doctor' },
                { key: 'patient_services', label: 'Services' },
                { key: 'actions', label: 'Actions' },
            ],
            activeServicesFields: [
                { key: 'name', label: 'Name' },
                { key: 'appointments', label: 'appointments' },
                // { key: 'next_session', label: 'Next Session' },
            ],
            insuranceApprovalsFields: [
                { key: 'id', label: 'ID' },
                { key: 'patient_services', label: 'Patient Services' },
                { key: 'insurance_provider_id', label: 'Insurance Provider' },
                { key: 'card_file_group', label: 'Card' },
                { key: 'approval_file_group', label: 'Approval' },
                { key: 'actions', label: 'Actions' },
            ],
            servicesFields: [
                { key: 'id', label: 'ID' },
                { key: 'name', label: 'Name' },
                { key: 'appointments', label: 'Appointments' },
                { key: 'required_appointments', label: 'number of sessions' },
                // { key: 'next_session', label: 'Next Session' },
                { key: 'actions', label: 'Actions' },
            ]
        }
    },
    mounted() {

        // this.groupedActiveServices;
        // this.doctors = [];
        // this.doctorIds = [];
        this.pateintServicesFilter = { patient_id: this.patient.id, active: 1, order_by: "Appointment Time" };
        this.apiRequest(
            (apiClient) => {
                return apiClient.get('patients/' + this.patient.id)
                    .then(patient => {
                        this.financialAccounts = patient.financial_accounts ? patient.financial_accounts : null;
                    });
            }
        );
    },
    methods: {
        handleReordering() {
            this.reorderingStatus = !this.reorderingStatus
            if (!this.reorderingStatus) {
                this.updateOrdering();
            }
        },
        reorder(e) {
            this.orderedList = e;
        },
        updateOrdering() {
            let payLoad = { patient_service_ids: this.orderedList }
            this.apiClient.put('patient-services/reorder', payLoad);
        },
        refresh() {
            this.$router.go(0);
        },
        newRequestedService: function () {
            this.$bvModal.hide('modal-1');
            this.requestedServices.push({
                title: 'Requested another crown from "Medical" by Dr. Hossam',
                status: 1
            });
        },
        appointmentFinished() {
            this.$bvModal.hide('finish-appointment-modal');
            this.$router.go(0);
        },
        appointmentSaved() {
            this.$bvModal.hide('appointment-modal');
            this.$router.go(0);

        },
        serviceInsuranceSaved() {
            this.$bvModal.hide('service-insurance-modal');
            this.$router.go(0);
        },
        patientPaymentSaved() {
            this.$bvModal.hide('patient-payment-modal');
            this.$router.go(0);
        },
        servicesSaved() {
            this.$bvModal.hide('patient-payment-modal');
            this.$router.go(0);
        },
        patientServiceSaved() {
            this.$router.go(0);
        },
        invoiceCreated() {
            this.$router.go(0);
        },
        addPayment() {
            var branch = this.userBranch();
            this.nextModal = 'patient-payment-modal';
            if (!branch) {
                this.$bvModal.show('branch-selector-modal');
            } else {
                this.branchSelected(branch);
            }
        },
        addInvoice() {
            var branch = this.userBranch();
            this.nextModal = 'add-invoice-modal';
            if (!branch) {
                this.$bvModal.show('branch-selector-modal');
            } else {
                this.branchSelected(branch);
            }
        },
        branchSelected(branch) {
            this.branch = branch;
            if (this.nextModal) {
                this.$bvModal.show(this.nextModal);
            }
        }
    }
}
</script>