import Vue from 'vue'
import Cleave from 'cleave.js';
import moment from 'moment';
import axios from 'axios';
import countryList from 'country-list';

// import Loader from './components/Loader'

Vue.component('loader', 
    () => import('./components/Loader')
)

Vue.component('WhatsappLink', 
    () => import('./components/WhatsappLink')
)


Vue.component('CallPhone', 
    () => import('./components/CallPhone')
)


Vue.component('FormErrors', 
    () => import('./components/FormErrors')
)

Vue.component('FormGroupError', 
    () => import('./components/FormGroupError')
)

Vue.component('ListView', 
    () => import('@/views/ListView')
)

Vue.component('SimpleListView', 
    () => import('@/views/SimpleListView')
)

Vue.component('BranchesSelect', 
    () => import('@/components/BranchesSelect')
)

Vue.component('PatientsSelect', 
    () => import('@/components/PatientsSelect')
)


Vue.component('ClinicsSelect', 
    () => import('@/components/ClinicsSelect')
)

Vue.component('WorkersSelect', 
    () => import('@/components/WorkersSelect')
)

Vue.component('PayablesSelect', 
    () => import('@/components/PayablesSelect')
)

Vue.component('HostsSelect', 
    () => import('@/components/HostsSelect')
)

Vue.component('FinancialAccountsSelect', 
    () => import('@/components/FinancialAccountsSelect')
)

Vue.component('ProductsSelect', 
    () => import('@/components/ProductsSelect')
)

Vue.component('DoctorsSelect', 
    () => import('@/components/DoctorsSelect')
)

Vue.component('RolesSelect', 
    () => import('@/components/RolesSelect')
)

Vue.component('InventoriesSelect', 
    () => import('@/components/InventoriesSelect')
)

Vue.component('SuppliersSelect', 
    () => import('@/components/SuppliersSelect')
)

Vue.component('SectionsSelect', 
    () => import('@/components/SectionsSelect')
)

Vue.component('ServicesSelect', 
    () => import('@/components/ServicesSelect')
)

Vue.component('InsuranceProvidersSelect', 
    () => import('@/components/InsuranceProvidersSelect')
)

Vue.component('PatientInsurancesSelect', 
    () => import('@/components/PatientInsurancesSelect')
)

Vue.component('FingerprintDevicesSelect', 
    () => import('@/components/FingerprintDevicesSelect')
)

Vue.component('UsersSelect', 
    () => import('@/components/UsersSelect')
)

Vue.component('VendorsSelect', 
    () => import('@/components/VendorsSelect')
)

Vue.component('OptionsSelect', 
    () => import('@/components/OptionsSelect')
)

Vue.component('ExternalServicesSelect', 
    () => import('@/components/ExternalServicesSelect')
)

Vue.component('MultipleDateTimePicker', 
    () => import('@/components/MultipleDateTimePicker')
)

Vue.component('FileUpload', 
    () => import('@/components/FileUpload')
)

Vue.component('FileGroupView', 
    () => import('@/components/FileGroupView')
)

Vue.component('DatePick', 
    () => import('vue-date-pick')
)

Vue.component('Slider', 
    () => import('@vueform/slider/dist/slider.vue2.js')
)

Vue.component('TimeRangeSlider', 
    () => import('@/components/TimeRangeSlider')
)

Vue.component('CountriesSelect', 
    () => import('@/components/CountriesSelect')
)

Vue.filter('textTime', function (value) {
    if (!value) return ''
    return moment(value,'h:mm a').format('h:mm a');
})

Vue.filter('datetime', function (value) {
    if (!value) return ''
    value = new moment(value);
    return value.format("ddd, DD MMM, YYYY h:mm A");
})

Vue.filter('date', function (value) {
    if (!value) return ''
    value = new moment(value);
    return value.format("ddd, DD MMM, YYYY");
})

Vue.filter('date_normal', function (value) {
    if (!value) return ''
    value = new moment(value);

    if (value.year() === moment().year()) {
        return value.format("DD MMM, YYYY");
    } else {
        return value.format("DD MMM, YYYY");
    }
})

Vue.filter('fulldate', function (value) {
    if (!value) return ''
    value = new moment(value);
    return value.format("ddd, DD MMM, YYYY");
})

Vue.filter('time', function (value) {
    if (!value) return ''
    value = new moment(value);
    return value.format("h:mm A");
})

Vue.filter('timediff', function (value) {
    if (!value) return ''
    value = new moment.duration(value * 1000);
    return value.humanize();
})

Vue.filter('price', function (value) {
    if (!value) return ''
    let val = (value/1).toFixed(2).replace('.', ',')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
})

Vue.filter('currency', function (value) {
    if (typeof value !== "number") {
        value = parseFloat(value);
    }
    var formatter = new Intl.NumberFormat('en-EG', {
        style: 'currency',
        currency: 'EGP',
        minimumFractionDigits: 2
    });
    return formatter.format(value);
});

Vue.filter('decimal', function (value) {
    if (typeof value !== "number") {
        value = parseFloat(value);
    }
    var formatter = new Intl.NumberFormat('en-EG', {
        style: 'decimal',
        minimumFractionDigits: 2
    });
    return formatter.format(value);
});

Vue.filter('str_limit', function (value, size) {
    if (!value) return '';
    value = value.toString();

    if (value.length <= size) {
        return value;
    }
    return value.substr(0, size) + '...';
});

Vue.component('TimePicker', 
    () => import('@/components/TimePicker')
)

Vue.component('FinishAppointmentModal', 
    () => import('@/components/Modals/FinishAppointmentModal')
)

Vue.component('Modals', 
    () => import('@/components/Modals/Modals')
)

Vue.component('AppointmentsListTable', 
    () => import('@/components/Lists/AppointmentsListTable')
)

Vue.component('BaseList', 
    () => import('@/components/Lists/BaseList')
)

Vue.component('FinancialAccountsBalance', 
    () => import('@/components/FinancialAccountsBalance')
)

Vue.component('MyDateRangePicker', 
    () => import('@/components/MyDateRangePicker')
)

Vue.mixin({
    data() {
        return {
            apiClient: null,
            currentModal: null,
            currentModalData: null,
            modalCallbackName: null,
        };
    },
    created() {
      this.apiClient = this.GlobalApiClient;
    },
    computed: {
        currentModalObject: function () {
            return {
                currentModal: this.currentModal,
                currentModalData: this.currentModalData,
                modalCallback: this.modalCallback,
                modalClosed: this.modalClosed
            };
        }
    },
    methods: {
        isMobile() {
            return this.$isMobile();
        },
        dynamicID() { return 'dynamicID-' + Math.floor(Math.random() * Date.now().toString()); },
        notify (title, message) {
            if ('Notification' in window) {
                // https://developer.mozilla.org/en-US/docs/Web/API/Notification/Notification#Parameters
                this.$notification.show(title, {
                    body: message
                }, {});
            }
        },
        formatHours(hours){
            var minutes = hours * 60;
            return moment().startOf('day').add(minutes, 'minutes').format('LT');
        },
        pushQuery(params) {
            params = this.removeObjectNulls(params);
            if (JSON.stringify(params) != JSON.stringify(this.$route.query)) {
                this.$router.push({query: params}) ;
                return true;
            }

            return false;
        },
        removeObjectNulls(obj) {
            for (var propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined) {
                delete obj[propName];
            }
            }
            return obj
        },
        confirm(message) {
            message = message || 'Are you sure?';
            return this.$bvModal.msgBoxConfirm(message);  
        },
        modal(currentModal, modalData, modalCallbackName) {
            this.currentModal = currentModal;
            this.currentModalData = modalData;
            this.modalCallbackName = modalCallbackName;
        },
        modalClosed() {
            this.currentModal = null;
        },
        modalCallback(data) {
            this.currentModal = null;
            if (typeof this.modalCallbackName === 'function') {
                this.modalCallbackName.call(this, data);
            } else if (this.modalCallbackName) {
                this[this.modalCallbackName](data);
            }
        },
        isCurrentModal(modalName) {
            return this.currentModal == modalName ? true : false;
        },
        loadUser() {
            return JSON.parse(localStorage.user);
        },
        apiRequest(fn, preCancelTokenSource) {
            if (preCancelTokenSource) {
                this.apiClient.cancel(preCancelTokenSource);
            }
            this.apiClient = this.GlobalApiClient;
            let cancelTokenSource = this.apiClient.cancelTokenSource();
            return fn.call(this, this.apiClient, cancelTokenSource)
            .catch(e => {
                if (axios.isCancel(e)) {
                    return; 
                }
                if (!e.response) {
                    throw e;
                }

                var status = e.response.status;
    
                if (status == 422) {
                    throw e;
                } else if (status == 401) {
                    localStorage.removeItem('isAuthenticated');
                    this.$router.push({ name: 'Login', query: { redirect:  window.location.pathname + window.location.search } });
                    throw e;
                } else {
                    this.showErrors(e.response.statusText);
                    throw e;
                }
            });
        },
        datetime: function (date) {
            return moment(date).format("YYYY-MM-DD HH:mm:ss");
        },
        date: function (date) {
            return moment(date).format("YYYY-MM-DD");
        },
        startOfYear: function (year) {
            return moment(year+'-01-01').startOf('year').format("YYYY-MM-DD");
        },
        endOfYear: function (year) {
            return moment(year+'-01-01').endOf('year').format("YYYY-MM-DD");
        },
        startOfMonth: function (month, year) {
            return moment(year+'-'+month+'-01').startOf('month').format("YYYY-MM-DD");
        },
        endOfMonth: function (month, year) {
            return moment(year+'-'+month+'-01').endOf('month').format("YYYY-MM-DD");
        },
        dayNumber: function (date) {
            date = moment(date);
            return (date.day()+2)%7;
        },
        can: function (key) {
            var user = this.loadUser();
            if (user) {
                if (user.role && user.role.permissions) {
                    return user.role.permissions.indexOf(key) > -1 ? true : false;
                }
            }

            return false;
        },
        userDoctor: function () {
            var user = this.loadUser();
            if (user && user.doctors) {
                return user.doctors.length == 1 ? user.doctors[0] : null;
            }

            return null;
        },
        userDoctors: function () {
            var user = this.loadUser();
            if (user && user.doctors) {
                return user.doctors;
            }

            return [];
        },


        userMultipleDoctors: function () {
            var user = this.loadUser();
            if (user && user.doctors) {
                return user.doctors.length > 1 ? true : false;
            }

            return false;
        },
        userBranch: function () {
            var user = this.loadUser();
            
            if (user && user.branches && user.branches.length == 1) {
                return user.branches[0];
            }

            return null;
        },
        countryName: function (countryCode) {
            var name = countryList.getName(countryCode); 
            return name ? name : countryCode;
        }
    },
});

function getInput(el) {
    if (el.tagName.toLocaleUpperCase() !== 'INPUT') {
      const els = el.getElementsByTagName('input')
      if (els.length !== 1) {
        throw new Error(`v-cleave requires 1 input, found ${els.length}`)
      } else {
        el = els[0]
      }
    }
    return el
  }
Vue.directive('cleave', {
    inserted: (el, binding) => {
        el = getInput(el)
        el.cleave = new Cleave(el, binding.value || {})
    },
    update: el => {
        el = getInput(el)
        const event = new Event('input', { bubbles: true })
        setTimeout(function() {
        el.value = el.cleave.properties.result
        el.dispatchEvent(event)
        }, 100)
    }
});