<template>
    <div>
        <base-list :collection='collection' :loading='loading' :fields='fields' :modalObject='currentModalObject' @pageChanged='pageChanged'>
            
            <template v-slot:cell(patient)='data'>
                <router-link :to="{ name: 'ViewPatient', params: {id: data.item.patient.id}}" class=''>
                    <h5>
                    {{ data.item.patient.name }}
                    </h5>
                </router-link>
            </template>
            <template v-slot:cell(description)='data'>
                <div :title='data.item.description'>
                    {{ data.item.description | str_limit(100) }}
                </div>
            </template>

            <template v-slot:cell(title)='data'>
                <div :title='data.item.title'>
                    {{ data.item.title | str_limit(50) }}
                </div>
            </template>
            <template v-slot:cell(created_by)='data'>
                <div class='badge badge-light'>
                    <i class='fa fa-user'></i>
                    {{ data.item.created_by.name }}
                </div>
                <br>
                <div class='badge badge-light mt-2'>
                {{ data.item.created_at | datetime }}
                </div>
            </template>
            <template v-slot:cell(file_group)='data'>
                <h4 v-if='data.item.file_group && data.item.file_group.files'>
                    <div 
                        v-for='(file,index) in data.item.file_group.files' :key='index'
                    >
                        <button
                            class='badge badge-light m-1'
                            @click='modal("fileForm", {file: file})'
                        >
                            <i class='fa fa-image'></i>
                            {{ file.name || "no name" | str_limit(20) }}
                        </button>
                    </div>
                </h4>

                <div v-else class='badge badge-light'>No Files</div>
            </template>

			<template v-slot:cell(actions)='data'>
				<button class='btn btn-xs btn-primary m-2' @click="modal('medicalRecordForm', {id: data.item.id}, 'refresh')">Edit</button>
				<button class='btn btn-xs btn-danger m-2' @click="confirmAction('deleteMedicalRecord', data.item, 'refresh')">Delete</button>
            </template>
        </base-list>
    </div>
</template>

<script>
import BaseList from './BaseList.vue';
import BaseListMethods from './BaseListMethods';

export default {
	props: ['viewType'],
    components: { BaseList },
    extends: BaseListMethods,
    data() {
        return {
			fields: [
                { key: 'id', label: 'ID' },
                { key: 'patient', label: 'Patient' },
                { key: 'title', label: 'Title' },
                { key: 'description', label: 'Description' },
                { key: 'file_group', label: 'Files' },
                { key: 'created_by', label: 'Created By' },
                { key: 'actions', label: 'Actions' },
			]
        }
    },
	methods: {
        load: function (apiClient, cancelToken, filter) {
			return apiClient.get('medical-records', filter, cancelToken);
		},
		deleteMedicalRecord(medicalRecord) {
			return this.apiRequest(apiClient => apiClient.delete('medical-records/'+medicalRecord.id));
		},
    },
    watch: {
		viewType: {
			immediate: true,
			handler: function () {
				if (this.viewType == 'patientView') {
					this.hideFields = ['patient'];
				}
			}
		},
    }
}
</script>