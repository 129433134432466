<template>
    <div>
        <base-list :collection='collection' :loading='loading' :fields='fields' :modalObject='currentModalObject' @pageChanged='pageChanged'>
            
			<template v-slot:cell(date)='data'>
                <h4>
                    <div class='badge badge-light m-2'>
                        <i class='fa fa-calendar'></i>
                        {{ data.item.date | date }}
                    </div>
                    <br>
                    <div class='badge badge-light m-2'>
                        {{ data.item.branch.name }}
                    </div>

                </h4>
            </template>
            
            <template v-slot:cell(amount)='data'>
                <div v-if='!["cash in", "cash out"].includes(data.item.type)'>
                    <div class='badge' :class='data.item.amount == data.item.amount_paid ? "badge-success" : "badge-light"'>
                        {{ data.item.amount_paid | currency }}
                        /
                        {{ data.item.amount | currency }}
                    </div>
                </div>
                <div v-else>
                    <div class='badge badge-light'>
                        {{ data.item.amount | currency }}
                    </div>
                </div>
            </template>
            <template v-slot:cell(title)='data'>
                <b>
                </b>
                <br>
                <div class='badge m-2' :class='data.item.status == "finished" ? "badge-success" : "badge-light"'>
                    {{ data.item.title }}
                </div>
                <div class='badge' :class='data.item.status == "finished" ? "badge-success" : "badge-light"'>
                    {{ data.item.status }}
                </div>
                <br>
                <div class='badge badge-light m-2'>
                    # {{ data.item.transactionable_id }}
                    {{ data.item.transactionable_type }}
                </div>
                <br>
                <div v-if='can("accounts.list")'>
                    <div v-if='!["cash in", "cash out"].includes(data.item.type)'>
                        <div class='badge m-2' :class='data.item.collected_from_customer == data.item.required_from_customer ? "badge-success" : "badge-light"'>
                            customer: {{ data.item.collected_from_customer }} / {{ data.item.required_from_customer }}
                        </div>
                        <div class='badge badge-light m-2'>
                            {{ 100 * parseFloat(data.item.collected_from_customer) / parseFloat(data.item.required_from_customer) }} %
                        </div>
                        <br>
                        <div class='badge m-2' :class='data.item.required_to_supplier == data.item.paid_to_supplier ? "badge-success" : "badge-light"'>
                            supplier: {{ data.item.paid_to_supplier }} / {{ data.item.required_to_supplier }}
                        </div>
                        <div class='badge badge-light m-2'>
                            {{ 100 * parseFloat(data.item.paid_to_supplier) / parseFloat(data.item.required_to_supplier) }} %
                        </div>
                    </div>
                </div>
            </template>

			<template v-slot:cell(entity)='data'>
                <h4 v-if='data.item.entity_type'>
                    <div class='badge badge-light m-1'>
                        {{ data.item.entity_type  }}
                    </div>
                    <div class='badge badge-light m-1'>
                        {{data.item.entity.name}}
                    </div>
                </h4>
            </template>

			<template v-slot:cell(actions)='data'>
				<button class='btn btn-success btn-xs m-1' @click="modal('financialTransactionsList', {filter: {transaction_id: data.item.id}}, 'refresh')">View Financial Transactions</button>
            </template>

        </base-list>
    </div>
</template>

<script>
import BaseList from './BaseList.vue';
import BaseListMethods from './BaseListMethods';

export default {
	props: ['viewType'],
    components: { BaseList },
    extends: BaseListMethods,
    data() {
        return {
			fields: [
                { key: 'id', label: 'ID' },
                { key: 'entity', label: 'Entity' },
                { key: 'date', label: 'Date' },
                { key: 'title', label: 'Title' },
                { key: 'amount', label: 'Amount' },
                { key: 'type', label: 'Type' },
                { key: 'actions', label: 'Actions' },
			]
        }
    },
	methods: {
        load: function (apiClient, cancelToken, filter) {
			return apiClient.get('transactions', filter, cancelToken);
		}
    },
    watch: {

		viewType: {
			immediate: true,
			handler: function () {
				if (this.viewType == 'entity') {
					this.hideFields = ['entity'];
				}
			}
		},
    }
}
</script>