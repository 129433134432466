<template>
    <div>
        <base-list :collection='collection' :loading='loading' :fields='fields' :modalObject='currentModalObject'
            @pageChanged='pageChanged'>

            <template v-slot:cell(branch)='data'>
                {{ data.item.branch ? data.item.branch.name : '' }}
            </template>
            <template v-slot:cell(amount)='data'>
                {{ Number(data.item.amount).toLocaleString("en-US") }}
            </template>
            <template v-slot:cell(actions)='data'>
                <button class='btn btn-success btn-xs m-1'
                    @click="modal('financialTransactionsList', { filter: { financial_account_id: data.item.id, branch_id: filter.branch_id, accountable_type: filter.accountable_type } }, 'refresh')">View
                    Financial Transactions</button>
                <button class='btn btn-success btn-xs m-1'
                    @click="modal('childAccountsList', { filter: { ...filter, parent_id: data.item.id } }, 'refresh')">View
                    Sub Accounts</button>
            </template>
        </base-list>
    </div>
</template>

<script>
import BaseList from './BaseList.vue';
import BaseListMethods from './BaseListMethods';

export default {
    components: { BaseList },
    extends: BaseListMethods,
    data() {
        return {
            fields: [
                { key: 'id', label: '#' },
                { key: 'name', label: 'Name' },
                { key: 'parent.name', label: 'Parent' },
                { key: 'branch', label: 'Branch' },
                { key: 'amount', label: 'Amount' },
                { key: 'actions', label: 'Actions' },
            ]
        }
    },
    methods: {
        load: function (apiClient, cancelToken, filter) {
            return apiClient.get('financial-accounts/account-children-statement', filter, cancelToken);
        },
    }
}
</script>