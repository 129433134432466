<template>
    <div>
        <financial-transactions-list :financialAccountId="financialAccounts[0].id"></financial-transactions-list>
    </div>
</template>

<script>
import FinancialTransactionsList from '../Accounting/FinancialTransactionsList.vue';


export default {
    components: {
        FinancialTransactionsList
    },
    props: ['patient'],
    data: function () {
        return {
            financialAccounts: []
        }
    },
    mounted() {
        var branch = this.userBranch();
        if (branch) {
            this.financialAccounts = this.patient.financial_accounts.filter(item => item.branch_id == branch.id ? true : false);
        } else {
            this.financialAccounts = this.patient.financial_accounts;
        }
    }
}
</script>