<template>
    <div>
        
        <b-form-group label="Date"
            class="ml-2"
        >
            <b-form-radio-group
                v-model="filter.period"
                :options="['week', 'month', 'custom']"
                name="radios-btn-default"
                buttons
                button-variant="primary"
            ></b-form-radio-group>
        </b-form-group>
        <v-date-picker v-if="filter.period == 'custom'" v-model='filter.date_range' value="range" is-range />
        <div class='form-group'>
            <label for="">Duration</label>
            <input type="number" class='form-control' v-model='duration'>
        </div>
        <div class='form-group'>
            <label for="">Intervals</label>
            <input type="number" class='form-control' v-model='intervals'>
        </div>
        <div class="list-group">
            <div v-for='(date, mainIndex) in dates' :key='mainIndex'  class='list-group-item'>
                <div class="row">
                    
                    <div class='col-2 p-2 m-2 h5'>
                        <strong>
                            {{ date.date | date }}
                        </strong>
                    </div>
                    <div class='col-14'>
                        <div v-for="(item, index) in date.items" :key="index">
                            <div v-if='!doctor_id'>
                                <strong class='m-2'>
                                    <i class='fa fa-user-md'></i>
                                    Dr. {{ doctor(item.doctor_id).name }}
                                </strong>
                                <div class='badge badge-light m-2'>
                                    <i class='fa fa-cube'></i>
                                    {{ doctor(item.doctor_id).section.name }}
                                </div>
                                <div class='badge badge-light m-2'>
                                    {{ branch(item.branch_id).name }}
                                </div>
                            </div>
                            <div class='row mt-2' v-for='(time, timeIndex) in item.times' :key='timeIndex'>
                                <div 
                                    class='badge badge-success badge-lg mr-1'
                                    :class="time.is_working_hour? (time.is_free ? 'badge-primary' : 'badge-success') : 'badge-danger'"
                                    :title='!time.is_working_hour? "!! Out of working hours" : ""'
                                    
                                    @click='min = time.from, max=time.to'
                                    v-b-modal.modal-2
                                >
                                    <strong>
                                        {{ time.from | time }}
                                        -
                                        {{ time.to | time }}
                                    </strong>
                                </div>
                                <div v-if='time.is_free' class='badge badge-lg  badge-primary ml-1' >
                                    <strong>
                                        Free
                                    </strong>
                                </div>
                                <div v-else class='badge badge-lg  badge-success'>
                                    <strong>
                                        {{ time.number_of_appointments }} appointments
                                    </strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal :no-close-on-backdrop=true id="modal-2" size='lg' hide-footer  title="Choose Time">   
            <time-picker @timePicked='timePicked' :min='this.min' :max='this.max' :duration='intervals' />
        </b-modal>

    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import TimePicker from './TimePicker.vue';
export default {
    extends: BaseList,
    props: {
        doctor_id: Number,
        branch_id: Number,
        duration: {
            type: Number,
            default: 10
        }
    },
    components: {
        TimePicker
    },
    methods: {
        load(apiClient, cancelToken) {
            var filter = {
                period : this.filter.period ? this.filter.period : null, 
                start_date : this.filter.date_range ? this.date(this.filter.date_range.start) : '', 
                end_date : this.filter.date_range ? this.date(this.filter.date_range.end) : '', 
                doctor_id : this.doctor_id ? this.doctor_id : '', 
                branch_id : this.branch_id ? this.branch_id : '', 
                section_id : this.filter.section ? this.filter.section.id : '', 
                hide_vacations: 1
            }

            return apiClient.get('calendar', filter, cancelToken).then(r => {
                this.doctors = r.doctors;
                this.branches = r.branches;
                this.dates = r.dates;
            });
        },
        chooseTime(time, doctor) {
            console.log(time)
            console.log(doctor);
        },
        timePicked(time) {
            this.$emit('datetimePicked', {date: time.toDate(), duration: this.duration});
        },
        doctor: function (doctorId) {
            return this.doctors.find(item => item.id == doctorId);
        },
        branch: function (branchId) {
            return this.branches.find(item => item.id == branchId);
        }
    },
    data: function() {
        return {
            collection: [],
            myDate: [],
            min: '0',
            max: '0',
            intervals: 15,
            doctors: [],
            branches: [],
            dates: [],
        }
    }
}
</script>