<template>
    <div>
        <loader v-if='loading'/>
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>
            <b-form-group label="Title">
                <b-form-input v-model="medicalRecord.title" :state="states.title" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.title'/>
            </b-form-group>

            <b-form-group label="Description">
                <b-form-textarea v-model="medicalRecord.description" :state="states.description" type="text" placeholder=""/>
                <FormGroupError :errors='errors.description'/>
            </b-form-group>


            <b-form-group label="Type">
                <v-select :options="types" v-model='medicalRecord.type'></v-select>

                <FormGroupError :errors='errors.type'/>
            </b-form-group>

            <b-form-group label="Upload Files">
                <FileUpload v-on:input='value => this.fileIds = value'/>
            </b-form-group>

            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
export default {
    extends: BaseForm,
    props: ["patientId"],
    data: function () {
        return {
            medicalRecord: {
            },
            fileIds: [],
            types: [
                'Scan',
                'Test',
                'Other',
            ]
        }
    },
    methods: {
        load: function(id) {
            return this.apiRequest(apiClient => apiClient.get('medical-records/'+id))
                .then(response => this.medicalRecord = response);
        },
        save: function (apiClient) {
            var medicalRecord = {
                ... this.medicalRecord,
                file_ids: this.fileIds
            };
            if (this.id) {
                return apiClient.put('medical-records/'+this.id, medicalRecord);
            } else {
                medicalRecord.patient_id = this.patientId;
                return apiClient.post('medical-records', medicalRecord);
            }
        },
        afterSave: function () {
            this.$emit('saved', 1);
        }
    },
}
</script>