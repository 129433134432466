<template>
    <div>
         <div v-if='insuranceApproval.patient_insurance.card_file_group && insuranceApproval.patient_insurance.card_file_group.files'>
            <h3>Card</h3>
            <div 
                v-for='(file,index) in insuranceApproval.patient_insurance.card_file_group.files' :key='index'
            >
                <b-form-checkbox size='lg' v-model='files' :value='file.id'>
                    <i v-if='file.type_group == "pdf"' class='fa fa-file-pdf'></i>
                    <i v-if='file.type_group == "image"' class='fa fa-image'></i>
                    {{ file.name || "no name" }}
                </b-form-checkbox>
            </div>
        </div>

        <div v-if='insuranceApproval.approval_file_group && insuranceApproval.approval_file_group.files'>
            <h3>Approval</h3>
            <div 
                v-for='(file,index) in insuranceApproval.approval_file_group.files' :key='index'
            >
                <b-form-checkbox size='lg' v-model='files' :value='file.id'>
                    <i v-if='file.type_group == "pdf"' class='fa fa-file-pdf'></i>
                    <i v-if='file.type_group == "image"' class='fa fa-image'></i>
                    {{ file.name || "no name" }}
                </b-form-checkbox>
            </div>
        </div>
        <hr>
        <div v-if='selectedMedicalRecords.length > 0'>
            <h3>Medical Records</h3>
            <div v-for='(medicalRecord, index) in selectedMedicalRecords' :key='index'>
                <b>{{ medicalRecord.title }}</b>
                <div v-if='medicalRecord.file_group'>
                    <div 
                        v-for='(file,index2) in medicalRecord.file_group.files' :key='index2'
                    >
                        <b-form-checkbox size='lg' v-model='files' :value='file.id'>
                            <i v-if='file.type_group == "pdf"' class='fa fa-file-pdf'></i>
                            <i v-if='file.type_group == "image"' class='fa fa-image'></i>
                            {{ file.name || "no name" }}
                        </b-form-checkbox>
                    </div>
                </div>
                <hr>

            </div>
        </div>
        
        
        <button class='btn btn-success m-2' @click='modal("medicalRecordsSelector", {multiple:true, baseFilter: {patient_id: insuranceApproval.patient_id}}, "addMedicalRecords")'>
            <i class='fa fa-plus'></i>
            Select Medical Records
        </button>

        <authorized-url class='btn btn-success m-2' v-if='this.files.length > 0' :url='mergeUrl' target="_blank">
            Print
        </authorized-url>

		<Modals :modalName='currentModal' :modalData='currentModalData' @saved='modalCallback' @closed='modalClosed'>
		</Modals> 
    </div>
</template>

<script>
import AuthorizedUrl from './AuthorizedUrl.vue';

export default {
  components: { AuthorizedUrl },
    props: ["insuranceApproval"],
    data() {
        return {
            files: [],
            token: localStorage.token,
            selectedMedicalRecords: []
        }
    },
    methods: {
        addMedicalRecords(medicalRecords) {
            medicalRecords.forEach(medicalRecord => {
                this.selectedMedicalRecords.push(medicalRecord);
                if (medicalRecord.file_group) {
                    this.files = [... this.files, ...medicalRecord.file_group.files.map(file => file.id)]
                }
            });

        },
    },
    mounted() {
        var files = [];
        if (this.insuranceApproval.approval_file_group) {
            files = [...this.insuranceApproval.approval_file_group.files];
        }

        if (this.insuranceApproval.patient_insurance.card_file_group) {
            files = [...files, ... this.insuranceApproval.patient_insurance.card_file_group.files];
        }

        this.files = files.map(item => item.id);
    },
    computed: {
        mergeUrl() {
            var baseUrl = process.env.VUE_APP_BASE_URL;
            
            var queryString = this.files.map(itemId => "file_ids[]="+itemId).join("&");

            return baseUrl + '/insurance-approvals/print/'+this.insuranceApproval.id+'?' + queryString;
        }
    }

}
</script>