<template>
    <div>

        <ListView title="InventoryTransactions List" :collection="collection" :fields="fields" @pageChanged='pageChanged' :loading="loading">
            <template v-slot:actions>
                <router-link :to="{name: 'InventoryMovementForm'}" class="btn btn-primary btn-sm">
                    <i class="fa fa-plus"></i>
                    New Movement
                </router-link>

            </template>

            <template v-slot:filter>
                <div class='form'>
                    <div class='form-row'>
                        <div class='form-group col-md-4'>
                            <b-form-input v-model="filter.keyword" placeholder="search ..."></b-form-input>
                        </div>
                    </div>
                </div>
            </template>

            <template v-slot:cell(source)='data'>
                <div v-if='data.item.source_inventory' class='badge badge-light'>
                    <i class='fa fa-cube'></i>
                    {{ data.item.source_inventory.name }}
                </div>
                <div  v-if='data.item.purchase'>
                    <div class='badge badge-light'>
                        <i class='fa fa-shopping-cart'></i>
                        purchase |
                        {{ data.item.purchase.created_at | date }}
                    </div>                
                    <div class='badge badge-light ml-2'>
                        <i class='fa fa-truck'></i>
                        {{ data.item.purchase.supplier.name }}
                    </div>
                </div>
            </template>

            <template v-slot:cell(target)='data'>
                <div v-if='data.item.target_inventory' class='badge badge-light'>
                    <i class='fa fa-cube'></i>
                    {{ data.item.target_inventory.name }}
                </div>
                <div v-if='data.item.patient_service'>
                    <div class='badge badge-light ml-2'>
                        #{{ data.item.patient_service.id }}
                        <i class='fa fa-stethoscope'></i>
                        {{ data.item.patient_service.name }}
                    </div>
                    <div class='badge badge-light ml-2'>
                        <i class='fa fa-user'></i>
                        {{ data.item.patient_service.patient.name }}
                    </div>   
                </div>
            </template>

            <template v-slot:cell(products)='data'>
                <div v-for='(invetoryProduct, index) in data.item.products' :key='index'>
                    <div class='badge badge-success ml-2'>
                        {{ invetoryProduct.product.name }}
                    </div>
                    <div class='badge badge-success ml-2'>
                        {{ invetoryProduct.stock_price | currency}}
                    </div>
                    <div class='badge badge-success ml-2'>
                        X
                        {{ invetoryProduct.quantity }}
                    </div>
                    <div class='badge badge-success ml-2'>
                        <i class='fa fa-coins'></i>
                        {{ invetoryProduct.stock_price * invetoryProduct.quantity | currency}}
                    </div>
                </div>
            </template>

            <template v-slot:cell(actions)='data'>

                <router-link v-if='data.item.purchase' :to="{ name: 'EditPurchase', params: {id: data.item.purchase.id}}" class='btn btn-primary mr-2'>
                    Edit Purchase
                </router-link>
                <b-button v-if='data.item.patient_service' v-b-modal.inventoryTransaction-modal class='mr-2' @click="selectedInventoryTransactionId = data.item.id">
                    Edit Service Transaction
                </b-button>
                <b-button v-if='data.item.source_inventory_id && data.item.target_inventory_id' v-b-modal.inventoryTransaction-modal class='mr-2' @click="selectedInventoryTransactionId = data.item.id">
                    Edit
                </b-button>
            </template>
                    
        </ListView>
        
        <b-modal :no-close-on-backdrop=true id="inventoryTransaction-modal" hide-footer  title="InventoryTransaction Form">
            <InventoryMovementForm @saved='inventoryTransactionSaved' :id='selectedInventoryTransactionId'></InventoryMovementForm>        
        </b-modal>
    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import InventoryMovementForm from '../Inventory/InventoryMovementForm';

export default {
    extends: BaseList,
    components: {InventoryMovementForm},
    methods: {
        load: function (apiClient, cancelToken) {
            return apiClient.get('inventory-transactions', this.filter, cancelToken);
        },
        inventoryTransactionSaved() {
            this.refresh();
            this.$bvModal.hide('inventoryTransaction-modal');
        }
    },
    data: function() {
        return {
            fields: [
                { key: 'id', label: '#' },
                { key: 'source', label: 'Source' },
                { key: 'target', label: 'Target' },
                { key: 'products', label: 'Products' },
                { key: 'actions', label: '' }
            ],
            selectedInventoryTransactionId: null
        };
    }
}
</script>
