<template>
    <div>
        <loader v-if='loading' />
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>
            <b-form-group label="Name">
                <b-form-input v-model="lead.name" :state="states.name" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.name' />
            </b-form-group>

            <b-form-group label="Phone Number">
                <b-form-input v-model="lead.phone" :state="states.phone" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.name' />
            </b-form-group>

            <b-form-group label="User">
                <users-select v-model='lead.user_id' :returnId='true' />
            </b-form-group>

            <b-form-group label="Branch">
                <branches-select v-model='lead.nearst_branch_id' :returnId='true' />
            </b-form-group>

            <b-form-group label="Section">
                <sections-select v-model='lead.section_id' :returnId='true' />
            </b-form-group>

            <b-form-group label="How do patient know about us?">
                <options-select v-model='lead.knowledge_option_id' :returnId='true' />
            </b-form-group>

            <b-form-group label="Nationality">
                <b-form-input v-model="lead.nationality" :state="states.nationality" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.nationality' />
            </b-form-group>

            <b-form-group label="Insurance">
                <insurance-providers-select v-model='lead.insurance_provider_id' :returnId='true' />
            </b-form-group>
            <b-form-group label="Source URL">
                <b-form-input v-model="lead.source_url" :state="states.source_url" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.source_url' />
            </b-form-group>
            <b-form-group label="Other Notes">
                <b-form-textarea v-model="lead.notes" :state="states.notes" type="text" placeholder="">
                </b-form-textarea>
                <FormGroupError :errors='errors.notes' />
            </b-form-group>
            <b-form-group>
                <b-form-checkbox v-model='lead.is_insured' value='1' unchecked-value="0">
                    <b>Insured</b>
                </b-form-checkbox>
            </b-form-group>

            <b-form-group>
                <b-form-group label="Status">
                    <b-form-radio-group v-model="lead.status"
                        :options="['pending', 'follow up', 'booked', 'not interested']"></b-form-radio-group>
                </b-form-group>
            </b-form-group>

            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
import InsuranceProvidersSelect from '../../components/InsuranceProvidersSelect.vue';
export default {
    components: { InsuranceProvidersSelect },
    extends: BaseForm,
    data: function () {
        return {
            lead: {
                not_interested: 0,
                is_insured: 0,
            }
        }
    },
    methods: {
        load: function (id) {
            return this.apiRequest(apiClient => apiClient.get('leads/' + id))
                .then(response => this.lead = response);
        },
        save: function (apiClient) {
            if (this.id) {
                return apiClient.put('leads/' + this.id, this.lead);
            } else {
                return apiClient.post('leads', this.lead);
            }
        },
        afterSave: function () {
            this.$emit('saved', 1);
        }
    },
}
</script>