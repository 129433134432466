<template>
    <div>

        <ListView title="Doctors List" :collection="collection" :fields="fields" :loading="loading">
            <template v-slot:actions>
                <b-button  v-if='can("doctors.create")' v-b-modal.doctor-modal @click="selectedDoctorId = null" variant='primary' class='mr-2'>
                    Create Doctor
                </b-button>

            </template>

            <template v-slot:filter>
                <div class='form'>
                    <div class='form-row'>
                        <div class='form-group col-md-4'>
                            <b-form-input v-model="filter.keyword" placeholder="search ..."></b-form-input>
                        </div>
                    </div>
                </div>
            </template>

            <template v-slot:cell(balance)='data'>
                <div v-for='(financialAccount, index) in data.item.financial_accounts' :key='index'>
                    <div class='badge badge-light'>
                        {{ financialAccount.branch ? financialAccount.branch.name : '' }}
                    </div>
                    <div v-if='financialAccount.balance < 0' class='badge badge-danger'>
                        {{ financialAccount.balance | currency}}
                    </div>
                    <div v-if='financialAccount.balance > 0' class='badge badge-success'>
                        {{ financialAccount.balance | currency}}
                    </div>
                    <div v-if='financialAccount.balance == 0' class='badge badge-light'>
                        {{ 0 | currency }}
                    </div>
                </div>
            </template>

            <template v-slot:cell(working_hours)='data'>
                <div v-for='(workingHour, index) in data.item.working_hours' :key='index'>
                    <h4>
                    <div class='badge badge-light'>
                        {{ workingHour.branch.name }}
                    </div>
                    </h4>
                    <button class='badge badge-xs badge-primary mr-2' @click='editWorkingHour(workingHour.id)'>
                        {{ workingHour.from }}
                        -
                        {{ workingHour.to }}
                    </button>
                    <button  v-if='can("working_days.edit")' class='btn btn-xxs btn-success mr-2' @click='editWorkingHour(workingHour.id)'>
                        <i class='fa fa-edit'></i>
                    </button>
                    <button v-if='can("working_days.delete")' class='btn btn-xxs btn-danger' @click='removeWorkingHour(workingHour.id)'>x</button>
                    <div>
                        <div class='badge badge-primary badge-xs m-1' v-for='(day, index) in workingHour.week_days' :key='index'>
                            {{ weekDays[day - 1] }}
                        </div>
                    </div>
                    <hr>
                </div>
                <button  v-if='can("working_days.edit")' class='btn btn-xxs btn-success' @click='addWorkingHour(data.item.worker_id)'>+</button>
            </template>
                    
            <template v-slot:cell(actions)='data'>
				<button v-if='can("accounts.create")' class='btn btn-xs btn-primary m-2' @click="modal('cashPaymentForm', {entityType: 'Doctor', entityId: data.item.id}, 'refresh')">Create Cash Payment</button>
                <b-button  v-if='can("doctors.edit")'  v-b-modal.doctor-modal class='mr-2' @click="selectedDoctorId = data.item.id">Edit</b-button>
                <router-link :to="{ name: 'DoctorAppointments', params: {id: data.item.id}}" class='btn btn-success btn-xs m-2'>View</router-link>
            </template>
                    
        </ListView>
        
        <b-modal :no-close-on-backdrop=true id="doctor-modal" hide-footer size='xl'  title="Doctor Form">
            <DoctorForm @saved='doctorSaved' :id='selectedDoctorId'></DoctorForm>        
        </b-modal>

        <b-modal :no-close-on-backdrop=true id="working-hour-modal" hide-footer title="Working Hour Form">
            <WorkingHourForm @saved='workingHourSaved'  :workerId='selectedWorkerId' :id='selectedWorkingHourId'></WorkingHourForm>        
        </b-modal>
		<Modals :modalName='currentModal' :modalData='currentModalData' @saved='modalCallback' @closed='modalClosed'/>
    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import DoctorForm from './DoctorForm';
import WorkingHourForm from './WorkingHourForm';

export default {
    extends: BaseList,
    components: {DoctorForm, WorkingHourForm},
    methods: {
        load: function (apiClient, cancelToken) {
            return apiClient.get('doctors', this.filter, cancelToken);
        },
        doctorSaved(doctor) {
            if (this.selectedDoctorId) {
                this.refresh();
                this.$bvModal.hide('doctor-modal');
            } else {
                this.selectedWorkerId = doctor.worker_id;
                this.$bvModal.hide('doctor-modal');
                this.$bvModal.show('working-hour-modal');
            }
        },
        workingHourSaved() {
            this.refresh();
            this.$bvModal.hide('working-hour-modal');
        },
        editWorkingHour(id) {
            if (!this.can("working_days.edit")) {
                return;
            }
            this.selectedWorkingHourId = id;
            this.$bvModal.show('working-hour-modal');
        },
        addWorkingHour(workerId) {
            this.selectedWorkerId = workerId;
            this.selectedWorkingHourId = null;
            this.$bvModal.show('working-hour-modal');
        },
        removeWorkingHour(id) {
            this.apiRequest(apiClient => apiClient.delete('working-hours/'+id))
            .then(() => this.refresh());
        }
    },
    data: function() {
        return {
            fields: [
                { key: 'id', label: '#' },
                { key: 'name', label: 'Name' },
                { key: 'balance', label: 'Balance' },
                { key: 'fees_percentage', label: 'Fees Percentage' },
                { key: 'working_hours', label: 'Working Hours' },
                { key: 'actions', label: '' }
            ],
            selectedDoctorId: null,
            selectedWorkingHourId: null,
            selectedWorkerId: null,
            weekDays: [
                'Saturday',
                'Sunday',
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thuresday',
                'Friday',
            ]
        };
    }
}
</script>
