<template>
    <div>
        <ListView title="Workers List" :modalObject='currentModalObject' >
            <template v-slot:actions>
                <b-button @click="modal('workerForm', {}, 'refreshTable')" variant='primary' class='mr-2'>
                    Create Worker
                </b-button>

            </template>

            <template v-slot:filter>
                <div class='form'>
                    <div class='form-row'>
                        <div class='form-group col-md-4'>
                            <b-form-input v-model="filter.keyword" placeholder="search ..."></b-form-input>
                        </div>
                    </div>
                </div>
            </template>

			<template v-slot:table>
				<WorkersListTable :filter='filter' ref='tableList' :viewType='viewType'></WorkersListTable>
			</template>
                    
        </ListView>
    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import WorkersListTable from '@/components/Lists/WorkersListTable';

export default {
    extends: BaseList,
    components: {WorkersListTable},
    methods: {
        refreshTable () {
            this.$refs.tableList.refresh();
        }
    }
}
</script>
