<template>
    <div>
        <base-list :collection='collection' :loading='loading' :fields='fields' :modalObject='currentModalObject' @pageChanged='pageChanged'>
            
            <template v-slot:cell(causer)='data'>
                <div>
                    <div class='badge badge-light' v-if='data.item.causer'>
                        <i class='fa fa-user'></i>
                        {{ data.item.causer.name }}
                    </div>
                </div>
            </template>
            <template v-slot:cell(subject)='data'>
                <div>
                    <div class='badge badge-light'>{{ data.item.subject_type }} #{{ data.item.subject_id }}</div>
                </div>
            </template>

            <template v-slot:cell(properties)='data'>
                <div class='badge' :class='descriptionClass(data.item.description)'>
                    {{ data.item.description }}
                </div>
                <div>
                    <pre>{{ data.item.properties}}</pre>
                </div>
            </template>
            <template v-slot:cell(created_at)='data'>
                {{ data.item.created_at | datetime }}
            </template>
            
        </base-list>
    </div>
</template>

<script>
import BaseList from './BaseList.vue';
import BaseListMethods from './BaseListMethods';

export default {
	props: ['viewType'],
    components: { BaseList },
    extends: BaseListMethods,
    data() {
        return {
			fields: [
                { key: 'id', label: 'ID' },
                { key: 'causer', label: 'User' },
                { key: 'subject', label: 'Subject' },
                { key: 'properties', label: 'Changes' },
                { key: 'created_at', label: 'Time' },
			]
        }
    },
	methods: {
        load: function (apiClient, cancelToken, filter) {
			return apiClient.get('user-logs', filter, cancelToken);
		},
        descriptionClass: function (description) {
            switch (description) {
                case 'created':
                    return 'badge-success';
                case 'updated':
                    return 'badge-warning';
                case 'deleted':
                    return 'badge-danger';
                default :
                    return 'badge-light';
            }
        }
    },
    watch: {

		// viewType: {
		// 	immediate: true,
		// 	handler: function () {
		// 	}
		// },
    }
}
</script>