<template>
    <div>
        <div v-if='patient'>
            <b-row>
                <b-col cols='9'>
                    <h3><i class='fa fa-hospital-user'></i> {{ patient.name }}</h3>
                </b-col>
                <b-col>
                    <h4 v-if='can("patients.view_contact_details")'>
                        <whatsapp-link :phone='patient.phone' class='m-2'>
                            <div class='badge badge-success'>
                                <i class='fab fa-whatsapp'></i>
                                message Whatsapp
                            </div>
                        </whatsapp-link>

                        <call-phone :phone='patient.phone' class='m-2'>
                            <div class='badge badge-success'>
                                <i class='fa fa-phone'></i>
                                call
                            </div>
                        </call-phone>
                    </h4>
                </b-col>
            </b-row>
            <button v-if='can("patients.delete")' @click='deletePatient' class='btn btn-danger btn-xs ml-2'>delete</button>
            <button v-if='can("patients.merge")' @click='modal("mergePatientsForm", {"targetPatient": patient}, "refresh")' class='btn btn-danger btn-xs ml-2'>Merge</button>
            <div class='profile-tab'>
                <div class='custom-tab-1'>
                    <b-tabs content-class="mt-3">
                        <b-tab title="Active" active lazy>
                            <ActiveBoard :patient='patient'></ActiveBoard>
                        </b-tab>
                        <b-tab title="Active Services" lazy>
                            <b-card>
                                
                            <patient-services-map-view :patientId='patient.id'></patient-services-map-view>
                            </b-card>
                        </b-tab>
                        <!-- <b-tab title="Information"><p>Contact Information</p></b-tab>
                        <b-tab title="Medical Records"><p>I'm the second tab</p></b-tab> -->
                        <b-tab title="Appointments" v-if='can("appointments.list")' lazy>
                            <AppointmentsList :patientId='patient.id'></AppointmentsList>
                        </b-tab>
                        <b-tab title="Services" v-if='can("patient_services.list")' lazy>
                            <PatientServicesList :patientId='patient.id' viewType='patientView'></PatientServicesList>
                        </b-tab>
                        <b-tab title="External Services" v-if='can("patient_services.list")' lazy>
                            <patient-external-services-list :patientId='patient.id' viewType='patientView'></patient-external-services-list>
                        </b-tab>
                        <b-tab title="Medical Records" v-if='can("patient_services.list")' lazy>
                            <medical-records-list :patientId='patient.id' viewType='patientView'></medical-records-list>
                        </b-tab>
                        <b-tab title="Notes" v-if='can("patient_services.list")' lazy>
                            <patient-notes-list :patientId='patient.id'></patient-notes-list>
                        </b-tab>
                        <b-tab title="Audit Notes" v-if='can("audit_notes.list")' lazy>
                            <audit-notes-list :patientId='patient.id'></audit-notes-list>
                        </b-tab>
                        <b-tab title="Insurance Approvals" v-if='can("insurance_approvals.list")' lazy>
                            <InsuranceApprovalsList :patientId='patient.id'></InsuranceApprovalsList>
                        </b-tab>
                        <b-tab title="Insurances" v-if='can("insurance_approvals.list")' lazy>
                            <PatientInsurancesList :patientId='patient.id'></PatientInsurancesList>
                        </b-tab>
                        <b-tab title="Invoices" lazy>
                            <InvoicesList :patientId='patient.id'></InvoicesList>
                        </b-tab>
                        <b-tab title="Payments" v-if='can("cash_payments.list")' lazy>
                            <cash-payments-list :patientId='id'></cash-payments-list>
                        </b-tab>
                        <b-tab title="Accounts" v-if='can("accounts.list")' lazy>
                            <Accounts :patient='patient'></Accounts>
                        </b-tab>
                        <b-tab title="Transactions" v-if='can("cash_payments.list")' lazy>
                            <transactions-list :entityId='patient.id' entityType='Patient'></transactions-list>
                        </b-tab>
                        <b-tab title="Logs" v-if='can("users.list")' lazy>
                            <user-logs-list :patientId='id'></user-logs-list>
                        </b-tab>
                    </b-tabs>
                </div>
            </div>
        </div>

		<Modals :modalName='currentModal' :modalData='currentModalData' @saved='modalCallback' @closed='modalClosed'/>
    </div>
</template>

<script>

import ActiveBoard from './ActiveBoard';
import PatientServicesList from '../Services/PatientServicesList';
import AppointmentsList from '../Appointments/AppointmentsList';
import Accounts from './Accounts';
import InsuranceApprovalsList from '../InsuranceApprovals/InsuranceApprovalsList';
import PatientInsurancesList from '../PatientInsurances/PatientInsurancesList';
import InvoicesList from '../Invoices/InvoicesList';
import MedicalRecordsList from '../MedicalRecords/MedicalRecordsList.vue';
import PatientNotesList from '../PatientNotes/PatientNotesList.vue';
import PatientExternalServicesList from '../PatientExternalServices/PatientExternalServicesList.vue';
import UserLogsList from '../UserLogs/UserLogsList.vue';
import CashPaymentsList from '../CashPayments/CashPaymentsList.vue';
import TransactionsList from '../Transactions/TransactionsList.vue';
import AuditNotesList from '../AuditNotes/AuditNotesList.vue';
import WhatsappLink from '../../components/WhatsappLink.vue';
import PatientServicesMapView from './PatientServicesMapView.vue';

export default {
    components:{
        ActiveBoard,
        AppointmentsList,
        Accounts,
        PatientServicesList,
        InsuranceApprovalsList,
        PatientInsurancesList,
        InvoicesList,
        MedicalRecordsList,
        PatientNotesList,
        PatientExternalServicesList,
        UserLogsList,
        CashPaymentsList,
        TransactionsList,
        AuditNotesList,
        WhatsappLink,
        PatientServicesMapView
    },
    props: ['id'],
    mounted() {
        this.apiRequest(
            (apiClient) => {
                return apiClient.get('patients/'+this.id)
                    .then(patient => {
                        this.patient = patient;
                    });
            }
        );
    },
    methods: {
        deletePatient() {
            this.confirm().then(
                accept => {
                    if (accept) {
                        this.apiRequest(apiClient => apiClient.delete('patients/'+this.id))
                            .then(() => this.$router.push({name: 'Patients'}));
                    }
                }
            );
        }
    },
    data: function () {
        return {
            patient: null
        }
    }
}
</script>