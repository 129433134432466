<template>
    <div>
        <base-list :collection='collection' :loading='loading' :fields='fields' :modalObject='currentModalObject' @pageChanged='pageChanged'>
            
			<template v-slot:cell(actions)='data'>
				<button class='btn btn-xs btn-primary m-2' @click="modal('urlTagForm', {id: data.item.id}, 'refresh')">Edit</button>
				<button class='btn btn-danger btn-xs m-1' @click="deleteUrlTag(data.item)">x</button>
            </template>
        </base-list>
    </div>
</template>

<script>
import BaseList from './BaseList.vue';
import BaseListMethods from './BaseListMethods';

export default {
	props: ['viewType'],
    components: { BaseList },
    extends: BaseListMethods,
    data() {
        return {
			fields: [
                { key: 'id', label: 'ID' },
                { key: 'name', label: 'Name' },
                { key: 'url_part', label: 'Url Part' },
                { key: 'actions', label: 'Actions' },
			]
        }
    },
	methods: {
        load: function (apiClient, cancelToken, filter) {
			return apiClient.get('url-tags', filter, cancelToken);
		},
        deleteUrlTag(urlTag) {
            this.confirmAction(
                () => {
                    return this.apiRequest(apiClient => apiClient.delete('url-tags/'+urlTag.id));
                }, urlTag
            );
        }
    },
    watch: {

		// viewType: {
		// 	immediate: true,
		// 	handler: function () {
		// 	}
		// },
    }
}
</script>