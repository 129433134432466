<template>
    <div>
        <ListView title="Source of Payments List" :modalObject='currentModalObject'>
            <template v-slot:actions>
                <b-button @click="modal('sourceOfPaymentForm', {}, 'refreshTable')" variant='primary' class='mr-2'>
                    Create Source of Payment
                </b-button>

            </template>

            <template v-slot:filter>
                <div class='form'>
                    <div class='form-row'>
                        <div class='form-group col-md-4'>
                            <b-form-input v-model="filter.keyword" placeholder="search ..."></b-form-input>
                        </div>
                    </div>
                </div>
            </template>

            <template v-slot:table>
                <SourceOfPaymentsListTable :filter='filter' ref='tableList' :viewType='viewType'>
                </SourceOfPaymentsListTable>
            </template>

        </ListView>
    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import SourceOfPaymentsListTable from '@/components/Lists/SourceOfPaymentsListTable.vue';

export default {
    extends: BaseList,
    components: { SourceOfPaymentsListTable },
    methods: {
        refreshTable() {
            this.$refs.tableList.refresh();
        }
    }
}
</script>
