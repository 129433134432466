<template>
    <div>
        <ListView title="MedicalRecords List" :modalObject='currentModalObject' :hideHeader='hideHeader() ? true : false'>
            <template v-slot:filter>
                <div class='form'>
                    <div class='form-row'>
                        <div class='form-group col-md-4'>
                            <b-form-input v-model="filter.keyword" placeholder="search ..."></b-form-input>
                        </div>
                    </div>
                </div>
            </template>

			<template v-slot:table>
				<MedicalRecordsListTable :filter='filter' ref='tableList' :viewType='viewType'></MedicalRecordsListTable>
			</template>
                    
        </ListView>
    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import MedicalRecordsListTable from '@/components/Lists/MedicalRecordsListTable';

export default {
    extends: BaseList,
    components: {MedicalRecordsListTable},
    props: ['patientId'],
    methods: {
        refreshTable () {
            this.$refs.tableList.refresh();
        },
		hideHeader: function () {
			return this.patientId ? true : false;
		}
    },
    watch: {
		patientId: {
			immediate: true,
			handler: function () {
				if (this.patientId) {
					this.viewType = 'patientView';
					this.filter.patient_id = this.patientId;
				}
			}
		},
    }
}
</script>
