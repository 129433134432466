<template>
    <div>

        <ListView title="Services List" :collection="collection" @pageChanged='pageChanged' :fields="fields"
            :loading="loading" hasFilterActions='ture'>
            <template v-slot:actions>
                <b-button v-if='can("services.create")' v-b-modal.service-modal @click="selectedServiceId = null"
                    variant='primary' class='mr-2'>
                    Create Service
                </b-button>

            </template>

            <template v-slot:filter>
                <div class='form'>
                    <div class='form-row'>
                        <div class='form-group col-md-4'>
                            <b-form-input v-model="filter.keyword" placeholder="search ..."></b-form-input>
                        </div>
                    </div>
                </div>
            </template>

            <template v-slot:filter_actions>
                <authorized-url v-if='can("services.export")' class='btn btn-primary ml-2 float-right' url='services/export'
                    :params='filter' target="_blank">
                    <i class="fa fa-file-excel"></i>
                    Export
                </authorized-url>
            </template>

            <template v-slot:cell(price)='data'>
                {{ data.item.price | currency }}
            </template>

            <template v-slot:cell(cost_price)='data'>
                <b-button v-if='can("services.edit")' size="xs" variant="primary"
                    @click="modal('serviceProductsForm', { id: data.item.id }, 'refresh')">{{
                        data.item.cost_price | currency }}</b-button>
                <span v-else>{{ data.item.cost_price | currency }}</span>
            </template>

            <template v-slot:cell(status)='data'>
                <div v-if='data.item.is_active' class='badge badge-success'>
                    Active
                </div>
                <div v-else class='badge badge-light'>
                    Inactive
                </div>
            </template>

            <template v-slot:cell(actions)='data'>
                <b-button v-if='can("services.edit")' v-b-modal.service-modal class='mr-2'
                    @click="selectedServiceId = data.item.id">Edit</b-button>
            </template>

        </ListView>

        <b-modal :no-close-on-backdrop=true id="service-modal" size='lg' hide-footer title="Service Form">
            <ServiceForm @saved='serviceSaved' :id='selectedServiceId'></ServiceForm>
        </b-modal>

        <Modals :modalName='currentModal' :modalData='currentModalData' @saved='modalCallback' @closed='modalClosed'>
        </Modals>


    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import ServiceForm from './ServiceForm';
import AuthorizedUrl from '../../components/AuthorizedUrl.vue';

export default {
    extends: BaseList,
    components: { ServiceForm, AuthorizedUrl },
    methods: {
        load: function (apiClient, cancelToken) {
            return apiClient.get('services', { ...this.filter, with_stock_price: 1 }, cancelToken);
        },
        serviceSaved() {
            this.refresh();
            this.$bvModal.hide('service-modal');
        }
    },
    data: function () {
        return {
            fields: [
                { key: 'id', label: '#' },
                { key: 'name', label: 'Name' },
                { key: 'section.name', label: 'Section' },
                { key: 'price', label: 'Price' },
                { key: 'cost_price', label: 'Cost' },
                { key: 'status', label: 'Status' },
                { key: 'actions', label: '' }
            ],
            selectedServiceId: null
        };
    }
}
</script>
