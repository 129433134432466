<template>
    <div class="card">
        <div class="card-header">
            <h4 class="card-title">Inventory Movement Form</h4>
        </div>
        <div class="card-body">
            <loader v-if='loading'/>
            <b-form class='basic-form' @submit="submit" novalidate>
                
                <div class='form-row mb-2' v-for="(orderLine, index) in orderLines" :key="index">
                    <b-col>
                        <b-form-group label="Product">
                            <ProductsSelect v-model='orderLine.product' v-on:input='product => orderLine.product_id = product.id'/>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group label="Quantity">
                            <input type="text" v-model='orderLine.quantity' class="form-control" placeholder="Quantity">
                        </b-form-group>
                    </b-col>
                    <b-col align-self="center">
                        <b-button variant='danger' @click='removeRow(index)'>x</b-button>
                    </b-col>

                </div>
                 <!-- excel file -->
        <div class="row">
            <b-col cols="5">
              <b-form-group label="Upload Products File">
                <b-form-file
                  v-model="excelFile"
                  drop-placeholder="Drop file here..."
                  accept=".xlsx, .xls"
                  @input="handleExcelFileChange"
                ></b-form-file>
              </b-form-group>
            </b-col>
            <b-col align-self="center">
              <b-button
                variant="success"
                :disabled="!excelFile"
                @click="uploadExcelFile"
                v-if="!uploading"
                >Upload</b-button
              >
  
              <b-button v-if='uploading' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                uploading...
            </b-button>
            </b-col>
          </div>
                <div class="row">
                    <b-col cols='5'>
                        <b-form-group label='select products to add'>
                            <ProductsSelect v-model='toAddProducts' multiple :closeOnSelect='false'/>
                        </b-form-group>
                    </b-col>
                    <b-col align-self="center">
                        <b-button variant='success' @click='addRows'>+ Add</b-button>
                    </b-col>

                    <b-col>
                        <authorized-url v-if="can('products.export')"  class='btn btn-primary ml-2 float-right' url='products/export' target="_blank">
                        <i class="fa fa-file-excel"></i>
                        Download Products Sheet
                        </authorized-url>
                    </b-col>
                </div>
                <div class='form-group'>
                    <label>Note</label>
                    <textarea class='form-control' cols="30" rows="2"></textarea>
                </div>
                <div v-if='!this.id || (inventoryTransaction.source_inventory && inventoryTransaction.target_inventory)'>
                    <b-form-group label="Source Inventory">
                        <InventoriesSelect v-model='inventoryTransaction.source_inventory' v-on:input='inventory => inventoryTransaction.source_inventory_id = inventory.id'/>
                        <FormGroupError :errors='errors.source_inventory_id'/>
                    </b-form-group>
                    <b-form-group label="Target Inventory">
                        <InventoriesSelect v-model='inventoryTransaction.target_inventory' v-on:input='inventory => inventoryTransaction.target_inventory_id = inventory.id'/>
                        <FormGroupError :errors='errors.target_inventory_id'/>
                    </b-form-group>
                </div>

                <button type="submit" class="btn btn-primary">Save</button>
            </b-form>
        </div>
    </div>
</template>
<script>
import BaseForm from '@/views/BaseForm';
import AuthorizedUrl from '../../components/AuthorizedUrl.vue';
export default {
  components: { AuthorizedUrl },
    extends: BaseForm,
    data: function () {
        return {
            inventoryTransaction: {
            },
            orderLines: [],
            toAddProducts: [],
            excelFile: null,
            uploading: false,
        };
    },
    methods: {
        load: function(id) {
            return this.apiRequest(apiClient => apiClient.get('inventory-transactions/'+id))
                .then(response => {
                    this.inventoryTransaction = response;
                    this.orderLines = response.products;
                    console.log(this.orderLines);
                });
        },
        removeRow(index) {
            this.orderLines.splice(index, 1);
        },
        addRows() {
            if (this.toAddProducts.length > 0) {
                this.toAddProducts.forEach(
                    item => this.orderLines.push({product: item})
                );
                this.toAddProducts = [];
            } else {
                this.orderLines.push({});
            }
        },
        save: function (apiClient) {
            this.inventoryTransaction.items = this.orderLines.map(item => {
                return {
                    product_id: item.product.id,
                    quantity: item.quantity
                }
            });
            if (this.id) {
                return apiClient.put('inventory-transactions/'+this.id, this.inventoryTransaction);
            } else {
                return apiClient.post('inventory-transactions', this.inventoryTransaction);
            }
        },
        afterSave: function (response) {
            this.$emit('saved', response.id);
            this.$router.push({name: 'InventoryTransactions'});
        },

        handleExcelFileChange: function(excelFile) {
            this.excelFile = excelFile;
        },
        uploadExcelFile: function() {
            if (this.excelFile) {
                const formData = new FormData();
                formData.append("file", this.excelFile);
                this.uploading = true;
                this.apiRequest((apiClient) =>
                apiClient.post("purchases/import-purchases", formData, {
                    headers: {
                    "Content-Type": "multipart/form-data",
                    },
                })
                ).then((response) => {
                this.uploading = false;
                this.orderLines = [...this.orderLines, ...response];
                });
            } else {
                console.log("file not in field");
            }
        },
    },
}
</script>