<template>
    <div class="card">
        <div class="card-header">
            <h4 class="card-title">Reqeusted Service Form</h4>
        </div>
        <div class="card-body">
            <b-form class='basic-form' @submit="checkForm">
                <div class='form-group'>
                    <label>Patient</label>
                    <v-select :options="patients"></v-select>
                </div>
                <div class='form-group'>
                    <label>Service</label>
                    <v-select :options="services"></v-select>
                </div>
                <div class='form-group'>
                    <label>Vendor</label>
                    <v-select :options="vendors"></v-select>
                </div>
                <div class='form-group'>
                    <label>Note</label>
                    <textarea class='form-control' cols="30" rows="2"></textarea>
                </div>
                <div class='form-group'>
                    <label>Requested Service</label>
                    <v-select :options="vendors"></v-select>
                </div>
                <button type="submit" class="btn btn-primary">Save</button>
            </b-form>
        </div>
    </div>
</template>
<script>
export default {
    data: function () {
        return {
            patients: [
                'Mahmoud Magdy',
                'Omar Osama'
            ],
            services: [
                'Crown t1',
                'Crown t2',
            ],
            vendors: [
                'Vendor 1',
                'Vendor 2',
            ],
            type: [
                'Implant',
                'Crown',
            ],
        };
    },
    methods: {
        checkForm: function (e) {
            e.preventDefault();
            this.saving = true;
            this.$router.push({name: 'Payments'});
        }
    },
}
</script>