import axios from 'axios';

class ClinicClient {
    constructor() {
        var baseUrl = process.env.VUE_APP_BASE_URL;
        this.baseurl = baseUrl;
        this.httpClient = axios.create({
            baseURL: this.baseurl
        });
        this.cancelToken = this.cancelTokenSource();
        this.pendingRequests = [];
    }

    setToken(token) {
        this.httpClient.defaults.headers = {
            Authorization: 'Bearer ' + token,
        };
        
    }

    async getNoCancel(endpoint, params) {
        return this.httpClient.get(endpoint, {
            params: params
        }).then(response => {
            return response.data;
        });
    }
    
    async get(endpoint, params, cancelTokenSource) {
        if (cancelTokenSource) {
            this.addCancelToken(cancelTokenSource);
        }
        
        return this.httpClient.get(endpoint, {
            params: params,
            cancelToken: cancelTokenSource ? cancelTokenSource.token : this.cancelToken.token
        }).then(response => {
            if (cancelTokenSource) {
                this.removeCancelToken(cancelTokenSource);
            }
            return response.data;
        });
    }

    async post(endpoint, body) {
        return this.httpClient.post(endpoint, body)
            .then(response => response.data);
    }

    async postFormData(endpoint, formData) {
        return this.httpClient.post(endpoint, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "X-Requested-With": "XMLHttpRequest"
            }
        });
    }

    async postBlob(endpoint, body) {
        return this.httpClient.post(endpoint, body, {responseType: 'blob'});
    }

    async put(endpoint, body) {
        return this.httpClient.put(endpoint, body)
            .then(response => response.data);
    }

    async delete(endpoint) {
        return this.httpClient.delete(endpoint)
            .then(response => response.data);
    }

    cancelTokenSource() {
        return axios.CancelToken.source();
    }

    cancel(cancelTokenSource) {
        this.removeCancelToken(cancelTokenSource);
        cancelTokenSource.cancel();
    }

    cancelAll() {
        this.cancelToken.cancel();
        this.cancelToken = this.cancelTokenSource();
        this.pendingRequests.forEach(item => item.cancel());
        this.pendingRequests = [];
    }

    addCancelToken(cancelTokenSource) {
        this.pendingRequests.push(cancelTokenSource);
    }

    removeCancelToken(cancelTokenSource) {
        var index = null;
        for (var i=0; i<this.pendingRequests.length; i++ ) {
            if (this.pendingRequests[i].token == cancelTokenSource.token) {
                index = i;
                break;
            }
        }

        if (index !== null) {
            this.pendingRequests.splice(index, 1);
        }
    }
}

export default ClinicClient;
