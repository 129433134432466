<template>
    <div>
        <loader v-if='loading'/>
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>
            <b-form-group label="Name">
                <b-form-input v-model="fingerprintDevice.name" :state="states.name" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.name'/>
            </b-form-group>

            <b-form-group label="Branch">
                <BranchesSelect v-model='fingerprintDevice.branch' v-on:input='branch => fingerprintDevice.branch_id = branch.id'/>
            </b-form-group>

            <b-form-group label="Host">
                <HostsSelect v-model='fingerprintDevice.host' v-on:input='host => fingerprintDevice.host_id = host.id'/>
            </b-form-group>

            <b-form-group label="Port">
                <b-form-input v-model="fingerprintDevice.port" :state="states.port" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.port'/>
            </b-form-group>
            
            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <div class='m-2'>
                <div @click='getDeviceInfo' class="btn btn-success"> <i class='fa fa-plug'></i> Test connection</div>
            </div>
            <div class='m-2' v-if="deviceInfo.serial_number">
                <b>
                {{ deviceInfo.serial_number }}
                </b>
                <br>
                <b>
                {{ deviceInfo.device_name }}
                </b>
                <br>
                <b>
                {{ deviceInfo.device_version }}
                </b>
                <br>
                <b>
                {{ deviceInfo.firmware_version }}
                </b>
                <br>
                <b>
                {{ deviceInfo.os_version }}
                </b>
            </div>
            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
export default {
    extends: BaseForm,
    data: function () {
        return {
            fingerprintDevice: {
            },
            deviceInfo: {},
            connected: false
        }
    },
    methods: {
        load: function(id) {
            return this.apiRequest(apiClient => apiClient.get('fingerprint-devices/'+id))
                .then(response => this.fingerprintDevice = response);
        },
        save: function (apiClient) {
            var fingerprintDevice = {
                name: this.fingerprintDevice.name,
                branch_id: this.fingerprintDevice.branch_id,
                host_id: this.fingerprintDevice.host_id,
                port: this.fingerprintDevice.port,
            };
            if (this.id) {
                return apiClient.put('fingerprint-devices/'+this.id, fingerprintDevice);
            } else {
                return apiClient.post('fingerprint-devices', fingerprintDevice);
            }
        },
        afterSave: function () {
            this.$emit('saved', 1);
        },
        getDeviceInfo() {
            return this.apiRequest(
                apiClient => apiClient.get('fingerprint-devices/device-info', {
                    "host_id": this.fingerprintDevice.host_id,
                    "port": this.port
                }).then(deviceInfo => {
                    this.deviceInfo = deviceInfo;
                    this.connected = true;
                })
            );
            
        }
    },
}
</script>