<template>
    <div>
        {{ patientService.branch.name }}
        {{ patientService.patient.name }}
        {{ patientService.name }}
        {{ patientService.doctor.name }}
        {{ patientService.doctor_fees_percentage }}
        {{ patientService.doctor_fees_percentage }}

    </div>
</template>
<script>
export default {
    components:{
    },
    props: ['id'],
    mounted() {
        this.apiRequest(
            (apiClient) => {
                return apiClient.get('patient-services/'+this.id)
                    .then(patientService => {
                        this.patientService = patientService;
                    });
            }
        );
    },
    data: function () {
        return {
            patientService: null
        }
    }
}
</script>