<template>
    <div>
        <base-list :collection='collection' :loading='loading' :fields='fields' :modalObject='currentModalObject' @pageChanged='pageChanged'>
			
                <template v-slot:cell(date)='data'>
                    {{ data.item.date | date }}
                    <div class='badge badge-light' v-if='data.item.branch'>
                        {{ data.item.branch.name }}
                    </div>
                </template>

                <template v-slot:cell(total)='data'>
                    {{ data.item.total | currency }}
                </template>
                <template v-slot:cell(supplier)='data'>
                    <div v-if='data.item.supplier'>
                        <div>
                            {{ data.item.supplier.name }}
                        </div>
                    </div>
                </template>
                <template v-slot:cell(expenseType)='data'>
                    <div v-if='data.item.expense_type'>
                        <div>
                            {{ data.item.expense_type.name }}
                        </div>
                    </div>
                </template>
                <template v-slot:cell(status)='data'>
                    
                    <div v-if='data.item.is_draft' class='badge light badge-warning'>
                        Draft
                    </div>

                    <div v-else class='badge light badge-primary'>
                        <i class='fa fa-check'></i>
                        approved
                    </div>
                </template>
                <template v-slot:cell(items)='data'>
                    <div v-for='(purchaseItem, index) in data.item.purchase_items' :key='index'>
                        <div class='badge badge-success ml-2'>
                            {{ purchaseItem.product.name }}
                        </div>
                        <div class='badge badge-success ml-2' v-if="purchaseItem.brand">
                            {{ purchaseItem.brand.name }}
                        </div>
                        <div class='badge badge-success ml-2'>
                            {{ purchaseItem.item_price | currency}}
                        </div>
                        <div class='badge badge-success ml-2'>
                            X
                            {{ purchaseItem.quantity }}
                        </div>
                        <div class='badge badge-success ml-2'>
                            <i class='fa fa-coins'></i>
                            {{ purchaseItem.item_price * purchaseItem.quantity | currency}}
                        </div>
                    </div>
                    <div class='badge badge-light' v-if='data.item.notes'>
                    {{ data.item.notes }}
                    </div>
                </template>

                <template v-slot:cell(files)='data'>
                    <h4 v-if='data.item.file_group && data.item.file_group.files'>
                        <div 
                            v-for='(file,index) in data.item.file_group.files' :key='index'
                        >
                            <button
                                class='badge badge-light m-1'
                                @click='modal("fileForm", {file: file})'
                            >
                                <i class='fa fa-image'></i>
                                {{ file.name || "no name" | str_limit(20) }}
                            </button>
                        </div>
                    </h4>

                    <div v-else class='badge badge-light'>No Files</div>
                </template>
                <template v-slot:cell(actions)='data'>
                    <b-button @click="modal('purchaseForm', {id: data.item.id}, 'refresh')" variant="primary" class='btn btn-primary  btn-xs sharp mr-2'>
                        <i class='fa fa-edit'></i>
                    </b-button>
                    <button class='btn btn-danger btn-xs sharp' @click="confirmAction('deletePurchase', data.item, 'refresh')">
                        <i class='fa fa-remove'></i>
                    </button>
                    <button class='btn btn-success btn-xs m-1' @click="modal('transactionsList', {filter: {purchase_id: data.item.id}}, 'refresh')">View Transactions</button>
                </template>
        </base-list>
    </div>
</template>

<script>
import BaseListMethods from './BaseListMethods';

export default {
	extends: BaseListMethods,
    props: ['isExpenses', 'expenseGroup'],
    data: function () {
		return {			
            fields: [
                { key: 'id', label: '#' },
                { key: 'date', label: 'Date' },
                { key: 'supplier', label: 'Supplier' },
                { key: 'items', label: 'Items' },
                { key: 'expenseType', label: 'Expense Type' },
                { key: 'files', label: 'Files' },
                { key: 'total', label: 'Total' },
                { key: 'status', label: 'Status' },
                { key: 'actions', label: 'Actions' },
            ]
		};
	},
    isExpenses: function () {
        this.refresh();
    },
    expenseGroup: function () {
        this.refresh();
    },
	methods: {
        load: function (apiClient, cancelToken, filter) {
            var f = {expenses: this.isExpenses ? 1 : 0 , ...filter, expense_group: this.isExpenses ? this.expenseGroup : null};
			return apiClient.get('purchases', f, cancelToken);
		},
        deletePurchase: function (purchase) {
			return this.apiRequest(apiClient => apiClient.delete('purchases/'+purchase.id));
        }
	},
}
</script>