<template>
    <div>
        <ListView title="Attendance List" :modalObject='currentModalObject' >
            <template v-slot:actions>
                <b-button @click="pullAttendance()" variant='primary' class='mr-2'>
                    Pull Attendance
                </b-button>

            </template>

            <template v-slot:filter>
                <div class='form ml-3'>
                    <div class='form-row'>
                        
                        <div class='col-3'>
                            <b-form-group label="Date">
                                <v-date-picker v-model="filterDate">
                                    <template v-slot="{ inputValue, inputEvents }">
                                        <input
                                        class="bg-white border px-2 py-1 rounded"
                                        :value="inputValue"
                                        v-on="inputEvents"
                                        />
                                    </template>
                                </v-date-picker>
                                
                            </b-form-group>
                        </div>
                        <div class='col-3'>
                            <div class='form-group m-2'>
                                <label>Branch</label>
                                <BranchesSelect v-model='filter.branch_id' :returnId='true'/>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

			<template v-slot:table>
				<AttendanceWorkersListTable :filter='filter' ref='tableList' :viewType='viewType'></AttendanceWorkersListTable>
			</template>
                    
        </ListView>
    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import AttendanceWorkersListTable from '@/components/Lists/AttendanceWorkersListTable';

export default {
    extends: BaseList,
    components: {AttendanceWorkersListTable},
    data: function () {
        return {
            defaultFilter: {
                for_attendance: 1
            },
            filterDate: null
        }
    },
    methods: {
        refreshTable () {
            this.$refs.tableList.refresh();
        },
        refresh() {
            this.refreshTable();
        },
        pullAttendance () {
            this.confirmAction(
                () => this.apiRequest(apiClient => apiClient.post('attendance/pull'))
            );
        }
    },
	watch: {
		filterDate: function () {
			var date = this.filterDate ? this.date(this.filterDate) : '';
			
			this.filter = {
				... this.filter,
				date: date
			};
		},
        filter: {
            handler() {
				var filter = this.filter;
				if (filter.date) {
					this.filterDate = filter.date
				}
            },
            deep: true
        }
    }
}
</script>
