<template>
    <div>
        <ListView title="FingerprintUsers List" :modalObject='currentModalObject' >
            <template v-slot:actions>
                <b-button @click="confirmAction('pullUsers')" variant='primary' class='mr-2'>
                    Pull Users
                </b-button>
            </template>

            <template v-slot:filter>
                <div class='form'>
                    <div class='form-row'>
                        <div class='form-group col-md-4'>
                            <b-form-input v-model="filter.keyword" placeholder="search ..."></b-form-input>
                        </div>
                    </div>
                </div>
            </template>

			<template v-slot:table>
				<FingerprintUsersListTable :filter='filter' ref='tableList' :viewType='viewType'></FingerprintUsersListTable>
			</template>
                    
        </ListView>
    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import FingerprintUsersListTable from '@/components/Lists/FingerprintUsersListTable';

export default {
    extends: BaseList,
    components: {FingerprintUsersListTable},
    methods: {
        refresh () {
            if (this.$refs.tableList) {
                this.$refs.tableList.refresh();
            }
        },
        refreshTable () {
            this.$refs.tableList.refresh();
        },
        pullUsers() {
            return this.apiRequest(apiClient => apiClient.post('fingerprint-devices/pull-users'));
        }
    }
}
</script>
