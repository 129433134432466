<template>
    <div>
        <loader v-if='loading'/>
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>

            <h3>From</h3>
            <div v-if='sourcePatient'>
                <h4>
                    #{{ sourcePatient.id }}
                    {{ sourcePatient.name }}
                </h4>
            </div>
                        
            <div class='m-2'>
                <b-button @click="modal('selectPatientForm', {}, 'patientSelected')" variant='success' size="sm" class='mr-2'>Select Patient</b-button>
            </div>

            <h3>To</h3>
            <div v-if='targetPatient'>
                <h4>
                    #{{ targetPatient.id }}
                    {{ targetPatient.name }}
                </h4>
            </div>
            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' :disabled='!this.isValid' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
		<Modals :modalName='currentModal' :modalData='currentModalData' @saved='modalCallback' @closed='modalClosed'/>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
export default {
    extends: BaseForm,
    props: ["targetPatient"],
    data: function () {
        return {
            sourcePatient: null,
            isValid: false
        }
    },
    methods: {
        save: function (apiClient) {
            return apiClient.post('patients/merge', {
                source_patient_id: this.sourcePatient.id,
                target_patient_id: this.targetPatient.id,
            });
        },
        patientSelected: function (patient) {
            this.sourcePatient = patient;
        },
        afterSave: function () {
            this.$emit('saved', 1);
        },
        updateValid() {
            if (this.sourcePatient && this.targetPatient && this.sourcePatient.id != this.targetPatient.id) {
                this.isValid = true;
            } else {
                this.isValid = false;
            }
        }
    },
    watch: {
        sourcePatient: function () {
            this.updateValid();
        },
        targetPatient: function () {
            this.updateValid();
        }
    }
}
</script>