<template>
    <div class='card'>
        <div class='card-header'>
            <h5 class="card-title">Attendance</h5>
        </div>
        <div class='card-body'>
            <div class="table-responsive recentOrderTable">
                <b-table hover :items="items" :fields="fields">
                    <template v-slot:cell(total)='data'>
                        <div class='badge badge-success'>
                            {{ data.item.total }} EGP
                        </div>    
                    </template>
                    <template v-slot:cell(supplier)='data'>
                        <div>
                            {{ data.item.supplier }}
                        </div>
                        <div class='badge light badge-primary'>
                            Balance: 5,000.00
                        </div>
                    </template>
                    <template v-slot:cell(actions)>
                        <button class='btn btn-primary btn-xs sharp mr-2'>
                            <i class='fa fa-edit'></i>
                        </button>
                        <button class='btn btn-danger btn-xs sharp'>
                            <i class='fa fa-remove'></i>
                        </button>
                    </template>
                </b-table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: function() {
        return {
            items: [
                {date: "2020-10-05", check_in: "10:00 AM", check_out: "5:00 PM", name: 'Nora Omar'},
                {date: "2020-10-05", check_in: "10:10 AM", check_out: "5:00 PM", name: 'Mona Ahmed'},
            ],
            fields: [
                { key: 'id', label: '#' },
                { key: 'date', label: 'Date' },
                { key: 'name', label: 'Name' },
                { key: 'check_in', label: 'Check In' },
                { key: 'check_out', label: 'Check Out' }
            ]
        };
    }
}
</script>