<template>
    <div>
        <ListView title="Accounts List" :modalObject='currentModalObject' :collection="collection" :fields="fields"
            @pageChanged='pageChanged' :loading="loading">
            <template v-slot:actions>
                <b-button v-if='can("accounts.create")' v-b-modal.financial-account-modal
                    @click="selectedFinancialAccount = null" variant='primary' class='mr-2'>
                    Create Account
                </b-button>
                <b-button v-if='can("accounts.import")' v-b-modal.import-accounts-modal variant='primary' class='mr-2'>
                    Import Accounts
                </b-button>
                <b-button v-if='can("accounts.set_default_accounts")' v-b-modal.default-accounts-modal variant='primary'>
                    Default Accounts
                </b-button>
            </template>

            <template v-slot:filter>
                <div class='form'>
                    <div class='form-row'>
                        <div class='form-group col-md-4'>
                            <b-form-input v-model="filter.keyword" placeholder="account name"></b-form-input>
                        </div>
                    </div>
                    <b-row>
                        <b-col cols=3>
                            <b-form-group label="Accountable Type">
                                <v-select :options="types" v-model='filter.accountable_type'></v-select>
                            </b-form-group>
                        </b-col>
                        <b-col cols=3>
                            <b-form-group label="Branch">
                                <branches-select v-model='filter.branch_id' :returnId='true' />
                            </b-form-group>
                        </b-col>
                        <b-col cols=3>
                            <b-form-group label="Parent">
                                <financial-accounts-select v-model='filter.parent_id' :returnId='true' />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
            </template>
            <template v-slot:cell(type)="data">
                <div v-if='data.item.balance_type' class='badge badge-light m-2'>
                    {{ data.item.balance_type }} Balance
                </div>
            </template>

            <template v-slot:cell(parent)="data">
                <div v-if='data.item.parent'>
                    {{ data.item.parent.name }} <span class="badge badge-light">{{ data.item.parent.number }}</span>
                </div>
            </template>

            <template v-slot:cell(name)="data">

                <template v-if="data.item.accountable">
                    <div v-if='data.item.accountable_type == "Patient"'>
                        <div class='badge badge-light'>
                            <i class='fa fa-user-md'></i>
                            {{ data.item.accountable.name }}
                        </div>
                        <br>
                        <div class='badge badge-light'>Patient</div>
                    </div>
                    <div v-else-if='data.item.accountable_type == "Worker"'>
                        <div class='badge badge-light'>
                            <i class='fa fa-user-md'></i>
                            {{ data.item.accountable.name }}
                        </div>
                        <br>
                        <div class='badge badge-light'>Doctor</div>
                    </div>
                    <div v-else-if='data.item.accountable_type == "InsuranceProvider"'>
                        <div class='badge badge-light'>
                            <i class='fa fa-id-card-alt'></i>
                            {{ data.item.accountable.name }}
                        </div>
                        <br>
                        <div class='badge badge-light'>Insurance Provider</div>
                    </div>
                    <div v-else-if='data.item.accountable_type == "Supplier"'>
                        <div class='badge badge-light'>
                            <i class='fa fa-shopping-cart'></i>
                            {{ data.item.accountable.name }}
                        </div>
                        <br>
                        <div class='badge badge-light'>Supplier</div>
                    </div>
                </template>
                <div v-else>
                    <b>
                        {{ data.item.name }}
                    </b>
                    <br>
                    <div v-if="data.item.accountable_type" class='badge badge-light'>
                        {{ data.item.accountable_type }}
                    </div>
                </div>
                <span class="badge badge-light">{{ data.item.number }}</span>
            </template>
            <template v-slot:cell(actions)='data'>
                <button v-if='can("accounts.edit")' v-b-modal.financial-account-modal class='btn btn-xs btn-primary m-2'
                    @click="selectedFinancialAccount = data.item.id">Edit</button>
                <button class='btn btn-success btn-xs m-1'
                    @click="modal('financialTransactionsList', { filter: { financial_account_id: data.item.id, branch_id: filter.branch_id, accountable_type: filter.accountable_type } }, 'refresh')">View
                    Financial Transactions</button>

                <button v-if='can("accounts.delete") && data.item.editable' class='btn btn-xs btn-danger m-2'
                    @click="confirmAction('deleteAccount', data.item)"
                >Delete</button>
            </template>
        </ListView>

        <b-modal :no-close-on-backdrop=true id="financial-account-modal" hide-footer title="Financial Account Form">
            <AccountForm :id="selectedFinancialAccount" @saved='financialAccountSaved'></AccountForm>
        </b-modal>

        <b-modal :no-close-on-backdrop=true id="default-accounts-modal" hide-footer title="Default Accounts">
            <default-accounts-form @saved="defaultAccountsSaved"></default-accounts-form>
        </b-modal>

        <b-modal :no-close-on-backdrop=true id="import-accounts-modal" hide-footer title="Import Financial Accounts">
            <import-accounts-form @saved="financialAccountImported"></import-accounts-form>
        </b-modal>
    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import AccountForm from './AccountForm';
import ImportAccountsForm from '@/components/ImportAccountsForm.vue';
import DefaultAccountsForm from '@/components/DefaultAccountsForm.vue';
import FinancialAccountsSelect from '@/components/FinancialAccountsSelect.vue';

export default {
    extends: BaseList,
    components: { AccountForm, ImportAccountsForm, DefaultAccountsForm, FinancialAccountsSelect },
    methods: {
        load: function (apiClient, cancelToken) {
            return apiClient.get('financial-accounts', this.filter, cancelToken);
        },
        financialAccountSaved() {
            this.refresh();
            this.$bvModal.hide('financial-account-modal');
        },
        financialAccountImported() {
            this.refresh();
            this.$bvModal.hide('import-accounts-modal');
        },
        defaultAccountsSaved() {
            this.refresh();
            this.$bvModal.hide('default-accounts-modal');
        },
        deleteAccount(account) {
            return this.apiRequest(apiClient => apiClient.delete('financial-accounts/' + account.id)).then(() => account);
        },
    },
    data: function () {
        return {
            selectedFinancialAccount: null,
            types: ['Patient', 'InsuranceProvider', 'Supplier', 'Worker'],
            fields: [
                { key: 'id', label: '#' },
                { key: 'name', label: 'Name' },
                { key: 'parent', label: 'Parent' },
                { key: 'branch.name', label: 'Branch' },
                { key: 'type', label: 'Type' },
                { key: 'balance', label: 'Balance' },
                { key: 'actions', label: 'Actions' },
            ]
        };
    }
}
</script>
