<template>
    <div>
        <ListView title="Transactions List" :modalObject='currentModalObject' hasFilterActions='ture'>
        
            <template v-slot:filter>
                <b-form>
                    <div  v-if='entityType != "Doctor"'>
                        <b-form-row>
                            <b-col cols='2'>
                                <b-form-group label="Min Date">
                                    <b-form-datepicker v-model="filter.start_date"
                                        today-button
                                        reset-button
                                        class="mb-2"
                                    >
                                    </b-form-datepicker>
                                </b-form-group>
                            </b-col>
                            <b-col cols='2'>
                                <b-form-group label="Max Date">
                                    <b-form-datepicker v-model="filter.end_date"
                                        today-button
                                        reset-button
                                        class="mb-2"
                                    ></b-form-datepicker>
                                </b-form-group>
                            </b-col>
                            <b-col cols='2'>
                                <b-form-group label="Date Range">
                                    <my-date-range-picker v-model='dateRange' :style='{display:"inline-block"}'/>
                                    <div class='btn btn-danger btn-xxs ml-2' @click='() => dateRange = {startDate: null, endDate: null}'>x</div>
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                        <b-form-row>
                            <b-col cols='3'>
                                <b-form-group label="Branch">
                                    <branches-select v-model="filter.branch_id" :returnId='true'/>
                                </b-form-group>
                            </b-col>
                            <b-col cols='3' v-if='!entityType'>
                                <b-form-group label="Select Payable Type">
                                    <v-select v-model='filter.entity_type' :options="payableTypes" /> 
                                </b-form-group>
                            </b-col>
                            <b-col cols='3' v-if='!entityId'>
                                <b-form-group label="Select Payable">
                                    <payables-select v-model='payable' :payableType='filter.entity_type'/> 
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                        <b-form-row>
                            <b-col cols='3'>
                                <b-form-group label="Select Status">
                                    <v-select v-model='filter.status' :options="status" :multiple='true' /> 
                                </b-form-group>
                            </b-col>
                            <b-col cols='3' v-if='!entityType'>
                                <b-form-group label="Select Transaction Type">
                                    <v-select v-model='filter.type' :options="transactionTypes"  :multiple='true'/> 
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                        <b-form-group label="Other">
                            <b-form-checkbox
                                v-model="filter.unpaid"
                                value="1"
                                unchecked-value="0"
                                :inline='true'
                            >
                                Unpaid
                            </b-form-checkbox>

                            <b-form-checkbox
                                v-model="filter.collected"
                                value="1"
                                unchecked-value="0"
                                :inline='true'
                            >
                                Collected
                            </b-form-checkbox>

                            <b-form-checkbox
                                v-model="filter.not_payment"
                                value="1"
                                unchecked-value="0"
                                :inline='true'
                            >
                                Not Payment
                            </b-form-checkbox>

                        </b-form-group>

                        <b-form-group>
                            <b-form-checkbox
                                v-model="filter.summary"
                                value="1"
                                unchecked-value="0"
                                :inline='true'
                            >
                                Summary
                            </b-form-checkbox>
                        </b-form-group>
                        <b-form-group label="Summary Options" v-if='filter.summary == 1'>
                            <b-form-checkbox
                                v-model="filter.group_by_entity_type"
                                value="1"
                                unchecked-value="0"
                                :inline='true'
                            >
                                Group By Entity Type
                            </b-form-checkbox>
                            <b-form-checkbox
                                v-model="filter.group_by_entity_id"
                                value="1"
                                unchecked-value="0"
                                :inline='true'
                            >
                                Group By Entity
                            </b-form-checkbox>

                            <b-form-group label="Group By">
                                <b-form-radio-group
                                    v-model="filter.group_by_period"
                                    :options="['day', 'month', 'year', 'none']"
                                ></b-form-radio-group>
                            </b-form-group>

                        </b-form-group>
                    </div>
                    <div v-else>
                        <b-form-row>
                            <b-col cols='3'>
                                <b-form-group label="Branch">
                                    <branches-select v-model="filter.branch_id" :returnId='true'/>
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                    </div>
                </b-form>
            </template>
   
            <template v-slot:filter_actions>
                <authorized-url v-if='can("transactions.export")' class='btn btn-primary ml-2 float-right' url='transactions/export' :params='filter' target="_blank">
                    <i class="fa fa-file-excel"></i>
                    Export
                </authorized-url>
            </template>

			<template v-slot:table>
                <div class='pull-right' v-if='totals'>
                    <h3 v-if='entityType!="Doctor"'>
                        <div class='badge badge-light m-2'>
                            Number Of Transactions
                            {{ totals.number_of_transactions}}
                        </div>

                        <div class='badge badge-light m-2'>
                            Total Transactions Amount:
                            {{ totals.total | decimal}}
                        </div>
                    </h3>
                    <h3>
                        <div class='badge badge-light m-2' :title='"collected: " + totals.collected_for_supplier + " paid: " + totals.amount_paid'>
                            Required:
                            {{ totals.collected_for_supplier - totals.amount_paid | decimal}}
                        </div>
                        <div class='badge badge-light m-2'>
                            Not Collected:
                            {{ totals.amount_remaining - (totals.collected_for_supplier - totals.amount_paid)  | decimal}}
                        </div>
                    </h3>
                </div>


                <TransactionsSummaryListTable v-if='filter.summary == 1' :filter='filter' ref='tableList' :viewType='viewType'></TransactionsSummaryListTable>
                <TransactionsListTable v-else :filter='filter' ref='tableList' :viewType='viewType'></TransactionsListTable>

			</template>
                    
        </ListView>
    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import TransactionsListTable from '@/components/Lists/TransactionsListTable';
import TransactionsSummaryListTable from '@/components/Lists/TransactionsSummaryListTable';
import AuthorizedUrl from '../../components/AuthorizedUrl.vue';

export default {
    extends: BaseList,
    components: {TransactionsListTable, TransactionsSummaryListTable, AuthorizedUrl},
    props: ['entityType', 'entityId'],
    mounted() {
        if (this.entityType) {
            this.filter = {
                ... this.filter,
                entity_type: this.entityType,
                entity_id: this.entityId
            };
            this.viewType = 'entity';

            if (this.entityType == 'Doctor') {
                this.filter.status = ['finished'];
            }
        }

        this.loadTotals();
    },
    methods: {
        refreshTable () {
            this.$refs.tableList.refresh();
        },
        loadTotals() {
            return this.apiRequest(
                (apiClient, cancelTokenSource) => {
                    this.totalsCancelToken =  cancelTokenSource; 
                    return apiClient.get('transactions/totals', this.filter , cancelTokenSource)
                }, this.totalsCancelToken
            )
            .then(response => this.totals = response);
        }
    },
    data: function () {
        return {
            totalsCancelToken: null,
            payable: null,
            dateRange: {},
            totals: {},
            payableTypes: ['Patient', 'Doctor', 'Supplier', 'Vendor', 'InsuranceProvider', 'Purchase'],
            transactionTypes: ['sale', 'purchase', 'service fees', 'cash in', 'cash out'],
            status: ['finished', 'in progress', 'pending'],
            defaultFilter: {
                status: ['finished', 'in progress'],
                not_payment: 1,
            },
        }
    },
    watch: {
        entityType: function () {
            if (this.entityType) {
                this.filter = {
                    ... this.filter,
                    entity_type: this.entityType,
                    entity_id: this.entityId
                };
                this.viewType = 'entity';

                if (this.entityType == 'Doctor') {
                    this.filter.status = ['finished'];
                }
            }

            this.loadTotals();
        },
        entityId: function () {
            if (this.entityType) {
                this.filter = {
                    ... this.filter,
                    entity_type: this.entityType,
                    entity_id: this.entityId
                };
                this.viewType = 'entity';

                if (this.entityType == 'Doctor') {
                    this.filter.status = ['finished'];
                }
            }

            this.loadTotals();
        },
        payable: function () {

			this.filter = {
				... this.filter,
				entity_type:this.payable?this.payable.type : '',
				entity_id: this.payable?this.payable.id : ''
			};
        },
		dateRange: function () {
			var startDate = this.dateRange.startDate ? this.date(this.dateRange.startDate) : null;
			var endDate = this.dateRange.endDate ? this.date(this.dateRange.endDate) : null; 
            
            
			if (startDate == this.filter.start_date && endDate == this.filter.end_date) {
				return;
			} 
			
			this.filter = {
				... this.filter,
				start_date: startDate,
				end_date: endDate
			};
		},
        filter: {
            handler() {
				var filter = this.filter;
                this.dateRange = {
                    startDate: filter.start_date ? filter.start_date : null,
                    endDate: filter.end_date ? filter.end_date : null
                }; 

                this.loadTotals();
            },
            deep: true
        }
    }
}
</script>
