<template>
    <div>
        <div class='m-2'>
            <b-button  variant="outline-primary" @click="print"><i class='fa fa-print'></i></b-button>
        </div>
        <loader v-if='!externalService'/>
        <div v-else id="printMe" class='m-2'>
            <b-row class='mt-4'>
                <b-col cols=1><b>Branch</b></b-col>
                <b-col>{{externalService.patient_service.branch.name}}</b-col>
            </b-row>
            <b-row class='mt-4'>
                <b-col cols=1><b>Patient</b></b-col>
                <b-col>{{externalService.patient_service.patient.name}}</b-col>
            </b-row>
            <b-row class='mt-4'>
                <b-col cols=1><b>Doctor</b></b-col>
                <b-col>{{externalService.doctor.name}}</b-col>
            </b-row>
            <b-row class='mt-4'>
                <b-col cols=1><b>Service</b></b-col>
                <b-col>{{externalService.patient_service.name}} {{ externalService.patient_service.body_parts }}</b-col>
            </b-row>
            <b-row class='mt-4'>
                <b-col cols=1><b>Description</b></b-col>
                <b-col>{{externalService.description}}</b-col>
            </b-row>
        </div>

    </div>
</template>
<script>
export default {
    props: ['id'],
    data: function () {
        return {
            externalService: null
        }
    },
    mounted() {
        this.apiRequest(apiClient => {
            return apiClient.get('patient-external-services/'+this.id).then(
                response => this.externalService = response
            );
        });
    },
    methods: {
        async print () {
            // Pass the element id here
            await this.$htmlToPaper('printMe');
        }
    }
}
</script>