<template>
    <div>
        <loader v-if='loading' />
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>
            <b-form-group label="Name">
                <b-form-input v-model="account.name" :state="states.name" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.name' />
            </b-form-group>

            <b-form-group label="Note">
                <b-form-input v-model="account.note" :state="states.note" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.note' />
            </b-form-group>

            <b-form-group label="Account number">
                <b-form-input v-model="account.number" :state="states.number" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.number' />
            </b-form-group>

            <b-form-group label="Nature of Account">
                <v-select v-model='account.balance_type' :options="['DEBIT', 'CREDIT']" />
                <FormGroupError :errors='errors.balance_type' />
            </b-form-group>

            <b-form-group label="Statment">
                <v-select v-model='account.statement' :options="statements" />
                <FormGroupError :errors='errors.statement' />
            </b-form-group>

            <b-form-group label="Statment Group" v-if="account.statement">
                <v-select v-model='account.statement_group' :options="statementGroups" />
                <FormGroupError :errors='errors.statement_group' />
            </b-form-group>

            <div v-if="!id">
                <div class='form-group'>
                    <b-form-checkbox v-model="createExpenseType" :value=1 :unchecked-value=0>
                        create expense type
                    </b-form-checkbox>
                </div>
                <div v-if="createExpenseType == '1'">
                    <b-form-group label="Expense Type Group">
                        <v-select :options="expenseGroups" v-model='expenseType.expense_group'></v-select>
                        <FormGroupError :errors='errors.expense_group' />
                    </b-form-group>
                </div>
            </div>

            <div class='form-group'>
                <label>
                    Parent
                </label>
                <financial-accounts-select v-model='account.parent_id' :returnId="true" />
            </div>

            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
import FinancialAccountsSelect from '../../components/FinancialAccountsSelect.vue';
export default {
    components: { FinancialAccountsSelect },
    extends: BaseForm,
    data: function () {
        return {
            statements: ["BALANCE SHEET", "INCOME STATEMENT"],
            balanceSheetGroups: ["ASSETS", "LIABILITIES", "SHAREHOLDERS EQUITY"],
            incomeStatementGroups: ["GROSS PROFIT", "EXPENSES"],
            account: {
            },
            createExpenseType: 0,
            expenseGroups: [
                'petty cash',
                'general',
                'inventory',
            ],
            expenseType: {}
        }
    },
    computed: {
        statementGroups() {
            if (this.account.statement == "BALANCE SHEET") {
                return this.balanceSheetGroups;
            } else {
                return this.incomeStatementGroups;
            }
        }
    },
    methods: {
        load: function (id) {
            return this.apiRequest(apiClient => apiClient.get('financial-accounts/' + id))
                .then(response => {
                    this.account = response;
                    this.branch = response.branch;
                });
        },
        save: function (apiClient) {
            var account = {
                name: this.account.name,
                note: this.account.note,
                number: this.account.number,
                balance_type: this.account.balance_type,
                statement: this.account.statement,
                statement_group: this.account.statement_group,
                parent_id: this.account.parent_id
            };
            if (this.id) {
                return apiClient.put('financial-accounts/' + this.id, account);
            } else {
                account.parent_id = this.account.parent_id;
                if (this.createExpenseType == '1') {
                    account.expense_type = this.expenseType;
                }
                return apiClient.post('financial-accounts', account);
            }
        },
        afterSave: function (response) {
            this.$emit('saved', response);
        }
    }
}
</script>