<template>
    <div>

        <ListView title="Service Products List">


            <template v-slot:filter>
                <div class='form'>
                    <div class='form-row'>
                        <b-form-group class="col-md-4" label="Search">
                            <b-form-input v-model="filter.keyword" placeholder="search ..."></b-form-input>
                        </b-form-group>
                        <b-form-group class="col-md-4" label="Service">
                            <services-select v-model="filter.service_id" :returnId="true" />
                        </b-form-group>
                        <b-form-group class="col-md-4" label="Product">
                            <products-select v-model="filter.product_id" :returnId="true" />
                        </b-form-group>
                    </div>
                </div>
            </template>
            <template #table>
                <service-products-list-table :filter='filter' ref='tableList' :viewType='viewType' />
            </template>

        </ListView>


    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import ServicesSelect from '../../components/ServicesSelect.vue';
import ProductsSelect from '../../components/ProductsSelect.vue';
import ServiceProductsListTable from '../../components/Lists/ServiceProductsListTable.vue';

export default {
    extends: BaseList,
    components: { ServicesSelect, ProductsSelect, ServiceProductsListTable },
    methods: {

    },
    data: function () {
        return {
        };
    }
}
</script>
