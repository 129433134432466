<template>
    <div>
        <ListView title="CashPayments List" :modalObject='currentModalObject' :hideHeader='hideHeader() ? true : false'
            hasFilterActions='ture'>
            <template v-slot:actions>
                <b-button v-if='can("cash_payments.create")' @click="modal('cashPaymentForm', {}, 'refreshTable')"
                    variant='primary' class='mr-2'>
                    Create Cash Payment
                </b-button>
                <b-button v-if='false' @click="modal('insuranceCollectionForm', {}, 'refreshTable')" variant='primary'
                    class='mr-2'>
                    Collect Insurance
                </b-button>

            </template>

            <template v-slot:filter>
                <b-form>
                    <b-form-row>
                        <b-col cols='3'
                            v-if="!filter.payable_type || ['Patient', 'InsuranceProvider'].includes(filter.payable_type)">
                            <b-form-group label="Payment Method">
                                <payment-methods-select v-model='filter.payment_method'
                                    :returnId="true"></payment-methods-select>
                            </b-form-group>
                        </b-col>
                        <b-col cols='3'
                            v-if="!filter.payable_type || ['Doctor', 'Supplier', 'Vendor', 'Purchase'].includes(filter.payable_type)">
                            <b-form-group label="Source Of Payment">
                                <source-of-payments-select v-model='filter.source_of_payment'
                                    :returnId="true"></source-of-payments-select>
                            </b-form-group>
                        </b-col>
                        <b-col cols='3'>
                            <b-form-group label="Branch">
                                <branches-select v-model="filter.branch_id" :returnId='true' />
                            </b-form-group>
                        </b-col>
                        <b-col cols='3'>
                            <b-form-group label="User">
                                <users-select v-model="filter.user_id" :returnId='true' />
                            </b-form-group>
                        </b-col>
                        <b-col cols='3' v-if='viewType != "patientView"'>
                            <b-form-group label="Select Payable Type">
                                <v-select v-model='filter.payable_type' :options="payableTypes" />
                            </b-form-group>
                        </b-col>
                        <b-col cols='3' v-if='viewType != "patientView"'>
                            <b-form-group label="Select Payable">
                                <payables-select v-model='payable' :payableType='filter.payable_type' />
                            </b-form-group>
                        </b-col>
                        <div v-if='can("accounts.list")'>
                            <v-date-picker v-model='dateRange' is-range />
                        </div>
                        <div v-else>
                            <v-date-picker v-model='dateValue' />
                        </div>



                    </b-form-row>

                    <b-form-group label="Other">
                        <b-form-checkbox v-model="filter.down_payments" value="1" unchecked-value="0" :inline='true'>
                            Down Payments
                        </b-form-checkbox>

                        <b-form-checkbox v-model="filter.unused_payments" value="1" unchecked-value="0" :inline='true'>
                            Unused Payments
                        </b-form-checkbox>
                    </b-form-group>
                </b-form>
            </template>


            <template v-slot:filter_actions>
                <authorized-url v-if='can("cash_payments.export")' class='btn btn-primary ml-2 float-right'
                    url='cash-payments/export' :params='filter' target="_blank">
                    <i class="fa fa-file-excel"></i>
                    Export
                </authorized-url>
            </template>
            <template v-slot:table>
                <div v-if='totals'>
                    <h3>
                        <div class='badge badge-light m-2'>
                            Number Of Payments:
                            {{ totals.number_of_payments }}
                        </div>
                        <div class='badge badge-success m-2'>
                            Total In:
                            {{ totals.total_cash_in | decimal }}
                        </div>
                        <div class='badge badge-danger m-2'>
                            Total Out:
                            {{ totals.total_cash_out | decimal }}
                        </div>
                    </h3>
                </div>
                <CashPaymentsListTable :filter='filter' ref='tableList' :viewType='viewType'></CashPaymentsListTable>
            </template>

        </ListView>
    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import CashPaymentsListTable from '@/components/Lists/CashPaymentsListTable';
import AuthorizedUrl from '../../components/AuthorizedUrl.vue';
import PaymentMethodsSelect from '../../components/PaymentMethodsSelect.vue';
import SourceOfPaymentsSelect from '../../components/SourceOfPaymentsSelect.vue';

export default {
    extends: BaseList,
    props: ['patientId'],
    components: { CashPaymentsListTable, AuthorizedUrl, PaymentMethodsSelect, SourceOfPaymentsSelect },
    mounted() {
        this.loadTotals();
    },
    data: function () {
        return {
            dateValue: null,
            dateRange: null,
            payable: null,
            totals: null,
            totalsCancelToken: null,
            paymentMethods: ['visa', 'cash', 'valu', 'online', 'sympl'],
            payableTypes: ['Patient', 'Doctor', 'Supplier', 'Vendor', 'InsuranceProvider', 'Purchase']
        }
    },
    methods: {
        refreshTable() {
            this.$refs.tableList.refresh();
        },
        hideHeader: function () {
            return this.patientId ? true : false;
        },
        loadTotals() {
            if (this.can('cash_payments.totals')) {
                return this.apiRequest(
                    (apiClient, cancelTokenSource) => {
                        this.totalsCancelToken = cancelTokenSource;
                        return apiClient.get('cash-payments/totals', this.filter, cancelTokenSource)
                    }, this.totalsCancelToken
                )
                    .then(response => this.totals = response);
            }
        }
    },
    watch: {
        patientId: {
            immediate: true,
            handler: function () {
                if (this.patientId) {
                    this.viewType = 'patientView';
                    this.filter.patient_id = this.patientId;
                }
            }
        },
        filter: {
            handler() {
                var filter = this.filter;

                if (this.can("accounts.list") && filter.start_date && filter.end_date) {
                    this.dateRange = {
                        start: filter.start_date,
                        end: filter.end_date
                    };
                } else if (filter.date) {
                    this.dateValue = filter.date;
                }

                this.loadTotals();
            },
            deep: true
        },
        dateValue: function () {
            var date = this.dateValue ? this.date(this.dateValue) : '';
            if (date == this.filter.date) {
                return;
            }

            this.filter = {
                ... this.filter,

                date: date
            };
        },
        dateRange: function () {
            var startDate = this.dateRange ? this.date(this.dateRange.start) : '';
            var endDate = this.dateRange ? this.date(this.dateRange.end) : '';

            if (startDate == this.filter.start_date && endDate == this.filter.end_date) {
                return;
            }

            this.filter = {
                ... this.filter,
                start_date: startDate,
                end_date: endDate
            };
        },
        payable: function () {

            this.filter = {
                ... this.filter,
                payable_type: this.payable ? this.payable.type : '',
                payable_id: this.payable ? this.payable.id : ''
            };
        }
    }
}
</script>
