<template>
    <div>

        <ListView title="InsuranceProviders List" :collection="collection" :fields="fields" :loading="loading">
            <template v-slot:actions>
                <b-button  v-if='can("insurance_providers.create")'  v-b-modal.insuranceProvider-modal @click="selectedInsuranceProviderId = null" variant='primary' class='mr-2'>
                    Create InsuranceProvider
                </b-button>

            </template>

            <template v-slot:filter>
                <div class='form'>
                    <div class='form-row'>
                        <div class='form-group col-md-4'>
                            <b-form-input v-model="filter.keyword" placeholder="search ..."></b-form-input>
                        </div>
                    </div>
                </div>
            </template>

            <template v-slot:cell(status)='data'>
                <div v-if='data.item.is_active' class='badge badge-success'>
                    Active
                </div>
                <div v-else class='badge badge-light'>
                    Inactive
                </div>
            </template>
            <template v-slot:cell(actions)='data'>
                <b-button  v-if='can("insurance_providers.edit")' v-b-modal.insuranceProvider-modal class='mr-2' @click="selectedInsuranceProviderId = data.item.id">Edit</b-button>
            </template>
                    
        </ListView>
        
        <b-modal :no-close-on-backdrop=true id="insuranceProvider-modal" size='lg' hide-footer  title="InsuranceProvider Form">
            <InsuranceProviderForm @saved='insuranceProviderSaved' :id='selectedInsuranceProviderId'></InsuranceProviderForm>        
        </b-modal>
    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import InsuranceProviderForm from './InsuranceProviderForm';

export default {
    extends: BaseList,
    components: {InsuranceProviderForm},
    methods: {
        load: function (apiClient, cancelToken) {
            return apiClient.get('insurance-providers', this.filter, cancelToken);
        },
        insuranceProviderSaved() {
            this.refresh();
            this.$bvModal.hide('insuranceProvider-modal');
        }
    },
    data: function() {
        return {
            fields: [
                { key: 'id', label: '#' },
                { key: 'name', label: 'Name' },
                { key: 'status', label: 'Status' },
                { key: 'actions', label: '' }
            ],
            selectedInsuranceProviderId: null
        };
    }
}
</script>
