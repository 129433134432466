<template>
    <div class='card'>
        <div class='card-header'>
            <h5 class="card-title">Suppliers Payments</h5>
            <router-link :to="{name: 'SupplierPaymentForm'}" class="btn btn-primary btn-sm">
                <i class="fa fa-plus"></i>
                New Payment
            </router-link>
        </div>
        <div class='card-body'>
            <div class="table-responsive recentOrderTable">
                <b-table hover :items="items" :fields="fields">
                    <template v-slot:cell(amount)='data'>
                        <div class='badge badge-success'>
                            {{ data.item.amount }} EGP
                        </div>    
                    </template>
                    <template v-slot:cell(supplier)='data'>
                        <div>
                            {{ data.item.supplier }}
                        </div>
                        <div class='badge light badge-primary'>
                            Balance: 5,000.00
                        </div>
                    </template>
                    <template v-slot:cell(actions)>
                        <button class='btn btn-primary btn-xs sharp mr-2'>
                            <i class='fa fa-edit'></i>
                        </button>
                        <button class='btn btn-danger btn-xs sharp'>
                            <i class='fa fa-remove'></i>
                        </button>
                    </template>
                </b-table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: function() {
        return {
            items: [
                {date: "2020-10-05", supplier: "Medical supplier", amount: '10,000.00'},
                {date: "2020-10-08", supplier: "MA", amount: '300.00'},
            ],
            fields: [
                { key: 'id', label: '#' },
                { key: 'date', label: 'Date' },
                { key: 'supplier', label: 'Supplier' },
                { key: 'amount', label: 'Amount' },
                { key: 'actions', label: 'Actions' },
            ]
        };
    }
}
</script>