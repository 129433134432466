<template>
    <div>
        <b-form class='basic-form' @submit="submit" novalidate>
            <b-form-group label="Insurance">
                <InsuranceProvidersSelect v-model='patientInsurance.insurance_provider_id' :returnId='true'/>
            </b-form-group>

            <b-form-group label="Expire Date">
                <v-date-picker v-model="patientInsurance.expire_at" :masks='{input: "DD-MM-YYYY"}'>
                    <template v-slot="{ inputValue, inputEvents }">
                        <input
                        class="bg-white border px-2 py-1 rounded"
                        :value="inputValue"
                        v-on="inputEvents"
                        />
                    </template>
                </v-date-picker>
                
            </b-form-group>

            <b-form-group label="Insurance ID">
                <FileUpload v-on:input='value => this.cardFileIds = value'/>
                <b-form-checkbox v-model='replaceFiles' value='1'>
                    <b>Replace Files</b>
                </b-form-checkbox>
            </b-form-group>
            
            <div class='mb-3'>
                <b-form-checkbox v-model='patientInsurance.active' value='1'>
                    active
                </b-form-checkbox>
            </div>
            <div class='mb-3' v-if='!this.id'>
                <b-form-checkbox v-model='patientInsurance.default_insurance' value='1'>
                    set as default insurance 
                </b-form-checkbox>
            </div>

            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'

export default {
    extends: BaseForm,
    props: ['patientId'],
    data: function () {
        return {
            patientInsurance: {
                is_active: 1,
                default_insurance: 1,
            },
            cardFileIds: []
        }
    },
    methods: {
        load: function(id) {
            return this.apiRequest(apiClient => apiClient.get('patient-insurances/'+id))
                .then(response => {
                    this.patientInsurance = response;
                });
        },
        save: function (apiClient) {            
            var patientInsurance  = {
                ... this.patientInsurance,
                patient_id: this.patientId,
                card_file_ids: this.cardFileIds,
                replace_files: this.replaceFiles,
            };
            
            patientInsurance.expire_at = this.patientInsurance.expire_at ? this.date(this.patientInsurance.expire_at) : null;
            if (this.id) {
                return apiClient.put('patient-insurances/'+this.id, patientInsurance);
            } else {
                return apiClient.post('patient-insurances', patientInsurance);
            }
        },
        afterSave: function (patientInsurance) {
            this.$emit('saved', patientInsurance);
        },
    },
}
</script>