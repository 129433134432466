<template>
    <div>
        <loader v-if='loading'/>
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>

            <b-form-group label="Worker">
                <workers-select v-model='fingerprintUser.worker_id' :filter='workersFilter' :returnId='true'/>
            </b-form-group>
            
            <b-form-group label="Fingerprint Device" v-if='!id'>
                <fingerprint-devices-select v-model='fingerprintUser.fingerprint_device_id' :returnId='true'/>
            </b-form-group>
            
            <b-form-group label="Fingerprint User Id" v-if='!id'>
                <b-form-input v-model="fingerprintUser.fp_user_id" :state="states.name" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.fp_user_id'/>
            </b-form-group>
            
            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
import FingerprintDevicesSelect from '../../components/FingerprintDevicesSelect.vue';
export default {
  components: { FingerprintDevicesSelect },
    extends: BaseForm,
    data: function () {
        return {
            fingerprintUser: {
            },
            workersFilter: {}
        }
    },
    methods: {
        load: function(id) {
            return this.apiRequest(apiClient => apiClient.get('fingerprint-users/'+id))
                .then(response => { 
                    this.fingerprintUser = response;
                    this.workersFilter.working_branch_id = response.fingerprint_device.branch_id;
                });
        },
        save: function (apiClient) {
            var fingerprintUser = {
                worker_id: this.fingerprintUser.worker_id,
                fingerprint_device_id: this.fingerprintUser.fingerprint_device_id,
                fingerprint_user_id: this.fingerprintUser.fingerprint_user_id,
            };
            if (this.id) {
                return apiClient.put('fingerprint-users/'+this.id, fingerprintUser);
            } else {
                return apiClient.post('fingerprint-users', fingerprintUser);
            }
        },
        afterSave: function () {
            this.$emit('saved', 1);
        }
    },
    watch: {
        fingerprintUser() {
            if (!this.id) {
                this.workersFilter.working_branch_id = this.fingerprintUser.fingerprintDevice.branch_id;
            }
        }
    }
}
</script>