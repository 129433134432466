<template>
    <div>
        <ListView title="PatientExternalServices List" :modalObject='currentModalObject' >
            <template v-slot:filter>
                <div class='form'>
                    <div class='form-row'>
                        <div class='form-group col-md-4'>
                            <b-form-input v-model="filter.keyword" placeholder="search ..."></b-form-input>
                        </div>
                    </div>
                </div>
            </template>

			<template v-slot:table>
				<PatientExternalServicesListTable :filter='filter' ref='tableList' :viewType='viewType'></PatientExternalServicesListTable>
			</template>
                    
        </ListView>
    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import PatientExternalServicesListTable from '@/components/Lists/PatientExternalServicesListTable';

export default {
    props: ['patientId'],
    extends: BaseList,
    components: {PatientExternalServicesListTable},
    methods: {
        refreshTable () {
            this.$refs.tableList.refresh();
        }
    },
	watch: {
		patientId: {
			immediate: true,
			handler: function () {
				if (this.patientId) {
					this.viewType = 'patientView';
					this.defaultFilter.patient_id = this.patientId;
					this.filter.patient_id = this.patientId;
				}
			}
        },
    }
}
</script>
