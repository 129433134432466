<template>
    <div>
        <v-select
            @search="fetchOptions"
            @open="onOpen"
            :filterable='filterable'
            :options="options"
            :label='label'
            :value='value'
            :getOptionKey='getOptionKey'
            v-on:input='updateValue'
            :loading="loading"
            :multiple='multiple'
            :closeOnSelect='closeOnSelect'
        >

            <template #selected-option="{ name, type }">

                <span v-if='type == "Patient"'>
                    <div class='badge badge-light ml-2'>
                        <i class='fa fa-user'></i>
                        {{ name }}
                    </div>
                    
                    <div class='badge badge-light ml-2'>Patient</div>
                </span>
                <span v-if='type == "Doctor"'>
                    <div class='badge badge-light ml-2'>
                        <i class='fa fa-user-md'></i>
                        {{ name }}
                    </div>
                    
                    <div class='badge badge-light ml-2'>Doctor</div>
                </span>
                <span v-if='type == "InsuranceProvider"'>
                    <div class='badge badge-light ml-2'>
                        <i class='fa fa-id-card-alt'></i>
                        {{ name }}
                    </div>
                    
                    <div class='badge badge-light ml-2'>Insurance Provider</div>
                </span>
                <span v-if='type == "Supplier"'>
                    <div class='badge badge-light ml-2'>
                        <i class='fa fa-shopping-cart'></i>
                        {{ name }}
                    </div>
                    
                    <div class='badge badge-light ml-2'>Supplier</div>
                </span>
                <span v-if='type == "Vendor"'>
                    <div class='badge badge-light ml-2'>
                        <i class='fa fa-hospital'></i>
                        {{ name }}
                    </div>
                    
                    <div class='badge badge-light ml-2'>Vendor</div>
                </span>
            </template>
            

            <template #option="{ name, type }">

                <span v-if='type == "Patient"'>
                    <div class='badge badge-light ml-2'>
                        <i class='fa fa-user'></i>
                        {{ name }}
                    </div>
                    
                    <div class='badge badge-light ml-2'>Patient</div>
                </span>
                <span v-if='type == "Doctor"'>
                    <div class='badge badge-light ml-2'>
                        <i class='fa fa-user-md'></i>
                        {{ name }}
                    </div>
                    
                    <div class='badge badge-light ml-2'>Doctor</div>
                </span>
                <span v-if='type == "InsuranceProvider"'>
                    <div class='badge badge-light ml-2'>
                        <i class='fa fa-id-card-alt'></i>
                        {{ name }}
                    </div>
                    
                    <div class='badge badge-light ml-2'>Insurance Provider</div>
                </span>
                <span v-if='type == "Supplier"'>
                    <div class='badge badge-light ml-2'>
                        <i class='fa fa-shopping-cart'></i>
                        {{ name }}
                    </div>
                    
                    <div class='badge badge-light ml-2'>Supplier</div>
                </span>
                <span v-if='type == "Vendor"'>
                    <div class='badge badge-light ml-2'>
                        <i class='fa fa-hospital'></i>
                        {{ name }}
                    </div>
                    
                    <div class='badge badge-light ml-2'>Vendor</div>
                </span>
            </template>
            
        </v-select>
    </div>
</template>

<script>
import BaseRemoteSelect from './BaseRemoteSelect.vue';

export default {
    extends: BaseRemoteSelect,
    props: ['payableType'],
    data: function() {
        return {
            label: 'name',
        }
    },
    methods: {
        getOptionKey(option) {
            return option.id + '-' + option.type;
        },
        load(apiClient, search, cancelToken) {
            var filter = {
                keyword: search,
                payable_type: this.payableType
            };

            return apiClient.get('financial-accounts/payables', filter, cancelToken);
        }
    },
    watch: {
        payableType: function () {
            this.fetchOptions('', true);
        }
    }
}
</script>
