<template>
    <div>
        <ListView title="FinancialOperations List" :modalObject='currentModalObject' >
            <template v-slot:actions>
                <b-button @click="modal('financialOperationForm', {}, 'refreshTable')" variant='primary' class='mr-2'>
                    Create FinancialOperation
                </b-button>

            </template>

            <template v-slot:filter>
                <div class='form'>
                    <div class='form-row'>
                        <div class='form-group col-md-4'>
                            <b-form-input v-model="filter.keyword" placeholder="search ..."></b-form-input>
                        </div>
                    </div>
                </div>
            </template>

			<template v-slot:table>
				<FinancialOperationsListTable :filter='filter' ref='tableList' :viewType='viewType'></FinancialOperationsListTable>
			</template>
                    
        </ListView>
    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import FinancialOperationsListTable from '@/components/Lists/FinancialOperationsListTable';

export default {
    extends: BaseList,
    components: {FinancialOperationsListTable},
    methods: {
        refreshTable () {
            this.$refs.tableList.refresh();
        }
    }
}
</script>
