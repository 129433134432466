<template>
    <div>
        <ul class="list-group">
            <li class="list-group-item" v-for='(slot, index) in slots' :key='index' @click="$emit('timePicked', slot)">
                <h3>
                {{ slot | time }}
                </h3>
            </li>
        </ul>
    </div>
</template>
<script>
import moment from 'moment';

export default {
    props: {
        min: {
            default: '',
            type: String
        },
        max: {
            default: '',
            type: String
        }, duration: {
            default: 10,
            type: Number
        }
    },
    computed: {
        slots() {
            var slots = [];

            console.log(this.min);
            var min = moment(this.min);
            var max = moment(this.max);
            if (min < max) {
                while (min < max) {
                    
                    slots.push(moment(min));
                    
                    min.add(this.duration, 'minutes');
                    min.subtract(min.minutes() % this.duration, 'minutes');
                    if (moment.duration(max.diff(min)).asMinutes() < this.duration) {
                        break;
                    }
                }
            }

            return slots;
        }
    },
}
</script>
