<template>
    <div>
        <base-list :collection='collection' :loading='loading' :fields='fields' :modalObject='currentModalObject'
            @pageChanged='pageChanged'>

            <template v-slot:cell(created_at)='data'>
                <div class='badge badge-success'>
                    <i class='fa fa-calendar'></i>
                    {{ data.item.created_at | date }}
                </div>
                <br>
                <div class='badge badge-success'>
                    <i class='fa fa-clock'></i>
                    {{ data.item.created_at | time }}
                </div>
            </template>

            <template v-slot:cell(section)='data'>
                {{ data.item.section ? data.item.section.name + " / " : "" }}

                {{ data.item.nearst_branch ? data.item.nearst_branch.name : "" }}
            </template>

            <template v-slot:cell(notes)='data'>
                <div class='text-wrap' style='max-width:200px;'>
                    {{ data.item.notes }}
                </div>
            </template>
            <template v-slot:cell(is_insured)='data'>
                <div v-if='data.item.insurance_provider' class='badge badge-warning'>
                    {{ data.item.insurance_provider.name }}
                </div>
                <div v-if='data.item.is_insured' class='badge badge-success'>
                    Insured
                </div>
            </template>

            <template v-slot:cell(user)='data'>
                <div class='badge badge-secondary' v-if="data.item.user">
                    <i class='fa fa-user'></i>
                    {{ data.item.user.name }}
                </div>
            </template>

            <template v-slot:cell(patient)='data'>
                <router-link v-if='data.item.patient_id' :to="{ name: 'ViewPatient', params: { id: data.item.patient_id } }"
                    class=''>
                    {{ data.item.patient ? data.item.patient.name : "" }}
                </router-link>
                <div v-if='data.item.not_interested' class='badge badge-light'>
                    not interested
                </div>
            </template>
            <template v-slot:cell(url_tags)='data'>
                <div v-if='data.item.source_url'>
                    <a :href="data.item.source_url" :title='data.item.source_url' target="_blank">
                        Source
                    </a>
                </div>
                <div>
                    {{ data.item.url_tags }}
                </div>
            </template>
            <template v-slot:cell(actions)='data'>
                <button class='btn btn-xs btn-primary m-2' v-if='!data.item.patient_id'
                    @click="modal('leadForm', { id: data.item.id }, 'refresh')">Edit</button>
                <button class='btn btn-xs btn-primary m-2' v-if='!data.item.patient_id'
                    @click="modal('patientForm', { lead: data.item }, 'refresh')">Create Patient</button>

                <router-link v-if='data.item.patient_id' :to="{ name: 'ViewPatient', params: { id: data.item.patient_id } }"
                    class=''>
                    view Patient
                </router-link>
            </template>
        </base-list>
    </div>
</template>

<script>
import BaseList from './BaseList.vue';
import BaseListMethods from './BaseListMethods';

export default {
    props: ['viewType'],
    components: { BaseList },
    extends: BaseListMethods,
    data() {
        return {
            fields: [
                { key: 'id', label: 'ID' },
                { key: 'created_at', label: 'Created At' },
                { key: 'name', label: 'Name' },
                { key: 'phone', label: 'Phone' },
                { key: 'nationality', label: 'Nationality' },
                { key: 'is_insured', label: 'Insurance' },
                { key: 'user', label: 'Assigned User' },
                { key: 'section', label: 'Section / Branch' },
                { key: 'notes', label: 'Notes' },
                { key: 'url_tags', label: 'Url Tags' },
                { key: 'status', label: 'Status' },
                { key: 'patient', label: 'Patient' },
                { key: 'actions', label: 'Actions' },
            ]
        }
    },
    methods: {
        load: function (apiClient, cancelToken, filter) {
            return apiClient.get('leads', filter, cancelToken);
        }
    },
    watch: {

        // viewType: {
        // 	immediate: true,
        // 	handler: function () {
        // 	}
        // },
    }
}
</script>