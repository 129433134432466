<template>
    <div>
        <loader v-if='loading' />
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>
            <b-form-group label="Name">
                <b-form-input v-model="paymentMethod.name" :state="states.name" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.name' />
            </b-form-group>

            <b-form-group label="Account">
                <FinancialAccountsSelect v-model='paymentMethod.financial_account_id' :branch_id="null" :returnId='true' />
                <FormGroupError :errors='errors.financial_account_id' />
            </b-form-group>

            <div class='form-group'>
                <b-form-checkbox v-model="paymentMethod.is_active" :value=1 :unchecked-value=0>
                    active
                </b-form-checkbox>
            </div>

            <div class='form-group'>
                <b-form-checkbox v-model="paymentMethod.has_due_date" :value=1 :unchecked-value=0>
                    Has Due Date
                </b-form-checkbox>
            </div>

            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
import FinancialAccountsSelect from '../../components/FinancialAccountsSelect.vue';
export default {
    extends: BaseForm,
    components: { FinancialAccountsSelect },
    data: function () {
        return {
            paymentMethod: {
                financial_account_id: null,
            }
        }
    },
    methods: {
        load: function (id) {
            return this.apiRequest(apiClient => apiClient.get('payment-methods/' + id))
                .then(response => this.paymentMethod = response);
        },
        save: function (apiClient) {
            if (this.id) {
                return apiClient.put('payment-methods/' + this.id, this.paymentMethod);
            } else {
                return apiClient.post('payment-methods', this.paymentMethod);
            }
        },
        afterSave: function () {
            this.$emit('saved', 1);
        }
    },
}
</script>