import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";
import PatientsList from "../views/Patients/PatientsList";
import PatientsActionBoard from "../views/Patients/PatientsActionBoard";
import PatientForm from "../views/Patients/PatientForm";
import PatientFile from "../views/Patients/PatientFile";
import AppointmentsList from "../views/Appointments/AppointmentsList";
import EditAppointmentForm from "../views/Appointments/EditAppointmentForm";
import Calendar from "../views/Calendar/Calendar";
import AppointmentForm from "../views/Appointments/AppointmentForm";
import PaymentsList from "../views/Payments/PaymentsList";
import PaymentForm from "../views/Payments/PaymentForm";
import InventoryList from "../views/Inventory/InventoryList";
import PurchaseList from "../views/Purchase/PurchaseList";
import PurchasedItemsList from "../views/Purchase/PurchasedItemsList";
import PurchaseForm from "../views/Purchase/PurchaseForm";
import ExpenseForm from "../views/Expenses/ExpenseForm"
import SupplierPaymentsList from "../views/Suppliers/SupplierPaymentsList";
import SupplierPaymentForm from "../views/Suppliers/SupplierPaymentForm";
import InventoryMovementsList from "../views/Inventory/InventoryMovementsList";
import InventoryMovementForm from "../views/Inventory/InventoryMovementForm";
import ServicePricesList from "../views/Services/ServicePricesList"
import PatientServicesList from "../views/Services/PatientServicesList"
import TreatmentPlansList from "../views/TreatmentPlans/TreatmentPlansList"
import TreatmentPlanForm from "../views/TreatmentPlans/TreatmentPlanForm"
import AttendanceList from "../views/Attendance/AttendanceList"
import RequestedServiceForm from "../views/RequestedServices/RequestedServiceForm"
import FinancialTransactionsList from "../views/Accounting/FinancialTransactionsList"
import FinancialOperationForm from "../views/Accounting/FinancialOperationForm"
import FinancialOperationsList from "../views/FinancialOperations/FinancialOperationsList"
import AccountsList from "../views/Accounting/AccountsList"
import ChartOfAccounts from "../views/Accounting/ChartOfAccounts.vue"
import BalanceSheet from "../views/Accounting/BalanceSheet"
import CashFlowList from "../views/Accounting/CashFlowList"
import IncomeStatement from "../views/Accounting/IncomeStatement"
import BranchesList from "../views/Branches/BranchesList"
import ClinicsList from "../views/Clinics/ClinicsList"
import SectionsList from "../views/Sections/SectionsList"
import ProductsList from "../views/Products/ProductsList"
import BrandsList from "../views/Brands/BrandsList"
import InventoriesList from "../views/Inventories/InventoriesList"
import DoctorsList from "../views/Doctors/DoctorsList"
import InsuranceProvidersList from "../views/InsuranceProviders/InsuranceProvidersList"
import InsuranceApprovalsList from "../views/InsuranceApprovals/InsuranceApprovalsList"
import ServicesList from "../views/Services/ServicesList"
import ServiceProductsList from "../views/ServiceProducts/ServiceProductsList"
import UsersList from "../views/Users/UsersList"
import RolesList from "../views/Roles/RolesList"
import VacationsList from "../views/Vacations/VacationsList"
import SuppliersList from "../views/Suppliers/SuppliersList"
import InventoryTransactionsList from "../views/InventoryTransactions/InventoryTransactionsList"
import HostsList from "../views/Hosts/HostsList"
import InvoicesList from "../views/Invoices/InvoicesList"
import FingerprintDevicesList from "../views/FingerprintDevices/FingerprintDevicesList"
import FingerprintActionsList from "../views/FingerprintActions/FingerprintActionsList"
import FingerprintUsersList from "../views/FingerprintUsers/FingerprintUsersList"
import DoctorView from "../views/Doctors/DoctorView"
import DoctorAppointments from "../views/Doctors/DoctorFile/DoctorAppointments"
import DoctorTransactions from "../views/Doctors/DoctorFile/DoctorTransactions"
import DoctorServices from "../views/Doctors/DoctorFile/DoctorServices"
import PatientInsurancesList from "../views/PatientInsurances/PatientInsurancesList"
import MedicalRecordsList from "../views/MedicalRecords/MedicalRecordsList"
import PatientNotesList from "../views/PatientNotes/PatientNotesList"
import AuditNotesList from "../views/AuditNotes/AuditNotesList"
import VendorsList from "../views/Vendors/VendorsList"
import ExternalServicesList from "../views/ExternalServices/ExternalServicesList"
import RequestedServicesList from "../views/PatientExternalServices/PatientExternalServicesList"
import PatientRequestedServiceView from "../views/PatientExternalServices/PatientExternalServiceView"
import UserLogsList from "../views/UserLogs/UserLogsList"
import CashPaymentsList from "../views/CashPayments/CashPaymentsList"
import CashReturnsList from "../views/CashReturns/CashReturnsList"
import WorkerPayablesList from "../views/WorkerPayables/WorkerPayablesList"
import WorkersList from "../views/Workers/WorkersList"
import WorkersAttendanceList from "../views/Attendance/WorkersAttendanceList"
import ExpenseTypesList from "../views/ExpenseTypes/ExpenseTypesList"
import PaymentMethodsList from "../views/PaymentMethods/PaymentMethodsList"
import SourceOfPaymentsList from "../views/SourceOfPayments/SourceOfPaymentsList"
import LeadsList from "../views/Leads/LeadsList"
import OtherTransactionsList from "../views/Transactions/TransactionsList"
import BodyPartGroupsList from "../views/BodyPartGroups/BodyPartGroupsList"
import OptionsList from "../views/Options/OptionsList"
import UrlTagsList from "../views/UrlTags/UrlTagsList"
import LoginForm from "../views/login/LoginForm"
import EmptyLayout from "../layouts/EmptyLayout"
import Main from "../views/Main"

Vue.use(VueRouter);


const routes = [
  {
    path: "/",
    name: "Main",
    component: Main
  },
  {
    path: "/calendar",
    name: "Calendar",
    component: Calendar
  },
  {
    path: "/patients",
    name: "Patients",
    component: PatientsList,
    props: { pushHistory: true },
    meta: {
      permission: 'patients.list'
    }
  },
  {
    path: "/patients-board",
    name: "PatientsBoard",
    component: PatientsActionBoard,
    props: { pushHistory: true },
    meta: {
      permission: 'patients.list'
    }
  },
  {
    path: "/patients/edit/:id",
    name: "EditPatient",
    component: PatientForm,
    props: true,
    meta: {
      permission: 'patients.edit'
    }
  },
  {
    path: "/patients/create",
    name: "CreatePatient",
    component: PatientForm,
    meta: {
      permission: 'patients.create'
    }
  },
  {
    path: "/patients/view/:id",
    name: "ViewPatient",
    props: true,
    component: PatientFile,
    meta: {
      permission: 'patients.list'
    }
  },
  {
    path: "/appointments",
    name: "Appointments",
    component: AppointmentsList,
    props: { pushHistory: true },
    meta: {
      permission: 'appointments.list'
    }
  },
  {
    path: "/appointments/create",
    name: "AppointmentForm",
    component: AppointmentForm,
    props: true,
    meta: {
      permission: 'appointments.create'
    }
  },

  {
    path: "/appointments/edit/:id",
    name: "EditAppointment",
    component: EditAppointmentForm,
    props: true,
  },
  {
    path: "/calendar",
    name: "Calendar",
    component: Calendar
  },
  {
    path: "/payments",
    name: "Payments",
    component: PaymentsList
  },
  {
    path: "/payments/create",
    name: "PatientPaymentForm",
    component: PaymentForm
  },
  {
    path: "/inventory",
    name: "InventoryList",
    component: InventoryList,
    props: { pushHistory: true },
  },
  {
    path: "/purchases",
    name: "Purchases",
    component: PurchaseList,
    props: { expenseGroup: 'inventory', pushHistory: true },
  },
  {
    path: "/purchased-items",
    name: "PurchasedItems",
    component: PurchasedItemsList
  },
  {
    path: "/purchase/create",
    name: "PurchaseForm",
    props: true,
    component: PurchaseForm,
    propse: { expenseGroup: 'inventory', pushHistory: true }
  },
  {
    path: "/expenses",
    name: "AllExpenses",
    component: PurchaseList,
    props: { isExpenses: true, pushHistory: true },
  },
  {
    path: "/general-expenses",
    name: "GeneralExpenses",
    component: PurchaseList,
    props: { isExpenses: true, expenseGroup: 'general', pushHistory: true },
  },
  {
    path: "/petty-expenses",
    name: "PettyCashExpenses",
    component: PurchaseList,
    props: { isExpenses: true, expenseGroup: 'petty cash', pushHistory: true },
  },
  {
    path: "/expenses/create",
    name: "ExpenseForm",
    props: { isSimple: true },
    component: PurchaseForm
  },
  {
    path: "/expenses/create-petty",
    name: "GeneralExpenseForm",
    props: { isSimple: true, expenseGroup: 'general' },
    component: PurchaseForm
  },
  {
    path: "/expenses/create-general",
    name: "PettyExpenseForm",
    props: { isSimple: true, expenseGroup: 'petty cash' },
    component: PurchaseForm
  },
  {
    path: "/purchase/edit/:id",
    name: "EditPurchase",
    props: true,
    component: PurchaseForm
  },
  {
    path: "/expenses/create",
    name: "ExpenseForm",
    component: ExpenseForm
  },
  {
    path: "/supplier-payments",
    name: "SupplierPaymentsList",
    component: SupplierPaymentsList
  },
  {
    path: "/supplier-payments/create",
    name: "SupplierPaymentForm",
    component: SupplierPaymentForm
  },
  {
    path: "/inventory-movements",
    name: "InventoryMovementsList",
    component: InventoryMovementsList
  },
  {
    path: "/inventory-movements/create",
    name: "InventoryMovementForm",
    component: InventoryMovementForm
  },
  {
    path: "/service-prices",
    name: "ServicePricesList",
    component: ServicePricesList,
    props: { pushHistory: true }
  },
  {
    path: "/patient-services",
    name: "PatientServicesList",
    component: PatientServicesList,
    props: { pushHistory: true }
  },
  {
    path: "/patient-insurances",
    name: "PatientInsurancesList",
    component: PatientInsurancesList,
    props: { pushHistory: true }
  },
  {
    path: "/treatment-plans",
    name: "TreatmentPlansList",
    component: TreatmentPlansList
  },
  {
    path: "/treatment-plans/create",
    name: "TreatmentPlanForm",
    component: TreatmentPlanForm
  },
  {
    path: "/attendance",
    name: "AttendanceList",
    component: AttendanceList
  },
  // {
  //   path: "/requested-services",
  //   name: "RequestedServicesList",
  //   component: RequestedServicesList
  // },
  {
    path: "/requested-services/create",
    name: "RequestedServiceForm",
    component: RequestedServiceForm
  },
  {
    path: "/accounting/transactions",
    name: "FinancialTransactionsList",
    component: FinancialTransactionsList,
    props: { pushHistory: true }
  },
  {
    path: "/accounting/financial-operations",
    name: "FinancialOperationsList",
    component: FinancialOperationsList
  },
  {
    path: "/accounting/financial-operations/create",
    name: "FinancialOperationForm",
    component: FinancialOperationForm
  },
  {
    path: "/accounting/financial-operations/edit/:id",
    name: "EditFinancialOperationForm",
    component: FinancialOperationForm,
    props: true
  },
  {
    path: "/accounting/accounts",
    name: "AccountsList",
    component: AccountsList
  },
  {
    path: "/accounting/chart-of-accounts",
    name: "ChartOfAccounts",
    props: { pushHistory: true },
    component: ChartOfAccounts
  },
  {
    path: "/accounting/balance-sheet",
    name: "BalanceSheet",
    component: BalanceSheet,
    props: { pushHistory: true },
  },
  {
    path: "/accounting/cash-flow",
    name: "CashFlowList",
    component: CashFlowList
  },
  {
    path: "/accounting/income-statement",
    name: "IncomeStatement",
    component: IncomeStatement,
    props: { pushHistory: true },
  },
  {
    path: "/branches",
    name: "Branches",
    component: BranchesList
  },
  {
    path: "/clinics",
    name: "Clinics",
    component: ClinicsList
  },
  {
    path: "/sections",
    name: "Sections",
    component: SectionsList
  },
  {
    path: "/users",
    name: "Users",
    component: UsersList
  },
  {
    path: "/roles",
    name: "Roles",
    component: RolesList
  },
  {
    path: "/products",
    name: "Products",
    component: ProductsList
  },
  {
    path: "/brands",
    name: "Brands",
    component: BrandsList
  },
  {
    path: "/inventories",
    name: "Inventories",
    component: InventoriesList
  },
  {
    path: "/doctors",
    name: "Doctors",
    component: DoctorsList
  },
  {
    path: "/doctors/:id",
    name: "DoctorDetails",
    component: DoctorView,
    props: true,
    children: [
      {
        name: "DoctorAppointments",
        path: '',
        component: DoctorAppointments,
        props: true
      },
      {
        name: "DoctorServices",
        path: 'services',
        component: DoctorServices,
        props: true
      },
      {
        name: "DoctorTransactions",
        path: 'transactions',
        component: DoctorTransactions,
        props: true
      },
    ],
  },
  {
    path: "/insurance-providers",
    name: "InsuranceProviders",
    component: InsuranceProvidersList
  },
  {
    path: "/insurance-approvals",
    name: "InsuranceApprovals",
    component: InsuranceApprovalsList,
    props: { pushHistory: true }
  },
  {
    path: "/services",
    name: "Services",
    component: ServicesList
  },
  {
    path: "/service-products",
    name: "ServiceProducts",
    component: ServiceProductsList
  },
  {
    path: "/vacations",
    name: "Vacations",
    component: VacationsList
  },
  {
    path: "/suppliers",
    name: "Suppliers",
    component: SuppliersList
  },
  {
    path: "/insurance-providers",
    name: "InsuranceProviders",
    component: InsuranceProvidersList
  },
  {
    path: "/inventory-transactions",
    name: "InventoryTransactions",
    component: InventoryTransactionsList
  },
  {
    path: "/hosts",
    name: "Hosts",
    component: HostsList
  },
  {
    path: "/fingerprint-devices",
    name: "FingerprintDevices",
    component: FingerprintDevicesList
  },
  {
    path: "/fingerprint-actions",
    name: "FingerprintActions",
    component: FingerprintActionsList
  },
  {
    path: "/fingerprint-users",
    name: "FingerprintUsers",
    component: FingerprintUsersList
  },
  {
    path: "/invoices",
    name: "InvoicesList",
    component: InvoicesList
  },
  {
    path: "/patient-notes",
    name: "PatientNotesList",
    component: PatientNotesList
  },
  {
    path: "/audit-notes",
    name: "AuditNotesList",
    component: AuditNotesList,
    props: { pushHistory: true },
    meta: {
      permission: 'audit_notes.list'
    }
  },
  {
    path: "/medical-records",
    name: "MedicalRecordsList",
    component: MedicalRecordsList
  },
  {
    path: "/patient-notes",
    name: "PatientNotesList",
    component: PatientNotesList
  },
  {
    path: "/vendors",
    name: "VendorsList",
    component: VendorsList
  },
  {
    path: "/external-services",
    name: "ExternalServicesList",
    component: ExternalServicesList
  },
  {
    path: "/requested-services",
    name: "RequestedServicesList",
    component: RequestedServicesList
  },
  {
    path: "/requested-services/:id",
    name: "PatientRequestedServiceView",
    component: PatientRequestedServiceView,
    props: true
  },
  {
    path: "/expense-types",
    name: "ExpenseTypesList",
    component: ExpenseTypesList
  },
  {
    path: "/payment-methods",
    name: "PaymentMethodsList",
    component: PaymentMethodsList
  },
  {
    path: "/source-of-payments",
    name: "SourceOfPaymentsList",
    component: SourceOfPaymentsList
  },
  {
    path: "/leads",
    name: "LeadsList",
    component: LeadsList,
    props: { pushHistory: true }
  },
  {
    path: "/user-logs",
    name: "UserLogsList",
    component: UserLogsList
  },
  {
    path: "/cash-payments",
    name: "CashPaymentsList",
    component: CashPaymentsList,
    props: { pushHistory: true }
  },
  {
    path: "/cash-returns",
    name: "CashReturnsList",
    component: CashReturnsList,
    props: { pushHistory: true }
  },
  {
    path: "/options",
    name: "OptionsList",
    component: OptionsList
  },
  {
    path: "/worker-payables",
    name: "WorkerPayablesList",
    component: WorkerPayablesList,
    props: { pushHistory: true }
  },
  {
    path: "/workers",
    name: "WorkersList",
    component: WorkersList,
    props: { pushHistory: true }
  },
  {
    path: "/workers-attendance",
    name: "WorkersAttendanceList",
    component: WorkersAttendanceList,
    props: { pushHistory: true }
  },
  {
    path: "/transactions-list",
    name: "OtherTransactionsList",
    component: OtherTransactionsList,
    props: { pushHistory: true }
  },
  {
    path: "/body-part-groups",
    name: "BodyPartGroupsList",
    component: BodyPartGroupsList,
    props: { pushHistory: true }
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/url-tags",
    name: "UrlTags",
    component: UrlTagsList,
    props: { pushHistory: true },
  },
  {
    path: "/login",
    name: "Login",
    component: LoginForm,
    meta: {
      layout: EmptyLayout,
      noAuth: true
    }
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});


router.beforeEach((to, from, next) => {
  Vue.GlobalApiClient.cancelAll();

  if (to.matched.some(record => !record.meta.noAuth)) {
    if (localStorage.isAuthenticated) {
      if (to.matched.some(
        record => {
          if (!record.meta.permission) {
            return true;
          }
          if (localStorage.user) {
            var user = JSON.parse(localStorage.user);
            if (user.role && user.role.permissions.indexOf(record.meta.permission) > -1) {
              return true;
            }
          }

        }
      )
      ) {
        next()
      }
      return
    }
    router.push({ name: 'Login', query: { redirect: window.location.pathname + window.location.search } });
  } else {
    next()
  }
});

export default router;
