<template>
    <div>
        <loader v-if='loading'/>
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>
            <div>
                <div v-if='!mainInsurancePatientService && !userDoctor()' class='form-group'>
                    <label for="">
                        <i class='fa fa-user-md'></i>
                        Doctor
                    </label>
                    <DoctorsSelect v-model='doctor' working='1'/>
                </div>

                <div class='form-group' v-if='!mainInsurancePatientService && doctor && doctor.branches.length > 1'>
                    <label for="">
                        <i class='fa fa-'></i>
                        Branch
                    </label>
                    <BranchesSelect v-model='branch' :doctorId='doctor.id'/>
                </div>
            </div>
            <div class='row' v-if='doctor && branch'>
                <div class='col-6' v-if='doctor.section.body_parts_images'>
                    <b-form-radio-group
                        v-if='bodyPartsImages.length > 1'
                        v-model="bodyPartsImage"
                        :options="bodyPartsImages.map(item => item.name)"
                        name="radios-btn-default"
                        buttons
                        button-variant="primary"
                    ></b-form-radio-group>
                    <clickable-svg :svgContent='bodyPartsImages.find(item => item.name == bodyPartsImage).svg_content' :selectable="true" v-model='selectedBodyParts' />
                    <div @click='selectedBodyParts = []' class='btn btn-danger m-2 float-right'>Clear</div>
                    <div @click='handleClickedItem(selectedBodyParts)' class='btn btn-primary m-2 float-right'>Select</div>
                </div>
                <div :class='bodyPartsImage ? "col-6" : "col-12"'>
                        <div class='m-2' v-if='mainInsurancePatientService'>
                            <h3>Main Services</h3>
                            <hr>
                        </div>
                        <div v-if='mainInsurancePatientService'>
                            <div v-for='(insurancePatientService, index) in insurancePatientServices' :key='index'>
                                {{ insurancePatientService.id }} -                    
                                {{ insurancePatientService.name }}                    
                            </div>
                            <div class='text-right'>
                                <div class='btn btn-primary m-2' @click="modal('patientServicesSelector', {baseFilter: {not_ids: allServiceIds(), patient_id: mainInsurancePatientService.patient_id, branch_id: mainInsurancePatientService.branch_id, section_id: mainInsurancePatientService.section_id}}, 'addInsuranceService')">Add Another Service</div>
                            </div>
                        </div>
                        <div class='m-2' v-if='mainInsurancePatientService'>
                            <h3>Virtual Insurance Services</h3>
                            <hr>
                        </div>

                        <div class='text-right' v-if='doctor'>
                            <b-button variant='primary' class='btn btn-primary btn ml-2 align-right' v-b-modal.modal-3 @click="bodyPartsRequired = 0;">Add Service</b-button>
                            <b-button v-if='mainInsurancePatientService' variant='primary' class='btn btn-primary btn ml-2 align-right' @click="modal('patientServicesSelector', {filter_optional: true, baseFilter: {not_ids: allServiceIds(), patient_id: mainInsurancePatientService.patient_id, branch_id: mainInsurancePatientService.branch_id, section_id: mainInsurancePatientService.section_id}}, 'addVirtualInsurancePatientServices')">Select Existing Patient Service</b-button>
                        </div>
                        <div class='m-2' v-if='mainInsurancePatientService'>
                            <div v-for='(insurancePatientService, index) in virtualInsurancePatientServices' :key='index'>
                                {{ insurancePatientService.id }} -                    
                                {{ insurancePatientService.name }}
                            </div>
                        </div>
                        <div v-for='(patientService, index) in patientServices' :key='index'>
                            <div class='form'>
                                <div class='badge badge-success badge-lg m-2' :title='patientService.service.name'>
                                    <i class='fa fa-stethoscope'></i>
                                    {{ patientService.service.name | str_limit(50) }}
                                    -
                                    {{ patientService.body_parts }}
                                </div>
                                    <b-button variant='danger' class='m-2 btn btn-danger btn-xs sharp' @click='removePatientService(index)'>
                                        <i class='fa fa-remove'></i>
                                    </b-button>
                                <div>
                                    <b-form-group label="Body Part">
                                        <v-select :options="bodyParts" v-model='patientService.body_parts' :multiple='patientService.service.multiple_body_parts ? true : false'></v-select>
                                    </b-form-group>
                                    <b-form-group label="Number Of Sessions">
                                        <b-form-input type='number' min='1' placeholder="Enter number of sessions" v-model='patientService.required_appointments'>
                                        </b-form-input>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
            
                        <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>
                        <div v-if='patientServices.length > 0 || virtualInsurancePatientServices.length > 0'>
                            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

                            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                                <b-spinner small type="grow"></b-spinner>
                                saving...
                            </b-button>
                        </div>

                </div>

            </div>

            <b-modal :no-close-on-backdrop=true id="modal-3" hide-footer  size='xl' title="Select Service" v-if='doctor'>
                <ServicesSelector @clickedItem='serviceSelected' :multiple='true' :forCreate='true' :sectionId='doctor.section_id' :bodyPartsRequired='this.bodyPartsRequired' ></ServicesSelector>        
            </b-modal>
        </b-form>

		<Modals :modalName='currentModal' :modalData='currentModalData' @saved='modalCallback' @closed='modalClosed'>
		</Modals>
    </div>
</template>
<script>
// import SVGKidsTeeth from '../../components/SVGKidsTeeth';
// import SVGDental from '../../components/SVGDental';
// import SVGBody from '../../components/SVGBody';
import ServicesSelector from '../../components/ServicesSelector';
import BaseForm from '@/views/BaseForm'
import ClickableSvg from '../../components/ClickableImages/ClickableSvg.vue';
// import DentistryBodyParts from '@/components/BodyParts/DentistryBodyParts';
// import PhysicalTherapyBodyParts from '@/components/BodyParts/PhysicalTherapyBodyParts';

export default {
    extends: BaseForm,
    props:['patientId', 'insurancePatientServices'],
    data: function () {
        return {
            patientServices: [],
            virtualInsurancePatientServices: [],
            isValid: true,
            body_part: null,
            bodyParts: [],
            doctor: null,
            branch: null,
            bodyPartsImages: [],
            selectedBodyParts: [],
            bodyPartsImage: null,
            bodyPartsRequired: null,
            mainInsurancePatientService: null,
            cancelTokenSource: null,
            imgCancelTokenSource: null
        }
    },
    components: {
        // SVGDental,
        // SVGBody,
        // SVGKidsTeeth,
        ServicesSelector,
        ClickableSvg
    },
    mounted() {
        if (this.insurancePatientServices && this.insurancePatientServices.length > 0) {
            this.branch = this.insurancePatientServices[0].branch;
            this.doctor = this.insurancePatientServices[0].doctor;
            this.mainInsurancePatientService = this.insurancePatientServices[0];
        } else {            
            this.doctor = this.userDoctor();
        }
    },
    methods: {
        allServiceIds() {
            return [...this.virtualInsurancePatientServices.map(item => item.id), ...this.insurancePatientServices.map(item => item.id)]
        },
        serviceSelected(services) {
            this.$bvModal.hide('modal-3');
            // var s = service + ' - ' + this.current;
            var selectedBodyParts = null;
            if (this.body_part) {
                selectedBodyParts = Array.isArray(this.body_part) ? this.body_part : [this.body_part];
           
                selectedBodyParts.forEach(
                    bodyPart => services.forEach(service => {
                        if (!service.multiple_body_parts) {
                            this.patientServices.push({
                                service: service,
                                service_id: service.id,
                                body_parts: [bodyPart],
                                required_appointments: 1
                            });
                        }
                    })
                );
            }

            services.forEach(service => {
                if (!selectedBodyParts || service.multiple_body_parts) {
                    this.patientServices.push({
                        service: service,
                        service_id: service.id,
                        body_parts: selectedBodyParts,
                        required_appointments: 1
                    });
                }
            });

            this.body_part = null;
        },
        addVirtualInsurancePatientServices(patientService) {
            this.virtualInsurancePatientServices = [...this.virtualInsurancePatientServices, patientService];
        },
        handleClickedItem(key) {
            this.body_part = key;
            this.bodyPartsRequired = null;
            this.$bvModal.show('modal-3');
        },
        load: function() {
            return Promise.resolve(true);
        },
        save: function (apiClient) {
            if (!this.branch && this.doctor.branches.length == 1) {
                this.branch =  this.doctor.branches[0];
            }
            return apiClient.post(
                'patients/'+this.patientId+'/services',
                {
                    branch_id: this.branch ? this.branch.id : null,
                    doctor_id: this.doctor.id,
                    patient_services: this.patientServices,
                    insurance_patient_service_ids: this.insurancePatientServices ? this.insurancePatientServices.map(item => item.id) : null,
                    virtual_insurance_patient_service_ids: this.virtualInsurancePatientServices ? this.virtualInsurancePatientServices.map(item => item.id) : null
                }
            );
        },
        afterSave() {
            this.$emit('saved');
        },
        removePatientService(index) {
            this.patientServices.splice(index, 1);
        },
        addInsuranceService(patientService) {
            var found = this.insurancePatientServices.find(item => item.id == patientService.id ? true : false); 
            if (!found) {
                this.insurancePatientServices.push(patientService);
            }
        }
    },
    watch: {
        doctor: function () {
            if (this.doctor) {
                var bodyPartsImages = this.doctor.section.body_parts_images;
                this.bodyPartsImage = bodyPartsImages ? bodyPartsImages[0] : null;
                // if (this.bodyPartsImage == "Adult Body") {
                //     this.bodyParts = PhysicalTherapyBodyParts;
                // } else if (this.bodyPartsImage == "Adults Teeth") {
                //     this.bodyParts = DentistryBodyParts;
                // } else if (this.bodyPartsImage == "Kids Teeth") {
                //     this.bodyParts = DentistryBodyParts;
                // }
                this.bodyParts = [];

                this.apiRequest((apiClient, cancelTokenSource) => {
                    this.cancelTokenSource = cancelTokenSource;
                    return apiClient.get('body-part-groups', {section_id: this.doctor.section_id}, cancelTokenSource);
                }, this.cancelTokenSource)
                .then(response => {
                    var bodyParts = [];
                    response.data.forEach(item => bodyParts = [...bodyParts, ...item.body_parts])
                    this.bodyParts = bodyParts;
                });

                this.apiRequest((apiClient, cancelTokenSource) => {
                    this.imgCancelTokenSource = cancelTokenSource;
                    return apiClient.get('sections/clickable-images/'+this.doctor.section_id, cancelTokenSource);
                }, this.imgCancelTokenSource)
                .then(response => {
                    this.bodyPartsImages = response;
                });
            }
            if (!this.branch && this.doctor.branches.length == 1) {
                this.branch =  this.doctor.branches[0];
            }
        }
    }
}
</script>
