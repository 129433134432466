<template>
    <div>
        <loader v-if='loading'/>
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>
            <b-form-group label="Worker">
                <workers-select v-model="workerPayable.worker_id" :returnId='true'/>
            </b-form-group>
            <b-form-group label="Amount">
                <b-form-input v-model="workerPayable.amount" :state="states.amount" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.amount'/>
            </b-form-group>
            <b-form-group label="Type">
                <v-select :options="types" v-model='workerPayable.type'></v-select>
                <FormGroupError :errors='errors.type'/>
            </b-form-group>
            <b-form-group label="Date">
                <v-date-picker v-model="workerPayable.date">
                    <template v-slot="{ inputValue, inputEvents }">
                        <input
                        class="bg-white border px-2 py-1 rounded"
                        :value="inputValue"
                        v-on="inputEvents"
                        />
                    </template>
                </v-date-picker>
            </b-form-group>
            
            <div class='form-group'>
                <label>Description</label>
                <textarea v-model='workerPayable.description' class='form-control'></textarea>
            </div>

            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
import WorkersSelect from '../../components/WorkersSelect.vue';
export default {
  components: { WorkersSelect },
    extends: BaseForm,
    data: function () {
        return {
            workerPayable: {
            },
            types: [
                'salary',
                'deduction',
                'bonus'
            ]
        }
    },
    methods: {
        load: function(id) {
            return this.apiRequest(apiClient => apiClient.get('worker-payables/'+id))
                .then(response => this.workerPayable = response);
        },
        save: function (apiClient) {
            var workerPayable = {...this.workerPayable, date: this.date(this.workerPayable.date)};
            if (this.id) {
                return apiClient.put('worker-payables/'+this.id, workerPayable);
            } else {
                return apiClient.post('worker-payables', workerPayable);
            }
        },
        afterSave: function () {
            this.$emit('saved', 1);
        }
    },
}
</script>