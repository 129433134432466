<template>
    <div>
        <base-list :collection='collection' :loading='loading' :fields='fields' :modalObject='currentModalObject'
            @pageChanged='pageChanged'>
            <template v-slot:cell(amount)='data'>
                <h4>
                    <b>{{ data.item.type }} </b>
                    <div class='badge' :class='data.item.type == "cash-in" ? "badge-success" : "badge-danger"'>
                        {{ data.item.amount | currency }}
                    </div>
                </h4>
            </template>
            <template v-slot:cell(payment_method)='data'>
                {{ data.item.payment_method.name }}
                <div class='badge badge-light' v-if='data.item.due_date' :title="data.item.due_date">
                    Due:
                    {{ data.item.due_date | date }}
                </div>

            </template>
            <template v-slot:cell(created_at)='data'>

                <div class='badge badge-light'>
                    <i class='fa fa-calendar'></i>
                    {{ data.item.date | date }}
                </div>
                <div class='badge badge-light'>
                    created at
                    <i class='fa fa-clock'></i>
                    {{ data.item.created_at | datetime }}
                </div>
                <br />
                <div v-if='data.item.branch' class='badge badge-light mt-1'>
                    {{ data.item.branch.name }}
                </div>

                <div class='badge badge-light' v-if='data.item.created_by'>
                    created by
                    <i class='fa fa-user'></i>
                    {{ data.item.created_by.name }}
                </div>
            </template>
            <template v-slot:cell(entity)='data'>
                <router-link v-if='data.item.entity_type == "Patient"'
                    :to="{ name: 'ViewPatient', params: { id: data.item.entity_id } }" class=''>
                    <h4>
                        {{ data.item.entity.name }}
                    </h4>
                </router-link>
                <div v-else-if='data.item.entity'>
                    <div class='badge badge-light m-1'>
                        {{ data.item.entity.name }}
                    </div>
                    <div class='badge badge-light m-1'>
                        {{ data.item.entity_type }}
                    </div>
                </div>

            </template>
            <template v-slot:cell(cash_payment_parts)='data'>
                <div v-for='(cashPaymentPart, index) in data.item.cash_payment_parts' :key='index' class='m-2'>
                    <div class='badge ml-2 text-wrap'
                        :class='cashPaymentPart.payable_transaction.status == "finished" ? "badge-success" : "badge-light"'
                        v-if='cashPaymentPart.payable_transaction'>
                        # {{ cashPaymentPart.payable_transaction.id }}
                        {{ cashPaymentPart.payable_transaction.title }}
                    </div>
                    <div class='badge badge-light ml-2' v-else-if='cashPaymentPart.transaction'>{{
                        cashPaymentPart.transaction.title }}</div>
                    <div class='badge badge-light ml-2'>{{ cashPaymentPart.amount | decimal }}</div>
                    <div v-if='cashPaymentPart.cash_return' class='badge badge-danger'>
                        returned at {{ cashPaymentPart.cash_return.date }} #{{ cashPaymentPart.cash_return_payment_id }}
                    </div>
                    <div v-if='cashPaymentPart.payable_transaction' class='m-2'>
                        <b
                            v-if='cashPaymentPart.payable_transaction.transactionable && cashPaymentPart.payable_transaction.transactionable_type == "Purchase"'>
                            {{ cashPaymentPart.payable_transaction.transactionable.notes }}
                        </b>
                    </div>
                </div>
                <div v-for='(cashPaymentPart, index) in data.item.return_cash_payment_parts' :key='index' class='m-2'>
                    <div class='badge badge-danger'>return </div>
                    <div class='badge ml-2'
                        :class='cashPaymentPart.payable_transaction.status == "finished" ? "badge-success" : "badge-light"'
                        v-if='cashPaymentPart.payable_transaction'>
                        # {{ cashPaymentPart.payable_transaction.id }}
                        {{ cashPaymentPart.payable_transaction.title }}
                    </div>
                    <div class='badge badge-light ml-2' v-else-if='cashPaymentPart.transaction'>{{
                        cashPaymentPart.transaction.title }} </div>
                    <div class='badge badge-light ml-2'>{{ cashPaymentPart.amount | decimal }}</div>
                </div>
            </template>
            <template v-slot:cell(actions)='data'>
                <div v-if='data.item.cash_payment_parts.length > 0
                    && data.item.cash_payment_parts[0].payable_transaction
                    && data.item.cash_payment_parts[0].payable_transaction.transactionable_type == "Purchase"
                    && (data.item.cash_payment_parts[0].payable_transaction.transactionable.payed == 1
                        || data.item.cash_payment_parts[0].payable_transaction.transactionable.supplier_id)'>
                </div>
                <div v-else>
                    <button class='btn btn-success btn-xs m-1' v-if='!data.item.is_cash_return && can("cash_payments.edit")'
                        @click="modal('cashPaymentForm', { id: data.item.id }, 'refresh')">edit</button>
                    <button v-if='can("cash_payments.delete")' class='btn btn-danger btn-xs m-1'
                        @click="confirmAction('deleteCashPayment', data.item, 'refresh')">x</button>
                </div>
                <button class='btn btn-success btn-xs m-1'
                    @click="modal('transactionsList', { filter: { cash_payment_id: data.item.id } }, 'refresh')">View
                    Transactions</button>

            </template>
        </base-list>
    </div>
</template>

<script>
import BaseList from './BaseList.vue';
import BaseListMethods from './BaseListMethods';

export default {
    props: ['viewType'],
    components: { BaseList },
    extends: BaseListMethods,
    data() {
        return {
            fields: [
                { key: 'id', label: 'ID' },
                { key: 'entity', label: 'Entity' },
                { key: 'payment_method', label: 'Payment Method' },
                { key: 'cash_payment_parts', label: 'Parts' },
                { key: 'amount', label: 'Amount' },
                { key: 'created_at', label: 'Time' },
                { key: 'actions', label: 'Actions' },
            ]
        }
    },
    methods: {
        load: function (apiClient, cancelToken, filter) {
            return apiClient.get('cash-payments', filter, cancelToken);
        },
        deleteCashPayment(cashPayment) {
            return this.apiRequest(apiClient => apiClient.delete('cash-payments/' + cashPayment.id));
        }
    },
    watch: {

        // viewType: {
        // 	immediate: true,
        // 	handler: function () {
        // 	}
        // },
    }
}
</script>