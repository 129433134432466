<template>
    <div>
        <loader v-if='loading' />
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>

            <b-form-group label="Patient" v-if='!patientId'>
                <patients-select v-model='auditNote.patient_id' returnId='true' />
            </b-form-group>
            <b-form-group v-if='!userBranch()' label="Branch">
                <branches-select v-model='auditNote.branch_id' returnId='true' />
            </b-form-group>

            <b-form-group label="Note">
                <b-form-textarea v-model="auditNote.note" :state="states.note" type="text" placeholder="">
                </b-form-textarea>
                <FormGroupError :errors='errors.text' />
            </b-form-group>

            <b-form-group label="Resolution">
                <b-form-textarea v-model="auditNote.resolution" :state="states.resolution" type="text" placeholder="">
                </b-form-textarea>
                <FormGroupError :errors='errors.text' />
            </b-form-group>

            <b-form-group>
                <b-form-checkbox v-model="auditNote.is_closed" :value="1" unchecked-value="0" size="lg">
                    Resolved
                </b-form-checkbox>
            </b-form-group>

            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
import PatientsSelect from '../../components/PatientsSelect.vue';
import BranchesSelect from '../../components/BranchesSelect.vue';
export default {
    components: { PatientsSelect, BranchesSelect },
    extends: BaseForm,
    props: ['patientId'],
    data: function () {
        return {
            auditNote: {
            }
        }
    },
    mounted() {
        this.auditNote.patient_id = this.patientId;
    },
    methods: {
        load: function (id) {
            return this.apiRequest(apiClient => apiClient.get('audit-notes/' + id))
                .then(response => this.auditNote = response);
        },
        save: function (apiClient) {
            if (this.id) {
                return apiClient.put('audit-notes/' + this.id, this.auditNote);
            } else {
                return apiClient.post('audit-notes', this.auditNote);
            }
        },
        afterSave: function (item) {
            this.$emit('saved', item);
        }
    },
}
</script>