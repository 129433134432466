<template>
    <div class='card'>
        <div class='card-header'>
            <h5 class="card-title">Cash Flow List</h5>
        </div>
        <div class='card-body'>
            <div class="table-responsive recentOrderTable">
                <b-table hover :items="items" :fields="fields">
                </b-table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: function() {
        return {
            items: [
                {title: "Crown Service", amount: '1,000', account: 'Cash'},
                {title: "Expense", amount: '-500', account: 'Cash'},
            ],
            fields: [
                { key: 'id', label: '#' },
                { key: 'title', label: 'Title' },
                { key: 'account', label: 'Account' },
                { key: 'amount', label: 'Amount' },
            ]
        };
    }
}
</script>