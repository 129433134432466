<template>
    <div>
        <ListView title="Invoices List" :collection="collection" :hasFilterActions='true'>

            <template v-slot:filter>
                <div class='form '>
                    <div class='form-row'>
                        <div class='form-group col-md-6'>
                            <b-form-input v-model="filter.keyword" placeholder="search ..."></b-form-input>
                        </div>

                    </div>

                    <div class='form-row'>


                        <b-form-group label="Insurance" class="col-md-4">
                            <InsuranceProvidersSelect v-model='filter.insurance_provider_id' :returnId='true' />
                        </b-form-group>
                        <b-form-group label="Branch" class="col-md-4">
                            <BranchesSelect v-model='filter.branch_id' :returnId='true' />
                        </b-form-group>
                        <b-form-group label="Claimed At" class="col-md-6">
                            <my-date-range-picker v-model='dateRange' :style='{ display: "inline-block" }' />
                            <div class='btn btn-danger btn-xxs ml-2'
                                @click='() => dateRange = { startDate: null, endDate: null }'>x</div>
                        </b-form-group>

                    </div>
                    <div class='form-row'>
                        <b-form-group label="Claim Status">
                            <b-form-radio-group v-model="filter.claim_status"
                                :options="[{ text: 'All', value: '' }, { text: 'Claimed', value: 1 }, { text: 'Not Claimed', value: 0 }]">
                            </b-form-radio-group>
                        </b-form-group>
                    </div>


                </div>
            </template>

            <template v-slot:filter_actions>
                <authorized-url v-if='can("invoices.export")' class='btn btn-primary float-right' url='invoices/export'
                    :params='filter' target="_blank">
                    <i class="fa fa-file-excel"></i>
                    Export
                </authorized-url>
            </template>

            <template v-slot:table>

                <div v-if='can("accounts.list") && totals'>
                    <h3>
                        <div class='badge badge-light m-2'>
                            Number Of Invoices
                            {{ totals.number_of_invoices }}
                        </div>
                        <div class='badge badge-light m-2'>
                            Total Insurance Pay:
                            {{ totals.total_insurance_pay | decimal }}
                        </div>
                        <div class='badge badge-light m-2'>
                            Total Patient Pay:
                            {{ totals.total_patient_pay | decimal }}
                        </div>
                    </h3>
                </div>
                <invoices-list-table :filter='filter' ref='tableList' :viewType='viewType'></invoices-list-table>
            </template>

        </ListView>

    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import AuthorizedUrl from '../../components/AuthorizedUrl.vue';
import InvoicesListTable from '../../components/Lists/InvoicesListTable.vue';

export default {
    props: ['patientId'],
    extends: BaseList,
    components: { AuthorizedUrl, InvoicesListTable },
    mounted() {
        this.loadTotals();
    },
    methods: {
        refreshTable() {
            this.$refs.tableList.refresh();
        },
        hideHeader: function () {
            return this.patientId ? true : false;
        },
        loadTotals() {
            return this.apiRequest(
                (apiClient, cancelTokenSource) => {
                    this.totalsCancelToken = cancelTokenSource;
                    return apiClient.get('invoices/totals', this.filter, cancelTokenSource)
                }, this.totalsCancelToken
            )
                .then(response => this.totals = response);
        }
    },
    data: function () {
        return {
            dateRange: {},
            totals: null,
        };
    },
    watch: {
        dateRange: function () {
            let startDate = this.dateRange.startDate ? this.date(this.dateRange.startDate) : null;
            let endDate = this.dateRange.endDate ? this.date(this.dateRange.endDate) : null;

            if (startDate == this.filter.claimed_start_date && endDate == this.filter.claimed_end_date) {
                return;
            }

            this.filter = {
                ... this.filter,
                claimed_start_date: startDate,
                claimed_end_date: endDate
            };
        },
        patientId: {
            immediate: true,
            handler: function () {
                if (this.patientId) {
                    this.viewType = 'patientView';
                    this.defaultFilter.patient_id = this.patientId;
                    this.filter.patient_id = this.patientId;
                }
            }
        },
        filter: {
            handler() {
                var filter = this.filter;

                this.dateRange = {
                    startDate: filter.claimed_start_date ? filter.claimed_start_date : null,
                    endDate: filter.claimed_end_date ? filter.claimed_end_date : null
                };
                this.loadTotals();
            },
            deep: true
        }
    },
}
</script>
