<template>
    <div>
        <base-list :collection='collection' :loading='loading' :fields='fields' :modalObject='currentModalObject'
            @pageChanged='pageChanged'>

            <template v-slot:cell(name)='data'>
                <div v-if='data.item.financial_operation'>
                    <div v-if='data.item.financial_operation.source_type == "PatientService"'>
                        <div class='badge badge-light' :title="data.item.financial_operation.source.name">
                            #{{ data.item.financial_operation.source.id }}
                            {{ data.item.financial_operation.source.name | str_limit(50) }}
                        </div>
                        <br>
                        <div class='badge badge-light'>
                            # {{ data.item.financial_operation.source.patient.id }}
                            {{ data.item.financial_operation.source.patient.name }}
                        </div>
                    </div>
                    <div v-else-if='data.item.financial_operation.source_type == "Purchase"' class='badge badge-light'>
                        Purchase
                        #{{ data.item.financial_operation.source.id }}
                        {{ data.item.financial_operation.source.created_at | date }}
                        <span v-if='data.item.financial_operation.source.supplier'>
                            -
                            #{{ data.item.financial_operation.source.supplier.id }}
                            {{ data.item.financial_operation.source.supplier.name }}
                        </span>
                    </div>
                    <div v-else-if='data.item.financial_operation.source_type == "CashPayment"' class='badge badge-light'>
                        Cash Payment
                        #{{ data.item.financial_operation.source.id }}
                        {{ data.item.financial_operation.source.created_at | date }}
                    </div>
                    <div v-else>
                        {{ data.item.name | str_limit(100) }}
                    </div>
                </div>
                <div v-else>
                    {{ data.item.name | str_limit(100) }}
                </div>
            </template>
            <template v-slot:cell(financial_account)='data'>
                <div>
                    <b>
                        {{ data.item.financial_account.name }}
                    </b>
                </div>
                <div v-if='data.item.financial_account.balance_type == "DEBIT"' class="badge badge-warning">
                    Debit Balance
                </div>
                <div v-if='data.item.financial_account.balance_type == "CREDIT"' class="badge badge-success">
                    Credit Balance
                </div>
            </template>
            <template v-slot:cell(credit)='data'>
                <div v-if='data.item.amount > 0'>
                    {{ parseFloat(data.item.amount) + 0 | decimal }}
                </div>
            </template>
            <template v-slot:cell(debit)='data'>
                <div v-if='data.item.amount < 0'>
                    {{ parseFloat(data.item.amount) * -1 | decimal }}
                </div>
            </template>
            <template v-slot:cell(operation)='data'>
                <div v-if='data.item.financial_operation'>
                    {{ data.item.financial_operation.id }}

                    <div v-if='data.item.financial_operation.status == "pending"' class='badge badge-warning'>
                        {{ data.item.financial_operation.status }}
                    </div>
                </div>

            </template>
            <template v-slot:cell(date)='data'>
                {{ data.item.financial_operation.date | date }}
            </template>
            <template v-slot:cell(created_at)='data'>
                {{ data.item.created_at | datetime }}
            </template>
            <template v-slot:cell(balance_after)='data'>
                {{ data.item.balance_after | decimal }}
            </template>
            <template v-slot:cell(actions)='data'>
                <div
                    v-if='data.item.financial_operation && !data.item.financial_operation.source && data.item.financial_operation.editable'>
                    <router-link
                        :to="{ name: 'EditFinancialOperationForm', params: { id: data.item.financial_operation_id } }"
                        class='btn btn-primary btn-xs sharp mr-2'>
                        <i class='fa fa-edit'></i>
                    </router-link>
                    <button class='btn btn-danger btn-xs sharp'
                        @click="deleteFinancialOperation(data.item.financial_operation_id)">
                        <i class='fa fa-remove'></i>
                    </button>
                </div>
            </template>
        </base-list>
    </div>
</template>

<script>
import BaseList from './BaseList.vue';
import BaseListMethods from './BaseListMethods';

export default {
    props: ['viewType'],
    components: { BaseList },
    extends: BaseListMethods,
    data() {
        return {
            fields: [
                { key: 'id', label: '#' },
                { key: 'date', label: 'Date' },
                { key: 'name', label: 'Name' },
                { key: 'branch.name', label: 'Branch' },
                { key: 'financial_account', label: 'Account' },
                { key: 'debit', label: 'Debit' },
                { key: 'credit', label: 'Credit' },
                { key: 'balance_after', label: 'Balance After' },
                { key: 'operation', label: 'Operation' },
                { key: 'actions', label: 'Actions' },
            ]
        }
    },
    methods: {
        load: function (apiClient, cancelToken, filter) {
            return apiClient.get('financial-transactions', filter, cancelToken);
        },
        deleteFinancialOperation(financialOperationId) {
            this.$bvModal.msgBoxConfirm('Are you sure this will deleted the entire financial operation?')
                .then(value => {
                    if (value) {
                        this.apiRequest(apiClient => apiClient.delete('financial-operations/' + financialOperationId))
                            .then(() => this.refresh());
                    }
                })
                .catch(err => {
                    // An error occurred
                    console.log(err);
                });
        },
    },
    watch: {
        viewType: {
            immediate: true,
            handler: function () {
                if (this.viewType == 'accountView') {
                    this.hideFields = ['financial_account'];
                }
            }
        },
        hideFields: {
            immediate: true,
            handler: function () {
                this.filterFields();
            }
        }
    }
}
</script>