<template>
    <div>
        <loader v-if='loading'/>
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>
            
            <b-form-group label="Invoice Date Range">
                <my-date-range-picker v-model='dateRange' :style='{display:"inline-block"}'/>
                <div class='btn btn-danger btn-xxs ml-2' @click='() => dateRange = {startDate: null, endDate: null}'>x</div>
            </b-form-group>
            
            <b-form-group label="Insurance Provider">
                <insurance-providers-select v-model='insuranceProviderId' :returnId='true'/>
            </b-form-group>

            <b-form-group label="Branch">
                <branches-select v-model='branchId' :returnId='true'/>
            </b-form-group>

            <b-form-group label="Payment">
                <b-form-input v-model='payment' type='number'/>
            </b-form-group>

            <b-row>
                <b-col>
                    <div v-if='collectionSummary'>
                        <h3>
                            Required From Insurance
                            <div class='badge badge-light'>
                            {{ collectionSummary.insurance_pay }}
                            </div>
                        </h3>
                        <h3>
                            Paid By Insurance
                            <div class='badge badge-light'>
                                {{ collectionSummary.paid_by_insurance }}
                            </div>
                        </h3>
                    </div>
                </b-col>
                <b-col>
                    <FinancialAccountsBalance :financialAccounts='financialAccounts'/>
                </b-col>
            </b-row>
            <div v-for='(patientService, index) in excludedPatientServices' :key='index'>
                <div class='badge badge-light'>
                {{ patientService.id }}
                -
                {{ patientService.name }}
                </div>
                <button class='btn btn-xxs btn-danger' @click='removeExcludedService(index)'>x</button>
                
            </div>
            <div class='m-2' v-if='branchId && insuranceProviderId'>
                <b-button variant='primary' @click='modal("patientServicesSelector", {multiple: true, baseFilter:{status: ["finished", "in progress"], invoice_date: true, ...filter, insurance_unpaid: true}}, "addPatientServices")'>Exclude Patient Services</b-button>
            </div>

            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving && branchId && insuranceProviderId' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
		<Modals :modalName='currentModal' :modalData='currentModalData' @saved='modalCallback' @closed='modalClosed'>
		</Modals>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm';
import InsuranceProvidersSelect from '../../components/InsuranceProvidersSelect.vue';
import BranchesSelect from '../../components/BranchesSelect.vue';

export default {
  components: { InsuranceProvidersSelect, BranchesSelect },
    extends: BaseForm,
    data: function () {
        return {
            dateRange: {start_date: null, end_date: null},
            summaryCancelToken: null,
            financialAccounts: [],
            insuranceProviderId: null,
            branchId: null,
            collectionSummary: null,
            excludedPatientServices: [],
            excludedInvoices: [],
            payment: null
        }
    },
    mounted: function() {
    },
    methods: {
        removeExcludedService(index) {
            this.excludedPatientServices.splice(index, 1);
        },
        loadSummary() {
            if (!this.branchId || !this.insuranceProviderId) {
                return;
            }
            this.apiRequest(
                (apiClient, cancelToken) => {
                    var startDate = this.dateRange.startDate ? this.date(this.dateRange.startDate) : null;
                    var endDate = this.dateRange.endDate ? this.date(this.dateRange.endDate) : null; 

                    var filter = {
                        insurance_provider_id: this.insuranceProviderId,
                        branch_id: this.branchId,
                        start_date: startDate,
                        end_date: endDate,
                    };
                    this.summaryCancelToken = cancelToken;
                    return apiClient.get('insurance-providers/insurance-collection-summary', filter, this.summaryCancelToken);
                }
            ).then(response => this.collectionSummary = response);
        },
        save: function (apiClient) {            
			var startDate = this.dateRange.startDate ? this.date(this.dateRange.startDate) : null;
			var endDate = this.dateRange.endDate ? this.date(this.dateRange.endDate) : null; 

            var data = {
                insurance_provider_id: this.insuranceProviderId,
                branch_id: this.branchId,
                start_date: startDate,
                end_date: endDate,
                payment: this.payment,
                exclude_patient_services_ids: this.excludedPatientServices.map(item => item.id),
                exclude_invoice_ids: this.excludedInvoices.map(item => item.id)
            };
            
            return apiClient.post('insurance-providers/settle-payments', data);
        },
        afterSave: function () {
            this.$emit('saved', 1);
        },
        loadAccounts() {
            this.financialAccounts = [];
            if (this.insuranceProviderId) {
                var filter = {
                    accountable_type: 'InsuranceProvider',
                    accountable_id: this.insuranceProviderId
                };
                this.apiRequest(apiClient => apiClient.get('financial-accounts', filter))
                    .then(response => this.financialAccounts = response.data);
            }
        },
        addPatientServices(patientService) {
            this.excludedPatientServices = [... this.excludedPatientServices,  patientService]
        }
    },
    watch: {
        branchId: function () {
            this.loadSummary();
        },
        insuranceProviderId: function () {
            this.loadSummary();
            this.loadAccounts();
        },
        dateRange: function () {
            this.loadSummary();
        }
    },
    computed: {
        filter: function () {
            var startDate = this.dateRange.startDate ? this.date(this.dateRange.startDate) : null;
            var endDate = this.dateRange.endDate ? this.date(this.dateRange.endDate) : null; 

            return {
                insurance_provider_id: this.insuranceProviderId,
                branch_id: this.branchId,
                start_date: startDate,
                end_date: endDate,
            };
        }
    }
}
</script>