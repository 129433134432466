<template>
    <div>
        <ListView :title="isExpenses ? 'Expenses List' : 'Purchase List'" :collection="collection"
            @pageChanged='pageChanged' :loading="loading" hasFilterActions='ture'>
            <template v-slot:filter>
                <div class='form-row'>
                    <b-form-group label="Status">
                        <b-form-radio-group v-model="filter.status"
                            :options="['all', 'approved', 'draft']"></b-form-radio-group>
                    </b-form-group>
                </div>
                <div class='form-row'>
                    <div class='col-3'>
                        <div class='form-group m-2'>
                            <label>Branch</label>
                            <BranchesSelect v-model='filter.branch_id' :returnId='true' />
                        </div>
                    </div>
                    <div class='col-3'>
                        <div class='form-group m-2'>
                            <label>Expense Type</label>
                            <expense-types-select v-model='filter.expense_type_id' :filter='{ expense_group: expenseGroup }'
                                :returnId='true' />
                        </div>
                    </div>

                    <div class='col-3'>
                        <b-form-group label="Date Range">
                            <my-date-range-picker v-model='dateRange' :style='{ display: "inline-block" }' />
                            <div class='btn btn-danger btn-xxs ml-2'
                                @click='() => dateRange = { startDate: null, endDate: null }'>x</div>
                        </b-form-group>
                    </div>
                </div>
                <b-row>
                    <b-col>
                        <b-form-checkbox v-model="filter.summary" value="1" unchecked-value="0" :inline='true'>
                            Summary
                        </b-form-checkbox>
                    </b-col>
                </b-row>

                <b-form-group label="Summary Options" v-if='filter.summary == 1'>
                    <b-form-checkbox v-model="filter.group_by" value="expense_type" unchecked-value="" :inline='true'>
                        Group By expense type
                    </b-form-checkbox>
                    <b-form-group label="Group By">
                        <b-form-radio-group v-model="filter.group_by_period"
                            :options="['day', 'month', 'year', 'none']"></b-form-radio-group>
                    </b-form-group>

                </b-form-group>
            </template>
            <template v-slot:actions>
                <b-button
                    @click="modal('purchaseForm', { isSimple: isExpenses, expenseGroup: expenseGroup }, 'refreshTable')"
                    variant='primary' class='mr-2'>
                    <i class="fa fa-plus"></i>
                    <span v-if='isExpenses && expenseGroup == "petty cash"'>New Petty Cash Expense</span>
                    <span v-else-if='isExpenses && expenseGroup == "general"'>New General Expense</span>
                    <span v-else>
                        New Purchase
                    </span>
                </b-button>
            </template>

            <template v-slot:filter_actions>
                <authorized-url v-if='can("expenses.export") || can("purchases.export")'
                    class='btn btn-primary m-2 float-right' url='purchases/export' :params='finalFilter' target="_blank">
                    <i class="fa fa-file-excel"></i>
                    Export
                </authorized-url>
                
                <authorized-url v-if="can('products.export')" class='btn btn-primary ml-2 float-right' url='products/export' target="_blank">
                    <i class="fa fa-file-excel"></i>
                    Export Products List
                </authorized-url>
            </template>
            <template v-slot:table>
                <div class='text-right m-2' v-if='can("accounts.list") && totals'>
                    <h3>
                        Total
                        {{ totals.total | currency }}
                    </h3>
                </div>
                <purchase-list-table v-if='filter.summary != 1' ref='tableList' :isExpenses='isExpenses'
                    :expenseGroup='expenseGroup' :filter='filter' />
                <purchase-list-summary-table v-else :isExpenses='isExpenses' ref='tableList' :expenseGroup='expenseGroup'
                    :filter='filter' />
            </template>
        </ListView>

        <Modals :modalName='currentModal' :modalData='currentModalData' @saved='modalCallback' @closed='modalClosed'>
        </Modals>
    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import ExpenseTypesSelect from '../../components/ExpenseTypesSelect.vue';
import PurchaseListTable from '../../components/Lists/PurchaseListTable.vue';
import PurchaseListSummaryTable from '../../components/Lists/PurchaseListSummaryTable.vue';
import AuthorizedUrl from '../../components/AuthorizedUrl.vue';

export default {
    components: { ExpenseTypesSelect, PurchaseListTable, PurchaseListSummaryTable, AuthorizedUrl },
    extends: BaseList,
    props: ['isExpenses', 'expenseGroup'],
    data: function () {
        return {
            dateRange: {},
            totals: null
        };
    },
    methods: {
        refreshTable() {
            this.$refs.tableList.refresh();
        },
        loadTotals() {
            return this.apiRequest(
                (apiClient, cancelTokenSource) => {
                    this.totalsCancelToken = cancelTokenSource;
                    var f = { expenses: this.isExpenses ? 1 : 0, ...this.filter, expense_group: this.isExpenses ? this.expenseGroup : null };
                    return apiClient.get('purchases/totals', f, cancelTokenSource)
                }, this.totalsCancelToken
            )
                .then(response => this.totals = response);
        }
    },
    computed: {
        finalFilter: function () {
            return { expenses: this.isExpenses ? 1 : 0, ...this.filter, expense_group: this.isExpenses ? this.expenseGroup : null };
        }
    },
    watch: {
        dateRange: function () {
            var startDate = this.dateRange.startDate ? this.date(this.dateRange.startDate) : null;
            var endDate = this.dateRange.endDate ? this.date(this.dateRange.endDate) : null;


            if (startDate == this.filter.start_date && endDate == this.filter.end_date) {
                return;
            }

            this.filter = {
                ... this.filter,
                start_date: startDate,
                end_date: endDate
            };
        },
        filter: {
            handler() {
                var filter = this.filter;

                this.dateRange = {
                    startDate: filter.start_date ? filter.start_date : null,
                    endDate: filter.end_date ? filter.end_date : null
                };

                this.loadTotals();
            },
            deep: true
        }
    }
}
</script>