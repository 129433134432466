<template>
    <div>
        <loader v-if='loading' />
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>
            <h3 class="mb-4">Service <b-badge variant="primary">{{ service.name }}</b-badge></h3>
            <div class="row mb-4">
                <b-form-group class="col-md-6" label="Search">
                    <b-form-input v-model="search" type="text" placeholder="Search...">
                    </b-form-input>
                </b-form-group>
                <div class="col-md-6">
                    <h2 class="text-primary">
                        Total Cost : {{ totalCost | currency }}
                    </h2>
                </div>
                <b-form-checkbox class="col-md-6" v-model="showUsedOnly" :value="1" :unchecked-value="0" size="lg">
                    Show Used Products Only
                </b-form-checkbox>
            </div>


            <table class="table table-bordered">
                <thead class="thead-dark">
                    <tr>
                        <th>Material</th>
                        <th>Amount</th>
                        <th>Stock Price</th>
                        <th>Cost</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(product, index) in filteredProduts" :key='index'>
                        <td>{{ product.name }}</td>
                        <td>
                            <b-form-input v-model="product.amount" type="text" placeholder="">
                            </b-form-input>
                        </td>
                        <td>{{ product.stock_price | currency }}</td>
                        <td>{{ ((product.amount ? product.amount : 0) * product.stock_price) | currency }}</td>
                    </tr>
                </tbody>
            </table>
            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
export default {
    extends: BaseForm,
    data: function () {
        return {
            search: '',
            showUsedOnly: '1',
            service: {
                require_external_service: false,
                service_products: [{}],
                branch_id: 1
            },
            products: [],
            branch: 'unset'
        }
    },
    created() {
        this.apiRequest(apiClient => apiClient.get('services/' + this.id + '/products'))
            .then(response => {
                this.products = response;
            });
    },
    computed: {
        totalCost() {
            if (this.products.length > 0) {
                return this.products.reduce((accumulator, product) => {

                    return accumulator + ((product.amount ?? 0) * product.stock_price);
                }, 0);
            }
            return 0;
        },
        usedProducts() {
            return this.products.filter((product) => (product.amount > 0));
        },
        filteredProduts() {
            return this.products.filter((product) => {
                let condition = true;
                if (this.showUsedOnly) {
                    condition = product.amount > 0;
                }
                if (this.search) {
                    condition = condition && product.name.toLowerCase().includes(this.search.toLowerCase());
                }
                return condition;
            });
        },
    },
    methods: {
        load: function (id) {
            return this.apiRequest(apiClient => apiClient.get('services/' + id))
                .then(response => this.service = response);
        },
        save: function (apiClient) {
            var service = { ...this.service };

            service.service_products = this.usedProducts.map(product => {
                return {
                    product_id: product.id,
                    amount: product.amount,
                }
            });
            return apiClient.put('services/' + this.id, service);
        },
        afterSave: function () {
            this.$emit('saved', 1);
        },
        addProductItem() {
            if (!this.service.service_products) {
                this.service.service_products = [];
            }
            this.service.service_products.push({});
        },
        deleteProductItem(index) {
            this.service.service_products.splice(index, 1);
        }
    },
}
</script>