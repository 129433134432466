<template>
    <div>
        <base-list :collection='collection' :loading='loading' :fields='fields' :modalObject='currentModalObject' @pageChanged='pageChanged'>
            
			<template v-slot:cell(actions)='data'>
				<button class='btn btn-xs btn-primary m-2' @click="modal('workerForm', {id: data.item.id}, 'refresh')">Edit</button>
            </template>

            <template v-slot:cell(working_hours)='data'>
                <div v-for='(workingHour, index) in data.item.working_hours' :key='index'>
                    <h4>
                    <div class='badge badge-light'>
                        {{ workingHour.branch.name }}
                    </div>
                    </h4>
                    <button class='badge badge-xs badge-primary mr-2' @click='editWorkingHour(workingHour.id)'>
                        {{ workingHour.from }}
                        -
                        {{ workingHour.to }}
                    </button>
                    <button  v-if='can("working_days.edit")' class='btn btn-xxs btn-success mr-2' @click='editWorkingHour(workingHour.id)'>
                        <i class='fa fa-edit'></i>
                    </button>
                    <button v-if='can("working_days.delete")' class='btn btn-xxs btn-danger' @click='removeWorkingHour(workingHour.id)'>x</button>
                    <div>
                        <div class='badge badge-primary badge-xs m-1' v-for='(day, index) in workingHour.week_days' :key='index'>
                            {{ weekDays[day - 1] }}
                        </div>
                    </div>
                    <hr>
                </div>
                <button  v-if='can("working_days.edit")' class='btn btn-xxs btn-success' @click='addWorkingHour(data.item.id)'>+</button>
            </template>
                    
			<template v-slot:cell(is_active)='data' class='badge badge-success'>
                <div v-if='data.item.is_active' class='badge badge-success'>
                    active
                </div>
                <div v-else class='badge badge-light'>
                    inactive
                </div>
            </template>
        </base-list>

        <b-modal :no-close-on-backdrop=true id="working-hour-modal" hide-footer title="Working Hour Form">
            <working-hour-form @saved='workingHourSaved'  :workerId='selectedWorkerId' :id='selectedWorkingHourId'></working-hour-form>        
        </b-modal>
    </div>
</template>

<script>
import WorkingHourForm from '../../views/Doctors/WorkingHourForm.vue';
import BaseList from './BaseList.vue';
import BaseListMethods from './BaseListMethods';

export default {
	props: ['viewType'],
    components: { BaseList, WorkingHourForm },
    extends: BaseListMethods,
    data() {
        return {
            selectedWorkerId: null,
            selectedWorkingHourId: null,
			fields: [
                { key: 'id', label: 'ID' },
                { key: 'name', label: 'Name' },
                { key: 'salary', label: 'Salary' },
                { key: 'working_hours', label: 'Working Hours' },
                { key: 'is_active', label: 'Status' },
                { key: 'actions', label: 'Actions' },
			],
            weekDays: [
                'Saturday',
                'Sunday',
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thuresday',
                'Friday',
            ]
        }
    },
	methods: {
        load: function (apiClient, cancelToken, filter) {
			return apiClient.get('workers', filter, cancelToken);
		},
        workingHourSaved() {
            this.refresh();
            this.$bvModal.hide('working-hour-modal');
        },
        editWorkingHour(id) {
            if (!this.can("working_days.edit")) {
                return;
            }
            this.selectedWorkingHourId = id;
            this.$bvModal.show('working-hour-modal');
        },
        addWorkingHour(workerId) {
            this.selectedWorkerId = workerId;
            this.selectedWorkingHourId = null;
            this.$bvModal.show('working-hour-modal');
        },
        removeWorkingHour(id) {
            this.apiRequest(apiClient => apiClient.delete('working-hours/'+id))
            .then(() => this.refresh());
        }
    },
    watch: {

		// viewType: {
		// 	immediate: true,
		// 	handler: function () {
		// 	}
		// },
    }
}
</script>