<template>
    <div>
        <ListView title="Patients Services" :hideHeader='(patientId || doctorId) ? true : false'
            :modalObject='currentModalObject' hasFilterActions='ture'>
            <template v-slot:actions>
                <b-button v-if='can("accounts.list") && false' @click="modal('insuranceCollectionForm', {}, 'refreshTable')"
                    variant='primary' class='mr-2'>
                    Collect Insurance
                </b-button>
            </template>

            <template v-slot:filter>
                <div class='form'>
                    <div class='form-row'>
                        <div v-if='!patientId' class='form-group col-md-4'>
                            <b-form-input v-model="filter.keyword" placeholder="#ID - name - phone"></b-form-input>
                        </div>
                    </div>
                </div>
                <div class="'form">
                    <b-row>
                        <b-col>
                            <b-form-group label="Invoice Date Range">
                                <my-date-range-picker v-model='dateRange' :style='{ display: "inline-block" }' />
                                <div class='btn btn-danger btn-xxs ml-2'
                                    @click='() => dateRange = { startDate: null, endDate: null }'>x</div>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group label="Claimed At">
                                <my-date-range-picker v-model='claimDateRange' :style='{ display: "inline-block" }' />
                                <div class='btn btn-danger btn-xxs ml-2'
                                    @click='() => claimDateRange = { startDate: null, endDate: null }'>x</div>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group label="Invoice Created At">
                                <my-date-range-picker v-model='invoiceDateRange' :style='{ display: "inline-block" }' />
                                <div class='btn btn-danger btn-xxs ml-2'
                                    @click='() => invoiceDateRange = { startDate: null, endDate: null }'>x</div>
                            </b-form-group>
                        </b-col>
                    </b-row>

                </div>
                <div class='form'>
                    <div class='form-row'>
                        <b-col>
                            <b-form-group label="Claim Status">
                                <b-form-radio-group v-model="filter.claim_status"
                                    :options="[{ text: 'All', value: '' }, { text: 'Claimed', value: 1 }, { text: 'Not Claimed', value: 0 }]">
                                </b-form-radio-group>
                            </b-form-group>
                        </b-col>
                    </div>
                    <div class='form-row'>
                        <b-col>
                            <b-form-group label="Status">
                                <v-select v-model='filter.status'
                                    :options="['pending', 'in progress', 'finished', 'virtual insurance']"
                                    :multiple='true' />

                            </b-form-group>
                        </b-col>
                    </div>

                    <div class='form-row'>
                        <b-col cols='3'>
                            <b-form-group label="Payment Method">
                                <v-select :options="paymentMethods" v-model='filter.payment_method'></v-select>
                            </b-form-group>
                        </b-col>
                    </div>
                    <div class='form-row'>
                        <b-col>
                            <b-form-group v-if='!patientId' label="Patient">
                                <patients-select v-model='filter.patient_id' returnId='true' />
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group v-if='!userBranch()' label="Branch">
                                <branches-select v-model='filter.branch_id' returnId='true' />
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group label="Section">
                                <sections-select v-model='filter.section_id' returnId='true' />
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group label="Doctor">
                                <doctors-select v-model='filter.doctor_id' :section_id='filter.section_id'
                                    returnId='true' />
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group label="Service">
                                <services-select v-model='filter.service_id' :section_id='filter.section_id'
                                    returnId='true' />
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group label="Insurance">
                                <insurance-providers-select v-model='filter.insurance_provider_id' returnId='true' />
                            </b-form-group>
                        </b-col>
                    </div>

                    <b-form-group label="Other">
                        <b-form-checkbox v-model="filter.insured" value="1" unchecked-value="0" :inline='true'>
                            Insured
                        </b-form-checkbox>
                        <b-form-checkbox v-model="filter.not_insured" value="1" unchecked-value="0" :inline='true'>
                            Not Insured
                        </b-form-checkbox>

                        <b-form-checkbox v-model="filter.patient_paid" value="1" unchecked-value="0" :inline='true'>
                            Paid By Patient
                        </b-form-checkbox>

                        <b-form-checkbox v-model="filter.insurance_paid" value="1" unchecked-value="0" :inline='true'>
                            Paid By Insurance
                        </b-form-checkbox>

                        <b-form-checkbox v-model="filter.patient_unpaid" value="1" unchecked-value="0" :inline='true'>
                            Not Paid By Patient
                        </b-form-checkbox>

                        <b-form-checkbox v-model="filter.insurance_unpaid" value="1" unchecked-value="0" :inline='true'>
                            Not Paid By Insurance
                        </b-form-checkbox>


                        <b-form-checkbox v-model="filter.invalid_prices" value="1" unchecked-value="0" :inline='true'>
                            Invalid Prices
                        </b-form-checkbox>

                        <b-form-checkbox v-model="filter.invoiced" value="1" unchecked-value="0" :inline='true'>
                            Invoiced
                        </b-form-checkbox>


                        <b-form-checkbox v-model="filter.not_invoiced" value="1" unchecked-value="0" :inline='true'>
                            Not Invoiced
                        </b-form-checkbox>

                        <b-form-checkbox v-model="filter.has_approval" value="1" unchecked-value="0" :inline='true'>
                            Has Approval
                        </b-form-checkbox>

                        <b-form-checkbox v-model="filter.has_no_approval" value="1" unchecked-value="0" :inline='true'>
                            Has No Approval
                        </b-form-checkbox>
                    </b-form-group>
                </div>

                <div>
                    <b-form-group label="Group By">
                        <v-select v-model='filter.group_by' :options='groupByOptions' :multiple='true' />
                    </b-form-group>
                </div>
                <div>
                    <b-form-group label="Order By" class="ml-2">
                        <b-form-radio-group v-model="filter.order_by"
                            :options="['Creation Time', 'Appointment Time', 'Invoice Date']" name="radios-btn-default"
                            buttons button-variant="primary"></b-form-radio-group>
                    </b-form-group>
                </div>
            </template>

            <template v-slot:filter_actions>
                <authorized-url v-if='can("patient_services.export")' class='btn btn-primary ml-2 float-right'
                    url='patient-services/export' :params='filter' target="_blank">
                    <i class="fa fa-file-excel"></i>
                    Export
                </authorized-url>
            </template>

            <template v-slot:table>
                <div v-if='can("accounts.list") && totals'>
                    <h3>
                        <div class='badge badge-light m-2'>
                            Number Of Services
                            {{ totals.number_of_services }}
                        </div>
                        <div class='badge badge-light m-2'>
                            Total Price:
                            {{ totals.total_price | decimal }}
                        </div>
                        <div class='badge badge-light m-2'>
                            Patient Should Pay:
                            {{ totals.total_patient_price | decimal }}
                        </div>
                        <div class='badge badge-light m-2'>
                            Insurance Should Pay:
                            {{ totals.total_insurance_price | decimal }}
                        </div>
                        <div class='badge badge-light m-2'>
                            Doctor Fees:
                            {{ totals.total_doctor_fees | decimal }}
                        </div>

                        <div class='badge badge-light m-2'>
                            Fully Paid By Patient:
                            {{ totals.fully_paid_by_patient | decimal }}
                        </div>
                        <div class='badge badge-light m-2'>
                            Partially Paid By Patient:
                            {{ totals.partially_paid_by_patient | decimal }}
                        </div>
                        <div class='badge badge-light m-2'>
                            Unpaid By Patient:
                            {{ totals.unpaid_by_patient | decimal }}
                        </div>


                        <div class='badge badge-light m-2'>
                            Paid By Patient:
                            {{ totals.paid_by_patient | decimal }}
                        </div>
                        <div class='badge badge-light m-2'>
                            Paid By Insurance:
                            {{ totals.paid_by_insurance | decimal }}
                        </div>
                    </h3>
                </div>
                <div class='profile-tab'>
                    <div class='custom-tab-1'>
                        <b-tabs content-class="mt-3">
                            <b-tab title="List" lazy>
                                <patient-services-list-table :filter='filter'
                                    :viewType='viewType'></patient-services-list-table>
                            </b-tab>
                            <b-tab title="Summary" lazy v-if='can("accounts.list")'>
                                <patient-services-summary-table :filter='filter'></patient-services-summary-table>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
            </template>
        </ListView>
    </div>
</template>

<script>
import BaseList from '@/views/BaseList.vue';
import PatientServicesListTable from '../../components/Lists/PatientServicesListTable.vue';
import PatientServicesSummaryTable from '../../components/Lists/PatientServicesSummaryTable.vue';
import AuthorizedUrl from '../../components/AuthorizedUrl.vue';

export default {
    extends: BaseList,
    props: ['patientId', 'doctorId'],
    components: { PatientServicesListTable, PatientServicesSummaryTable, AuthorizedUrl },
    mounted() {
        this.loadTotals();
    },
    methods: {
        filterFields() {
            this.fields = this.fields.filter(item => this.hideFields.indexOf(item.key) > -1 ? false : true);
        },
        loadTotals() {
            return this.apiRequest(
                (apiClient, cancelTokenSource) => {
                    this.totalsCancelToken = cancelTokenSource;
                    return apiClient.get('patient-services/totals', this.filter, cancelTokenSource)
                }, this.totalsCancelToken
            )
                .then(response => this.totals = response);
        }
    },
    watch: {
        dateRange: function () {
            var startDate = this.dateRange.startDate ? this.date(this.dateRange.startDate) : null;
            var endDate = this.dateRange.endDate ? this.date(this.dateRange.endDate) : null;


            if (startDate == this.filter.start_date && endDate == this.filter.end_date) {
                return;
            }

            this.filter = {
                ... this.filter,
                start_date: startDate,
                end_date: endDate
            };
        },
        invoiceDateRange: function () {
            var startDate = this.invoiceDateRange.startDate ? this.date(this.invoiceDateRange.startDate) : null;
            var endDate = this.invoiceDateRange.endDate ? this.date(this.invoiceDateRange.endDate) : null;


            if (startDate == this.filter.invoice_start_date && endDate == this.filter.invoice_end_date) {
                return;
            }

            this.filter = {
                ... this.filter,
                invoice_start_date: startDate,
                invoice_end_date: endDate
            };
        },
        claimDateRange: function () {
            var startDate = this.claimDateRange.startDate ? this.date(this.claimDateRange.startDate) : null;
            var endDate = this.claimDateRange.endDate ? this.date(this.claimDateRange.endDate) : null;


            if (startDate == this.filter.claim_start_date && endDate == this.filter.claim_end_date) {
                return;
            }

            this.filter = {
                ... this.filter,
                claim_start_date: startDate,
                claim_end_date: endDate
            };
        },
        patientId: {
            immediate: true,
            handler: function () {
                if (this.patientId) {
                    this.viewType = 'patientView';
                    this.defaultFilter.patient_id = this.patientId;
                    this.filter.patient_id = this.patientId;
                }
            }
        },
        doctorId: {
            immediate: true,
            handler: function () {
                if (this.doctorId) {
                    this.viewType = 'doctorView';
                    this.defaultFilter.doctor_id = this.doctorId;
                    this.filter.doctor_id = this.doctorId;
                }
            }
        },
        filter: {
            handler() {
                var filter = this.filter;

                this.dateRange = {
                    startDate: filter.start_date ? filter.start_date : null,
                    endDate: filter.end_date ? filter.end_date : null
                };

                this.invoiceDateRange = {
                    startDate: filter.invoice_start_date ? filter.invoice_start_date : null,
                    endDate: filter.invoice_end_date ? filter.invoice_end_date : null
                };

                this.claimDateRange = {
                    startDate: filter.claim_start_date ? filter.claim_start_date : null,
                    endDate: filter.claim_end_date ? filter.claim_end_date : null
                };
                this.loadTotals();
            },
            deep: true
        }
    },
    data: function () {
        return {
            paymentMethods: ['visa', 'cash', 'valu', 'online', 'sympl'],
            groupByOptions: ['service', 'doctor'],
            dateRange: {},
            claimDateRange: {},
            invoiceDateRange: {},
            hideFields: [],
            selectedPatientServiceId: null,
            totals: {},
            totalsCancelToken: null,
            defaultFilter: {
                order_by: "Appointment Time"
            },
            fields: [
                { key: 'id', label: 'ID' },
                { key: 'name', label: 'Service' },
                { key: 'patient', label: 'Patient' },
                { key: 'appointments', label: 'Appointments' },
                { key: 'price', label: 'Patient Price' },
                { key: 'status', label: 'Status' },
                { key: 'insurance', label: 'Insurance' },
                { key: 'actions', label: 'Actions' },
            ]
        };
    }
}
</script>