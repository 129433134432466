<template>
    <div>
        <b-form class='basic-form' @submit="submit" novalidate>
            <b-form-group label="Branch">
                <branches-select v-model='branch'/>
            </b-form-group>
            <b-form-group label="Date">
                <v-date-picker v-model="salaryDate">
                    <template v-slot="{ inputValue, inputEvents }">
                        <input
                        class="bg-white border px-2 py-1 rounded"
                        :value="inputValue"
                        v-on="inputEvents"
                        />
                    </template>
                </v-date-picker>
                
            </b-form-group>

            <b-form-group
                label="Workers"
            >
                <div 
                    v-for='(worker, index) in workers'
                        :key='index'
                >
                    <b-row>
                        <b-col cols='5'>
                            <b-form-checkbox
                                v-model="workerIds"
                                name="workers"
                                :value="worker.id"
                                unchecked-value="false"
                                size="lg"
                            >
                            {{ worker.name }}
                            </b-form-checkbox>
                        </b-col>
                        <b-col
                            cols='3'
                        >
                            <div class='badge badge-green'>{{ worker.salary }}</div> 
                        </b-col>
                    </b-row>
                </div>
            </b-form-group>
            
            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
    </div>
</template>
<script>
import BaseForm from '@/views/BaseForm'
import moment from 'moment';

export default {
    extends: BaseForm,
    data() {
        return {
            workers: [],
            workerIds: [],
            branch: {},
            salaryDate: moment().format('YYYY-MM-DD')
        }
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.apiRequest(
                apiClient => apiClient.get('workers', {not_payed: 1, date: this.salaryDate, branch_id:this.branch.id})
            ).then(response => this.workers = response.data);
        },
        save: function (apiClient) {            
            return apiClient.post('worker-payables/salaries', {worker_ids: this.workerIds, date: this.date(this.salaryDate), branch_id:this.branch.id});
        },
        afterSave: function (patientInsurance) {
            this.$emit('saved', patientInsurance);
        },
    },
    watch: {
        salaryDate: function () {
            this.refresh();
        },
        branch: function () {
            this.refresh();
        }
    }
}
</script>