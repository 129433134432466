<template>

    <SimpleListView title="Patients Services List" :collection="collection" :fields="fields" :loading="loading" @pageChanged='pageChanged'>
        
        <template v-slot:filter>
            <div class='form'>
                <div class='form-row'>
                    <div  class='form-group col-md-4'>
                    </div>
                </div>
            </div>
        </template>
        
        <template v-slot:cell(date)='data'>
            {{ data.item.date | date }}
        </template>
        <template v-slot:cell(branch)='data'>
            {{ data.item.branch.name }}
        </template>
        <template v-slot:cell(amount)='data'>
            <div v-if='!["cash in", "cash out"].includes(data.item.type)'>
                <div class='badge' :class='data.item.amount == data.item.amount_paid ? "badge-success" : "badge-light"'>
                    {{ data.item.amount_paid | currency }}
                    /
                    {{ data.item.amount | currency }}
                </div>
            </div>
            <div v-else>
                <div class='badge badge-light'>
                    {{ data.item.amount | currency }}
                </div>
            </div>
        </template>
        <template v-slot:cell(title)='data'>
            {{ data.item.title }}
            <br>
            <div class='badge badge-light m-2'>
            # {{ data.item.transactionable_id }}
            {{ data.item.transactionable_type }}
            </div>
            <br>
            <div v-if='can("accounts.list")'>

                <div class='badge badge-light m-2'>
                    customer: {{ data.item.collected_from_customer }} / {{ data.item.required_from_customer }}
                </div>
                <div class='badge badge-light m-2'>
                    {{ 100 * parseFloat(data.item.collected_from_customer) / parseFloat(data.item.required_from_customer) }} %
                </div>
                <br>
                <div class='badge badge-light m-2'>
                    supplier: {{ data.item.paid_to_supplier }} / {{ data.item.required_to_supplier }}
                </div>
                <div class='badge badge-light m-2'>
                    {{ 100 * parseFloat(data.item.paid_to_supplier) / parseFloat(data.item.required_to_supplier) }} %
                </div>
            </div>

        </template>

        <template v-slot:cell(actions)='data'>
            <button class='btn btn-primary' @click="$emit('clickedItem', data.item)">Select</button>
        </template>
                
    </SimpleListView>
</template>

<script>
import BaseList from '@/views/BaseList';

export default {
    extends: BaseList,
    props: ['baseFilter'],
    methods: {
        load: function (apiClient, cancelToken) {
            return apiClient.get('transactions', {... this.filter, ...this.baseFilter}, cancelToken);
        }
    },
    data: function() {
        return {
            fields: [
                { key: 'id', label: '#' },
                { key: 'title', label: 'Title' },
                { key: 'status', label: 'Status' },
                { key: 'date', label: 'Date' },
                { key: 'branch', label: 'Branch' },
                { key: 'amount', label: 'Amount' },
                
                { key: 'actions', label: '' }
            ]
        };
    }
}
</script>

