<template>
    <div>

        <ListView title="PatientInsurances List" :hideHeader='hideHeader() ? true : false'>      
            
			<template v-slot:table>
				<patient-insurances-list-table :filter='filter' :viewType='viewType'></patient-insurances-list-table>
			</template>      
        </ListView>
    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import PatientInsurancesListTable from '@/components/Lists/PatientInsurancesListTable';

export default {
    extends: BaseList,
    props: ['patientId'],
	methods: {
		hideHeader: function () {
			return this.patientId ? true : false;
		}
	},
    components: {PatientInsurancesListTable},
    watch: {
		patientId: {
			immediate: true,
			handler: function () {
				if (this.patientId) {
					this.viewType = 'patientView';
					this.filter.patient_id = this.patientId;
				}
			}
		},
    }
}
</script>
