<template>
    <div class='card'>
        <div class='card-header'>
            <h5 class="card-title">Treatment Plans</h5>
            <router-link :to="{name: 'TreatmentPlanForm'}" class="btn btn-primary btn-sm">
                <i class="fa fa-plus"></i>
                New Treatment Plan
            </router-link>
        </div>
        <div class='card-body'>
            <div class="table-responsive recentOrderTable">
                <b-table hover :items="items" :fields="fields">

					<template v-slot:cell(services)="data">
						<div v-for='service in data.item.services' :key='service' class='badge badge-success badge-lg m-2'>
							<i class='fa fa-stethoscope'></i>
							{{ service }}
						</div>
					</template>
                    <template v-slot:cell(actions)>
                        <button class='btn btn-primary btn-xs mr-2'>
                            Send
                        </button>
                        <button class='btn btn-primary btn-xs mr-2'>
                            Set Insurance Pay
                        </button>
                        <button class='btn btn-primary btn-xs sharp mr-2'>
                            <i class='fa fa-edit'></i>
                        </button>
                        <button class='btn btn-danger btn-xs sharp'>
                            <i class='fa fa-remove'></i>
                        </button>
                    </template>
                </b-table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: function() {
        return {
            items: [
                {
                    date: "2020-10-05",
                    patient: "Mahmoud Magdy",
                    services: ['checkup', 'crown'],
                    total: '4,000.00',
                    insurance: "Egypt Insurance",
                    status: "sent"
                },
                {
                    date: "2020-10-06",
                    patient: "Omar Osama",
                    services: ['checkup', 'crown'],
                    total: '4,000.00',
                    insurance: "Egypt Insurance",
                    status: "approved"
                },
            ],
            fields: [
                { key: 'id', label: '#' },
                { key: 'date', label: 'Date' },
                { key: 'patient', label: 'Patient' },
                { key: 'services', label: 'Services' },
                { key: 'total', label: 'Total' },
                { key: 'insurance', label: 'Insurance' },
                { key: 'status', label: 'Status' },
                { key: 'actions', label: 'Actions' },
            ]
        };
    }
}
</script>