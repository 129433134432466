<template>
    <div>

        <ListView title="Users List" :collection="collection" :fields="fields" @pageChanged='pageChanged' :loading="loading">
            <template v-slot:actions>
                <b-button  v-if='can("users.create")'  v-b-modal.user-modal @click="selectedUserId = null" variant='primary' class='mr-2'>
                    Create User
                </b-button>

            </template>

            <template v-slot:filter>
                <div class='form'>
                    <div class='form-row'>
                        <div class='form-group col-md-4'>
                            <b-form-input v-model="filter.keyword" placeholder="search ..."></b-form-input>
                        </div>
                    </div>
                    <div class='form-row'>
                        <b-form-group label="Status">
                            <b-form-radio-group
                                v-model="filter.status"
                                :options="['active', 'inactive', 'all']"
                            ></b-form-radio-group>
                        </b-form-group>
                    </div>
                </div>
            </template>

            <template v-slot:cell(branches)='data'>
                <div v-for='(branch, index) in data.item.branches' :key='index' class='badge badge-light'>
                    {{ branch.name }}
                </div>
            </template>
                    
            <template v-slot:cell(actions)='data'>
                <b-button  v-if='can("users.edit")' v-b-modal.user-modal class='mr-2' @click="selectedUserId = data.item.id">Edit</b-button>
            </template>
                    
        </ListView>
        
        <b-modal :no-close-on-backdrop=true id="user-modal" hide-footer  title="User Form">
            <UserForm @saved='userSaved' :id='selectedUserId'></UserForm>        
        </b-modal>
    </div>
</template>

<script>
import BaseList from '@/views/BaseList';
import UserForm from './UserForm';

export default {
    extends: BaseList,
    components: {UserForm},
    methods: {
        load: function (apiClient, cancelToken) {
            return apiClient.get('users', this.filter, cancelToken);
        },
        userSaved() {
            this.refresh();
            this.$bvModal.hide('user-modal');
        }
    },
    mounted() {
        this.filter.status = 'active';
    },
    data: function() {
        return {
            fields: [
                { key: 'id', label: '#' },
                { key: 'username', label: 'User Name' },
                { key: 'name', label: 'Name' },
                { key: 'role.name', label: 'Role' },
                { key: 'doctor.name', label: 'Doctor' },
                { key: 'branches', label: 'Branches' },
                { key: 'actions', label: '' }
            ],
            selectedUserId: null
        };
    }
}
</script>
